import React from 'react';
import './styles/foundation.css';
import './styles/custom.css';
import './styles/main.css.map';
import './styles/main.css';
import './styles/main_new.css';
import './styles/main.scss';

//import Scss
//import './Assets/scss/themes.scss';



// import CreateProject from './Admin/CreateProject';
// import AdminDashboard from './Admin/AdminDashboard';
// import EditProject from './Admin/EditProject';

// import FormManagement from './IshwarCode/FormManagement';

// import FormManagementMy from './Admin/FormManagement';
// import forme from './IshwarCode/forme'

// import './App.scss';
import Routes from './Routes'

function App() {
  return (
    // <div className="App">
    //       Cetas
    // </div>
     <Routes />

    // <forme/>
    // <FormManagementMy/>
    // <FormManagement/>
    // <EditProject/>
    // <CreateProject />
    // <AdminDashboard />
  );
}
export default App;

// import React, {Component} from "react";

// class App extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { 
//        file: '',
//         imagePreviewUrl: '',
//      };
//   }

//   getPhoto = event =>{
//      event.preventDefault();

//       let reader = new FileReader();
//       let file = event.target.files[0];
//             // this.setState({file: e.target.files[0],})
//       console.log("file my",this.state.file);
//       reader.onloadend = () => {
//         this.setState({
//           file:file,
//           imagePreviewUrl: reader.result
//         });
//       }
//       reader.readAsDataURL(file);
            
//   }


//   render() {
//     let {imagePreviewUrl} = this.state;
//             let imagePreview = null;
//             if (imagePreviewUrl) {
//               imagePreview = (<img style={{height:'10%', width:'10%'}} src={imagePreviewUrl} />);
//             } else {
//               imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
//             }
//     return (
//        <div>
//               <form action='.' enctype="multipart/form-data">
//                 <input type='file'  onChange={this.getPhoto}/>
//                 {/* <button onClick={this.pressButton}> Get it </button> */}
//               </form>
//               <div>
//                 {imagePreview}
//               </div>
//               </div>
      
//     );
//   }
// }

// export default App;


// import React from "react";
// import ReactDOM from "react-dom";

// // import "./styles.css";

// export default function App() {
//   const uploadedImage = React.useRef(null);
//   const imageUploader = React.useRef(null);

//   const handleImageUpload = e => {
//     const [file] = e.target.files;
//     if (file) {
//       const reader = new FileReader();
//       const { current } = uploadedImage;
//       current.file = file;
//       reader.onload = e => {
//         current.src = e.target.result;
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "center"
//       }}
//     >
//       <input
//         type="file"
//         accept="image/*"
//         onChange={handleImageUpload}
//         ref={imageUploader}
//         style={{
//           display: "none"
//         }}
//       />
//       <div
//         style={{
//           height: "60px",
//           width: "60px",
//           border: "1px dashed black"
//         }}
//         onClick={() => imageUploader.current.click()}
//       >
//         <img
//           ref={uploadedImage}
//           style={{
//             width: "100%",
//             height: "100%",
//             position: "acsolute"
//           }}
//         />
//       </div>
//       Click to upload Image
//     </div>
//   );
// }

// import React, {Component} from 'react';
// import SweetAlert from 'react-bootstrap-sweetalert';

// export default class App extends Component {

//   constructor(props) {
//     super(props);

//     this.state = {
//       alert: null
//     };
//   } 

//   deleteThisGoal() {
//     const getAlert = () => (
//       <SweetAlert 
//         success 
//         title="Woot!" 
//         onConfirm={() => this.hideAlert()}
//       >
//         Hello world!
//       </SweetAlert>
//     );

//     this.setState({
//       alert: getAlert()
//     });
//   }

//   hideAlert() {
//     console.log('Hiding alert...');
//     this.setState({
//       alert: null
//     });
//   }

//   render() {
//     return (
//       <div style={{ padding: '20px' }}>
//           <a 
//             onClick={() => this.deleteThisGoal()}
//             className='btn btn-danger'
//           >
//             <i className="fa fa-trash" aria-hidden="true"></i> Delete Goal
//         </a>
//         {this.state.alert}
//       </div>
//     );
//   }
// }



// import axios from 'axios';   
// import React,{Component} from 'react'; 
// class App extends Component { 
//     state = { 
//       // Initially, no file is selected 
//       selectedFile: null
//     }; 
     
//     // On file select (from the pop up) 
//     onFileChange = event => { 
     
//       // Update the state 
//       this.setState({ selectedFile: event.target.files[0] }); 
     
//     }; 
     
//     // On file upload (click the upload button) 
//     onFileUpload = () => { 
     
//       // Create an object of formData 
//       const formData = new FormData(); 
     
//       // Update the formData object 
//       formData.append( 
//         "myFile", 
//         this.state.selectedFile, 
//         this.state.selectedFile.name 
//       ); 
     
//       // Details of the uploaded file 
//       console.log(this.state.selectedFile); 
     
//       // Request made to the backend api 
//       // Send formData object 
//       axios.post("api/uploadfile", formData); 
//     }; 
     
//     // File content to be displayed after 
//     // file upload is complete 
//     fileData = () => { 
     
//       if (this.state.selectedFile) { 
          
//         return ( 
//           <div> 
//             <h2>File Details:</h2> 
//             <p>File Name: {this.state.selectedFile.name}</p> 
//             <p>File Type: {this.state.selectedFile.type}</p> 
//             <p> 
//               Last Modified:{" "} 
//               {this.state.selectedFile.lastModifiedDate.toDateString()} 
//             </p> 
//           </div> 
//         ); 
//       } else { 
//         return ( 
//           <div> 
//             <br /> 
//             <h4>Choose before Pressing the Upload button</h4> 
//           </div> 
//         ); 
//       } 
//     }; 
     
//     render() { 
     
//       return ( 
//         <div> 
//             <h1> 
//               GeeksforGeeks 
//             </h1> 
//             <h3> 
//               File Upload using React! 
//             </h3> 
//             <div> 
//                 <input type="file" onChange={this.onFileChange} /> 
//                 <button onClick={this.onFileUpload}> 
//                   Upload! 
//                 </button> 
//             </div> 
//           {this.fileData()} 
//         </div> 
//       ); 
//     } 
//   } 
  
//   export default App;
