import React, { Component, useRef , useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Col, Popover, PopoverHeader, PopoverBody, Alert } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingBar from "react-top-loading-bar";
import PhoneInput from 'react-phone-input-2';
import { Multiselect } from 'react-widgets';
//import Spinner from '../Assets/Spinner'
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
import '../landingpage.scss';
import LoginHeader from './Loginheader';
import { AutoplayButton } from 'hero-slider';
import { bool, boolean } from 'yup';


export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}



class Signupinfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowBar: false,
      alert: null,
      redirect: false,
      redirectSignup:false,
      redirectSignin:false,
      redirecthome:false,
      redirectAdmin: false,
      redirectAdministrator: false,
      redirectVisitor: false,
      redirectResercher: false,
      redirectManager: false,
      redirectEmployee: false,
      redirectClientDashboard: false,
      redirectPClientDashboard: false,
      
      redirectAdministratorDashboard: false,
      redirectVisitorDashboard: false,
      redirectKolProfile: false,
      redirectTermsandPrivacy: false,

      
      kdidentificationhidediv:false,
      kdprofilinghidediv:false,
      kdinteracthidediv:false,


      loginModal: false,
      ClientLoginModal: false,
      AdministratorLoginModal: false,
      VisitorLoginModal: false,
      KolProfileloginModal: false,
      WrongTeamAlertModal: false,
      ContactUsModal: false,
      NewGurusModal: false,
      NewClientsModal: false,
      TermsAndConditionModal: false,
      TermsAndConditionModalForTeam: false,

      //resercherd id for redirectig to researcher dashoard
      RID: '',
      resercher_name: '',

      termconditionAgree:false,


      Email_Duplicate_status:'',
      // Manager info
      manager_id: '',
      manager_name: '',

      // Employee info
      employee_id: '',
      employee_name: '',

      // For validations
      UsernameErr: '',
      UseremailErr:'',
      CompanyNameErr:'',      
      PasswordErr: '',
      confirmpasswordErr: '',
      confirmpassword:'',
      termconditionErr:'',

      SelectRoleErr: '',
      wrngUsPsErr: '',

      clientUsernameErr: '',
      clientPasswordErr: '',

      administratorUsernameErr: '',
      administratorPasswordErr: '',

      visitorUsernameErr: '',
      visitorPasswordErr: '',

      kolProfileUsernameErr: '',
      kolProfilePasswordErr: '',
      // /kolProfileSelectRoleErr: '',

      //For getting values of input
      signupData: {
        client_name: '',
        email_id: '',
        company_nm: '',
        password: '',
        signup_method:'',
        status:'New',
        //email_verified:'pending',
       // loginType: 'pre_client',
      },

      ClientLoginData: {
        login_id: '',
        password: '',
        login_type: 'client',
      },
      teamLoginIdForTerms: '',
      //Administrator
      AdministratorLoginData: {
        login_id: '',
        password: '',
        login_type: 'administrator',
      },
      //visitor
      VisitorLoginData: {
        login_id: '',
        password: '',
        login_type: 'visitor',
      },

      KolProfileLoginData: {
        username: '',
        password: '',
        // kol_login_role: '',
      },
      kolLoginIdForTerms: '',

      contact_us: {
        first_name: '',
        last_name: '',
        organization: '',
        email_id: '',
        phone_no: '',
        country: '',
        learn_qpharma: '',
        representative_contact_me: '',
        qpharmas_products: '',
        technical_support_issue: '',
        need_of_assistance: '',
        receive_qpharma_news: '',
        describe_issue_question: '',
      },
      cuFirstNameErr: '',
      cuLastNameErr: '',
      cuOrganizaErr: '',
      cuEmailErr: '',
      cuPhoneErr: '',
      cuCountryErr: '',

      new_guru: {
        first_name: '',
        last_name: '',
        mobile_no: '',
        email_id: '',
        speciality: '',
        sub_speciality: '',
        procedure_type: '',
        country: '',
        state: '',
        current_place_of_work: '',
        department: '',
        linkedin_link: '',
        additional_info: '',
      },
      ngFirstNameErr: '',
      ngLastNameErr: '',
      ngEmailErr: '',
      cuPhoneErr: '',
      ngCountryErr: '',
      ngStateErr: '',

      new_client: {
        first_name: '',
        last_name: '',
        email_id: '',
        mobile_no: '',
        msg: '',
      },
      ncFirstNameErr: '',
      ncLastNameErr: '',
      ncEmailErr: '',
      // ncPhoneErr: '',

      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],
      StateData: [],

      // to set form values
      Speciality: '',
      SubSpecialityType: [],
      ProcedureType: [],

      Market: [],
      Market2: [],

      PersonalInformation: {},

      toggleForgotPasswordModal: false,
      ForgotPasswordData: {
        email_id: '',
        role: '',
      }
    };
    
    //this.login = this.login.bind(this);
    
   // this.setState({redirect: true})


  }

  
  componentDidMount = async () => {
    console.log('in componentDidMount');
    
  
    let getSession_quotaiton_id = sessionStorage.getItem('quotation_id')
    
    if (getSession_quotaiton_id !== null) {        
      let { signupData } = this.state;
      signupData.signup_method = 'quotation';
      signupData.quotation_id=getSession_quotaiton_id;
      this.setState({ signupData });
      
    }
    else
    {
      let { signupData } = this.state;
      signupData.signup_method = 'Portal';
      signupData.quotation_id='';
      this.setState({ signupData });
      
    }
    
    //quotation_id  

    // this.setState({
    // 	ShowBar: true,
    // 	progressBar: true,
      
    // })

    // this.setState({
    // 	ShowBar: false,
    // 	progressBar: false,
      
    // })
  }

  
  
  // // contact us 
  // toggleContactUsModal() {
  //   this.setState({
  //     ContactUsModal: !this.state.ContactUsModal,
  //     cuFirstNameErr: '',
  //     cuLastNameErr: '',
  //     cuOrganizaErr: '',
  //     cuEmailErr: '',
  //     // cuPhoneErr: '',
  //     cuCountryErr: '',
  //   });
  // }
  // validateContactUs = () => {
  //   let cuFirstNameErr = '';
  //   let cuLastNameErr = '';
  //   let cuOrganizaErr = '';
  //   let cuEmailErr = '';
  //   // let cuPhoneErr= '';
  //   let cuCountryErr = '';

  //   if (!this.state.contact_us.first_name) {
  //     cuFirstNameErr = 'Please Enter First Name';
  //   }

  //   if (!this.state.contact_us.last_name) {
  //     cuLastNameErr = 'Please Enter Last Name';
  //   }
  //   if (!this.state.contact_us.organization) {
  //     cuOrganizaErr = 'Please Enter Organization';
  //   }
  //   if (!this.state.contact_us.email_id) {
  //     cuEmailErr = 'Please Enter Email';
  //   }
  //   else if (typeof this.state.contact_us.email_id !== "undefined") {
  //     let lastAtPos = this.state.contact_us.email_id.lastIndexOf('@');
  //     let lastDotPos = this.state.contact_us.email_id.lastIndexOf('.');

  //     if (!(lastAtPos < lastDotPos && lastAtPos > 0)) {
  //       //   formIsValid = false;
  //       cuEmailErr = "Email is not valid";
  //       if (cuEmailErr) {
  //         this.setState({ cuEmailErr })
  //       }
  //     } else {
  //       this.setState({ cuEmailErr: '' })
  //     }
  //   }
  //   else {
  //     this.setState({ cuEmailErr: '' })
  //   }
  //   // if(!this.state.contact_us.phone_no)  {
  //   //     cuPhoneErr = 'Please Enter Phone Number';
  //   // }
  //   if (!this.state.contact_us.country) {
  //     cuCountryErr = 'Please Selct Country';
  //   }


  //   if (cuFirstNameErr || cuLastNameErr || cuOrganizaErr || cuEmailErr || cuCountryErr) {
  //     this.setState({ cuFirstNameErr, cuLastNameErr, cuOrganizaErr, cuEmailErr, cuCountryErr });
  //     return false;
  //   }
  //   return true;
  // }
  
 

  //login = toggle validate and login
  toggleLoginModal() {
    //console.log("loginclicked")
    this.setState({
      loginModal: !this.state.loginModal,
      UsernameErr: '',
      PasswordErr: '',
      SelectRoleErr: '',
      MySProfilePropover: false,
    });
  }

  toggleWithoutidModal()
  {
    
  //   let { loginData } = this.state;
  //   loginData.client_id = 'CHCCI002';
  //   loginData.password = 'xyv$23ixz';
  //   this.setState({ loginData });

  //  this.Clientlogin();

      this.setState({redirectSignup:true})
    

  }

  redirectSignin()
  {
    this.setState({redirectSignin:true})
    
  }

  // validate = () => {
  //   let UsernameErr = '';
  //   let PasswordErr = '';
    
  //   if (!this.state.loginData.client_id) {
  //     UsernameErr = 'Please Enter User Id';
  //   }
  //   if (!this.state.loginData.password) {
  //     PasswordErr = 'Please Enter Password';
  //   }
  //   // if (!this.state.loginData.role) {
  //   //   SelectRoleErr = 'Please Select Role';
  //   // }

  //   // if (UsernameErr || PasswordErr || SelectRoleErr) {
  //   //   this.setState({ UsernameErr, PasswordErr, SelectRoleErr });
  //   //   return false;
  //   // }
  //   if (UsernameErr || PasswordErr ) {
  //     this.setState({ UsernameErr, PasswordErr });
  //     return false;
  //   }
  //   return true;
  // }



  loginfun() {
  
    const isValid = this.validate();
    //Alert(isValid);
    if (isValid) {
      
    const clid=this.state.loginData.client_id;
      //Alert(clid);
    var cutchar = (clid.substring(0,5)).toUpperCase();

    //Alert(cutchar);
    switch(cutchar)
    {
      case"CHCEM":
      {   
             
        this.Userlogin();
        return ;
        
      }
      case"CHCCI":
      {

       this.Clientlogin();
       return ;
      }
      case"CHCUI":
      {
        this.KolProfileLogin();
        //Panelist Login
        return ;
      }
      default:
      {
        if(clid.includes("@"))
          {
          this.Visitorlogin();  
          return ; 
          }       
      }
    }

  
  }
    
  }



  Visitorlogin() {
    
    this.setState({
        ShowBar: true,
      })

      console.log("this.state.loginData.visitor_id", this.state.loginData.client_id)
      console.log("this.state.loginData.password", this.state.loginData.password)

      const url = global.websiteUrl+'/service/User/login';
      let formData = new FormData();
      formData.append('login_id', this.state.loginData.client_id);
      formData.append('password', this.state.loginData.password);
      formData.append('login_type', "visitor");

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }
      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data.Client_Info;
          console.log("lgdata===========", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("lgstatus", JSON.stringify(lgstatus));
          console.log("response message2", response.data.message)
          this.setState({
            ShowBar: false,
          })
          if (lgstatus == true) {
            this.setState({ redirectVisitorDashboard: true })
            lgdata.map((data) => {
              sessionStorage.setItem('visitor_email', data.email_id)
              sessionStorage.setItem('visitor_id', data.client_id)
              sessionStorage.setItem('visitor_name', data.client_name)
            })
          }
          else if (lgstatus == false) {
            // alert("Wrong Username Or Password")
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Wrong Username or Password!',
              // showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: '#d33',
              confirmButtonText: 'OK'
            })

            this.setState({ShowBar:false,})

          }
        })
        .catch(error => {
          console.log(error);
        });

      this.setState({
        loginModal: false,
        loginData: {
          client_id: '',
          password: '',
        }
      });
    
  }



  validate = () => {
    let UsernameErr = '';
    let UseremailErr = '';
    let CompanyNameErr = '';
    let PasswordErr = '';
    let confirmpasswordErr = '';
    let termconditionErr='';

    if (!this.state.signupData.client_name) {
      UsernameErr = 'Please Enter Full Name';
    }
    if (!this.state.signupData.email_id) {
      UseremailErr = 'Please Enter Email id';
    }

    if (!this.state.signupData.company_nm) {
      CompanyNameErr = 'Please Enter Company Name';
    }
    
    if (!this.state.signupData.password) {
      PasswordErr = 'Please Enter Password';
    }

    if (!this.state.confirmpassword) {
      confirmpasswordErr = 'Please Enter Confirm Password';
    }
    
    if (!this.state.termconditionAgree) {
      termconditionErr = 'Please Accept term and condition';
    }

    if (UsernameErr || UseremailErr ||CompanyNameErr || PasswordErr || confirmpasswordErr || termconditionErr) {
      this.setState({ UsernameErr, PasswordErr ,UseremailErr,CompanyNameErr,confirmpasswordErr,termconditionErr });
      return false;
    }
    return true;
  }


  passmatchfunc()
  {
    if(this.state.signupData.password != this.state.confirmpassword)
    {
        this.setState({
          confirmpasswordErr:"Passwords don't match",
          // ShowBar: false,
          // UsernameErr:'',
          // UseremailErr:'',
          // CompanyNameErr:'',
          // PasswordErr:'',         
        
        })
       
    }
    else
    {
      this.setState({
        confirmpasswordErr:'',
        confirmpasswordErr:"Passwords match",
        color:'green'
      })
    }
  }

  Validate_Duplicate_Email()
  {   
    
    this.setState({ShowBar:true,})

    const url = global.websiteUrl+'/service/KOL_landing/check_duplicate_signup';
    let formData = new FormData();
    formData.append('Signup_Info',JSON.stringify(this.state.signupData));
  
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var lgstatus = response.data.status;
        console.log("lgstatus", JSON.stringify(lgstatus));     
        
        this.setState({ShowBar:false,})
       // alert(lgstatus);
        if(lgstatus == false)
        {
          this.Save_Signup_Data();
        }
        else
        {
          Swal.fire({
            icon: 'error',
            title:'Oops',
            text: 'Emails Already exits pls change new email',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })

          this.setState({      
                        UseremailErr: 'Change New Email',
                        confirmpasswordErr:'',
                        UsernameErr:'',
                        CompanyNameErr:'',
                        PasswordErr:'',   
                        termconditionErr:'',
                      })
        }

      })
      .catch(error => {
        console.log(error);
      });
        

    
  }

  // Verified_Duplicate_Email = () =>
  // {
    
    
  //   let eem=this.state.signupData.email_id;
    
  //   if(eem.includes('@') && eem.includes('.'))
  //   {
  //               this.setState({
  //                 ShowBar: true,
  //               })
  
  //                 const url = global.websiteUrl+'/service/KOL_landing/check_duplicate_signup';
  //                 let formData = new FormData();
  //                 formData.append('Signup_Info',JSON.stringify(this.state.signupData));
                
  //                 const config = {
  //                   //   headers: { 'content-type': 'multipart/form-data' }
  //                 }
  //                 axios.post(url, formData, config)
  //                   .then(response => {
  //                     console.log(response);
  //                     var lgdata = response.data.signup_List;
  //                     console.log("lgdata===========", JSON.stringify(lgdata));
  //                     var lgstatus = response.data.status;
  //                     console.log("lgstatus", JSON.stringify(lgstatus));
  //                     console.log("response message2", response.data.message)

  //                     this.setState({
  //                       ShowBar: false,
  //                     })
  //                         if (lgstatus == true) 
  //                         {
  //                                 this.setState({
  //                                   Email_Duplicate_status:true,
  //                                   UseremailErr: 'Email exist pls change new',
  //                                   confirmpasswordErr:'',
  //                                   UsernameErr:'',
  //                                   CompanyNameErr:'',
  //                                   PasswordErr:'',
  //                                   termconditionErr:'',                  
  //                                 })

                              

  //                         }
  //                         else
  //                         {
                            
  //                             this.setState({
  //                               Email_Duplicate_status:false,
  //                               UseremailErr: '',                                  
  //                                 confirmpasswordErr:'',
  //                                 UsernameErr:'',
  //                                 CompanyNameErr:'',
  //                                 PasswordErr:'',
  //                                 termconditionErr:'',
                                        
  //                                             })
      
  //                         }

  //                   })
  //                   .catch(error => {
  //                     console.log(error);
  //                   });
                      
      
  //   }
  //   else
  //   {
  //     // Email Envalid
  //     this.setState({
  //     Email_Duplicate_status:true,
  //     UseremailErr: 'Invalid email',
  //     confirmpasswordErr:'',
  //     UsernameErr:'',
  //     CompanyNameErr:'',
  //     PasswordErr:'',   
  //     termconditionErr:'',
  //   })

    
   
  //   }

  //   return this.state.Email_Duplicate_status;
  // }

checkboxHandler = (event) => {
  //   // if agree === true, it will be set to false
  //   // if agree === false, it will be set to true
  //   //setAgree(!agree);
  //  //termconditionAgree
  //  alert(event.target.id)
      this.setState({ termconditionAgree: !this.state.termconditionAgree})

     // alert(this.state.termconditionAgree);

    // Don't miss the exclamation mark
  }


UserSignupfun() {

  
const isValid =this.validate();
// const isValid ="true";    
 if (isValid)
 {
      let eem=this.state.signupData.email_id;    
      if(eem.includes('@') && eem.includes('.'))
      {   
  
                if(this.state.signupData.password != this.state.confirmpassword)
                {
                  this.setState({
                    confirmpasswordErr:"Passwords don't match",      
                    UsernameErr:'',
                    UseremailErr:'',
                    CompanyNameErr:'',
                    PasswordErr:'', 
                    termconditionErr:''          
                  })
                
              }else
              { 
              this.Validate_Duplicate_Email();
              }
  
      }
      else
      {
          // Email Envalid
          this.setState({      
            UseremailErr: 'Invalid email',
            confirmpasswordErr:'',
            UsernameErr:'',
            CompanyNameErr:'',
            PasswordErr:'',   
            termconditionErr:'',
          })

         
        
      }
 }


}

Save_Signup_Data()
{
  
  this.setState({
    ShowBar: true,
  })

  
  const url = global.websiteUrl+'/service/KOL_landing/save_signup_data';
  let formData = new FormData();

  formData.append('Signup_Info', JSON.stringify(this.state.signupData));
  
  const config = {
    //   headers: { 'content-type': 'multipart/form-data' }
  }

  axios.post(url, formData, config)
    .then(response => {
      console.log(response);
      var lgdata = response.data;
      console.log("lgdata", JSON.stringify(lgdata));
      var lgstatus = response.data.status;
      console.log("lgstatus", JSON.stringify(lgstatus));
      console.log("response message2", response.data.message)
   
      this.setState({
        ShowBar: false,
      })
      //  alert(response.data.status);
      if (response.data.status == true) {
          Swal.fire({
            icon: 'success',
            //text: response.data.message,
            text: 'We are now working to create your account, and will be sending out the details via email in next 24 hours.',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'

          })

          sessionStorage.removeItem('quotation_id','');
         // this.setState({infoQuoteModal:false})
        // this.setState({redirect: true})
    
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      }
    
      this.setState({redirecthome:true})
      
      
    })
    .catch(error => {
      console.log(error);
    });
  
  
  

}




  makespaceondivkdidentification()
  {
    
    this.setState({

      hidedivkdprofiling:false,
      hidedivkdinteract:false,
      hidedivkdidentification:true,
      


    });

  }

  makespaceondivkdprofiling()
  {
    
    this.setState({

      hidedivkdidentification:false,
      hidedivkdinteract:false,
      hidedivkdprofiling:true,

    });

  }

 


  makespaceondivkdinteract()
  {
    
    this.setState({
      hidedivkdidentification:false,
      hidedivkdprofiling:false,
      hidedivkdinteract:true,

    });

  }

  

  makespaceondivhome()
  {    
    this.setState({
      hidedivkdidentification:false,
      hidedivkdprofiling:false,
      hidedivkdinteract:false,

    });
  }

  
  render() {

   if (this.state.redirect) {
            return (<Redirect to={'/'}/>)
  }

    if (this.state.redirectSignin) {
        return (<Redirect to={'/Login'}/>)
    }

    if (this.state.redirecthome) {
      return (<Redirect to={'/home'} />)
    }

    if (this.state.redirectResercher) {
      return (<Redirect to={{
        pathname: '/ResercherDashboard',
        state: { RID: this.state.RID, resercher_name: this.state.resercher_name }
      }} />)
    }

    if (this.state.redirectManager) {
      return (<Redirect to={{
        pathname: '/ManagerDashboard',
        // state:{manager_id: this.state.manager_id , manager_name: this.state.manager_name} 
      }} />)
    }

    if (this.state.redirectEmployee) {
      return (<Redirect to={{
        pathname: '/EmployeeDashboard',
        //  state:{employee_id: this.state.employee_id , employee_name: this.state.employee_name} 
      }} />)
    }

    if (this.state.redirectClientDashboard) {
      return (<Redirect to={{
        pathname: '/ClientViewProject',
      }} />)
    }

    if (this.state.redirectPClientDashboard) {
      return (<Redirect to={{
        pathname: '/PClientViewKolList',
      }} />)
    }


    if (this.state.redirectAdministratorDashboard) {
      return (<Redirect to={{
        pathname: '/AdministratorViewProject',
      }} />)
    }

    if (this.state.redirectVisitorDashboard) {
      return (<Redirect to={{
        pathname: '/VisitorDashboard',
      }} />)
    }

    if (this.state.redirectKolProfile) {
      return (<Redirect to={{
        pathname: '/KolProfileDashboard',
      }} />)
    }

    if (this.state.redirectTermsandPrivacy) {
      return (<Redirect to={{
        pathname: '/TermsPrivacyPolicy',
      }} />)
    }

    // // Speciality Type options 
    // let speciality_type = this.state.SpecialityData;
    // let OptionsSpeciality = speciality_type.map((speciality_type) =>
    //   <option key={speciality_type.id} value={speciality_type.id}>
    //     {speciality_type.speciality_type}</option>
    // );

    // // Procedure List
    // let procedure_list = this.state.ProcedureData;
    // let OptionProcedure = procedure_list.map((procedure_list) =>
    //   <option key={procedure_list.name} value={procedure_list.name}>
    //     {procedure_list.procedure_type}</option>
    // );

    // // Sub Speciality List
    // let subSpeciality_list = this.state.SubSpecialityData;
    // let OptionSubSpeciality = subSpeciality_list.map((subSpeciality_list) =>
    //   <option key={subSpeciality_list.name} value={subSpeciality_list.name}>
    //     {subSpeciality_list.sub_speciality_type}</option>
    // );

    // // Country List
    // let Country_list = this.state.CountryListData;
    // let OptionsCountry = Country_list.map((Country_list) =>
    //   <option key={Country_list.id} value={Country_list.id}>
    //     {Country_list.country_name}</option>
    // );

    // // State List
    // let state_list = this.state.StateData;
    // let OptionsState = state_list.map((state_list) =>
    //   <option key={state_list.name} value={state_list.name}>
    //     {state_list.state_name}</option>
    // );

    return (

        
      <body id="landing-body" style={{ backgroundColor:'#ccc'}}>


        <div>
          {this.state.ShowBar ? <Modal isOpen={true} className='propagateLoader' centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' />
          </Modal> : null}
        </div>
        {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
          <PropagateLoader color={'#A90007'} size='40px' loading='true' />
        </Modal> : null}

        {/* Call the function to add a library */}
  {/* {AddLibrary('https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js')} */}
{/* 
  {AddLibrary('./js/jquery.min.js')}
   {AddLibrary('./js/bootstrap.bundle.min.js')}
  {AddLibrary('./js/owl.carousel.min.js')} */}
  {AddLibrary('./js/app.js')}
  


{/* <div style={{ backgroundColor:'#fff', height:'590px'}}>         */}
       
<div style={{ backgroundColor:'#fff', height:'480px'}}>        
   
    <section id="services" className="text-center" >
    
        <div className="container" >
            <div className="row">
                <div className="col-12">
                    <div className="login" style={{width:'100%', marginTop:'-100px'}} >

                                    <div className='inputbox'>
                                        <label for="" style={{ marginBottom:'10px'}} >Full Name <span class="mandatory">*</span></label>
                                        <Input id="username" placeholder="Enter Full Name" tabindex='1' class="input"
                                        type="email"
                                        value={this.state.signupData.client_name} onChange={(e) => {
                                          let { signupData } = this.state;
                                          signupData.client_name = e.target.value;
                                          this.setState({ signupData });
                                          // this.setState({ loginData, teamLoginIdForTerms: e.target.value });
                                        }}
                                      />                                     
                                    </div>
                                    <div className="error"> {this.state.UsernameErr}</div>

                                    <div className='inputbox'>
                                        <label for="" style={{ marginBottom:'10px'}}>Email <span class="mandatory">*</span></label>
                                        <Input id="email_id" placeholder="Enter email address" tabindex='2' class="input"
                                        type="email"
                                        value={this.state.signupData.email_id}
                                        onChange={(e) => {
                                          let { signupData } = this.state;
                                          signupData.email_id = e.target.value;
                                          this.setState({ signupData });
                                        }}

                                      />                                     
                                    </div>
                                    <div className="error"> {this.state.UseremailErr}</div>

                                    <div className='inputbox'>
                                        <label for="" style={{ marginBottom:'10px'}}>Company Name <span class="mandatory">*</span></label>
                                        <Input id="companyname" placeholder="Enter Company Name" tabindex='3' class="input"
                                        type="email"
                                        value={this.state.signupData.company_nm} onChange={(e) => {
                                          let { signupData } = this.state;
                                          signupData.company_nm = e.target.value;
                                          this.setState({ signupData });
                                        }}
                                      
                                      />                                     
                                    </div>
                                    <div className="error"> {this.state.CompanyNameErr}</div>


                                    <div className='inputbox'>
                                    <label for="" style={{marginBottom:'10px'}} >Password<span class="mandatory">*</span></label>
                                    <Input id="password" placeholder="Password" class="input" tabindex='4'
                                          type="password"
                                          value={this.state.signupData.password} onChange={(e) => {
                                            let { signupData } = this.state;
                                            signupData.password = e.target.value;
                                            this.setState({ signupData });
                                          }}
                                        />

                                    </div>
                                    <div className="error"> {this.state.PasswordErr}</div>

                                    <div className='inputbox'>
                                    <label for="" style={{ marginBottom:'10px'}} >Confirm Password<span class="mandatory">*</span></label>
                                    <Input id="confirmpassword" placeholder="Confirm Password" class="input" tabindex='5'
                                          type="password"
                                          value={this.state.confirmpassword} 
                                          onChange={(e) => {
                                            
                                            this.setState({confirmpassword:e.target.value})
                                            
                                          }                                          
                                        }
                                        onClick={(e)=>{ this.passmatchfunc();}}
                                        />

                                    </div>
                                    <div className="error"> {this.state.confirmpasswordErr}</div>

                                    <div className='tc'>
                                      <chbox> <input type="checkbox" tabindex='6' style={{width:'20px',height:'20px'}} id="agree" onChange={this.checkboxHandler}  /></chbox>
                                      <label> I agree to <a href='https://www.cetas-healthcare.com/terms-of-use/' target='_blank'><b>terms and conditions</b></a></label>
                                      <error >{this.state.termconditionErr}</error>
                                    </div>
                                    <div style={{ marginTop:'30px'}}>
                                       <button type="submit" tabindex='7' onClick={this.UserSignupfun.bind(this)}>
                                        Sign Up
                                      </button>
                                  </div>
                                 {/* </form>
                            </div> */}    
<div style={{visibility:'hidden',height:'1px'}}>
                                  <h5>Create account with</h5>    
                                  <div className='inputboxsocial'>
                                   <a href="#" target="_blank"><i className='bx bxl-facebook'></i></a>
                                   <a href="#" target="_blank"><i className='bx bxl-google'></i></a>
                                   <a href="https://www.linkedin.com/company/cetas-healthcare/" target="_blank"><i className='bx bxl-linkedin'></i></a>
                                  
                                   <a href="#" target="_blank"><i className='bx bxl-twitter'></i></a>
                  
                                  </div>
</div>
                                             
                    </div>
                </div>
            </div>            
          
        </div>
    </section>

    {/* <footer>
        <div className="footer-top text-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 text-center">
                        {/* <div className="col-auto social-icons">
                            <a href="https://www.linkedin.com/company/cetas-healthcare/" target="_blank"><i className='bx bxl-linkedin'></i></a>
                            <a href="https://twitter.com/cetashealthcare" target="_blank"><i className='bx bxl-twitter'></i></a>
                            <a href="https://www.youtube.com/channel/UCzJPjYnPXRZjWs-H1IqvMFQ" target="_blank"><i className='bx bxl-youtube'></i></a>
                        </div>
                        <div className="col-auto conditions-section">
                            <a href="https://www.cetas-healthcare.com/privacy_policy/" target="_blank" rel="opener">Privacy</a>
                            <a href="https://www.cetas-healthcare.com/terms-of-use/" target="_blank" rel="opener">Terms</a>
                            <a href="https://www.cetas-healthcare.com/cookie-notice/" target="_blank" rel="opener">Cookie Notice</a>
                        </div> 
                        <span className="mb-0">© 2023 Cetas Healthcare. - ISO 9001 Certified</span>
                    </div>
                </div>
            </div>
        </div>
    </footer> */}

</div>
    



  

    
    



      {/* </header> */}
 
      </body>
    );
  }
}

export default Signupinfo;