import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
//import LoadingBar from "react-top-loading-bar";
import { Chart } from "react-google-charts";
import { Bar, Line } from 'react-chartjs-2';
import ApexChart from './ApexChart';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Multiselect } from 'react-widgets';
import Swal from 'sweetalert2';
import ClientHeader from './ClientHeader';
import Signup from '../Login/Signupinfo';
//import 'flag-icon-css/css/flag-icon.min.css';
 //import '../styles/summary.css';
// showing progress bar npm 

import Quoteform from '../PClient/Quoteform';

import Pagefooter from '../Login/footer';
import { PropagateLoader } from 'react-spinners';
import LoadingBar from "react-top-loading-bar";
import {NumberFormatter} from 'react-number-formatter';



 
class ClientKolProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kol_id: '',
            client_name: '',
            client_id:'',
            secondryEmail:'',
            quotation_id:'',
            procedureshowtype:false,
             // For validations
            noofkolErr: '',
            specilityErr:'',
            procedureErr:'',      
            countryErr: '',
            profilingtypeErr: '',
            Multiple_single_profileErr:'',
            
            QuoteData: {
              client_id:'',
              client_name:'',
              kol_id:'',
              no_of_deep_profile: 0,
              no_of_basic_profile: 0,  
              no_of_kol: '',      
              specialty_id: '',
              procedure_type: '',
              countrieslist:[],
              Multiple_single_profile: '',
                        
              status: 'New',
              
            },
           // state for progress bar
            ShowBar: false,
            progressBar: false,
            QuoteModal:false,
            SignupModal:false,
            NewQuoteModal:false,

            multiplekolquotmodel:false,

            DoctorCompleteName:"",
            ranking:"",
            RedirectSignUp:false,
            RedirectLogin:false,
            redirectknowmore:false,
            redirecthome:false,
            DoctorFormattedContactNumber:"",
            DoctorFormattedFaxNumber:"",
            DoctorFormattedAssistentContactNumber:"",
           
//****************** */ Chart Color **************
        annotationfontsize:10,
        annotationfontcolor:'Black',
        annotationfontbold:false,

       legendfontsize:12,
        legendfontcolor:'Black',
        legendfontbold:false,

        titlefontsize:18,
        titlefontcolor:'#035B96',
        titlefontbold:true,


        hAxisfontsize:12,
        hAxisfontcolor:'Black',
        hAxisfontbold:false,

        
        vAxisfontsize:14,
        vAxisfontcolor:'Black',
        vAxisfontbold:true,

        series1color: '#035B96',
      series2color: '#A55B52' ,
      series3color: '#72DADD' ,
      series4color: '#D7B4B0' ,   
      
      
      chartAreawidth:'80%',
      chartAreaheight:'60%',



// End Chart Color *****************

            // ProfileBySession: this.props.location.state.ProfileBySession,

            RedirectFlag: false,

            redirectBackToDashboard:false,
            redirectBackToKolList:false,

            //Flag to show form of selected drawer item
            PersonalInformationFlag: true,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            SummaryFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,

            // to store data fetch from api
            PersonalInformation: {},
            WorkDetails: [],
            Biography: {},
            ProfessionalMemberships: [],
            Education: [],
            AdvisoryBoard: [],
            AwardsHonours: [],
            BooksMonographs: [],
            Commitees: [],
            ClinicalTrialDetails: [],
            EventsDetails: [],
            Grants: [],
            Guidelines: [],
            HospitalAffiliations: [],
            Interactions: [],
            PressItem: [],
            Presentation: [],
            PublicationDetails: [],
            SunshinePayment: [],

            profile_photo: '',
            LanguageSpoken: [],
            BiographyResume: '',
            profile_photo_for_edit: '',
            BiographyResume_edit: '',
            BiographyResumeFileName: '',
            FinalResumeName: '',

            CountryListData: [],
            ProcedureData:[],
            SpecialityData: [],

            StateData: [],
            CityData: [],
            EmployeeListData: [],

            // KolRatingScore data
            event_kol_rating: [],
            publication_kol_rating: [],
            ad_board_kol_rating: [],
            guideline_kol_rating: [],
            clinicaltrial_kol_rating: [],

            //piechart data
            event_piechart: [],
            ad_board_piechart: [],
            publication_piechart: [],
            guideline_piechart: [],
            clinicaltrial_piechart: [],

            home_piechart_flag: true,


// *************** New Chart Data **********************






            // all columns 
            columnsWorkDetails: [
                {
                    dataField: 'current_place_of_work',
                    text: 'Current Place of Work',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        // return { textAlign: 'center', width: '10%' };
                        return { width: '10%' };
                    },
                },
                {
                    dataField: 'designation',
                    text: 'Designation',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%' };
                    },
                },
                {
                    dataField: 'department',
                    text: 'Department',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%' };
                    },
                },
                // new
                {
                    dataField: 'from_year',
                    text: 'From',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%' };
                    },
                },
                // new
                {
                    dataField: 'to_year',
                    text: 'To',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%' };
                    },
                },
            ],

            columnsProfessionalMemberships: [
                {
                    dataField: 'organization',
                    text: 'Organization',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '12%' };
                    },
                },
                {
                    dataField: 'organization_type',
                    text: 'Organization Type',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { borderColor:'#02365F',width: '12%' };
                    },
                },
                {
                    dataField: 'department',
                    text: 'Department',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '12%' };
                    },
                },
                {
                    dataField: 'position',
                    text: 'Position',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '12%' };
                    },
                },
               
                {
                    dataField: 'location',
                    text: 'Location',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { borderColor:'#02365F',width: '12%' };
                    },
                },
                
                {
                    dataField: 'tenure',
                    text: 'From',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '12%' };
                    },
                    
                },
                {
                    dataField: 'tenure1' ,
                    text: 'To',                    
                    headerStyle: (colum, colIndex) => {
                        return { borderColor:'#02365F',width: '12%' };
                    },
                   
                },

                {
                    dataField: 'organization_website',
                    text: 'Organization Website',
                    
                    formatter: (cell, row) => {
                      return(
                      <div >
                        
                        <a href={row.organization_website}target="_blank"  style={{ color: '#069', wordWrap: 'break-word' ,fontSize:'12px',textDecoration: 'underline', cursor: 'pointer'
        }}
          > {row.organization_website} </a>
                         
            
                        </div>
                      )
            
                     },
                   
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '12%' };
                    },
                },
               
            ],
            columnsEducation: [
                {
                    dataField: 'institute_name',
                    text: 'Alma  Mater',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { borderColor:'#02365F', width: '15%' };
                    },
                },
                {
                    dataField: 'institute_type',
                    text: 'Alma Mater Type',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '15%' };
                    },
                },
                {
                    dataField: 'degrees',
                    text: 'Qualification',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { borderColor:'#02365F', width: '15%' };
                    },
                },
                {
                    dataField: 'speciality_type',
                    text: 'Speciality',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '15%' };
                    },
                },
                {
                    dataField: 'years_attended_from',
                    text: 'From',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '15%' };
                    },
                },
                {
                    dataField: 'years_attended_to',
                    text: 'To',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '15%' };
                    },
                },
                {
                    dataField: 'source_link',
                    text: 'Institution Link',
                    // align: 'center',
                    formatter: (cell, row) => {
                      return(
                      <div >
                        
                        <a href={row.source_link}target="_blank"  style={{ color: '#069', fontSize:'12px',textDecoration: 'underline', cursor: 'pointer'
        }}
          > {row.source_link} </a>
                         
            
                        </div>
                      )
            
                     },
                    headerStyle: (colum, colIndex) => {
                        return { borderColor:'#02365F', width: '15%' };
                    },
                },
            ],
            columnsAdvisoryBord: [
                {
                    dataField: 'year',
                    text: 'From',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '10%' };
                    },
                },
                // new
                {
                    dataField: 'to_year',
                    text: 'To',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '10%' };
                    },
                },
                // {
                //     dataField: 'speciality_type',
                //     text: 'Speciality Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '10%' };
                //     },
                // },
                // {
                //     dataField: 'sub_specialty_id',
                //     text: 'Sub Speciality Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '15%' };
                //     },
                // },
                // {
                //     dataField: 'procedure_type_id',
                //     text: 'Procedure Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '15%' };
                //     },
                // },
                {
                    dataField: 'company',
                    text: 'Company',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '10%' };
                    },
                },
                {
                    dataField: 'type_of_company',
                    text: 'Type of Company',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '10%' };
                    },
                },
                {
                    dataField: 'advisory_board',
                    text: 'Advisory Board',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '15%' };
                    },
                },
                {
                    dataField: 'topic',
                    text: 'Topic',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'role_id',
                    text: 'Role',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '10%' };
                    },
                },
                // {
                //     dataField: 'manual_weightage',
                //     text: 'Manual Weightage',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //     return { width: '15%' };
                //     },
                // },
            ],
            columnsAwardsHonours: [
                {
                    dataField: 'year',
                    text: 'From',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '5%' };
                    },
                },
                // new
                {
                    dataField: 'to_year',
                    text: 'To',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '5%' };
                    },
                },
                {
                    dataField: 'awards_honours',
                    text: 'Awards Honours',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '5%' };
                    },
                },
                {
                    dataField: 'types',
                    text: 'Types of Awards & Honours',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '5%' };
                    },
                },
            ],
            columnsBooksMonographs: [
                {
                    dataField: 'year',
                    text: 'Year',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '10%' };
                    },
                },
                {
                    dataField: 'title',
                    text: 'Title',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '15%' };
                    },
                },
                {
                    dataField: 'author',
                    text: 'Author',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '15%' };
                    },
                },
                {
                    dataField: 'publication',
                    text: 'Publication',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '15%' };
                    },
                },
                {
                    dataField: 'edition',
                    text: 'Edition',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { borderColor:'#02365F',width: '8%' };
                    },
                },
                {
                    dataField: 'pages',
                    text: 'Pages',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '8%' };
                    },
                },
                {
                    dataField: 'editors',
                    text: 'Editors',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { borderColor:'#02365F',width: '20%' };
                    },
                },
            ],
            columnsCommitees: [
                {
                    dataField: 'year',
                    text: 'Year',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '10%' };
                    },
                },
                // new
                {
                    dataField: 'body_org_name',
                    text: 'Body/Org Name',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '10%' };
                    },
                },
                {
                    dataField: 'committee',
                    text: 'Committee',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '10%' };
                    },
                },
                {
                    dataField: 'role_id',
                    text: 'Role',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { borderColor:'#02365F',width: '10%' };
                    },
                },
            ],
            columnsClinicalTrialDetails: [
                {
                    dataField: 'study_details',
                    text: 'Study Details',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '10%' };
                    },
                },
                // {
                //     dataField: 'speciality_type',
                //     text: 'Speciality Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '10%' };
                //     },
                // },
                // {
                //     dataField: 'sub_speciality',
                //     text: 'Sub Speciality Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '10%' };
                //     },
                // },
                // {
                //     dataField: 'procedure_type',
                //     text: 'Procedure Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '10%' };
                //     },
                // },
                {
                    dataField: 'start_date',
                    text: 'From',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '8%' };
                    },
                },
                {
                    dataField: 'end_date',
                    text: 'To',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '8%' };
                    },
                },
                {
                    dataField: 'type',
                    text: 'Type',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { borderColor:'#02365F',width: '8%' };
                    },
                },
                {
                    dataField: 'phase',
                    text: 'Phase',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '8%' };
                    },
                },
                {
                    dataField: 'role_id',
                    text: 'Role',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '8%' };
                    },
                },
                {
                    dataField: 'no_of_study_locations',
                    text: 'Study Locations',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { borderColor:'#02365F',width: '10%' };
                    },
                },
                {
                    dataField: 'conditions',
                    text: 'Condition',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { borderColor:'#02365F',width: '10%' };
                    },
                },
                // {
                //     dataField: 'conditions',
                //     text: 'Condition',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '10%' };
                //     },
                // },
                {
                    dataField: 'treatment_intervention',
                    text: 'Intervention',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { borderColor:'#02365F',width: '10%' };
                    },
                },
                // {
                //     dataField: 'primary_outcomes',
                //     text: 'Primary Outcomes',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '10%' };
                //     },
                // },
                // {
                //     dataField: 'secondary_outcomes',
                //     text: 'Secondary Outcomes',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '10%' };
                //     },
                // },
                {
                    dataField: 'sponsors',
                    text: 'Sponsors',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '10%' };
                    },
                },
                {
                    dataField: 'collaborators',
                    text: 'Collaborators',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {borderColor:'#02365F', width: '10%' };
                    },
                },
                // {
                //     dataField: 'manual_weightage',
                //     text: 'Manual Weightage',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //     return { width: '10%' };
                //     },
                // },
        //         {
        //             dataField: 'source_link',
        //             text: 'Source Link',
        //             // align: 'center',
        //             headerStyle: (colum, colIndex) => {
        //                 return { borderColor:'#02365F',width: '10%' };
        //             },

        //             formatter: (cell, row) => {
        //               return(
        //               <div >
                        
        //                 <a href={row.source_link}target="_blank"  style={{ color: 'blue', fontSize:'12px',textDecoration: 'underline', cursor: 'pointer', position:'relative',display:'inline-block'
        // }}
        //   > {row.source_link} </a>
                         
            
        //                 </div>
        //               )
            
        //              },
        //         },
                {
                  dataField: 'source_link',
                  text: 'NCT ID',
                  // align: 'center',
                  headerStyle: (colum, colIndex) => {
                      return { width: '10%',borderColor:'#02365F' };
                  },
              },
            ],
            columnsEventsDetails: [
                {
                    dataField: 'events',
                    text: 'Event Name',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'event_type',
                    text: 'Event Type',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                // {
                //     dataField: 'speciality_type',
                //     text: 'Speciality Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '10%' };
                //     },
                // },
                // {
                //     dataField: 'sub_speciality',
                //     text: 'Sub Speciality Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '10%' };
                //     },
                // },
                // {
                //     dataField: 'procedure_type',
                //     text: 'Procedure Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '10%' };
                //     },
                // },
                {
                    dataField: 'event_desc',
                    text: 'Event Description',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%' ,borderColor:'#02365F'};
                    },
                },
                {
                    dataField: 'event_topic',
                    text: 'Event Topic',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'conditions',
                    text: 'Conditions',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'intervention',
                    text: 'Interventions',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'date',
                    text: 'Date',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '8%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'session_type',
                    text: 'Session Type',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'role_id',
                    text: 'Role',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '8%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'event_sponsor',
                    text: 'Sponsor',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '15%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'total_event_attendees',
                    text: 'Total Event Attendees',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '15%' ,borderColor:'#02365F'};
                    },
                },
                {
                    dataField: 'country_name',
                    text: 'Country',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%' ,borderColor:'#02365F'};
                    },
                },
                // {
                //     dataField: 'source_link',
                //     text: 'Source Link',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '15%' };
                //     },
                // },
            ],
            columnsGrants: [
                {
                    dataField: 'company',
                    text: 'Company',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%' ,borderColor:'#02365F'};
                    },
                },
                {
                    dataField: 'title',
                    text: 'Title',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'year',
                    text: 'Year',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'amount',
                    text: 'Amount',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'co_investigator',
                    text: 'Co-Investigator',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
            ],
            columnsGuidelines: [
                {
                    dataField: 'year',
                    text: 'Year',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                // {
                //     dataField: 'speciality_type',
                //     text: 'Speciality Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '10%' };
                //     },
                // },
                // {
                //     dataField: 'sub_speciality',
                //     text: 'Sub Speciality Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '15%' };
                //     },
                // },
                // {
                //     dataField: 'procedure_type',
                //     text: 'Procedure Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '15%' };
                //     },
                // },
                {
                  dataField: 'organization',
                  text: 'Organization',
                  // align: 'center',
                  headerStyle: (colum, colIndex) => {
                      return { width: '10%' ,borderColor:'#02365F'};
                  },
              },
              {
                  dataField: 'type_of_organization',
                  text: 'Type of Organization',
                  // align: 'center',
                  headerStyle: (colum, colIndex) => {
                      return { width: '10%' ,borderColor:'#02365F'};
                  },
              },
                
                {
                    dataField: 'title',
                    text: 'Title',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                  dataField: 'role',
                  text: 'Role',
                  // align: 'center',
                  headerStyle: (colum, colIndex) => {
                      return { width: '10%' ,borderColor:'#02365F'};
                  },
              },
                
                // {
                //     dataField: 'manual_weightage',
                //     text: 'Manual Weightage',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //     return {width: '8%' };
                //     },
                // },
            ],
            columnsHospitalAffiliations: [
                {
                    dataField: 'hospital',
                    text: 'Hospital',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                // new
                {
                    dataField: 'role',
                    text: 'Role',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%' ,borderColor:'#02365F'};
                    },
                },
                // new
                {
                    dataField: 'from_year',
                    text: 'From',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                // new
                {
                    dataField: 'to_year',
                    text: 'To',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'city_name',
                    text: 'City',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'state_name',
                    text: 'State',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'country_name',
                    text: 'Country',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%' ,borderColor:'#02365F'};
                    },
                },
            ],
            columnsInteractions: [
                {
                    dataField: 'date',
                    text: 'Date',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'opinion_leader',
                    text: 'Opinion Leader',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%' ,borderColor:'#02365F'};
                    },
                },
                {
                    dataField: 'emp_name',
                    text: 'Entered By',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'product',
                    text: 'Product',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'type',
                    text: 'Type',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%' ,borderColor:'#02365F'};
                    },
                },
                {
                    dataField: 'mode',
                    text: 'Mode',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'time',
                    text: 'Time',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'activity_or_tool_used',
                    text: 'Activity or Tool Used',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '13%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'comments',
                    text: 'Comments',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '13%',borderColor:'#02365F' };
                    },
                },
            ],

            columnsPressItem: [
                {
                    dataField: 'title',
                    text: 'Title',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '25%',borderColor:'#02365F' };
                    },
                },
                // new
                {
                    dataField: 'quote',
                    text: 'Quote',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '25%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'date',
                    text: 'Date',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {width: '25%', borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'source_link',
                    text: 'Press Item Link',
                    formatter: (cell, row) => {
                      return(
                      <div >
                        
                        <a href={row.source_link}target="_blank"  style={{ color: '#069', fontSize:'12px',textDecoration: 'underline', cursor: 'pointer'
                            }}
                              > {row.source_link} </a>                                           
            
                        </div>
                      )
            
                     },
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return {width: '25%', borderColor:'#02365F' };
                    },
                },
            ],
            columnsPresentation: [
                {
                    dataField: 'title',
                    text: 'Title',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                // new
                {
                    dataField: 'event_name',
                    text: 'Event Name',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%' ,borderColor:'#02365F'};
                    },
                },
                {
                    dataField: 'type',
                    text: 'Type',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%' ,borderColor:'#02365F'};
                    },
                },
                {
                    dataField: 'sponsor',
                    text: 'Sponsor',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'location',
                    text: 'Location',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'date',
                    text: 'Date',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%' ,borderColor:'#02365F'};
                    },
                },
            ],
            columnsPublicationDetails: [
                {
                    dataField: 'journal',
                    text: 'Journal',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '12%',borderColor:'#02365F' };
                    },
                },
                // {
                //     dataField: 'speciality_type',
                //     text: 'Speciality Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '10%' };
                //     },
                // },
                // {
                //     dataField: 'sub_speciality',
                //     text: 'Sub Speciality Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '10%' };
                //     },
                // },
                // {
                //     dataField: 'procedure_type',
                //     text: 'Procedure Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '10%' };
                //     },
                // },
                {
                    dataField: 'type_of_journal',
                    text: 'Type of Journal',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '12%' ,borderColor:'#02365F'};
                    },
                },
                {
                    dataField: 'publication_title',
                    text: 'Publication Title',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '15%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'date',
                    text: 'Date',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '12%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'position',
                    text: 'Position',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '12%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'co_author',
                    text: 'Co-Author',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '12%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'key_words',
                    text: 'Key Words',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '12%',borderColor:'#02365F' };
                    },
                },
                // {
                //     dataField: 'manual_weightage',
                //     text: 'Manual Weightage',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //     return { width: '8%' };
                //     },
                // },
                {
                    dataField: '',
                    text: 'PMID',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '12%',borderColor:'#02365F' };
                    },
                },
            ],
            columnsSunshinePayment: [
                {
                    dataField: 'date',
                    text: 'Date',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'type',
                    text: 'Type',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'devices',
                    text: 'Devices',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'location',
                    text: 'Location',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%' ,borderColor:'#02365F'};
                    },
                },
                
                {
                    dataField: 'amount',
                    text: 'Amount ($)',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'payment_type',
                    text: 'Payment Type',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '10%',borderColor:'#02365F' };
                    },
                },
                {
                    dataField: 'OL_address',
                    text: 'Company Name',
                    // align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '15%' ,borderColor:'#02365F'};
                    },
                },
                // {
                //     dataField: 'speciality_type',
                //     text: 'Speciality Type',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '15%' };
                //     },
                // },
                // {
                //     dataField: 'NPI',
                //     text: 'NPI',
                //     // align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { width: '10%' };
                //     },
                // },
            ],

        };
    }

    //Sub Navbar functions of summary piechart
  HomePiechartFun = async () => {
    await this.setState({
      home_piechart_flag: true,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  EventPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: true,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  PublicationPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: true,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  AdboardPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: true,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  GuidelinePiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: true,
      clinicaltrial_piechart_flag: false,
    });
  }
  ClinicaltrialPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: true,
    });
  }

    // Function for drawer side selection
    PersonalInformationFun = async () => {
        await this.setState({
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            SummaryFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            PersonalInformationFlag: true,
        })
    }

    validate = () => {
      let noofkolErr = '';
      let noofbasickolErr = '';
      let noofdeepkolErr = '';
      let specilityErr = '';
      let procedureErr = '';
      let countryErr = '';
      let Multiple_single_profileErr='';
    
      
      if(this.state.QuoteData.Multiple_single_profile=="BuyMultipleProfiles")
      {
          if (!this.state.QuoteData.Multiple_single_profile) {
            Multiple_single_profileErr = 'Please select';
          }
      
          if (!this.state.QuoteData.no_of_basic_profile) {
            noofbasickolErr = 'Please input no of basic profile';
          }
         
          if (!this.state.QuoteData.no_of_deep_profile) {
            noofdeepkolErr = 'Please input no of deep profile';
          }   
      
            if (this.state.QuoteData.countrieslist.length <= 0) {
              countryErr = 'Please select country';
            }
            
            if (!this.state.QuoteData.specialty_id) {
              specilityErr = 'Please select speciality';
            }
      
          // if (noofkolErr || procedureErr ||countryErr || specilityErr || profilingtypeErr || Multiple_single_profileErr) {
          //   this.setState({ noofkolErr, specilityErr ,procedureErr,countryErr,profilingtypeErr,Multiple_single_profileErr });
          //   return false;
          // }
          if (noofbasickolErr  ||countryErr || specilityErr || noofdeepkolErr || Multiple_single_profileErr) {
            this.setState({ noofbasickolErr, specilityErr ,countryErr,noofdeepkolErr,Multiple_single_profileErr });
            return false;
          }
    }
    else
    {
        if (!this.state.QuoteData.Multiple_single_profile) {
          Multiple_single_profileErr = 'Please select';
        }
        if (Multiple_single_profileErr) {
          this.setState({ Multiple_single_profileErr });
          return false;
        }
    }
    
      
      return true;
    }
  
   

    SaveQuoteinfo = async () =>
    {

    const isValid = this.validate();
   //  const isValid =true;
      if (isValid) {
     
        this.state.QuoteData.no_of_kol=Number(this.state.QuoteData.no_of_basic_profile) + Number(this.state.QuoteData.no_of_deep_profile);

      this.setState({
        ShowBar: true,
      })

      const url = global.websiteUrl+'/service/KOL_landing/save_quote_data';
      let formData = new FormData();

      formData.append('Quote_Info', JSON.stringify(this.state.QuoteData));
      
      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("lgstatus", JSON.stringify(lgstatus));
          console.log("response message2", response.data.message)
       
          // alert(response.data.status);
          // alert(response.data.quotation_id);
        

          this.setState({
            ShowBar: false,
          })
          if (response.data.status == true) {

            if(this.state.client_name !="Guest")
            {
              Swal.fire({
                icon: 'success',
                //text: response.data.message,
                text: ' Thanks for quote,Our experts will get back to you within 24 hours.!',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'

              })

              
              this.setState({infoQuoteModal:false,QuoteModal:false})
             
              
          }
          else
          {
           // alert(response.data.quotation_id);
            sessionStorage.setItem('quotation_id',response.data.quotation_id);
            this.setState({infoQuoteModal:false,QuoteModal:false,SignupModal:true,quotation_id:response.data.quotation_id})
           
           // this.setState({ RedirectSignUp: true })
          }
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(error => {
          console.log(error);
        });

      this.setState({
        pnameErr: '',
        psowErr: '',
      });
    }

    }


    redirectknowmore = async () =>
    {
      this.setState({redirectknowmore:true})
     // alert('redirectknowmorehi');
    }

    toggleBackToHome()
    {
      this.setState({redirecthome:true})
    }

    
   onChangeValue= async (event) => {

      if(event.target.value == "BuyMultipleProfiles")
      {
        let { QuoteData } = this.state;
        QuoteData.kol_id = '';
        this.setState({ QuoteData });

        //alert(event.target.value);
        this.setState({multiplekolquotmodel:true,Multiple_single_profileErr : '',})
      }else
      { 
        let { QuoteData } = this.state;
        QuoteData.kol_id =this.state.kol_id;
      QuoteData.no_of_kol='';
      QuoteData.no_of_deep_profile='';
      QuoteData.no_of_basic_profile='';
      QuoteData.specialty_id='';
      QuoteData.procedure_type='';
     // QuoteData.Profilingtype='';
      QuoteData.countrieslist='';
      this.setState({ QuoteData });


        // let { QuoteData } = this.state;
        // QuoteData.kol_id =this.state.kol_id;
        // this.setState({ QuoteData });

        //alert(event.target.value);
        this.setState({
          multiplekolquotmodel:false,
          //noofkolErr: '',
          noofbasickolErr: '',
          noofdeepkolErr: '',
          specilityErr: '',
          procedureErr :'',
          countryErr : '',
          Multiple_single_profileErr : '',
         // profilingtypeErr:'',  
        
        
        })
      }

    // alert(event.target.value);
      let { QuoteData } = this.state;
        QuoteData.Multiple_single_profile = event.target.value;
        this.setState({ QuoteData });
      console.log(event.target.value);
    }

    toggleinfoBuyModal = async () => {   
       this.setState({QuoteModal:true,})
    }

toggleDeleteModal()
{

  let idtoDelete = this.state.quotation_id;
 // alert(idtoDelete);
  if (idtoDelete !== "") {
    
    this.setState({
      SignupModal:!this.state.SignupModal,
    })

    this.setState({
        ShowProcedure: true,
    })
    const url = global.websiteUrl+'/service/KOL_Landing/delete_quote_data';

    let formData = new FormData();
   // formData.append('oper', "del");
    formData.append('id', idtoDelete);

    const config = {
        // headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post(url, formData, config)
        .then(response => {
            console.log(response.data);
           // alert(response.data.status);
            if(response.data.status == true)
            {
              // this.setState({
              //   SignupModal:!this.state.SignupModal,
              //   //toggleSignupModal:!this.state.toggleSignupModal,
              // })
            
            }
          
        })
        .catch(error => {
            console.log(error);
        });
    
  }
 
  

  // this.setState({
  //   SignupModal:!this.state.SignupModal,
  //  // toggleSignupModal:!this.state.toggleSignupModal,
  // })

}
  // toggleinfoBuyModal = async () => {             
  //   this.setState({infoQuoteModal:true,})   
     
  //  }
    
   togglecanQuoteinfoModal = async () => {             
              
    this.setState({infoQuoteModal:false,})     
   
 }

    

    toggleBuyModal = async () => {

      sessionStorage.removeItem('Buyer_order')
      sessionStorage.removeItem('Buyer_kol_id')        
      sessionStorage.removeItem('Buyer_client_name')
      sessionStorage.removeItem('Buyer_client_id')

      sessionStorage.setItem('Buyer_order',"NewBuyOrder");
      sessionStorage.setItem('Buyer_kol_id',this.state.kol_id);
      sessionStorage.setItem('Buyer_client_name',this.state.client_name);
      sessionStorage.setItem('Buyer_client_id',this.state.client_id);
      this.setState({infoQuoteModal:true})
     
      // if(this.state.client_name !="Guest")
      // {
      //  //alert(this.state.client_name);
      //  //alert(this.state.kol_id);
      //  this.setState({infoQuoteModal:true})
      //  //alert('Already Login');
      // }
      // else
      // {
      //   // alert(this.state.kol_id);
      //   // alert(this.state.client_name);
      //   // alert(this.state.client_id);
      //   this.setState({
      //     RedirectSignUp:true
      //   // redirectLogin:true
      //   })
      // }       
    
     
   }


     formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;
      
        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, '');
      
        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;
      
        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
      
        if (phoneNumberLength < 4) return  phoneNumber;
      
        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
      
        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        if(phoneNumberLength == 10 )
        {
            return `+${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
              3,
              6
            )}-${phoneNumber.slice(6, 10)}`;
        }
       else if(phoneNumberLength == 11 )
        {
            return `+${phoneNumber.slice(0, 1)} ${phoneNumber.slice(
              1,
              4
            )}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
        }
        else if(phoneNumberLength == 12 )
        {
          return `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
            2,
            5
          )}-${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`;

        }
        else if(phoneNumberLength > 5  )
        {
          
          return + phoneNumber;
        }

        // if(phoneNumberLength == 10 )
        // {
        // return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        //   3,
        //   6
        // )}-${phoneNumber.slice(6, 10)}`;
        // }
        // else
        // {
        //   return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
        //     2,
        //     5
        //   )}-${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`;

        // }
      }










    componentDidMount() {
        let getSession_Kol_Id = sessionStorage.getItem('kol_id')
        if (getSession_Kol_Id !== null) {
            this.setState({
                kol_id: JSON.parse(getSession_Kol_Id),
            })
          
        }

        let getSession_Client_Name = sessionStorage.getItem('client_name')
        if (getSession_Client_Name !== null) {
            this.setState({
                client_name: getSession_Client_Name,
            })

            let { QuoteData } = this.state;
                QuoteData.client_name = getSession_Client_Name;
            this.setState({ QuoteData });

        }

        let getSession_client_id = sessionStorage.getItem('client_id')
        if (getSession_client_id !== null) {
            this.setState({
              client_id: getSession_client_id,
            })

            let { QuoteData } = this.state;
                QuoteData.client_id = getSession_client_id;
            this.setState({ QuoteData });
        }
        

        console.log("kol_id", this.state.kol_id);
        console.log("getSession_Kol_Id", getSession_Kol_Id);

        this.setState({
           // LoadingBar:true,
            ShowBar: true,
        })

        const options = {
          method: 'POST',
        }

        //  Speciality type data
        fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
        .then((Response) => Response.json())
        .then((findresponse) => {
          console.log(findresponse)
          var testDataSpeciality = findresponse.data;
          console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
          this.setState({
            SpecialityData: findresponse.data,
          })
        })
           // Country list(market) 
    fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
    .then((Response) => Response.json())
    .then((findresponse) => {
      console.log(findresponse)
      var testDataCountryList = findresponse.Country_Details;
      console.log("testDataCountryList", JSON.stringify(testDataCountryList));
      this.setState({
        CountryListData: findresponse.Country_Details,
        Client_Unique_id: findresponse.client_system_id,
      })
      console.log("Client_Unique_id", this.state.Client_Unique_id);
    })

        
 


        // const options = {
        //     method: 'POST',
        // }
        const url = global.websiteUrl+'/service/KOL_Dashboard/get_FetchKOLInfo';
        let formData = new FormData();
        // formData.append('kol_id','CHCUI001');
        formData.append('kol_id', JSON.parse(getSession_Kol_Id));
        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var lgdata = response.data;
                console.log("lgdata=====", JSON.stringify(lgdata));
                var KOL_Personal_Info = response.data.KOL_Personal_Info;
                console.log("KOL_Personal_Info==========", JSON.stringify(KOL_Personal_Info));
                var KOL_Work_Details = response.data.KOL_Work_Details;
                console.log("KOL_Work_Details==========", JSON.stringify(KOL_Work_Details));
                this.setState({
                    PersonalInformation: KOL_Personal_Info,
                    WorkDetails: response.data.KOL_Work_Details,
                    Biography: response.data.KOL_Biography,
                    ProfessionalMemberships: response.data.KOL_Professional_Memberships,
                    Education: response.data.KOL_Education,
                    AdvisoryBoard: response.data.KOL_Advisory_Board,
                    AwardsHonours: response.data.KOL_Awards_Honours,
                    BooksMonographs: response.data.KOL_Books_Monographs,
                    Commitees: response.data.KOL_Commitees,
                    ClinicalTrialDetails: response.data.KOL_Clinical_Trial_Details,
                    EventsDetails: response.data.KOL_Events_Details,
                    Grants: response.data.KOL_Grants,
                    Guidelines: response.data.KOL_Guidelines,
                    HospitalAffiliations: response.data.KOL_Hospital_Affiliations,
                    Interactions: response.data.KOL_Interactions,
                    PressItem: response.data.KOL_Press_Item,
                    Presentation: response.data.KOL_Presentations,
                    PublicationDetails: response.data.KOL_Publication_Details,
                    SunshinePayment: response.data.KOL_Sunshine_Payment,

                    profile_photo_for_edit: response.data.KOL_Profile_Photo,
                    BiographyResume_edit: response.data.KOL_Resume,
                    BiographyResumeFileName: response.data.KOL_Resume,

                    profile_photo: response.data.KOL_Profile_Photo,
                    // BiographyResume: response.data.KOL_Resume, 

                })

                this.setState({
                    ShowBar: false,
                    // ContractFileName: ContractFileNameapi
                })
                console.log("PersonalInformation", this.state.PersonalInformation);
                console.log("profile_photo_for_edit", this.state.profile_photo_for_edit);
                console.log("Education", this.state.Education);
                console.log("WorkDetails===============", this.state.WorkDetails);

                // to convert country id to string 
                if (KOL_Personal_Info.country_id) {
                    if (KOL_Personal_Info.country_id !== '0') {
                        const options = {
                            method: 'POST',
                        }
                        fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
                            .then((Response) => Response.json())
                            .then((findresponse) => {
                                console.log(findresponse)
                                var testDataCountryList = findresponse.Country_Details;
                                console.log("testDataCountryList", JSON.stringify(testDataCountryList));


                                testDataCountryList.forEach(element => {
                                    if (element.id == this.state.PersonalInformation.country_id) {
                                        let { PersonalInformation } = this.state;
                                        PersonalInformation.country_id = element.country_name;
                                        this.setState({ PersonalInformation });
                                    }
                                })
                            })
                    }
                }

                if (KOL_Personal_Info.country_id) {
                    if (KOL_Personal_Info.country_id !== '0') {
                        const url = global.websiteUrl+'/service/Location/get_StateList';
                        let formData = new FormData();
                        formData.append('country_id', this.state.PersonalInformation.country_id);

                        const config = {
                            //   headers: { 'content-type': 'multipart/form-data' }
                        }
                        axios.post(url, formData, config)
                            .then(response => {
                                console.log(response);
                                var testStateData = response.data.Country_Details;
                                console.log("testStateData", JSON.stringify(testStateData));
                                this.setState({
                                    StateData: response.data.Country_Details,
                                })
                                console.log("StateData======", this.state.StateData)

                                testStateData.forEach(element => {
                                    if (element.id == this.state.PersonalInformation.state_id) {
                                        let { PersonalInformation } = this.state;
                                        PersonalInformation.state_id = element.state_name;
                                        this.setState({ PersonalInformation });
                                    }
                                })
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }

                if (KOL_Personal_Info.country_id) {
                    if (KOL_Personal_Info.country_id !== '0') {
                        const url = global.websiteUrl+'/service/Location/get_CityList';
                        let formData = new FormData();
                        formData.append('country_id', this.state.PersonalInformation.country_id);

                        const config = {
                            //   headers: { 'content-type': 'multipart/form-data' }
                        }
                        axios.post(url, formData, config)
                            .then(response => {
                                console.log(response);
                                var testCityData = response.data.Country_Details;
                                console.log("testCityData", JSON.stringify(testCityData));
                                this.setState({
                                    CityData: response.data.Country_Details,
                                })
                                console.log("CityData======", this.state.CityData)
                            })
                            .catch(error => {
                                console.log(error);
                            });


                    }
                }

                if (KOL_Personal_Info.specialty_id) {
                    fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
                        .then((Response) => Response.json())
                        .then((findresponse) => {
                            console.log(findresponse)
                            var testDataSpeciality = findresponse.data;
                            console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));

                            testDataSpeciality.forEach(element => {
                                if (element.id == this.state.PersonalInformation.specialty_id) {
                                    let { PersonalInformation } = this.state;
                                    PersonalInformation.specialty_id = element.speciality_type;
                                    this.setState({ PersonalInformation });
                                }
                            })
                        })
                }


                if(this.state.PersonalInformation.doctor_email2 !== "")
                {
                    this.setState({
    
                    secondryEmail:", " + this.state.PersonalInformation.doctor_email2,
                    })
    
                }
    


                this.setState({

                  DoctorCompleteName:this.state.PersonalInformation.salutation +" " +this.state.PersonalInformation.doctor_full_name +", "+this.state.PersonalInformation.suffix ,
          
                })
          

                this.setState({ranking:this.state.PersonalInformation.aggregate_score})
                // alert(this.state.DoctorCompleteName);


                // if(KOL_Personal_Info.specialty_id){
                //     console.log("in Procedure list");
                //     console.log("speciality_type_id",this.state.PersonalInformation.specialty_id);           
                //     const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
                //     let formData = new FormData();
                //     formData.append('speciality_type_id',this.state.PersonalInformation.specialty_id);
                //     formData.append('search_type',"Procedure");

                //     const config = {     
                //     //   headers: { 'content-type': 'multipart/form-data' }
                //     }
                //     axios.post(url, formData, config)
                //         .then(response => {
                //             console.log(response);
                //             var proceduredata= response.data;
                //             console.log("proceduredata",JSON.stringify(proceduredata));
                //             this.setState({
                //             ProcedureData : response.data.data,
                //             showProcedureSubSpecialityDropdown: false,
                //             showProcedureSubSpecialityDropdownAdvBoard: false,
                //             }) 
                //             console.log("ProcedureData",this.state.ProcedureData)
                //             this.setState({
                //             showProcedureSubSpecialityDropdown: true,
                //             showProcedureSubSpecialityDropdownAdvBoard: true,
                //             }) 

                //             var proceduredataFromJSON2 = [];
                //             var proceduredata= response.data.data;
                //             var proceduredataFromJSON = this.state.PersonalInformation.procedure_type_id.split(',');
                //             if(proceduredata){
                //                 proceduredata.forEach(element => {
                //                    proceduredataFromJSON.forEach(elementProcedure => {
                //                         if( element.id == elementProcedure ){
                //                             console.log("same option is here Team Members",element);
                //                             var joined = proceduredataFromJSON2.concat(element.procedure_type);
                //                             proceduredataFromJSON2 = joined;
                //                             console.log("proceduredataFromJSON2",proceduredataFromJSON2);
                //                         }
                //                     })       
                //                 })
                //             }
                //             let { PersonalInformation } = this.state;
                //             PersonalInformation.procedure_type_id = proceduredataFromJSON2.toString();
                //             this.setState({ PersonalInformation });

                //         })
                //         .catch(error => {
                //             console.log(error);
                //         }); 
                // }

                // if(KOL_Personal_Info.specialty_id){
                //     console.log("in sub speciality list");
                //     const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
                //     let formData = new FormData();
                //     formData.append('speciality_type_id',this.state.PersonalInformation.specialty_id);
                //     formData.append('search_type',"Sub Speciality");

                //     const config = {     
                //     //   headers: { 'content-type': 'multipart/form-data' }
                //     }
                //     axios.post(url, formData, config)
                //         .then(response => {
                //             console.log(response);
                //             var SubSpecialitydata= response.data;
                //             console.log("SubSpecialitydata",JSON.stringify(SubSpecialitydata));
                //             this.setState({
                //             SubSpecialitydata : response.data.data,
                //             showProcedureSubSpecialityDropdown: false,
                //             }) 
                //             console.log("SubSpecialitydata",this.state.SubSpecialitydata)
                //             this.setState({
                //             showProcedureSubSpecialityDropdown: true,
                //             }) 

                //             var subSpecialitydataFromJSON2 = [];
                //             var subSpecialitydata= response.data.data;
                //             var subSpecialitydataFromJSON = this.state.PersonalInformation.sub_specialty_id.split(',');
                //             if(subSpecialitydata){
                //                 subSpecialitydata.forEach(element => {
                //                    subSpecialitydataFromJSON.forEach(elementSubSpeciality => {
                //                         if( element.id == elementSubSpeciality ){
                //                             console.log("same option is here Team Members",element);
                //                             var joined = subSpecialitydataFromJSON2.concat(element.sub_speciality_type);
                //                             subSpecialitydataFromJSON2 = joined;
                //                             console.log("subSpecialitydataFromJSON2",subSpecialitydataFromJSON2);
                //                         }
                //                     })       
                //                 })
                //             }
                //             let { PersonalInformation } = this.state;
                //             PersonalInformation.sub_specialty_id = subSpecialitydataFromJSON2.toString();
                //             this.setState({ PersonalInformation });
                //             // this.setState({
                //             //     TeamMembers: TeamMembers3.toString(),
                //             // })

                //         })
                //         .catch(error => {
                //             console.log(error);
                //         }); 
                // }
            })


            

          const url2 = global.websiteUrl+'/service/KOL/report_by_kol_id';
           // const url2 ='http://localhost/Cetas/service/KOL/report_by_kol_id';
            let formData2 = new FormData();
            formData2.append('kol_unique_id', JSON.parse(getSession_Kol_Id));
            formData2.append('for_filter', false);
            formData2.append('filtered_kol_id ', []);
            formData2.append('Geography_type','National');
            axios.post(url2, formData2, config)
            .then(response => {
                console.log('response of report api', response.data);


              //Engaggement Summary charting data
              this.state.year=response.data.EngagementSummary.year;

              //Engagement Summary
              this.state.Summaryadvisoryboard=response.data.EngagementSummary.kol_advisory_board;
              this.state.Summaryeventdetails=response.data.EngagementSummary.kol_event_details;
              this.state.Summarypublicationdetails=response.data.EngagementSummary.kol_publication_details;
              this.state.Summaryguidlines=response.data.EngagementSummary.kol_guidlines;
              this.state.Summaryclinicaltrialdetails=response.data.EngagementSummary.kol_clinical_trial_details;
              this.state.Summaryoverall=response.data.EngagementSummary.overall;


              //Engagement RecentTrend
              this.state.RecentTrendadvisoryboard=response.data.RecentTrend.kol_advisory_board;
              this.state.RecentTrendeventdetails=response.data.RecentTrend.kol_event_details;
              this.state.RecentTrendpublicationdetails=response.data.RecentTrend.kol_publication_details;
              this.state.RecentTrendguidlines=response.data.RecentTrend.kol_guidlines;
	            this.state.RecentTrendclinicaltrialdetails=response.data.RecentTrend.kol_clinical_trial_details;






                //Events/Conferences charting data
              this.state.year=response.data.EventsConferencesChart.year;

              //Geographic Scope of the Speaking Engagements
              this.state.EventsGeographicLocal=response.data.EventsConferencesChart.event_Geographic_Scope_Local_Percent;
              this.state.EventsGeographicGlobal=response.data.EventsConferencesChart.event_Geographic_Scope_Global_Percent;
              this.state.EventsGeographicNational=response.data.EventsConferencesChart.event_Geographic_Scope_National_Percent;
              this.state.EventsGeographicRegional=response.data.EventsConferencesChart.event_Geographic_Scope_Regional_Percent;
              
              //Role of the Speaking Engagements
              this.state.EventsRoleRegular=response.data.EventsConferencesChart.event_Role_Speaking_Regular_Percent;
              this.state.EventsRoleKeynote=response.data.EventsConferencesChart.event_Role_Speaking_Keynote_Percent;
              this.state.EventsRoleChairperson=response.data.EventsConferencesChart.event_Role_Speaking_Chairperson_Percent;
              this.state.EventsRoleModerator=response.data.EventsConferencesChart.event_Role_Speaking_Moderator_Percent;
              
              
              //Publications charting data

              this.state.year=response.data.PublicationsChart.year;
              
              //Geographic Scope of the prominence Engagements
              this.state.PublicationsGeographicGlobal=response.data.PublicationsChart.publications_Geographic_Scope_Global_Percent;
              this.state.PublicationsGeographicRegional=response.data.PublicationsChart.publications_Geographic_Scope_Regional_Percent;
              this.state.PublicationsGeographicNational=response.data.PublicationsChart.publications_Geographic_Scope_National_Percent;
              this.state.PublicationsGeographicLocal=response.data.PublicationsChart.publications_Geographic_Scope_Local_Percent;
              
              //Role Prominence: Publication

               this.state.PublicationsRoleFirstAuthor=response.data.PublicationsChart.publications_Role_prominence_FirstAuthor_Percent;
               this.state.PublicationsRoleSecondAuthor=response.data.PublicationsChart.publications_Role_prominence_SecondAuthor_Percent;
               this.state.PublicationsRoleLastAuthor=response.data.PublicationsChart.publications_Role_prominence_LastAuthor_Percent;




              //Clinical Trials Chart charting data

              this.state.year=response.data.ClinicalTrialsChart.year;
              
              //Geographic Scope: Clinical Trials
              this.state.ClinicalsGeographicLocal=response.data.ClinicalTrialsChart.Clinical_Geographic_Scope_Local_Percent;
              this.state.ClinicalsGeographicGlobal=response.data.ClinicalTrialsChart.Clinical_Geographic_Scope_Global_Percent;
              this.state.ClinicalsGeographicNational=response.data.ClinicalTrialsChart.Clinical_Geographic_Scope_National_Percent;
              this.state.ClinicalsGeographicRegional=response.data.ClinicalTrialsChart.Clinical_Geographic_Scope_Regional_Percent;
              
               //Trial Location Prominence: Clinical Trials

              this.state.ClinicalsTrial50_more=response.data.ClinicalTrialsChart.Clinical_Trial_Location_50_more_Percent;
              this.state.ClinicalsTrial1=response.data.ClinicalTrialsChart.Clinical_Trial_Location_1_Percent;
              this.state.ClinicalsTrial1_10=response.data.ClinicalTrialsChart.Clinical_Trial_Location_1_10_Percent;
              this.state.ClinicalsTrial11_50=response.data.ClinicalTrialsChart.Clinical_Trial_Location_11_50_Percent;
            
              //Clinical Trials Type
              this.state.ClinicalsTrialInterventional=response.data.ClinicalTrialsChart.Clinical_Trial_Type_Interventional_Percent;
              this.state.ClinicalsTrialObservational=response.data.ClinicalTrialsChart.Clinical_Trial_Type_Observational_Percent;

              //Advisory Board  charting data

              this.state.year=response.data.AdvisoryBoardChart.year;
                          
              //Geographic Scope: Advisory Board                                                  

              this.state.AdvisoryGeographicLocal=response.data.AdvisoryBoardChart.Advisory_Board_Geographic_Scope_Local_Percent;
              this.state.AdvisoryGeographicGlobal=response.data.AdvisoryBoardChart.Advisory_Board_Geographic_Scope_Global_Percent;
              this.state.AdvisoryGeographicNational=response.data.AdvisoryBoardChart.Advisory_Board_Geographic_Scope_National_Percent;
              this.state.AdvisoryGeographicRegional=response.data.AdvisoryBoardChart.Advisory_Board_Geographic_Scope_Regional_Percent;
              
              
              //Role Prominence: Advisory Board                                                  

              this.state.AdvisoryRoleAdvisoryCommittee=response.data.AdvisoryBoardChart.Advisory_Board_Role_Prominence_Advisory_Committee_Percent;
              this.state.AdvisoryRolechair=response.data.AdvisoryBoardChart.Advisory_Board_Role_Prominence_chair_Percent;
              this.state.AdvisoryRolecochair=response.data.AdvisoryBoardChart.Advisory_Board_Role_Prominence_co_chair_Percent;
            
              //Affalation Type
              this.state.AdvisoryAffiliationMedtechCompanies=response.data.AdvisoryBoardChart.Advisory_Board_Affiliation_Type_Medtech_Companies_Percent;
              this.state.AdvisoryAffiliationPharmaCompanies=response.data.AdvisoryBoardChart.Advisory_Board_Affiliation_Type_Pharma_Companies_Percent;
              this.state.AdvisoryAffiliationProfessionalAssociations=response.data.AdvisoryBoardChart.Advisory_Board_Affiliation_Type_Professional_Associations_Percent;
              this.state.AdvisoryAffiliationGovernmentInstitutions=response.data.AdvisoryBoardChart.Advisory_Board_Affiliation_Type_Government_Institutions_Percent;

                //Guidelines  charting data

                this.state.year=response.data.Guidelineschart.year;
                                
                //Geographic Scope: Guidelines                                               
                this.state.GuidelinesGeographicLocal=response.data.Guidelineschart.Guidelines_Geographic_Scope_Local_Percent;
                this.state.GuidelinesGeographicGlobal=response.data.Guidelineschart.Guidelines_Geographic_Scope_Global_Percent;
                this.state.GuidelinesGeographicNational=response.data.Guidelineschart.Guidelines_Geographic_Scope_National_Percent;
                this.state.GuidelinesGeographicRegional=response.data.Guidelineschart.Guidelines_Geographic_Scope_Regional_Percent;


                //Role Prominence: Guidelines                                                 
                this.state.GuidelinesRoleAuthor=response.data.Guidelineschart.Guidelines_Role_Prominence_Author_Percent;
                this.state.GuidelinesRoleCommitteeMember=response.data.Guidelineschart.Guidelines_Role_Prominence_Committee_Member_Percent;
                this.state.GuidelinesRoleCommitteeChair=response.data.Guidelineschart.Guidelines_Role_Prominence_Committee_Chair_Percent;

                //Affalation Type
                this.state.GuidelinesAffiliationAcademicHospital=response.data.Guidelineschart.Guidelines_Affiliation_Type_Academic_Hospital_Percent;
                this.state.GuidelinesAffiliationProfessionalAssociations=response.data.Guidelineschart.Guidelines_Affiliation_Type_Professional_Associations_Percent;
                this.state.GuidelinesAffiliationGovernmentInstitutions=response.data.Guidelineschart.Guidelines_Affiliation_Type_Government_Institutions_Percent;


                
                


               
               // alert(this.state.EventsGeographicLocal.event_Scope_Lifetime);
                
               // this.state.latest_year=this.state.eventsgeographicscope.year.latest_year.toString();
                //alert('hhhhhhhhh');    



                // this.state.engagementSummary = response.data.EngagementSummary;

                // this.state.latest_year=this.state.engagementSummary.year.latest_year.toString();                

               
                // // activity index chart and kol score rating data
                // this.state.event_kol_rating = response.data.KolRatingScore.kol_event_details;
                // this.state.publication_kol_rating = response.data.KolRatingScore.kol_publication_details;
                // this.state.ad_board_kol_rating = response.data.KolRatingScore.kol_advisory_board;
                // this.state.guideline_kol_rating = response.data.KolRatingScore.kol_guidlines;
                // this.state.clinicaltrial_kol_rating = response.data.KolRatingScore.kol_clinical_trial_details;

                // // piechart data
                // this.state.event_piechart = response.data.PieChart.kol_event_details;
                // this.state.publication_piechart = response.data.PieChart.kol_publication_details;
                // this.state.ad_board_piechart = response.data.PieChart.kol_advisory_board;
                // this.state.guideline_piechart = response.data.PieChart.kol_guidlines;
                // this.state.clinicaltrial_piechart = response.data.PieChart.kol_clinical_trial_details;

                // console.log('this.state.event_piechart', this.state.event_piechart);
                // console.log('this.state.publication_piechart', this.state.publication_piechart);
                // console.log('this.state.ad_board_piechart', this.state.ad_board_piechart);
                // console.log('this.state.guideline_piechart', this.state.guideline_piechart);
                // console.log('this.state.clinicaltrial_piechart', this.state.clinicaltrial_piechart);


                // // kol peer group standing data
                // this.state.event_peer_group_standing = response.data.Kol_peer_group_standing.kol_event_details;
                // this.state.publication_peer_group_standing = response.data.Kol_peer_group_standing.kol_publication_details;
                // this.state.ad_board_peer_group_standing = response.data.Kol_peer_group_standing.kol_adboard_details;
                // this.state.guideline_peer_group_standing = response.data.Kol_peer_group_standing.kol_guideline_details;
                // this.state.clinicaltrial_peer_group_standing = response.data.Kol_peer_group_standing.kol_clinical_trial_details;

                // // engagement summary data (apex chart)
                // this.state.engagementSummary = response.data.EngagementSummary;

                // this.state.latest_year=this.state.engagementSummary.year.latest_year.toString();                
                // this.state.mid_year=this.state.engagementSummary.year.mid_year.toString();
                // this.state.last_year=this.state.engagementSummary.year.last_year.toString();

               

                // // engagement coverage overall data
                // this.state.engagementCoverage_Global = response.data.EngagementCoverageOverall.Global;
                // this.state.engagementCoverage_Regional = response.data.EngagementCoverageOverall.Regional;
                // this.state.engagementCoverage_National = response.data.EngagementCoverageOverall.National;
                // this.state.engagementCoverage_Local = response.data.EngagementCoverageOverall.Local;

                

                // // Kol peer group standing geographic scope of speaking engagement
                // this.state.EventDetailsGeographicScope_Global = response.data.EventDetailsGeographicScope.Global;
                // this.state.EventDetailsGeographicScope_Regional = response.data.EventDetailsGeographicScope.Regional;
                // this.state.EventDetailsGeographicScope_National = response.data.EventDetailsGeographicScope.National;
                // this.state.EventDetailsGeographicScope_Local = response.data.EventDetailsGeographicScope.Local;

                // // Kol peer group standing role prominence of speaking engagement
                // this.state.EventDetailsRoleProminence_KeynoteSpeaker = response.data.EventDetailsRoleProminence.KeynoteSpeaker;
                // this.state.EventDetailsRoleProminence_Regular = response.data.EventDetailsRoleProminence.Regular;
                // this.state.EventDetailsRoleProminence_Chairperson = response.data.EventDetailsRoleProminence.Chairperson;

                // // Kol peer group standing geographic scope of Publication
                // this.state.PublicationDetailsGeographicScope_Global = response.data.PublicationDetailsGeographicScope.Global;
                // this.state.PublicationDetailsGeographicScope_Regional = response.data.PublicationDetailsGeographicScope.Regional;
                // this.state.PublicationDetailsGeographicScope_Local = response.data.PublicationDetailsGeographicScope.Local;

                // // Kol peer group standing role prominence of Publication
                // this.state.PublicationDetailsRoleProminence_First_author = response.data.PublicationDetailsRoleProminence.First_author;
                // this.state.PublicationDetailsRoleProminence_Last_author = response.data.PublicationDetailsRoleProminence.Last_author;
                // this.state.PublicationDetailsRoleProminence_Second_author = response.data.PublicationDetailsRoleProminence.Second_author;

                // //kol peer group standing geographic scope of guideline
                // this.state.GuidelineDetailsGeographicScope_Global = response.data.GuidelineDetailsGeographicScope.Global;
                // this.state.GuidelineDetailsGeographicScope_Regional = response.data.GuidelineDetailsGeographicScope.Regional;
                // this.state.GuidelineDetailsGeographicScope_National = response.data.GuidelineDetailsGeographicScope.National;
                // this.state.GuidelineDetailsGeographicScope_Local = response.data.GuidelineDetailsGeographicScope.Local;

                // // kol peer group standing geographic scope advisory board
                // this.state.AdvisoryBoardGeographicScope_Global = response.data.AdvisoryBoardGeographicScope.Global;
                // this.state.AdvisoryBoardGeographicScope_Regional = response.data.AdvisoryBoardGeographicScope.Regional;
                // this.state.AdvisoryBoardGeographicScope_Local = response.data.AdvisoryBoardGeographicScope.Local;

                // // Kol peer group standing role prominence of advisory board
                // this.state.AdvisoryBoardRoleProminence_Board_Member = response.data.AdvisoryBoardRoleProminence.Board_Member;
                // this.state.AdvisoryBoardRoleProminence_Commitee_Member = response.data.AdvisoryBoardRoleProminence.Commitee_Member;

                // // kol peer group standing geographic scope of clinical trial
                // this.state.ClinicalTrialsGeographicScope_Global = response.data.ClinicalTrialsGeographicScope.Global;
                // this.state.ClinicalTrialsGeographicScope_Regional = response.data.ClinicalTrialsGeographicScope.Regional;
                // this.state.ClinicalTrialsGeographicScope_National = response.data.ClinicalTrialsGeographicScope.National;
                // this.state.ClinicalTrialsGeographicScope_Local = response.data.ClinicalTrialsGeographicScope.Local;

                // //kol peer group standing clinical trails of clinical trials
                // this.state.ClinicalTrialsRoleProminence_Interventional = response.data.ClinicalTrialsRoleProminence.Interventional;
                // this.state.ClinicalTrialsRoleProminence_observational = response.data.ClinicalTrialsRoleProminence.observational;

                // //for testing purpose only
                // // this.setState({
                // //   event_piechart: {
                // //     Regular: 1,
                // //     KeynoteSpeaker: 2,
                // //     Chairperson: 3,
                // //     Global: 4,
                // //     Regional: 2,
                // //     National: 3,
                // //     Local: 1,
                // //   },
                // //   publication_piechart: {
                // //     FirstAuthor: 1,
                // //     SecondAuthor: 2,
                // //     LastAuthor: 3,
                // //     Global: 1,
                // //     Regional: 2,
                // //     Local: 3,
                // //   },
                // //   ad_board_piechart: {
                // //     AdvisoryCommittee: 1,
                // //     BoardMember: 2,
                // //     Global: 1,
                // //     Local: 2,
                // //     Regional: 3,
                // //   },
                // //   clinicaltrial_piechart: {
                // //     Global: 1,
                // //     Interventional: 4,
                // //     Local: 2,
                // //     National: 3,
                // //     Observational: 5,
                // //     Regional: 6,
                // //   },
                // //   guideline_piechart: {
                // //     Global: 5,
                // //     Local: 2,
                // //     National: 3,
                // //     Regional: 1,
                // //   }
                // // }); //for tesing purpose only
            })
            .catch(error => {
                console.log(error);
            });

            const url3 = global.websiteUrl+'/service/KOL/get_score';
            let formData3 = new FormData();
            // formData3.append('kol_unique_id', JSON.parse(getSession_Kol_Id));
            axios.post(url3, formData3, config)
                .then(response => {
                console.log('response of get_score api', response.data);

            })
            .catch(error => {
                console.log(error);
            });


            
     
    }

   
    // To get procedure 
 getProcedureList = () => {
  console.log("in Procedure list");
  console.log("speciality_type_id", this.state.QuoteData.specialty_id);

//alert(this.state.QuoteData.Specialty_id);
  const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
  let formData = new FormData();
  formData.append('speciality_type_id', this.state.QuoteData.specialty_id);
  formData.append('search_type', "Procedure");

  const config = {
    //   headers: { 'content-type': 'multipart/form-data' }
  }
  axios.post(url, formData, config)
    .then(response => {
      console.log(response);
      var proceduredata = response.data;
      console.log("proceduredata", JSON.stringify(proceduredata));
      
      if (response.data.status == true) {
        this.setState({
          ProcedureData: response.data.data,
          procedureshowtype:true,
        })
       // alert(this.state.ProcedureData.length)
        //alert(this.state.ProcedureData[0]["procedure_type"])
      }
      else {
        
        //alert("No Procedure Data");
        this.setState({
          ProcedureData: [],
          procedureshowtype:false,
        })
      }
      
      console.log("ProcedureData", this.state.ProcedureData)
    })
    .catch(error => {
      console.log(error);
    });
}
 

// search modal functions
  toggleinfoBuyModal = () => {
    this.setState({
      QuoteModal: !this.state.QuoteModal,
      
    });
  }

  toggleinfoBuyModal = () => {
    this.setState({
      NewQuoteModal: !this.state.NewQuoteModal,
      
    });
  }
    // rediect funcs
    BackToDashboard() {
        this.setState({ redirectBackToDashboard:true })
    }

    BackToKolList(){
        this.setState({ redirectBackToKolList:true })

    }

    render() {
        if (this.state.redirectBackToKolList) {
            return (<Redirect to={{
                pathname:'/PClientViewKolList',
            }} />)
        }

        if (this.state.RedirectSignUp) {
          return (<Redirect to={{
            pathname: '/SignUp',
          }} />)
        }

        if(this.state.redirectLogin){

            return( <Redirect to={{
                pathname:'/Login',
            }} /> )
        }

        if(this.state.redirecthome){

          return( <Redirect to={{
              pathname:'/Home',
          }} /> )
      }

        if(this.state.redirectknowmore){

          return( <Redirect to={{
              pathname:'/Profiletypedetails',
          }} /> )
      }

      let speciality_type = this.state.SpecialityData;
      let OptionsSpeciality = speciality_type.map((speciality_type) =>
        <option key={speciality_type.id} value={speciality_type.id}>
          {speciality_type.speciality_type}</option>
      );

        // Procedure List
    let procedure_list = this.state.ProcedureData;
    let OptionProcedure = procedure_list.map((procedure_list) =>
      <option key={procedure_list.id} value={procedure_list.procedure_type}>
        {procedure_list.procedure_type}</option>
    );

//************ New Chart Data */


const EngagementSumChartData=
  [
   // ["Event", this.state.ESChart.EngagementSumLifetime,{role:"annotation"},this.state.ESChart.EngagementSumYear1,{role:"annotation"},this.state.ESChart.EngagementSumYear2,{role:"annotation"},this.state.ESChart.EngagementSumYear3,{role:"annotation"}],
     
  //  ["Event", "Lifetime",{role:"annotation"},"2019",{role:"annotation"},"2020",{role:"annotation"},"2021",{role:"annotation"}],
  
  //   ["Overall", 43,43,24,24,14,14,40,40],
  //   ["Trial", 43,43,24,24,14,14,40,40],
  //   ["Guideline", 25,25,44,44,24,24,34,34],
  //   ["Event",35,35,18,18,13,13,23,23],
  //   ["Publication",35, 35,28,28,45,45,48,48],
  //   ["Advisory",25, 25,44,44,22,22,45,45]

  
  ["Event", "Lifetime",{role:"annotation"},this.state.latest_year,{role:"annotation"},this.state.mid_year,{role:"annotation"},this.state.last_year,{role:"annotation"}],
  ["Overall", 43,43,24,24,14,14,40,40],  
  ["Trial", 43,43,24,24,14,14,40,40],
  ["Guideline", 25,25,44,44,24,24,34,34],
  ["Event",35,35,18,18,13,13,23,23],
  ["Publication",35, 35,28,28,45,45,48,48],
  //["Advisory",this.state.engagementSummary.kol_advisory_board.count_latest_year, 25,44,44,22,22,45,45]
  
]


  const EngagementSumChartOptions = {
   
    vAxis: {
      maxValue: 100,
      title: 'Percentile',
      //title:this.state.engagementSummary.year.last_year,
      //title:this.state.engagementSummary.year.last_year,
      
      //format: 'percent',

      titleTextStyle : {
       // fontName : "Oswald",
        //italic : false,
        color : this.state.vAxisfontcolor,
        bold:this.state.vAxisfontbold,       
        fontSize:this.state.vAxisfontsize,
        

      },

      

      gridlines: { count: 0 },
      
     
    },    

  
     hAxis: {
       textStyle: {
         bold:this.state.hAxisfontbold,
         fontSize:this.state.hAxisfontsize,
         color:this.state.hAxisfontcolor
         
       },
    
     },
    
     titleTextStyle: {
       bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
   
     },

    
     chartArea:{width:this.state.chartAreawidth  ,height:this.state.chartAreaheight},

    title: "Engagement Summary",
    //titlePosition: 'Center',
   
    //bar: {width:"65%",gap:"30%"},
    bar: {width:"75%"},

    legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold}
    ,

     
  
  },
    
   //isStacked:'relative',
   //isStacked: 'percent',

    annotations: {
      alwaysOutside: true,
      highContrast: true,
      textStyle: {
        color: this.state.annotationfontcolor,fontSize:this.state.annotationfontsize,bold: this.state.annotationfontbold
      }
    },

    series: { 
      0: { color: '#01345C'},
      1: { color: '#A55B52' },
      2: { color: '#035B96' },
      3: { color: '#D7B4B0' },
    },
    

   
  };


  




// ************ Recent Trend Chart  ***************

const RecentTrendChartData=
[
 ["", "2018",{role:"annotation"},"2019",{role:"annotation"},"2020",{role:"annotation"}],
 
 ["Clinical Trials", 1,1,2,2,2,2],
  ["Guidelines", 1,1,0,0,1,1],
  ["Events/Conferences", 5,5,7,7,8,8],
  ["Publication", 50,50,45,45,40,40],
  ["Advisory",0,0,1,1,1,1]
  
]

const RecentTrendChartOptions = {

   
  vAxis: {
    maxValue: 100,
    //title: 'Percentile',
    //format: 'percent',


    titleTextStyle : {
     // fontName : "Oswald",
      //italic : false,
      color : this.state.vAxisfontcolor,
      bold:this.state.vAxisfontbold,       
      fontSize:this.state.vAxisfontsize,
    },

    

    gridlines: { count: 0 },
    
   
  },    

  chartArea:{width:this.state.chartAreawidth  ,height:this.state.chartAreaheight},

   hAxis: {
     textStyle: {
       bold:this.state.hAxisfontbold,
       fontSize:this.state.hAxisfontsize,
       color:this.state.hAxisfontcolor
       
     }
   },
  
   titleTextStyle: {
     bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
   },

  title: "Recent Trends",
  
  //bar: {width:"65%",gap:"30%"},
  bar: {width:"25%",gap:"35%"},

  legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
  
 //isStacked:'relative',
 //isStacked: 'percent',

  annotations: {
    alwaysOutside: true,
    highContrast: true,
    textStyle: {
      color: this.state.annotationfontcolor,fontSize:this.state.annotationfontsize,bold: this.state.annotationfontbold
    }
  },

  series: { 
        0: { color: '#01345C'},
        1: { color: '#A55B52' },
        2: { color: '#035B96' },
        3: { color: '#D7B4B0' },
        
      },
  
};



///************ Event/Confrence Chart  */

// const EventConfrenceGeographicChartData=

//    [

//   //     [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
//   //     ["Lifetime",this.state.EventsGeographicLocal.event_Scope_Lifetime,this.state.EventsGeographicLocal.event_Scope_Lifetime+'%'	,this.state.EventsGeographicLocal.event_Scope_latest_year,this.state.EventsGeographicLocal.event_Scope_latest_year+'%',this.state.EventsGeographicLocal.event_Scope_mid_year,this.state.EventsGeographicLocal.event_Scope_mid_year+'%',	this.state.EventsGeographicLocal.event_Scope_last_year,this.state.EventsGeographicLocal.event_Scope_last_year+'%'],
//   //     ["2020",25,25+'%',	30,30+'%',	30,30+'%',	15,15+'%'],
//   //     ["2019",20,20+'%',	30,30+'%',35,35+'%',15,15+'%'],
//   //     ["2018",20,20+'%',40,40+'%',30,30+'%',10,10+'%'], 

//  ]

  
  const EventConfrenceGeographicChartOptions = {

   
    vAxis: {
      //maxValue: 10,
      //title: 'Percentile',
      format: 'percent',


      titleTextStyle : {
       // fontName : "Oswald",
        //italic : false,
        color : this.state.vAxisfontcolor,
        bold:this.state.vAxisfontbold,       
        fontSize:this.state.vAxisfontsize,
      },

      

      gridlines: { count: 0 },
      
     
    },    

    chartArea:{width:this.state.chartAreawidth  ,height:this.state.chartAreaheight},

     hAxis: {
       textStyle: {
         bold:this.state.hAxisfontbold,
         fontSize:this.state.hAxisfontsize,
         color:this.state.hAxisfontcolor
         
       }
     },
    
     titleTextStyle: {
       bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
     },

    title: "Geographic Scope of the Speaking Engagements",
    
    //bar: {width:"65%",gap:"30%"},
    bar: {width:"75%",gap:"40%"},

    legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
    
   //isStacked:'relative',
   //isStacked: 'percent',
   isStacked:'percent',

    annotations: {
     // alwaysOutside: true,
     // alwaysInnerside:true,
      highContrast: true,
      textStyle: {
        color: this.state.annotationfontcolor,fontSize:this.state.annotationfontsize,bold: this.state.annotationfontbold
      }
    },

    series: { 
      0: { color: this.state.series1color},
      1: { color: this.state.series2color },
      2: { color: this.state.series3color },
      3: { color: this.state.series4color },      
    },
    
  };

  

// Event Role 

// const EventConfrenceRoleChartData=

//   [
//     [ "","Event Chair",{role:"annotation"},"Moderator/Chair",{role:"annotation"},	"Regular",{role:"annotation"},"Keynote Speaker",{role:"annotation"}],
    
//      // [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
//       ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%',	10,10+'%'],
//       ["2020",25,25+'%',	30,30+'%',	30,30+'%',	15,15+'%'],
//       ["2019",20,20+'%',	30,30+'%',35,35+'%',15,15+'%'],
//       ["2018",20,20+'%',40,40+'%',30,30+'%',10,10+'%'], 

//   ]

  
  const EventConfrenceRoleChartOptions = {

   
    vAxis: {
      //maxValue: 10,
      //title: 'Percentile',
      format: 'percent',


      titleTextStyle : {
       // fontName : "Oswald",
        //italic : false,
        color : this.state.vAxisfontcolor,
        bold:this.state.vAxisfontbold,       
        fontSize:this.state.vAxisfontsize,
      },

      

      gridlines: { count: 0 },
      
     
    },    
    chartArea:{width:this.state.chartAreawidth  ,height:this.state.chartAreaheight},

  
     hAxis: {
       textStyle: {
         bold:this.state.hAxisfontbold,
         fontSize:this.state.hAxisfontsize,
         color:this.state.hAxisfontcolor
         
       }
     },
    
     titleTextStyle: {
       bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
     },

    title: "Role of the Speaking Engagements",
    
    //bar: {width:"65%",gap:"30%"},
    bar: {width:"75%",gap:"40%"},

    legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
    
   isStacked:'relative',
   //isStacked: 'percent',

    annotations: {
     // alwaysOutside: true,
      highContrast: true,
      textStyle: {
        color: this.state.annotationfontcolor,fontSize:this.state.annotationfontsize,bold: this.state.annotationfontbold
      }
    },

    series: { 
      0: { color: this.state.series1color},
      1: { color: this.state.series2color },
      2: { color: this.state.series3color },
      3: { color: this.state.series4color },      
    },
    
  };



// Publication ***************

// const PublicationGeographicChartData=

//   [

//       [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
//       ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%',	10,10+'%'],
//       ["2020",25,25+'%',	30,30+'%',	30,30+'%',	15,15+'%'],
//       ["2019",20,20+'%',	30,30+'%',35,35+'%',15,15+'%'],
//       ["2018",20,20+'%',40,40+'%',30,30+'%',10,10+'%'], 

//   ]


  const PublicationGeographicChartOptions = {

   
    vAxis: {
      //maxValue: 10,
      //title: 'Percentile',
      format: 'percent',


      titleTextStyle : {
       // fontName : "Oswald",
        //italic : false,
        color : this.state.vAxisfontcolor,
        bold:this.state.vAxisfontbold,       
        fontSize:this.state.vAxisfontsize,
      },

      

      gridlines: { count: 0 },
      
     
    },    
    chartArea:{width:this.state.chartAreawidth  ,height:this.state.chartAreaheight},

  
     hAxis: {
       textStyle: {
         bold:this.state.hAxisfontbold,
         fontSize:this.state.hAxisfontsize,
         color:this.state.hAxisfontcolor
         
       }
     },
    
     titleTextStyle: {
       bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
     },

    title: "Geographic Scope: Publication",
    
    //bar: {width:"65%",gap:"30%"},
    bar: {width:"75%",gap:"40%"},

    legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
    
   isStacked:'relative',
   //isStacked: 'percent',

    annotations: {
     // alwaysOutside: true,
      highContrast: true,
      textStyle: {
        color: this.state.annotationfontcolor,fontSize:this.state.annotationfontsize,bold: this.state.annotationfontbold
      }
    },

    series: { 
      0: { color: this.state.series1color},
      1: { color: this.state.series2color },
      2: { color: this.state.series3color },
      3: { color: this.state.series4color },      
    },
    
  };






  // const PublicationProminenceRoleChartData=

  // [
  //   [ "","Other Author",{role:"annotation"},"Second Author",{role:"annotation"},	"First Author",{role:"annotation"}],
    
  //    // [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
  //     ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%'],
  //     ["2020",25,25+'%',	30,30+'%',	30,30+'%'],
  //     ["2019",20,20+'%',	30,30+'%',35,35+'%'],
  //     ["2018",20,20+'%',40,40+'%',30,30+'%'], 

  // ]

  const PublicationProminenceRoleChartOptions = {

   
    vAxis: {
      //maxValue: 10,
      //title: 'Percentile',
      format: 'percent',


      titleTextStyle : {
       // fontName : "Oswald",
        //italic : false,
        color : this.state.vAxisfontcolor,
        bold:this.state.vAxisfontbold,       
        fontSize:this.state.vAxisfontsize,
      },

      

      gridlines: { count: 0 },
      
     
    },    

    chartArea:{width:this.state.chartAreawidth  ,height:this.state.chartAreaheight},

  
     hAxis: {
       textStyle: {
         bold:this.state.hAxisfontbold,
         fontSize:this.state.hAxisfontsize,
         color:this.state.hAxisfontcolor
         
       }
     },
    
     titleTextStyle: {
       bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
     },

    title: "Role Prominence: Publication",
    
    //bar: {width:"65%",gap:"30%"},
    bar: {width:"75%",gap:"40%"},

    legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
    
   isStacked:'relative',
   //isStacked: 'percent',

    annotations: {
     // alwaysOutside: true,
      highContrast: true,
      textStyle: {
        color: this.state.annotationfontcolor,fontSize:this.state.annotationfontsize,bold: this.state.annotationfontbold
      }
    },

    series: { 
      0: { color: this.state.series1color},
      1: { color: this.state.series2color },
      2: { color: this.state.series3color },
      3: { color: this.state.series4color },      
    },
    
  };


// *************** Clinical Trial *********************


//const ClinicalTrialsGeographicChartData=

// [
//   [ "","more than 50",{role:"annotation"},"11-50",{role:"annotation"},"1-10",{role:"annotation"},	"1",{role:"annotation"}],
  
//     [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
//     ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%',	10,10+'%'],
//     ["2020",25,25+'%',	30,30+'%',	30,30+'%',	15,15+'%'],
//     ["2019",20,20+'%',	30,30+'%',35,35+'%',15,15+'%'],
//     ["2018",20,20+'%',40,40+'%',30,30+'%',10,10+'%'], 

// ]

const ClinicalTrialsGeographicChartOptions = {

   
  vAxis: {
    //maxValue: 10,
    //title: 'Percentile',
    format: 'percent',


    titleTextStyle : {
     // fontName : "Oswald",
      //italic : false,
      color : this.state.vAxisfontcolor,
      bold:this.state.vAxisfontbold,       
      fontSize:this.state.vAxisfontsize,
    },

    

    gridlines: { count: 0 },
    
   
  },    


   hAxis: {
     textStyle: {
       bold:this.state.hAxisfontbold,
       fontSize:this.state.hAxisfontsize,
       color:this.state.hAxisfontcolor
       
     }
   },
  
   titleTextStyle: {
     bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
   },

  title: "Geographic Scope: Clinical Trials",
  
  chartArea:{width:this.state.chartAreawidth  ,height:this.state.chartAreaheight},

  //bar: {width:"65%",gap:"30%"},
  bar: {width:"75%",gap:"40%"},

  legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
  
 isStacked:'relative',
 //isStacked: 'percent',

  annotations: {
   // alwaysOutside: true,
    highContrast: true,
    textStyle: {
      color: this.state.annotationfontcolor,fontSize:this.state.annotationfontsize,bold: this.state.annotationfontbold
    }
  },

  series: { 
    0: { color: this.state.series1color},
    1: { color: this.state.series2color },
    2: { color: this.state.series3color },
    3: { color: this.state.series4color },      
  },
  
};






// const ClinicalTrialsLocationChartData=

// [
//   [ "","more than 50",{role:"annotation"},"11-50",{role:"annotation"},"1-10",{role:"annotation"},	"1",{role:"annotation"}],
  
//     ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%',	10,10+'%'],
//     ["2020",25,25+'%',	30,30+'%',	30,30+'%',	10,10+'%'],
//     ["2019",20,20+'%',	30,30+'%',35,35+'%',	10,10+'%'],
//     ["2018",20,20+'%',40,40+'%',30,30+'%',	10,10+'%'], 

// ]



const ClinicalTrialsLocationChartOptions = {

   
  vAxis: {
    //maxValue: 10,
    //title: 'Percentile',
    format: 'percent',


    titleTextStyle : {
     // fontName : "Oswald",
      //italic : false,
      color : this.state.vAxisfontcolor,
      bold:this.state.vAxisfontbold,       
      fontSize:this.state.vAxisfontsize,
    },

    

    gridlines: { count: 0 },
    
   
  },    

  chartArea:{width:this.state.chartAreawidth  ,height:this.state.chartAreaheight},

   hAxis: {
     textStyle: {
       bold:this.state.hAxisfontbold,
       fontSize:this.state.hAxisfontsize,
       color:this.state.hAxisfontcolor
       
     }
   },
  
   titleTextStyle: {
     bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
   },

  title: "Trial Location Prominence: Clinical Trials",
  
  //bar: {width:"65%",gap:"30%"},
  bar: {width:"75%",gap:"40%"},

  legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
  
 isStacked:'relative',
 //isStacked: 'percent',

  annotations: {
   // alwaysOutside: true,
    highContrast: true,
    textStyle: {
      color: this.state.annotationfontcolor,fontSize:this.state.annotationfontsize,bold: this.state.annotationfontbold
    }
  },

  series: { 
    0: { color: this.state.series1color},
    1: { color: this.state.series2color },
    2: { color: this.state.series3color },
    3: { color: this.state.series4color },      
  },
  
};


const ClinicalTrialsTypeChartOptions = {

   
  vAxis: {
    //maxValue: 10,
    //title: 'Percentile',
    format: 'percent',


    titleTextStyle : {
     // fontName : "Oswald",
      //italic : false,
      color : this.state.vAxisfontcolor,
      bold:this.state.vAxisfontbold,       
      fontSize:this.state.vAxisfontsize,
    },

    

    gridlines: { count: 0 },
    
   
  },    

  chartArea:{width:this.state.chartAreawidth  ,height:this.state.chartAreaheight},

   hAxis: {
     textStyle: {
       bold:this.state.hAxisfontbold,
       fontSize:this.state.hAxisfontsize,
       color:this.state.hAxisfontcolor
       
     }
   },
  
   titleTextStyle: {
     bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
   },

  title: "Clinical Trials Type ",
  
  //bar: {width:"65%",gap:"30%"},
  bar: {width:"75%",gap:"40%"},

  legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
  
 isStacked:'relative',
 //isStacked: 'percent',

  annotations: {
   // alwaysOutside: true,
    highContrast: true,
    textStyle: {
      color: this.state.annotationfontcolor,fontSize:this.state.annotationfontsize,bold: this.state.annotationfontbold
    }
  },

  series: { 
    0: { color: this.state.series1color},
    1: { color: this.state.series2color },
    // 2: { color: this.state.series3color },
//    3: { color: this.state.series4color },      
  },
  
};
// *************** Advisory Board ********************

// const AdvisoryBoardGeographicChartData=

//   [

//       [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
//       ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%',	10,10+'%'],
//       ["2020",25,25+'%',	30,30+'%',	30,30+'%',	15,15+'%'],
//       ["2019",20,20+'%',	30,30+'%',35,35+'%',15,15+'%'],
//       ["2018",20,20+'%',40,40+'%',30,30+'%',10,10+'%'], 

//   ]

  const AdvisoryBoardGeographicChartOptions = {

   
    vAxis: {
      //maxValue: 10,
      //title: 'Percentile',
      format: 'percent',


      titleTextStyle : {
       // fontName : "Oswald",
        //italic : false,
        color : this.state.vAxisfontcolor,
        bold:this.state.vAxisfontbold,       
        fontSize:this.state.vAxisfontsize,
      },

      

      gridlines: { count: 0 },
      
     
    },    

  
     hAxis: {
       textStyle: {
         bold:this.state.hAxisfontbold,
         fontSize:this.state.hAxisfontsize,
         color:this.state.hAxisfontcolor
         
       }
     },
    
     chartArea:{width:this.state.chartAreawidth  ,height:this.state.chartAreaheight},

     titleTextStyle: {
       bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
     },

    title: "Geographic Scope: Advisory Board",
    
    //bar: {width:"65%",gap:"30%"},
    bar: {width:"75%",gap:"40%"},

    legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
    
   isStacked:'relative',
   //isStacked: 'percent',

    annotations: {
     // alwaysOutside: true,
      highContrast: true,
      textStyle: {
        color: this.state.annotationfontcolor,fontSize:this.state.annotationfontsize,bold: this.state.annotationfontbold
      }
    },

    series: { 
      0: { color: this.state.series1color},
      1: { color: this.state.series2color },
      2: { color: this.state.series3color },
      3: { color: this.state.series4color },      
    },
    
  };








  // const AdvisoryBoardRoleProminenceChartData=

  // [
  //   [ "","committee Member",{role:"annotation"},"Co Chair",{role:"annotation"},"Chair",{role:"annotation"}],
    
  //     ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%'],
  //     ["2020",25,25+'%',	30,30+'%',	30,30+'%'],
  //     ["2019",20,20+'%',	30,30+'%',35,35+'%'],
  //     ["2018",20,20+'%',40,40+'%',30,30+'%'], 

  // ]

  
  const AdvisoryBoardRoleProminenceChartOptions = {

   
    vAxis: {
      //maxValue: 10,
      //title: 'Percentile',
      format: 'percent',


      titleTextStyle : {
       // fontName : "Oswald",
        //italic : false,
        color : this.state.vAxisfontcolor,
        bold:this.state.vAxisfontbold,       
        fontSize:this.state.vAxisfontsize,
      },

      

      gridlines: { count: 0 },
      
     
    },    

  
     hAxis: {
       textStyle: {
         bold:this.state.hAxisfontbold,
         fontSize:this.state.hAxisfontsize,
         color:this.state.hAxisfontcolor
         
       }
     },

     chartArea:{width:this.state.chartAreawidth  ,height:this.state.chartAreaheight},

    
     titleTextStyle: {
       bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
     },

    title: "Role Prominence: Advisory Board",
    
    //bar: {width:"65%",gap:"30%"},
    bar: {width:"75%",gap:"40%"},

    legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
    
   isStacked:'relative',
   //isStacked: 'percent',

    annotations: {
     // alwaysOutside: true,
      highContrast: true,
      textStyle: {
        color: this.state.annotationfontcolor,fontSize:this.state.annotationfontsize,bold: this.state.annotationfontbold
      }
    },

    series: { 
      0: { color: this.state.series1color},
      1: { color: this.state.series2color },
      2: { color: this.state.series3color },
      3: { color: this.state.series4color },      
    },
    
  };

  

  
  // const AdvisoryBoardAffiliationTypeChartData=

  // [
  //   [ "","Goverment Institution",{role:"annotation"},"Professional Association",{role:"annotation"},"Medtech Companies",{role:"annotation"},"Pharma Companies",{role:"annotation"}],
    
  //     ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%',20,20+'%'],
  //     ["2020",25,25+'%',	30,30+'%',	30,30+'%',20,20+'%'],
  //     ["2019",20,20+'%',	30,30+'%',35,35+'%',20,20+'%'],
  //     ["2018",20,20+'%',40,40+'%',30,30+'%',20,20+'%'], 

  // ]

  
  const AdvisoryBoardAffiliationTypeChartOptions = {

   
    vAxis: {
      //maxValue: 10,
      //title: 'Percentile',
      format: 'percent',


      titleTextStyle : {
       // fontName : "Oswald",
        //italic : false,
        color : this.state.vAxisfontcolor,
        bold:this.state.vAxisfontbold,       
        fontSize:this.state.vAxisfontsize,
      },

      

      gridlines: { count: 0 },
      
     
    },    

  
     hAxis: {
       textStyle: {
         bold:this.state.hAxisfontbold,
         fontSize:this.state.hAxisfontsize,
         color:this.state.hAxisfontcolor
         
       }
     },
    
     chartArea:{width:this.state.chartAreawidth  ,height:this.state.chartAreaheight},

     titleTextStyle: {
       bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
     },

    title: "Affiliation Type",
    
    //bar: {width:"65%",gap:"30%"},
    bar: {width:"75%",gap:"40%"},

    legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
    
   isStacked:'relative',
   //isStacked: 'percent',

    annotations: {
     // alwaysOutside: true,
      highContrast: true,
      textStyle: {
        color: this.state.annotationfontcolor,fontSize:this.state.annotationfontsize,bold: this.state.annotationfontbold
      }
    },

    series: { 
      0: { color: this.state.series1color},
      1: { color: this.state.series2color },
      2: { color: this.state.series3color },
      3: { color: this.state.series4color },      
    },
    
  };


/// **************Guideline ****************


const GuidelinesGeographicChartData=

[

    [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
    ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%',	10,10+'%'],
    ["2020",25,25+'%',	30,30+'%',	30,30+'%',	15,15+'%'],
    ["2019",20,20+'%',	30,30+'%',35,35+'%',15,15+'%'],
    ["2018",20,20+'%',40,40+'%',30,30+'%',10,10+'%'], 

]

const GuidelinesGeographicChartOptions = {

 
  vAxis: {
    //maxValue: 10,
    //title: 'Percentile',
    format: 'percent',


    titleTextStyle : {
     // fontName : "Oswald",
      //italic : false,
      color : this.state.vAxisfontcolor,
      bold:this.state.vAxisfontbold,       
      fontSize:this.state.vAxisfontsize,
    },

    

    gridlines: { count: 0 },
    
   
  },    


   hAxis: {
     textStyle: {
       bold:this.state.hAxisfontbold,
       fontSize:this.state.hAxisfontsize,
       color:this.state.hAxisfontcolor
       
     }
   },
  
   titleTextStyle: {
     bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
   },

  title: "Geographic Scope: Guidelines",
  
  //bar: {width:"65%",gap:"30%"},
  bar: {width:"75%",gap:"40%"},

  legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
  
 isStacked:'relative',
 //isStacked: 'percent',

  annotations: {
   // alwaysOutside: true,
    highContrast: true,
    textStyle: {
      color: this.state.annotationfontcolor,fontSize:this.state.annotationfontsize,bold: this.state.annotationfontbold
    }
  },

  chartArea:{width:this.state.chartAreawidth  ,height:this.state.chartAreaheight},

  series: { 
    0: { color: this.state.series1color},
    1: { color: this.state.series2color },
    2: { color: this.state.series3color },
    3: { color: this.state.series4color },      
  },
  
};








const GuidelinesRoleProminenceChartData=

[
  [ "","Committee Member",{role:"annotation"},"Committee Chair",{role:"annotation"},"Author",{role:"annotation"}],
  
    ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%'],
    ["2020",25,25+'%',	30,30+'%',	30,30+'%'],
    ["2019",20,20+'%',	30,30+'%',35,35+'%'],
    ["2018",20,20+'%',40,40+'%',30,30+'%'], 

]


const GuidelinesRoleProminenceChartOptions = {

 
  vAxis: {
    //maxValue: 10,
    //title: 'Percentile',
    format: 'percent',


    titleTextStyle : {
     // fontName : "Oswald",
      //italic : false,
      color : this.state.vAxisfontcolor,
      bold:this.state.vAxisfontbold,       
      fontSize:this.state.vAxisfontsize,
    },

    

    gridlines: { count: 0 },
    
   
  },    


   hAxis: {
     textStyle: {
       bold:this.state.hAxisfontbold,
       fontSize:this.state.hAxisfontsize,
       color:this.state.hAxisfontcolor
       
     }
   },
  
   titleTextStyle: {
     bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
   },

  title: "Role Prominence: Guidelines",
  
  //bar: {width:"65%",gap:"30%"},
  bar: {width:"75%",gap:"40%"},

  legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
  
 isStacked:'relative',
 //isStacked: 'percent',

  annotations: {
   // alwaysOutside: true,
    highContrast: true,
    textStyle: {
      color: this.state.annotationfontcolor,fontSize:this.state.annotationfontsize,bold: this.state.annotationfontbold
    }
  },
  chartArea:{width:this.state.chartAreawidth  ,height:this.state.chartAreaheight},

  series: { 
    0: { color: this.state.series1color},
    1: { color: this.state.series2color },
    2: { color: this.state.series3color },
    3: { color: this.state.series4color },      
  },
  
};




const GuidelinesAffiliationTypeChartData=

[
  [ "","Goverment Institutions",{role:"annotation"},"Professional Association",{role:"annotation"},"Academic/Hospital",{role:"annotation"}],
  
    ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%'],
    ["2020",25,25+'%',	30,30+'%',	30,30+'%'],
    ["2019",20,20+'%',	30,30+'%',35,35+'%'],
    ["2018",20,20+'%',40,40+'%',30,30+'%'], 

]


const GuidelinesAffiliationTypeChartOptions = {

 
  vAxis: {
    //maxValue: 10,
    //title: 'Percentile',
    format: 'percent',


    titleTextStyle : {
     // fontName : "Oswald",
      //italic : false,
      color : this.state.vAxisfontcolor,
      bold:this.state.vAxisfontbold,       
      fontSize:this.state.vAxisfontsize,
    },

    

    gridlines: { count: 0 },
    
   
  },    


   hAxis: {
     textStyle: {
       bold:this.state.hAxisfontbold,
       fontSize:this.state.hAxisfontsize,
       color:this.state.hAxisfontcolor
       
     }
   },
  
   titleTextStyle: {
     bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
   },

   chartArea:{width:this.state.chartAreawidth  ,height:this.state.chartAreaheight},

  title: "Affiliation Type",
  
  //bar: {width:"65%",gap:"30%"},
  bar: {width:"75%",gap:"40%"},

  legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
  
 isStacked:'relative',
 //isStacked: 'percent',

  annotations: {
   // alwaysOutside: true,
    highContrast: true,
    textStyle: {
      color: this.state.annotationfontcolor,fontSize:this.state.annotationfontsize,bold: this.state.annotationfontbold
    }
  },

  series: { 
    0: { color: this.state.series1color},
    1: { color: this.state.series2color },
    2: { color: this.state.series3color },
    3: { color: this.state.series4color },      
  },
  
};




        let dataProfessionalMembership = this.state.ProfessionalMemberships.map((dataProfessionalMembership) => {
            return (

                <tr key={dataProfessionalMembership.organization}>
                    <td>{dataProfessionalMembership.organization}</td>
                    <td>{dataProfessionalMembership.organization_type}</td>
                    <td>{dataProfessionalMembership.source_link}</td>
                    <td>{dataProfessionalMembership.location}</td>
                    <td>{dataProfessionalMembership.position}</td>
                    <td>{dataProfessionalMembership.tenure}</td>
                    <td>{dataProfessionalMembership.organization_website}</td>
                </tr>

            )
        });


        
        return (

          <div style={{backgroundColor:'#F9F9F9',paddingBottom:'35px', marginTop:'-10px', paddingTop:'30px'}}>

            
          {this.state.ShowBar ? <Modal isOpen={true} centered>
            <PropagateLoader color={'#A90007'} size='30px' loading='true' />
          </Modal> : null}
          {this.state.progressBar ? <div style={{ position: 'fixed', top: '0' }}>
            <LoadingBar
              progress='100'
              height={3} color="#A90007" transitionTime={4000}
              loaderSpeed={3000} />
          </div> : null}


            <ClientHeader/>

             
  <div class="container-fluid" id="mainClientDashboard" style={{width:'98%' }} >
  <div >
        <div class="col-lg-12 col-md-12 col-sm-12 col-12" >                  
                      <div class="row breadcum" >
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12" >
                          <h4><b>Profile Details</b></h4>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12"  >
                        {/* <h5> <label className='gbck'><a href='#' onClick={this.BackToKolList.bind(this)} subTitleClassName="caret left" href='p'><i class="arrow left"></i> Go Back</a></label></h5> */}
                        <p ><a href='#' onClick={this.toggleBackToHome.bind(this)}>Home</a><b><span class="btn-label">  </span></b><b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> <a href='#' onClick={this.BackToKolList.bind(this)}>Opinion Leaders</a> <b><span class="btn-label">  </span></b><b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Profile Details</p>
           
                          {/* <p ><a href='#' onClick={this.BackToClientProject.bind(this)}>Dashboard</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Opinion Leaders</p> */}
                        </div>
                        {/* <hr style={{width:'96.5%' , marginBottom:'10px',height:'.2px', marginLeft:'1%'}}></hr> */}
                      </div>
                     
                  </div>

        {/* <div class="profile-backoptiopn">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12" >
                  <p ><a href='#' onClick={this.BackToDashboard.bind(this)}>Dashboard</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b><a href='#' onClick={this.BackToKolList.bind(this)} >Opinion Leaders</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Profile Details</p>
            </div>
        </div>  */}


    <div class="container-fluid text-dark detailContainer" >                

        {/* <h3 className='pfDetails'>Profile Details <hr></hr></h3> */}
        <div className='' >
        {/* <h5 className='pfDetails'><b>Profile Details</b></h5> */}
        {/* <hr style={{maxWidth:'101.5%', minWidth:'101.5%'}} ></hr>  */}
       
          <div className='row'>
            <div className='col col-lg-2' >
              <div className='card mb-2 crd_menu_des'  >
                <div className='card-body'>
                  <ul className="nav nav-pills">
                    <li className="nav-item pill-1">
                              <a  href='#' className="nav-link nav-linkss active" data-bs-toggle="pill">Personal Information
                                                            {/* {
                                                                (this.state.PersonalInformationFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Personal Information</text>
                                                                    </div>

                                                                ) : (
                                                                    <div>
                                                                        <text>Personal Information</text>
                                                                    </div>
                                                                )
                                                            } */}
                                                        </a>
                    {/* <a onClick={this.BiographyFun} >
                                                            {
                                                                (this.state.BiographyFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Biography</text>
                                                                    </div>

                                                                ) : (
                                                                    <div><text>Biography</text>
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                      {/* <a className="nav-link nav-linkss active" data-bs-toggle="pill" href="#personalInformation">Personal Information</a> */}
                    </li> 
                    <li className="nav-item pill-2">
                      <a  onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss"  data-bs-toggle="pill" href="#">Summary</a>
                    </li>
                    <li className="nav-item pill-3">
                      {/* <a className="nav-link nav-linkss" data-bs-toggle="pill" href="#Biography">Biography</a> */}
                      <a onClick={this.toggleinfoBuyModal.bind(this)} href='#' className="nav-link nav-linkss" data-bs-toggle="pill" >Biography
                                                            {/* {
                                                                (this.state.BiographyFlag) ? (
                                                                    <div >
                                                                        <text className="nav-link">
                                                                            Biography</text>
                                                                    </div>

                                                                ) : (
                                                                    <div><text className="nav-linkss" >Biography</text>
                                                                    </div>
                                                                )
                                                            } */}
                                                        </a>
                    </li>
                    <li className="nav-item pill-4">
                      {/* <a className="nav-link nav-linkss" data-bs-toggle="pill" href="#Professionaldtl">Professional</a> */}
                      <a onClick={this.toggleinfoBuyModal.bind(this)} href='#' className="nav-link nav-linkss " data-bs-toggle="pill">Professional
                                                            {/* {
                                                                (this.state.ProfessionalMembershipFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Professional Membership</text>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Professional Membership</text>
                                                                    </div>
                                                                )
                                                            } */}
                                                        </a>
                    </li>
                    <li className="nav-item pill-5">
                      <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Education</a>
                    </li>
                    <li className="nav-item pill-6">
                      <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Advisory Board</a>
                    </li>
                    <li className="nav-item pill-7">
                      <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Awards and Honours </a>
                    </li>
                    <li className="nav-item pill-8">
                      <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Books</a>
                    </li>
                    <li className="nav-item pill-9">
                      <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Committees</a>
                    </li>
                    <li className="nav-item pill-10">
                      <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Clinical Trial</a>
                    </li>
                    <li className="nav-item pill-11">
                      <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Events</a>
                    </li>
                    <li className="nav-item pill-12">
                      <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Grants</a>
                    </li>
                    <li className="nav-item pill-13">
                      <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Guidelines</a>
                    </li>
                    <li className="nav-item pill-14">
                      <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Hospital Affiliations</a>
                    </li>
                    <li className="nav-item pill-15">
                      <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Interactions</a>
                    </li>
                    <li className="nav-item pill-16">
                      <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Press Item</a>
                    </li>
                    <li className="nav-item pill-17">
                      <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Presentations</a>
                    </li>
                    <li className="nav-item pill-18">
                      <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Publications</a>
                    </li>
                    <li className="nav-item pill-19">
                      <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Sunshine Payment</a>
                    </li>


                  </ul>
                  </div>
                </div>
              </div>
            <div className='col col-lg-10' >
              <div className='card'>
                <div className='card-body'  >
                  <div className='row'>
                    <div className='col col-lg-2 col-md-2 col-sm-2 col-xs-2'  >
                          <img className='prflpge' src={this.state.profile_photo}  alt='KOL' ></img>
                    </div>
                    <div className='col col-lg-8 col-md-8 col-sm-8 col-xs-8' style={{marginLeft:'-5%'}} >
                      <ul1 className='hdlead'>
                        <li><h6><b>{this.state.DoctorCompleteName}</b></h6></li>
                        <li><b>Speciality: </b>{this.state.PersonalInformation.specialty_id}</li>

                        {(this.state.PersonalInformation.expertise !=="")?(
                          
                             <li><b>Expertise:</b> {this.state.PersonalInformation.expertise}</li>
                       
                        ):
                        (<div></div>)

                         }
                        <li><b>Procedure Type:</b> {this.state.PersonalInformation.procedure_type_id}</li>
                      </ul1>
                    </div>
                    <div className='col-2 mt-3' >
                    
                    {/* <div className='col col-lg-2 col-md-2 col-sm-2 col-xs-2' style={{marginLeft:'4%',backgroundColor:'pink'}}> */}
                              {/* <a onClick={this.toggleGetQuoteModal.bind(this)} >           */}
                            
                              <a onClick={this.toggleinfoBuyModal.bind(this)} >
                                <div  className='Buy_now_anchor'> Unlock Profile</div>  
                                 {/* <button class="btn1 btn-danger btn-sm btn-labeled" style={{width:'140px',height:'40px', fontSize:'12px',marginTop:'10px'}}>
                                    Buy Now
                                  </button> */}
                              </a>
                      
                    </div>
                  </div>
                  
                </div>
              </div>
              {/* <div class="tab-content tbpnsd"> */}
<div class="tbpnsd">
              {/* <a id="overall-details"></a> */}

    {
                  
       (this.state.PersonalInformationFlag == true) ? (
               
				<div class="tab-pane container-xxl mt-2 active" id="personalInformation">
                  <div className='card scrl_cntnt'>
                    <div className='card-body'>
                      <h6><b>Personal Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-6 col-md-6 col-sm-6 col-xs-6 dtlpge'>
                          <p className='pbio'>
                            <li className='biogr_li'><b>Alma Mater: </b>{this.state.PersonalInformation.medical_schools}</li>
                            <li className='biogr_li'><b>Degree: </b>{this.state.PersonalInformation.qualification}</li>
                            <li className='biogr_li'><b>Clinical Experience: </b>{this.state.PersonalInformation.experience}</li>
                            <li className='biogr_li'><b>License No: </b>{this.state.PersonalInformation.medical_registration_no}</li>
                            <li className='biogr_li'><b>NPI No: </b>{this.state.PersonalInformation.NPI_no}</li>
                          </p>
                        </div>
                        <div className='col col-lg-6 col-md-6 col-sm-6 col-xs-6 dtlpge'>


                        {/* <div className='row'>
                        <div  style={{width:"90%", height:"30px", backgroundColor:'orange'}}>
                            <label>na</label>
                            <label>
                            <PhoneInput
                              value={this.state.PersonalInformation.doctor_contact_no}
                            />
                            </label>
                        </div>  
                         </div>   */}

                        <p className='pbio'>


                          <li className='biogr_li'><b>Contact No: </b>
                          {
                            (this.state.PersonalInformation.doctor_contact_no !=="")?
                            (
                              this.formatPhoneNumber(this.state.PersonalInformation.doctor_contact_no)
                              //this.state.PersonalInformation.doctor_contact_no
                            ):(<div></div>)
                          }
                          {/* { (this.state.PersonalInformation.doctor_contact_no !=="")?
                           (
                          
                          <label style={{height:"10px"}}>
                            <PhoneInput
                              value={this.state.PersonalInformation.doctor_contact_no}
                              buttonStyle={{
                                backgroundColor: "#fff",
                                border: "1px solid #fff",
                              // borderColor: "#fff",
                                //boxShadow: "0px 0px 0px 1px #fff",
                                //visibility:false
                              display:"none",
                              //height: "1px",
                               
                              }}
                              inputStyle={{
                                //height: "24px",
                                //width: "100%",
                                //border: "0px solid #d3d3d3"
                                //outline: "none",
                                borderColor: "#fff",
                                boxShadow: "0px 0px 0px 1px #fff",
                               // position:"relative",
                               //height: "1px",
                                
                              
                              }}
                              
                              disabled
                              

                            />
                            </label>
                           ):(<div></div>)
                          } */}
                         </li>
                         


                         
                            {/* <li className='biogr_li'><b>Contact No: </b> {this.state.PersonalInformation.doctor_contact_no}</li> */}
                            {/* <li className='biogr_li'><b>Fax  No: </b> {this.state.PersonalInformation.fax}</li> */}
                        
                         <li className='biogr_li'><b>Fax  No: </b>
                        {
                         (this.state.PersonalInformation.fax !=="")?
                            (
                              this.formatPhoneNumber(this.state.PersonalInformation.fax)
                              //this.state.PersonalInformation.doctor_contact_no
                            ):(<div></div>)
                          }

                        </li>

                            <li className='biogr_li'><b>Email: </b><a href ={"mailto:"+this.state.PersonalInformation.doctor_email}> {this.state.PersonalInformation.doctor_email}</a></li>
                            <li className='biogr_li'><b>Secondary Email: </b><a  href ={"mailto:"+this.state.PersonalInformation.doctor_email2}> {this.state.PersonalInformation.doctor_email2}</a></li>
                            {/* <li className='biogr_li'><b>Assistant/Secretary Contact No: </b>{this.state.PersonalInformation.assistance_contact_no}</li> */}
                           
                            <li className='biogr_li'><b>Assistant/Secretary Contact No: </b>
                          
                            {
                         (this.state.PersonalInformation.assistance_contact_no !=="")?
                            (
                              this.formatPhoneNumber(this.state.PersonalInformation.assistance_contact_no)
                              //this.state.PersonalInformation.doctor_contact_no
                            ):(<div></div>)
                          }
                         </li>
                         
                           
                           
                            <li className='biogr_li'><b>Assistant Mail: </b><a href ={"mailto:"+this.state.PersonalInformation.assistance_email}> {this.state.PersonalInformation.assistance_email}</a></li>
                          </p>
                        </div>
                        <div className='col col-lg-6 col-md-6 col-sm-6 col-xs-6 dtlpge'>
                          <p className='pbio'>
                            <li className='biogr_li'><b>Primary Work Address: </b>{this.state.PersonalInformation.address_1}, {this.state.PersonalInformation.address_2}</li>
                            <li className='biogr_li'><b>City: </b>{this.state.PersonalInformation.city}</li>
                            <li className='biogr_li'><b>State: </b>{this.state.PersonalInformation.state_id}</li>
                            <li className='biogr_li'><b>Country: </b>{this.state.PersonalInformation.country_id} - {this.state.PersonalInformation.zip_code}</li>
                          </p>
                        </div>
                        <div className='col col-lg-6 col-md-6 col-sm-6 col-xs-6 dtlpge'>
                          <p className='pbio'>
                            <li className='biogr_li'><b>Website URL: </b> <a href ={this.state.PersonalInformation.website} target='_blank'> {this.state.PersonalInformation.website}</a></li>
                            <li className='biogr_li'><b>LinkedIn Profile: </b><a href ={this.state.PersonalInformation.linked_in_profile} target='_blank'> {this.state.PersonalInformation.linked_in_profile}</a></li>
                            <li className='biogr_li'><b>Twitter Profile: </b><a href ={this.state.PersonalInformation.twitter_handle} target='_blank'> {this.state.PersonalInformation.twitter_handle}</a></li>
                          </p>
                        </div>

                        
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12 dtlpge'>
                        <h6><b>Work Details</b></h6>
                        {/* <hr></hr> */}
                         
                          <p className='pbio'>
                          
                            <div className='table-responsive mb-1'>
                              <table className='table table-striped mb-2'>
                                {/* <tbody className=''> */}
                                <div >
                                                                    <BootstrapTable
                                                                        keyField='id'
                                                                        noDataIndication="Table is Empty"
                                                                        data={this.state.WorkDetails}
                                                                        columns={this.state.columnsWorkDetails}
                                                                        filter={filterFactory()}
                                                                       // striped
                                                                        hover
                                                                        condensed
                                                                        
                                                                        rowStyle={{backgroundColor:'#fff'}}
                                                                        
                                                                    />
                                                                </div>


                                {/* </tbody> */}
                              </table>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
) : (
  <div>
    
  </div>
)
}


{
                  
                  (this.state.SummaryFlag == true) ? (
             

                <div class="tab-pane container-xxxl mt-2" id="Summary">

                  <ul className="nav nav-pills pl-4">
                    
                    <li className="nav-item pill-20">
                      <a className="nav-link nav-linkss active" onClick={this.HomePiechartFun} data-bs-toggle="pill" href="#home_sum">Home</a>
                    </li>
                    <li className="nav-item pill-21">
                      <a className="nav-link nav-linkss" data-bs-toggle="pill" onClick={this.EventPiechartFun} href="#event_sum">Events</a>
                    </li>
                    <li className="nav-item pill-22">
                      <a className="nav-link nav-linkss" data-bs-toggle="pill" onClick={this.PublicationPiechartFun} href="#publication_sum">Publications</a>
                    </li>
                    <li className="nav-item pill-23">
                      <a className="nav-link nav-linkss" data-bs-toggle="pill" onClick={this.ClinicaltrialPiechartFun} href="#ct_sum">Clinical Trials</a>
                    </li>
                    <li className="nav-item pill-24">
                      <a className="nav-link nav-linkss" data-bs-toggle="pill" onClick={this.AdboardPiechartFun} href="#advisory_sum">Advisory Boards</a>
                    </li>
                    <li className="nav-item pill-25">
                      <a className="nav-link nav-linkss" data-bs-toggle="pill" onClick={this.GuidelinePiechartFun} href="#guideline_sum">Guidelines</a>
                    </li>
                  </ul>
 
                  <div class="tbpnsd1">
                    {
                      (this.state.home_piechart_flag == true) ? (

                                <div class="tab-pane container-xxl mt-2 active" id="home_sum">
                                <div className='card scrl_cntnts'>
                                  <div className='card-body' >
                                   
                                  <div className="row center">
                                  
                                        <div className="col-9" style={{marginBottom:'80px'}} >
                                               
                                                <Chart
                                                    chartType={'ColumnChart'}
                                        
                                                    loader={<div>Loading Chart</div>}
                                                              
                                                     data={[
                                                      ["", "Lifetime",{role:"annotation"},this.state.year.latest_year.toString(),{role:"annotation"},this.state.year.mid_year.toString(),{role:"annotation"},this.state.year.last_year.toString(),{role:"annotation"}],
                                                      ["Overall",this.state.Summaryoverall.Lifetime,this.state.Summaryoverall.Lifetime,this.state.Summaryoverall.latest_year,this.state.Summaryoverall.latest_year,this.state.Summaryoverall.mid_year,this.state.Summaryoverall.mid_year,this.state.Summaryoverall.last_year,this.state.Summaryoverall.last_year], 
                                                      ["Trial",this.state.Summaryclinicaltrialdetails.Lifetime,this.state.Summaryclinicaltrialdetails.Lifetime,this.state.Summaryclinicaltrialdetails.latest_year,this.state.Summaryclinicaltrialdetails.latest_year,this.state.Summaryclinicaltrialdetails.mid_year,this.state.Summaryclinicaltrialdetails.mid_year,this.state.Summaryclinicaltrialdetails.last_year,this.state.Summaryclinicaltrialdetails.last_year], 
                                                      ["Guideline",this.state.Summaryguidlines.Lifetime,this.state.Summaryguidlines.Lifetime,this.state.Summaryguidlines.latest_year,this.state.Summaryguidlines.latest_year,this.state.Summaryguidlines.mid_year,this.state.Summaryguidlines.mid_year,this.state.Summaryguidlines.last_year,this.state.Summaryguidlines.last_year], 
                                                      ["Event",this.state.Summaryeventdetails.Lifetime,this.state.Summaryeventdetails.Lifetime,this.state.Summaryeventdetails.latest_year,this.state.Summaryeventdetails.latest_year,this.state.Summaryeventdetails.mid_year,this.state.Summaryeventdetails.mid_year,this.state.Summaryeventdetails.last_year,this.state.Summaryeventdetails.last_year],
                                                      ["Publication",this.state.Summarypublicationdetails.Lifetime,this.state.Summarypublicationdetails.Lifetime,this.state.Summarypublicationdetails.latest_year,this.state.Summarypublicationdetails.latest_year,this.state.Summarypublicationdetails.mid_year,this.state.Summarypublicationdetails.mid_year,this.state.Summarypublicationdetails.last_year,this.state.Summarypublicationdetails.last_year],
                                                      ["Advisory",this.state.Summaryadvisoryboard.Lifetime,this.state.Summaryadvisoryboard.Lifetime,this.state.Summaryadvisoryboard.latest_year,this.state.Summaryadvisoryboard.latest_year,this.state.Summaryadvisoryboard.mid_year,this.state.Summaryadvisoryboard.mid_year,this.state.Summaryadvisoryboard.last_year,this.state.Summaryadvisoryboard.last_year],  
                                                      
                                                    ]}

                                                    options={EngagementSumChartOptions}
                                                   
                                                    width={"100%"}
                                                    height={"350px"}
                                                    rootProps={{ 'data-testid': '1' }}
                                              
                                                    /> 



                                        </div>
                                        <div className="col-9" style={{marginBottom:'80px'}}>
                                        <Chart
                                                 chartType={'ColumnChart'}
                                                  data={
                                                    [
                                                      ["", "Lifetime",{role:"annotation"},this.state.year.latest_year.toString(),{role:"annotation"},this.state.year.mid_year.toString(),{role:"annotation"},this.state.year.last_year.toString(),{role:"annotation"}],
                                                      
                                                       ["Clinical Trials",this.state.RecentTrendclinicaltrialdetails.Lifetime,this.state.RecentTrendclinicaltrialdetails.Lifetime,this.state.RecentTrendclinicaltrialdetails.latest_year,this.state.RecentTrendclinicaltrialdetails.latest_year,this.state.RecentTrendclinicaltrialdetails.mid_year,this.state.RecentTrendclinicaltrialdetails.mid_year,this.state.RecentTrendclinicaltrialdetails.last_year,this.state.RecentTrendclinicaltrialdetails.last_year], 
                                                       ["Guidelines",this.state.RecentTrendguidlines.Lifetime,this.state.RecentTrendguidlines.Lifetime,this.state.RecentTrendguidlines.latest_year,this.state.RecentTrendguidlines.latest_year,this.state.RecentTrendguidlines.mid_year,this.state.RecentTrendguidlines.mid_year,this.state.RecentTrendguidlines.last_year,this.state.RecentTrendguidlines.last_year], 
                                                       ["Events/Conferences",this.state.RecentTrendeventdetails.Lifetime,this.state.RecentTrendeventdetails.Lifetime,this.state.RecentTrendeventdetails.latest_year,this.state.RecentTrendeventdetails.latest_year,this.state.RecentTrendeventdetails.mid_year,this.state.RecentTrendeventdetails.mid_year,this.state.RecentTrendeventdetails.last_year,this.state.RecentTrendeventdetails.last_year],
                                                       ["Publication",this.state.RecentTrendpublicationdetails.Lifetime,this.state.RecentTrendpublicationdetails.Lifetime,this.state.RecentTrendpublicationdetails.latest_year,this.state.RecentTrendpublicationdetails.latest_year,this.state.RecentTrendpublicationdetails.mid_year,this.state.RecentTrendpublicationdetails.mid_year,this.state.RecentTrendpublicationdetails.last_year,this.state.RecentTrendpublicationdetails.last_year],
                                                       ["Advisory",this.state.RecentTrendadvisoryboard.Lifetime,this.state.RecentTrendadvisoryboard.Lifetime,this.state.RecentTrendadvisoryboard.latest_year,this.state.RecentTrendadvisoryboard.latest_year,this.state.RecentTrendadvisoryboard.mid_year,this.state.RecentTrendadvisoryboard.mid_year,this.state.RecentTrendadvisoryboard.last_year,this.state.RecentTrendadvisoryboard.last_year],
                                                  ]}
                                                  options={RecentTrendChartOptions}
                                                    //view={view}
                                                    
                                                    width={"100%"}
                                                    height={"350px"}
                                                    rootProps={{ 'data-testid': '1' }}
                                                  />

                                        </div>
                                  </div>

                                  </div>
                                </div>
                              </div>






                        ) : (
                          <div>
                                                        
                          </div>
                        )
                    }


                    {
                      (this.state.event_piechart_flag == true) ? (

                                <div class="tab-pane container-xxl mt-2 active" id="event_sum">
                                <div className='card scrl_cntnts'>
                                  <div className='card-body' >
                                    {/* <h6>Events/Conferences </h6> */}

                                    <div className="row center">
                                        <div className="col-9" style={{marginBottom:'80px'}} >
      
                                    <Chart
                                      //chartType="ColumnChart"
                                      chartType={'ColumnChart'}
                                     // loader={<div>Loading Data...</div>}
                                      
                                    data={
                                      [


                                        [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
                                        [this.state.year.Lifetime.toString(),this.state.EventsGeographicGlobal.Lifetime,this.state.EventsGeographicGlobal.Lifetime+'%'	,this.state.EventsGeographicRegional.Lifetime,this.state.EventsGeographicRegional.Lifetime+'%',this.state.EventsGeographicNational.Lifetime,this.state.EventsGeographicNational.Lifetime+'%',	this.state.EventsGeographicLocal.Lifetime,this.state.EventsGeographicLocal.Lifetime+'%'], 
                                        [this.state.year.latest_year.toString(),this.state.EventsGeographicGlobal.latest_year,this.state.EventsGeographicGlobal.latest_year+'%'	,this.state.EventsGeographicRegional.latest_year,this.state.EventsGeographicRegional.latest_year+'%',this.state.EventsGeographicNational.latest_year,this.state.EventsGeographicNational.latest_year+'%',	this.state.EventsGeographicLocal.latest_year,this.state.EventsGeographicLocal.latest_year+'%'],
                                        [this.state.year.mid_year.toString(),this.state.EventsGeographicGlobal.mid_year,this.state.EventsGeographicGlobal.mid_year+'%'	,this.state.EventsGeographicRegional.mid_year,this.state.EventsGeographicRegional.mid_year+'%',this.state.EventsGeographicNational.mid_year,this.state.EventsGeographicNational.mid_year+'%',	this.state.EventsGeographicLocal.mid_year,this.state.EventsGeographicLocal.mid_year+'%'],
                                        [this.state.year.last_year.toString(),this.state.EventsGeographicGlobal.last_year,this.state.EventsGeographicGlobal.last_year+'%'	,this.state.EventsGeographicRegional.last_year,this.state.EventsGeographicRegional.last_year+'%',this.state.EventsGeographicNational.last_year,this.state.EventsGeographicNational.last_year+'%',	this.state.EventsGeographicLocal.last_year,this.state.EventsGeographicLocal.last_year+'%'],
                                       

                                      
                                      ]
                                      
                                                                          
                                    }
                                    options={EventConfrenceGeographicChartOptions}
                                      //view={view}
                                      
                                      width={"100%"}
                                      height={"350px"}
                                      rootProps={{ 'data-testid': '1' }}
                                    />

                              </div>

        <div className="col-9" style={{marginBottom:'80px'}} >

        <Chart
                                      //chartType="ColumnChart"
                                      chartType={'ColumnChart'}
                                     // loader={<div>Loading Data...</div>}
                                      
                                    data={
                                       [
                                          [ "","Event Chair",{role:"annotation"},"Moderator/Chair",{role:"annotation"},	"Regular",{role:"annotation"},"Keynote Speaker",{role:"annotation"}],
                                          [this.state.year.Lifetime.toString(),this.state.EventsRoleChairperson.Lifetime,this.state.EventsRoleChairperson.Lifetime+'%'	,this.state.EventsRoleModerator.Lifetime,this.state.EventsRoleModerator.Lifetime+'%',this.state.EventsRoleRegular.Lifetime,this.state.EventsRoleRegular.Lifetime+'%',	this.state.EventsRoleKeynote.Lifetime,this.state.EventsRoleKeynote.Lifetime+'%'],                                
                                          [this.state.year.latest_year.toString(),this.state.EventsRoleChairperson.latest_year,this.state.EventsRoleChairperson.latest_year+'%'	,this.state.EventsRoleModerator.latest_year,this.state.EventsRoleModerator.latest_year+'%',this.state.EventsRoleRegular.latest_year,this.state.EventsRoleRegular.latest_year+'%',	this.state.EventsRoleKeynote.latest_year,this.state.EventsRoleKeynote.latest_year+'%'],
                                          [this.state.year.mid_year.toString(),this.state.EventsRoleChairperson.mid_year,this.state.EventsRoleChairperson.mid_year+'%'	,this.state.EventsRoleModerator.mid_year,this.state.EventsRoleModerator.mid_year+'%',this.state.EventsRoleRegular.mid_year,this.state.EventsRoleRegular.mid_year+'%',	this.state.EventsRoleKeynote.mid_year,this.state.EventsRoleKeynote.mid_year+'%'],
                                          [this.state.year.last_year.toString(),this.state.EventsRoleChairperson.last_year,this.state.EventsRoleChairperson.last_year+'%'	,this.state.EventsRoleModerator.last_year,this.state.EventsRoleModerator.last_year+'%',this.state.EventsRoleRegular.last_year,this.state.EventsRoleRegular.last_year+'%',	this.state.EventsRoleKeynote.last_year,this.state.EventsRoleKeynote.last_year+'%'],
  
                                        ]
                                    }
                                    options={EventConfrenceRoleChartOptions}
                                      //view={view}
                                      
                                      width={"100%"}
                                      height={"350px"}
                                      rootProps={{ 'data-testid': '1' }}
                                    />




        </div>

    </div>


                                  </div>
                                </div>
                              </div>


                        ) : (
                          <div>
                                                        
                          </div>
                        )
                    }


{
                      (this.state.publication_piechart_flag == true) ? (

                                <div class="tab-pane container-xxl mt-2 active" id="publication_sum">
                                <div className='card scrl_cntnts'>
                                  <div className='card-body' >
                                    {/* <h6>Publications</h6> */}
                                    <div className="row center">
                                   <div className="col-9" style={{marginBottom:'80px'}}>

                                   <Chart
      //chartType="ColumnChart"
      chartType={'ColumnChart'}
     // loader={<div>Loading Data...</div>}
      
    data={
      

      [

      [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},"National",{role:"annotation"},"Local",{role:"annotation"}],
      [this.state.year.Lifetime.toString(),this.state.PublicationsGeographicGlobal.Lifetime,this.state.PublicationsGeographicGlobal.Lifetime+'%',this.state.PublicationsGeographicRegional.Lifetime,this.state.PublicationsGeographicRegional.Lifetime+'%',this.state.PublicationsGeographicNational.Lifetime,this.state.PublicationsGeographicNational.Lifetime+'%',this.state.PublicationsGeographicLocal.Lifetime,this.state.PublicationsGeographicLocal.Lifetime+'%'], 
      [this.state.year.latest_year.toString(),this.state.PublicationsGeographicGlobal.latest_year,this.state.PublicationsGeographicGlobal.latest_year+'%'	,this.state.PublicationsGeographicRegional.latest_year,this.state.PublicationsGeographicRegional.latest_year+'%',this.state.PublicationsGeographicNational.latest_year,this.state.PublicationsGeographicNational.latest_year+'%',	this.state.PublicationsGeographicLocal.latest_year,this.state.PublicationsGeographicLocal.latest_year+'%'],
      [this.state.year.mid_year.toString(),this.state.PublicationsGeographicGlobal.mid_year,this.state.PublicationsGeographicGlobal.mid_year+'%'	,this.state.PublicationsGeographicRegional.mid_year,this.state.PublicationsGeographicRegional.mid_year+'%',this.state.PublicationsGeographicNational.mid_year,this.state.PublicationsGeographicNational.mid_year+'%',	this.state.PublicationsGeographicLocal.mid_year,this.state.PublicationsGeographicLocal.mid_year+'%'],
      [this.state.year.last_year.toString(),this.state.PublicationsGeographicGlobal.last_year,this.state.PublicationsGeographicGlobal.last_year+'%'	,this.state.PublicationsGeographicRegional.last_year,this.state.PublicationsGeographicRegional.last_year+'%',this.state.PublicationsGeographicNational.last_year,this.state.PublicationsGeographicNational.last_year+'%',	this.state.PublicationsGeographicLocal.last_year,this.state.PublicationsGeographicLocal.last_year+'%'],

      ]

    }
     options={PublicationGeographicChartOptions}
      //view={view}
      
      width={"100%"}
      height={"350px"}
      rootProps={{ 'data-testid': '1' }}
    />

                                    </div>

                                    <div className="col-9" style={{marginBottom:'80px'}}>

                                    <Chart
      //chartType="ColumnChart"
      chartType={'ColumnChart'}
     // loader={<div>Loading Data...</div>}
      
    data={
        [
    [ "","First Author",{role:"annotation"},"Second Author",{role:"annotation"},	"Other Author",{role:"annotation"}],
    [this.state.year.Lifetime.toString(),this.state.PublicationsRoleFirstAuthor.Lifetime,this.state.PublicationsRoleFirstAuthor.Lifetime+'%'	,this.state.PublicationsRoleSecondAuthor.Lifetime,this.state.PublicationsRoleSecondAuthor.Lifetime+'%',this.state.PublicationsRoleLastAuthor.Lifetime,this.state.PublicationsRoleLastAuthor.Lifetime+'%'],                                
    [this.state.year.latest_year.toString(),this.state.PublicationsRoleFirstAuthor.latest_year,this.state.PublicationsRoleFirstAuthor.latest_year+'%'	,this.state.PublicationsRoleSecondAuthor.latest_year,this.state.PublicationsRoleSecondAuthor.latest_year+'%',this.state.PublicationsRoleLastAuthor.latest_year,this.state.PublicationsRoleLastAuthor.latest_year+'%'],
    [this.state.year.mid_year.toString(),this.state.PublicationsRoleFirstAuthor.mid_year,this.state.PublicationsRoleFirstAuthor.mid_year+'%'	,this.state.PublicationsRoleSecondAuthor.mid_year,this.state.PublicationsRoleSecondAuthor.mid_year+'%',this.state.PublicationsRoleLastAuthor.mid_year,this.state.PublicationsRoleLastAuthor.mid_year+'%'],
    [this.state.year.last_year.toString(),this.state.PublicationsRoleFirstAuthor.last_year,this.state.PublicationsRoleFirstAuthor.last_year+'%'	,this.state.PublicationsRoleSecondAuthor.last_year,this.state.PublicationsRoleSecondAuthor.last_year+'%',this.state.PublicationsRoleLastAuthor.last_year,this.state.PublicationsRoleLastAuthor.last_year+'%'],
    ]

    }
     options={PublicationProminenceRoleChartOptions}
      //view={view}
      
      width={"100%"}
      height={"350px"}
      rootProps={{ 'data-testid': '1' }}
    />



                                    </div>
                                    </div>

                                  </div>
                                </div>
                              </div>


                        ) : (
                          <div>
                                                        
                          </div>
                        )
                    }




{
                      (this.state.clinicaltrial_piechart_flag == true) ? (

                                <div class="tab-pane container-xxl mt-2 active" id="ct_sum">
                                <div className='card scrl_cntnts'>
                                  <div className='card-body' >
                                    {/* <h6>Clinical Trials</h6> */}

                                    <div className="row center">
                                   <div className="col-9" style={{marginBottom:'80px'}} >
                                   <Chart
                                      //chartType="ColumnChart"
                                      chartType={'ColumnChart'}
                                    // loader={<div>Loading Data...</div>}
                                      
                                    data={
                                      [
  
                                        [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
                                        [this.state.year.Lifetime.toString(),this.state.ClinicalsGeographicGlobal.Lifetime,this.state.ClinicalsGeographicGlobal.Lifetime+'%'	,this.state.ClinicalsGeographicRegional.Lifetime,this.state.ClinicalsGeographicRegional.Lifetime+'%',this.state.ClinicalsGeographicNational.Lifetime,this.state.ClinicalsGeographicNational.Lifetime+'%',	this.state.ClinicalsGeographicLocal.Lifetime,this.state.ClinicalsGeographicLocal.Lifetime+'%'], 
                                        [this.state.year.latest_year.toString(),this.state.ClinicalsGeographicGlobal.latest_year,this.state.ClinicalsGeographicGlobal.latest_year+'%'	,this.state.ClinicalsGeographicRegional.latest_year,this.state.ClinicalsGeographicRegional.latest_year+'%',this.state.ClinicalsGeographicNational.latest_year,this.state.ClinicalsGeographicNational.latest_year+'%',	this.state.ClinicalsGeographicLocal.latest_year,this.state.ClinicalsGeographicLocal.latest_year+'%'],
                                        [this.state.year.mid_year.toString(),this.state.ClinicalsGeographicGlobal.mid_year,this.state.ClinicalsGeographicGlobal.mid_year+'%'	,this.state.ClinicalsGeographicRegional.mid_year,this.state.ClinicalsGeographicRegional.mid_year+'%',this.state.ClinicalsGeographicNational.mid_year,this.state.ClinicalsGeographicNational.mid_year+'%',	this.state.ClinicalsGeographicLocal.mid_year,this.state.ClinicalsGeographicLocal.mid_year+'%'],
                                        [this.state.year.last_year.toString(),this.state.ClinicalsGeographicGlobal.last_year,this.state.ClinicalsGeographicGlobal.last_year+'%'	,this.state.ClinicalsGeographicRegional.last_year,this.state.ClinicalsGeographicRegional.last_year+'%',this.state.ClinicalsGeographicNational.last_year,this.state.ClinicalsGeographicNational.last_year+'%',	this.state.ClinicalsGeographicLocal.last_year,this.state.ClinicalsGeographicLocal.last_year+'%'],
                                      ]
                                    
                                    }
                                    options={ClinicalTrialsGeographicChartOptions}
                                      //view={view}
                                      
                                      width={"100%"}
                                      height={"350px"}
                                      rootProps={{ 'data-testid': '1' }}
                                    />

                                    </div>
                                    <div className="col-9" style={{marginBottom:'80px'}} >
                                    <Chart
                                      //chartType="ColumnChart"
                                      chartType={'ColumnChart'}
                                    // loader={<div>Loading Data...</div>}
                                      
                                    data={
                                      
                                      [
                                        [ "","more than 50",{role:"annotation"},"11-50",{role:"annotation"},"1-10",{role:"annotation"},	"1",{role:"annotation"}],
  
                                        [this.state.year.Lifetime.toString(),this.state.ClinicalsTrial50_more.Lifetime,this.state.ClinicalsTrial50_more.Lifetime+'%'	,this.state.ClinicalsTrial11_50.Lifetime,this.state.ClinicalsTrial11_50.Lifetime+'%',this.state.ClinicalsTrial1_10.Lifetime,this.state.ClinicalsTrial1_10.Lifetime+'%',	this.state.ClinicalsTrial1.Lifetime,this.state.ClinicalsTrial1.Lifetime+'%'],                                
                                        [this.state.year.latest_year.toString(),this.state.ClinicalsTrial50_more.latest_year,this.state.ClinicalsTrial50_more.latest_year+'%'	,this.state.ClinicalsTrial11_50.latest_year,this.state.ClinicalsTrial11_50.latest_year+'%',this.state.ClinicalsTrial1_10.latest_year,this.state.ClinicalsTrial1_10.latest_year+'%',	this.state.ClinicalsTrial1.latest_year,this.state.ClinicalsTrial1.latest_year+'%'],
                                        [this.state.year.mid_year.toString(),this.state.ClinicalsTrial50_more.mid_year,this.state.ClinicalsTrial50_more.mid_year+'%'	,this.state.ClinicalsTrial11_50.mid_year,this.state.ClinicalsTrial11_50.mid_year+'%',this.state.ClinicalsTrial1_10.mid_year,this.state.ClinicalsTrial1_10.mid_year+'%',	this.state.ClinicalsTrial1.mid_year,this.state.ClinicalsTrial1.mid_year+'%'],
                                        [this.state.year.last_year.toString(),this.state.ClinicalsTrial50_more.last_year,this.state.ClinicalsTrial50_more.last_year+'%'	,this.state.ClinicalsTrial11_50.last_year,this.state.ClinicalsTrial11_50.last_year+'%',this.state.ClinicalsTrial1_10.last_year,this.state.ClinicalsTrial1_10.last_year+'%',	this.state.ClinicalsTrial1.last_year,this.state.ClinicalsTrial1.last_year+'%'],
                                      ]
                                    
                                    }
                                    options={ClinicalTrialsLocationChartOptions}
                                      //view={view}
                                      
                                      width={"100%"}
                                      height={"350px"}
                                      rootProps={{ 'data-testid': '1' }}
                                    />

                                    </div>



                                    
                                    <div className="col-9" style={{marginBottom:'80px'}} >
                                    <Chart
                                      //chartType="ColumnChart"
                                      chartType={'ColumnChart'}
                                    // loader={<div>Loading Data...</div>}
                                      
                                    data={
                                      
                                      [
                                        [ "","Interventional",{role:"annotation"},"Observational",{role:"annotation"}],  
                                        [this.state.year.Lifetime.toString(),this.state.ClinicalsTrialInterventional.Lifetime,this.state.ClinicalsTrialInterventional.Lifetime+'%'	,this.state.ClinicalsTrialObservational.Lifetime,this.state.ClinicalsTrialObservational.Lifetime+'%'],                                
                                        [this.state.year.latest_year.toString(),this.state.ClinicalsTrialInterventional.latest_year,this.state.ClinicalsTrialInterventional.latest_year+'%'	,this.state.ClinicalsTrialObservational.latest_year,this.state.ClinicalsTrialObservational.latest_year+'%'],
                                        [this.state.year.mid_year.toString(),this.state.ClinicalsTrialInterventional.mid_year,this.state.ClinicalsTrialInterventional.mid_year+'%'	,this.state.ClinicalsTrialObservational.mid_year,this.state.ClinicalsTrialObservational.mid_year+'%'],
                                        [this.state.year.last_year.toString(),this.state.ClinicalsTrialInterventional.last_year,this.state.ClinicalsTrialInterventional.last_year+'%'	,this.state.ClinicalsTrialObservational.last_year,this.state.ClinicalsTrialObservational.last_year+'%'],
                                      ]
                                    
                                    }
                                    options={ClinicalTrialsTypeChartOptions}
                                      //view={view}
                                      
                                      width={"100%"}
                                      height={"350px"}
                                      rootProps={{ 'data-testid': '1' }}
                                    />

                                    </div>

                                    </div>




                                  </div>
                                </div>
                              </div>


                        ) : (
                          <div>
                                                        
                          </div>
                        )
                    }



{
                      (this.state.ad_board_piechart_flag == true) ? (

                                <div class="tab-pane container-xxl mt-2 active" id="advisory_sum">
                                <div className='card scrl_cntnts'>
                                  <div className='card-body' >
                                    {/* <h6>Advisory Board </h6> */}

                                    <div className="row center">
                                   <div className="col-9" style={{marginBottom:'80px'}} >
                                   <Chart
                                      //chartType="ColumnChart"
                                      chartType={'ColumnChart'}
                                    // loader={<div>Loading Data...</div>}
                                      
                                    data={
                                      [
                                            [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
                                            [this.state.year.Lifetime.toString(),this.state.AdvisoryGeographicGlobal.Lifetime,this.state.AdvisoryGeographicGlobal.Lifetime+'%'	,this.state.AdvisoryGeographicRegional.Lifetime,this.state.AdvisoryGeographicRegional.Lifetime+'%',this.state.AdvisoryGeographicNational.Lifetime,this.state.AdvisoryGeographicNational.Lifetime+'%',	this.state.AdvisoryGeographicLocal.Lifetime,this.state.AdvisoryGeographicLocal.Lifetime+'%'], 
                                            [this.state.year.latest_year.toString(),this.state.AdvisoryGeographicGlobal.latest_year,this.state.AdvisoryGeographicGlobal.latest_year+'%'	,this.state.AdvisoryGeographicRegional.latest_year,this.state.AdvisoryGeographicRegional.latest_year+'%',this.state.AdvisoryGeographicNational.latest_year,this.state.AdvisoryGeographicNational.latest_year+'%',	this.state.AdvisoryGeographicLocal.latest_year,this.state.AdvisoryGeographicLocal.latest_year+'%'],
                                            [this.state.year.mid_year.toString(),this.state.AdvisoryGeographicGlobal.mid_year,this.state.AdvisoryGeographicGlobal.mid_year+'%'	,this.state.AdvisoryGeographicRegional.mid_year,this.state.AdvisoryGeographicRegional.mid_year+'%',this.state.AdvisoryGeographicNational.mid_year,this.state.AdvisoryGeographicNational.mid_year+'%',	this.state.AdvisoryGeographicLocal.mid_year,this.state.AdvisoryGeographicLocal.mid_year+'%'],
                                            [this.state.year.last_year.toString(),this.state.AdvisoryGeographicGlobal.last_year,this.state.AdvisoryGeographicGlobal.last_year+'%'	,this.state.AdvisoryGeographicRegional.last_year,this.state.AdvisoryGeographicRegional.last_year+'%',this.state.AdvisoryGeographicNational.last_year,this.state.AdvisoryGeographicNational.last_year+'%',	this.state.AdvisoryGeographicLocal.last_year,this.state.AdvisoryGeographicLocal.last_year+'%'],
                                      ]                               
                                    }
                                    options={AdvisoryBoardGeographicChartOptions}
                                      
                                      width={"100%"}
                                      height={"350px"}
                                      rootProps={{ 'data-testid': '1' }}
                                    />

                                    </div>
                                    <div className="col-9" style={{marginBottom:'80px'}}>
                                    <Chart
                                      //chartType="ColumnChart"
                                      chartType={'ColumnChart'}
                                    // loader={<div>Loading Data...</div>}
                                   
                                    data={
                                       
                                    [
                                      [ "","committee Member",{role:"annotation"},"Chair",{role:"annotation"},"Co Chair",{role:"annotation"}],
                                        
                                      [this.state.year.Lifetime.toString(),this.state.AdvisoryRoleAdvisoryCommittee.Lifetime,this.state.AdvisoryRoleAdvisoryCommittee.Lifetime+'%'	,this.state.AdvisoryRolechair.Lifetime,this.state.AdvisoryRolechair.Lifetime+'%',this.state.AdvisoryRolecochair.Lifetime,this.state.AdvisoryRolecochair.Lifetime+'%'],                                
                                      [this.state.year.latest_year.toString(),this.state.AdvisoryRoleAdvisoryCommittee.latest_year,this.state.AdvisoryRoleAdvisoryCommittee.latest_year+'%'	,this.state.AdvisoryRolechair.latest_year,this.state.AdvisoryRolechair.latest_year+'%',this.state.AdvisoryRolecochair.latest_year,this.state.AdvisoryRolecochair.latest_year+'%'],
                                      [this.state.year.mid_year.toString(),this.state.AdvisoryRoleAdvisoryCommittee.mid_year,this.state.AdvisoryRoleAdvisoryCommittee.mid_year+'%'	,this.state.AdvisoryRolechair.mid_year,this.state.AdvisoryRolechair.mid_year+'%',this.state.AdvisoryRolecochair.mid_year,this.state.AdvisoryRolecochair.mid_year+'%'],
                                      [this.state.year.last_year.toString(),this.state.AdvisoryRoleAdvisoryCommittee.last_year,this.state.AdvisoryRoleAdvisoryCommittee.last_year+'%'	,this.state.AdvisoryRolechair.last_year,this.state.AdvisoryRolechair.last_year+'%',this.state.AdvisoryRolecochair.last_year,this.state.AdvisoryRolecochair.last_year+'%'],
                                    ]
                                    }
                                    options={AdvisoryBoardRoleProminenceChartOptions}
                                                                    //view={view}
                                      
                                      width={"100%"}
                                      height={"350px"}
                                      rootProps={{ 'data-testid': '1' }}
                                    />

                                    </div>
                                    </div>



                                    <div className="row center">
                                   <div className="col-9" >
                                   <Chart
                                      //chartType="ColumnChart"
                                      chartType={'ColumnChart'}
                                    // loader={<div>Loading Data...</div>}
                                  
                                    data={
                                        [
                                          [ "","Medtech Companies",{role:"annotation"},"Pharma Companies",{role:"annotation"},"Professional Association",{role:"annotation"},"Goverment Institution",{role:"annotation"}],
                                          [this.state.year.Lifetime.toString(),this.state.AdvisoryAffiliationMedtechCompanies.Lifetime,this.state.AdvisoryAffiliationMedtechCompanies.Lifetime+'%'	,this.state.AdvisoryAffiliationPharmaCompanies.Lifetime,this.state.AdvisoryAffiliationPharmaCompanies.Lifetime+'%',this.state.AdvisoryAffiliationProfessionalAssociations.Lifetime,this.state.AdvisoryAffiliationProfessionalAssociations.Lifetime+'%',	this.state.AdvisoryAffiliationGovernmentInstitutions.Lifetime,this.state.AdvisoryAffiliationGovernmentInstitutions.Lifetime+'%'],                                
                                          [this.state.year.latest_year.toString(),this.state.AdvisoryAffiliationMedtechCompanies.latest_year,this.state.AdvisoryAffiliationMedtechCompanies.latest_year+'%'	,this.state.AdvisoryAffiliationPharmaCompanies.latest_year,this.state.AdvisoryAffiliationPharmaCompanies.latest_year+'%',this.state.AdvisoryAffiliationProfessionalAssociations.latest_year,this.state.AdvisoryAffiliationProfessionalAssociations.latest_year+'%',	this.state.AdvisoryAffiliationGovernmentInstitutions.latest_year,this.state.AdvisoryAffiliationGovernmentInstitutions.latest_year+'%'],
                                          [this.state.year.mid_year.toString(),this.state.AdvisoryAffiliationMedtechCompanies.mid_year,this.state.AdvisoryAffiliationMedtechCompanies.mid_year+'%'	,this.state.AdvisoryAffiliationPharmaCompanies.mid_year,this.state.AdvisoryAffiliationPharmaCompanies.mid_year+'%',this.state.AdvisoryAffiliationProfessionalAssociations.mid_year,this.state.AdvisoryAffiliationProfessionalAssociations.mid_year+'%',	this.state.AdvisoryAffiliationGovernmentInstitutions.mid_year,this.state.AdvisoryAffiliationGovernmentInstitutions.mid_year+'%'],
                                          [this.state.year.last_year.toString(),this.state.AdvisoryAffiliationMedtechCompanies.last_year,this.state.AdvisoryAffiliationMedtechCompanies.last_year+'%'	,this.state.AdvisoryAffiliationPharmaCompanies.last_year,this.state.AdvisoryAffiliationPharmaCompanies.last_year+'%',this.state.AdvisoryAffiliationProfessionalAssociations.last_year,this.state.AdvisoryAffiliationProfessionalAssociations.last_year+'%',	this.state.AdvisoryAffiliationGovernmentInstitutions.last_year,this.state.AdvisoryAffiliationGovernmentInstitutions.last_year+'%'],
                                        ]

                                    }
                                    options={AdvisoryBoardAffiliationTypeChartOptions}
                                    
                                      width={"100%"}
                                      height={"350px"}
                                      rootProps={{ 'data-testid': '1' }}
                                    />

                                    </div>
                                    </div>





                                  </div>
                                </div>
                              </div>


                        ) : (
                          <div>
                                                        
                          </div>
                        )
                    }


{
                      (this.state.guideline_piechart_flag == true) ? (

                                <div class="tab-pane container-xxl mt-2 active" id="guideline_sum">
                                <div className='card scrl_cntnts'>
                                  <div className='card-body' >
                                    {/* <h6>Guidelines</h6> */}



                                    <div className="row center" style={{marginBottom:'80px'}}>
                                   <div className="col-9" >
                                   <Chart
                                      //chartType="ColumnChart"
                                      chartType={'ColumnChart'}
                                    // loader={<div>Loading Data...</div>}
                                      
                                    data={
                                      
                                      [
                                        [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
                                        [this.state.year.Lifetime.toString(),this.state.GuidelinesGeographicGlobal.Lifetime,this.state.GuidelinesGeographicGlobal.Lifetime+'%'	,this.state.GuidelinesGeographicRegional.Lifetime,this.state.GuidelinesGeographicRegional.Lifetime+'%',this.state.GuidelinesGeographicNational.Lifetime,this.state.GuidelinesGeographicNational.Lifetime+'%',	this.state.GuidelinesGeographicLocal.Lifetime,this.state.GuidelinesGeographicLocal.Lifetime+'%'], 
                                        [this.state.year.latest_year.toString(),this.state.GuidelinesGeographicGlobal.latest_year,this.state.GuidelinesGeographicGlobal.latest_year+'%'	,this.state.GuidelinesGeographicRegional.latest_year,this.state.GuidelinesGeographicRegional.latest_year+'%',this.state.GuidelinesGeographicNational.latest_year,this.state.GuidelinesGeographicNational.latest_year+'%',	this.state.GuidelinesGeographicLocal.latest_year,this.state.GuidelinesGeographicLocal.latest_year+'%'],
                                        [this.state.year.mid_year.toString(),this.state.GuidelinesGeographicGlobal.mid_year,this.state.GuidelinesGeographicGlobal.mid_year+'%'	,this.state.GuidelinesGeographicRegional.mid_year,this.state.GuidelinesGeographicRegional.mid_year+'%',this.state.GuidelinesGeographicNational.mid_year,this.state.GuidelinesGeographicNational.mid_year+'%',	this.state.GuidelinesGeographicLocal.mid_year,this.state.GuidelinesGeographicLocal.mid_year+'%'],
                                        [this.state.year.last_year.toString(),this.state.GuidelinesGeographicGlobal.last_year,this.state.GuidelinesGeographicGlobal.last_year+'%'	,this.state.GuidelinesGeographicRegional.last_year,this.state.GuidelinesGeographicRegional.last_year+'%',this.state.GuidelinesGeographicNational.last_year,this.state.GuidelinesGeographicNational.last_year+'%',	this.state.GuidelinesGeographicLocal.last_year,this.state.GuidelinesGeographicLocal.last_year+'%'],
                                      ]
                                    }
                                    options={GuidelinesGeographicChartOptions}
                                      
                                      width={"100%"}
                                      height={"350px"}
                                      rootProps={{ 'data-testid': '1' }}
                                    />

                                    </div>
                                    <div className="col-9" >
                                    <Chart
                                      //chartType="ColumnChart"
                                      chartType={'ColumnChart'}
                                    // loader={<div>Loading Data...</div>}
                                      
                                    data={
                                      [
                                       [ "","Author",{role:"annotation"},"Committee Member",{role:"annotation"},"Committee Chair",{role:"annotation"}],
                                          
                                        [this.state.year.Lifetime.toString(),this.state.GuidelinesRoleAuthor.Lifetime,this.state.GuidelinesRoleAuthor.Lifetime+'%'	,this.state.GuidelinesRoleCommitteeMember.Lifetime,this.state.GuidelinesRoleCommitteeMember.Lifetime+'%',this.state.GuidelinesRoleCommitteeChair.Lifetime,this.state.GuidelinesRoleCommitteeChair.Lifetime+'%'],                                
                                        [this.state.year.latest_year.toString(),this.state.GuidelinesRoleAuthor.latest_year,this.state.GuidelinesRoleAuthor.latest_year+'%'	,this.state.GuidelinesRoleCommitteeMember.latest_year,this.state.GuidelinesRoleCommitteeMember.latest_year+'%',this.state.GuidelinesRoleCommitteeChair.latest_year,this.state.GuidelinesRoleCommitteeChair.latest_year+'%'],
                                        [this.state.year.mid_year.toString(),this.state.GuidelinesRoleAuthor.mid_year,this.state.GuidelinesRoleAuthor.mid_year+'%'	,this.state.GuidelinesRoleCommitteeMember.mid_year,this.state.GuidelinesRoleCommitteeMember.mid_year+'%',this.state.GuidelinesRoleCommitteeChair.mid_year,this.state.GuidelinesRoleCommitteeChair.mid_year+'%'],
                                        [this.state.year.last_year.toString(),this.state.GuidelinesRoleAuthor.last_year,this.state.GuidelinesRoleAuthor.last_year+'%'	,this.state.GuidelinesRoleCommitteeMember.last_year,this.state.GuidelinesRoleCommitteeMember.last_year+'%',this.state.GuidelinesRoleCommitteeChair.last_year,this.state.GuidelinesRoleCommitteeChair.last_year+'%'],
                                      ]
                                    }
                                    options={GuidelinesRoleProminenceChartOptions}
                                                                    //view={view}
                                      
                                      width={"100%"}
                                      height={"350px"}
                                      rootProps={{ 'data-testid': '1' }}
                                    />

                                    </div>
                                    </div>



                                    <div className="row center">
                                   <div className="col-9" >
                                   <Chart
                                      //chartType="ColumnChart"
                                      chartType={'ColumnChart'}
                                    // loader={<div>Loading Data...</div>}
                                      
                                    data={
                                      [
                                       [ "","Academic Hospital",{role:"annotation"},"Professional Associations",{role:"annotation"},"Government Institutions",{role:"annotation"}],
                                       [this.state.year.Lifetime.toString(),this.state.GuidelinesAffiliationAcademicHospital.Lifetime,this.state.GuidelinesAffiliationAcademicHospital.Lifetime+'%'	,this.state.GuidelinesAffiliationProfessionalAssociations.Lifetime,this.state.GuidelinesAffiliationProfessionalAssociations.Lifetime+'%',this.state.GuidelinesAffiliationGovernmentInstitutions.Lifetime,this.state.GuidelinesAffiliationGovernmentInstitutions.Lifetime+'%'],                                
                                       [this.state.year.latest_year.toString(),this.state.GuidelinesAffiliationAcademicHospital.latest_year,this.state.GuidelinesAffiliationAcademicHospital.latest_year+'%'	,this.state.GuidelinesAffiliationProfessionalAssociations.latest_year,this.state.GuidelinesAffiliationProfessionalAssociations.latest_year+'%',this.state.GuidelinesAffiliationGovernmentInstitutions.latest_year,this.state.GuidelinesAffiliationGovernmentInstitutions.latest_year+'%'],
                                       [this.state.year.mid_year.toString(),this.state.GuidelinesAffiliationAcademicHospital.mid_year,this.state.GuidelinesAffiliationAcademicHospital.mid_year+'%'	,this.state.GuidelinesAffiliationProfessionalAssociations.mid_year,this.state.GuidelinesAffiliationProfessionalAssociations.mid_year+'%',this.state.GuidelinesAffiliationGovernmentInstitutions.mid_year,this.state.GuidelinesAffiliationGovernmentInstitutions.mid_year+'%'],
                                       [this.state.year.last_year.toString(),this.state.GuidelinesAffiliationAcademicHospital.last_year,this.state.GuidelinesAffiliationAcademicHospital.last_year+'%'	,this.state.GuidelinesAffiliationProfessionalAssociations.last_year,this.state.GuidelinesAffiliationProfessionalAssociations.last_year+'%',this.state.GuidelinesAffiliationGovernmentInstitutions.last_year,this.state.GuidelinesAffiliationGovernmentInstitutions.last_year+'%'],
                                          
                                       
                                      ]
                                    }
                                    options={GuidelinesRoleProminenceChartOptions}
                                      width={"100%"}
                                      height={"350px"}
                                      rootProps={{ 'data-testid': '1' }}
                                    />

                                   
                                    </div>
                                    </div>

                                  </div>
                                </div>
                              </div>


                        ) : (
                          <div>
                                                        
                          </div>
                        )
                    }


                  </div> 



                </div>

) : (
  <div>
    
  </div>
)
}

                {/* <a id="advisory-board"></a> */}
                                                

                {
                  (this.state.BiographyFlag == true) ? (
                    
                      
                <div class="tab-pane container-xxl mt-2" id="Biography">
                <div className='card mb-3 scrl_cntnt'>
                    <div className='card-body'>
                      <h6><b>Biography Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-3 col col-md-3 col col-sm-3 col col-xs-3 dtlpge'>
                          <p><b>Citizenship:</b> {this.state.Biography.citizenship}</p>
                        </div>
                        <div className='col col-lg-3 col col-md-3 col col-sm-3 col col-xs-3 dtlpge'>
                          <p><b>Language Spoken:</b> {this.state.Biography.language_spoken}</p>
                        </div>
                        <div className='col col-lg-3 col col-md-3 col col-sm-3 col col-xs-3 dtlpge'>
                          <p><b>Linkedin Followers:</b> {this.state.Biography.linkedin_followers}</p>
                        </div>
                        <div className='col col-lg-3 col col-md-3 col col-sm-3 col col-xs-3 dtlpge'>
                          <p><b>Twitter Followers:</b> {this.state.Biography.twitter_followers}</p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12 dtlpge'>
                          <p><b>Areas of Interest:</b>{this.state.Biography.areas_of_interest}</p>
                        </div>
                        {/* <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12 dtlpge'>
                          <p><b>Expertise:</b> {this.state.PersonalInformation.expertise}</p>
                        </div> */}
                        
                      </div>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12 dtlpge'>
                        <p className='pbio'><b>Biography:</b>
                        <view style={{ whiteSpace: "break-spaces"}}>                                                                             
                                                                                {this.state.Biography.biography}
                                                                        </view>
                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                ) : (
                                                        <div>
                                                        
                                                        </div>
                                                    )
                                                }


                                                
                                                {/* Professional membership */}
                                                {
                                                    (this.state.ProfessionalMembershipFlag == true) ? (
                                                
                <div class="tab-pane container-xxl mt-2" id="Professionaldtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Professional Membership Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                            <div className='bg-light'>
                                                                        <BootstrapTable
                                                                            // responsive  
                                                                            // scrollX   
                                                                            // wrapperClasses="table-responsive"
                                                                            // wrapperClasses ="tablebootstrap" 
                                                                            // style={{width:'200%'}}   
                                                                            noDataIndication="Table is Empty"
                                                                            keyField='id'
                                                                            data={this.state.ProfessionalMemberships}
                                                                            columns={this.state.columnsProfessionalMemberships}
                                                                            filter={filterFactory()}
                                                                            //striped
                                                                            hover
                                                                            condensed

                                                                            rowStyle={{backgroundColor:'#fff'}}
                                                                        />
                                                                    </div>  
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

) : (
  <div>
  </div>
)
}


{
                                                    (this.state.EducationFlag == true) ? (
                <div class="tab-pane container-xxl mt-2" id="Educationdtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Education Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                            <div className='bg-light'>
                            <BootstrapTable
                                                                            noDataIndication="Table is Empty"
                                                                            keyField='id'
                                                                            data={this.state.Education}
                                                                            columns={this.state.columnsEducation}
                                                                            filter={filterFactory()}
                                                                           // striped
                                                                            hover
                                                                            condensed
                                                                            rowStyle={{backgroundColor:'#fff'}}
                                                                        />

                                                                        </div>

                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

) : (
  <div>
    
  </div>
)
}



{
  (this.state.AdvisoryBoardFlag == true) ? (
                <div class="tab-pane container-xxl mt-2" id="Advisorydtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Advisory Board Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                              <div className='bg-light'>
                                <BootstrapTable
                                                                            noDataIndication="Table is Empty"
                                                                            keyField='id'
                                                                            data={this.state.AdvisoryBoard}
                                                                            columns={this.state.columnsAdvisoryBord}
                                                                            //striped
                                                                            hover
                                                                            condensed
                                                                            rowStyle={{backgroundColor:'#fff'}}
                                                                        />
                                </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

) : (
  <div>
    
  </div>
)
}

{
                                                    (this.state.AwardsAndHonoursFlag == true) ? (

                <div class="tab-pane container-xxl mt-2" id="Awardsdtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Awards and Honours Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                              <div className='bg-light'>
                              <BootstrapTable
                                                                        noDataIndication="Table is Empty"
                                                                        keyField='id'
                                                                        data={this.state.AwardsHonours}
                                                                        columns={this.state.columnsAwardsHonours}
                                                                       // striped
                                                                        hover
                                                                        condensed
                                                                        rowStyle={{backgroundColor:'#fff'}}
                                                                    />
                              </div>
                              
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

) : (
  <div>
    
  </div>
)
}


{
  (this.state.BooksAndMonographFlag == true) ? (

                <div class="tab-pane container-xxl mt-2" id="Booksdtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Books and Monographs</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                            <div className='bg-light'>

                            <BootstrapTable
                                                                            noDataIndication="Table is Empty"
                                                                            keyField='id'
                                                                            data={this.state.BooksMonographs}
                                                                            columns={this.state.columnsBooksMonographs}
                                                                          //  striped
                                                                            hover
                                                                            condensed
                                                                            rowStyle={{backgroundColor:'#fff'}}
                                                                        />
                             </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
) : (
  <div>
    
  </div>
)
}



{
                                                    (this.state.CommiteesFlag == true) ? (
                <div class="tab-pane container-xxl mt-2" id="Committeedtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Committee Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                            <div className='bg-light'>

                            <BootstrapTable
                                                                        noDataIndication="Table is Empty"
                                                                        keyField='id'
                                                                        data={this.state.Commitees}
                                                                        columns={this.state.columnsCommitees}
                                                                        //striped
                                                                        hover
                                                                        condensed
                                                                        rowStyle={{backgroundColor:'#fff'}}
                                                                    />

                            </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

) : (
  <div>
    
  </div>
)
}


{
  (this.state.ClinicalTrialDetailsFlag == true) ? (
                <div class="tab-pane container-xxl mt-2" id="ctdtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Clinical Trial Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                            <div className='bg-light'>
                            <BootstrapTable
                                                                            noDataIndication="Table is Empty"
                                                                            keyField='id'
                                                                            data={this.state.ClinicalTrialDetails}
                                                                            columns={this.state.columnsClinicalTrialDetails}
                                                                           // striped
                                                                            hover
                                                                            rowStyle={{backgroundColor:'#fff'}}
                                                                            condensed
                                                                        />
</div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


) : (
  <div>
    
  </div>
)
}


{
                                                    (this.state.EventsDetailsFlag == true) ? (

                <div class="tab-pane container-xxl mt-2" id="Eventdtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Event Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                            <div className='bg-light'>

                            <BootstrapTable
                                                                            noDataIndication="Table is Empty"
                                                                            keyField='id'
                                                                            data={this.state.EventsDetails}
                                                                            columns={this.state.columnsEventsDetails}
                                                                            //striped
                                                                            hover
                                                                            condensed
                                                                            rowStyle={{backgroundColor:'#fff'}}
                                                                        />
                            </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
) : (
  <div>
    
  </div>
)
}


{
  (this.state.GrantsFlag == true) ? (

                <div class="tab-pane container-xxl mt-2" id="Grantsdtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Grants Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                            <div className='bg-light'>
                            <BootstrapTable
                                                                        noDataIndication="Table is Empty"
                                                                        keyField='id'
                                                                        data={this.state.Grants}
                                                                        columns={this.state.columnsGrants}
                                                                        //striped
                                                                        hover
                                                                        condensed
                                                                        rowStyle={{backgroundColor:'#fff'}}
                                                                    />
                                </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


) : (
  <div>
    
  </div>
)
}


{
                                                    (this.state.GuidelinesFlag == true) ? (

                <div class="tab-pane container-xxl mt-2" id="Guidelinesdtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Guidelines Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                            <div className='bg-light'>
                            <BootstrapTable
                                                                            noDataIndication="Table is Empty"
                                                                            keyField='id'
                                                                            data={this.state.Guidelines}
                                                                            columns={this.state.columnsGuidelines}
                                                                            //striped
                                                                            hover
                                                                            condensed
                                                                            rowStyle={{backgroundColor:'#fff'}}
                                                                        />
                              </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

) : (
  <div>
    
  </div>
)
}


{
  (this.state.HospitalAffiliationsFlag == true) ? (

                <div class="tab-pane container-xxl mt-2" id="Hospitaldtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Hospital Affiliation Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                            <div className='bg-light'>
                            <BootstrapTable
                                                                        noDataIndication="Table is Empty"
                                                                        keyField='id'
                                                                        data={this.state.HospitalAffiliations}
                                                                        columns={this.state.columnsHospitalAffiliations}
                                                                        //striped
                                                                        hover
                                                                        condensed
                                                                        rowStyle={{backgroundColor:'#fff'}}
                                                                    />

                              </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

) : (
  <div>
    
  </div>
)
}



{
                                                    (this.state.InteractionsFlag == true) ? (

                <div class="tab-pane container-xxl mt-2" id="Interactionsdtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Interactions Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                              <div className='bg-light'>
                              <BootstrapTable
                                                                            noDataIndication="Table is Empty"
                                                                            keyField='id'
                                                                            data={this.state.Interactions}
                                                                            columns={this.state.columnsInteractions}
                                                                          //  striped
                                                                            hover
                                                                            condensed
                                                                            rowStyle={{backgroundColor:'#fff'}}
                                                                        />
                               </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
) : (
  <div>
    
  </div>
)
}

{
  (this.state.PressItemFlag == true) ? (

                <div class="tab-pane container-xxl mt-2" id="Pressdtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Press Item Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                            <div className='bg-light'>
                                      <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField='id'
                                          data={this.state.PressItem}
                                          columns={this.state.columnsPressItem}
                                          //striped
                                          
                                          hover
                                          condensed
                                          rowStyle={{backgroundColor:'#fff'}}
                                      />
                              </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

) : (
  <div>
    
  </div>
)
}

{
                                                    (this.state.PresentationsFlag == true) ? (

                <div class="tab-pane container-xxl mt-2" id="Presentationsdtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Presentation Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                            <div className='bg-light'>
                            <BootstrapTable
                                                                        noDataIndication="Table is Empty"
                                                                        keyField='id'
                                                                        data={this.state.Presentation}
                                                                        columns={this.state.columnsPresentation}
                                                                       // striped
                                                                        hover
                                                                        condensed
                                                                        rowStyle={{backgroundColor:'#fff'}}
                                                                    />
                              </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
) : (
  <div>
    
  </div>
)
}



{
  (this.state.PublicationDetailsFlag == true) ? (




                <div class="tab-pane container-xxl mt-2" id="Publicationsdtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Publication Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                            <div className='bg-light'>
                            <BootstrapTable
                                                                            noDataIndication="Table is Empty"
                                                                            keyField='id'
                                                                            data={this.state.PublicationDetails}
                                                                            columns={this.state.columnsPublicationDetails}
                                                                            //striped
                                                                            hover
                                                                            condensed
                                                                            rowStyle={{backgroundColor:'#fff'}}
                                                                        />
                              </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

) : (
  <div>
    
  </div>
)
}


{
                                                    (this.state.SunshinePaymentFlag == true) ? (

                <div class="tab-pane container-xxl mt-2" id="Sunshinedtl">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b>Sunshine Payment Details</b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                            <div className='bg-light'>
                            <BootstrapTable
                                                                            noDataIndication="Table is Empty"
                                                                            keyField='id'
                                                                            data={this.state.SunshinePayment}
                                                                            columns={this.state.columnsSunshinePayment}
                                                                            //striped
                                                                            hover
                                                                            condensed
                                                                            rowStyle={{backgroundColor:'#fff'}}
                                                                        />
                              </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


) : (
  <div>
    
  </div>
)
}

{/* 
                <div class="tab-pane container-xxl mt-2" id="">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b></b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                              <thead className='bg-light'>
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody className='table_srl'>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}



<Modal isOpen={this.state.NewQuoteModal} toggle={this.toggleinfoBuyModal.bind(this)} centered>
                                        <ModalHeader toggle={this.toggleinfoBuyModal.bind(this)}
                                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                            style={{ borderBottom: '0px' }}>
                                            <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Get a quote</h3>
                                        </ModalHeader>

                                        {/* <ModalHeader toggle={this.toggleinfoBuyModal.bind(this)}
                                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                            style={{ borderBottom: '0px' , backgroundColor:'#ccc' }}>
                                            <h3 style={{ justifyContent: 'center', marginTop: '20px' }} id="login-tit">Get a quote</h3>
                                        </ModalHeader> */}
                                      
                                        <ModalBody style={{ padding: '0px 70px 0px 70px' }}>

                                           
                                                  <Quoteform/>

                                        </ModalBody>
                                
                         </Modal>



                
              </div>
            </div>
          </div>
        </div>


</div>
</div>
</div>




          {/* <footer class="footer_not">

                                  <div class="cetas-footer">
                                      <div class="container">
                                          <p>All rights reserved. www.cetashealthcare.com</p>
                                      </div>
                                  </div>
            </footer>  */}


<Pagefooter/>   
        </div>

);
  
    
          
                                              }
                                            }
                            
export default ClientKolProfile;