import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import Swal from 'sweetalert2';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Col, Popover, PopoverHeader, PopoverBody, Alert } from 'reactstrap';
import { Router } from 'react-router-dom/cjs/react-router-dom.min';

//import '../styles/ClientHeader.css';
//import './Client.css';

class ClientHeader extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            redirect: false,
            client_id: '',
            client_name: '',
            client_email: '',
            kol_id:'',
            project_id:'',
            speciality_id:'',
            speciality_name:'',
            redirectPClientViewKolList: false,
            redirectBackToKolList:false,

            logoutflag:false,            
            redirectLogin:false,
            redirectSignup:false,

            SpecialityData:[],

            SpecialityDataL: [
              { id: '76', speciality_type: 'Physics' },
              { id: 'Genetic Epidemiology', speciality_type: 'Genetic Epidemiology' },
              { id: 'English', speciality_type: 'English' },
              { id: 'Bengali', speciality_type: 'Bengali' },
              { id: 'Portuguese', speciality_type: 'Portuguese' },
              { id: 'Russian', speciality_type: 'Russian' },
              { id: 'Japanese', speciality_type: 'Japanese' },
              { id: 'Western Punjabi[10]', speciality_type: 'Western Punjabi[10]' },
            ],
        };        
    }

    componentDidMount = async () => {

      

        let getSession_Client_Email = await sessionStorage.getItem('client_email')
        if (getSession_Client_Email !== null) {
          await this.setState({
            client_email: getSession_Client_Email,
          })
        }
    
        let getSession_Client_ID = await sessionStorage.getItem('client_id')
        if (getSession_Client_ID !== null) {
          await this.setState({
            client_id: getSession_Client_ID,
          })
        }
        else
        { 
            this.setState({redirect: true})
        }
    
        let getSession_Client_Name = await sessionStorage.getItem('client_name')
        if (getSession_Client_Name !== null) {
          await this.setState({
            client_name: getSession_Client_Name,
          })
        }
        else
        { 
            this.setState({redirect: true})
        }
    
        if(this.state.client_id=="Guest")
        {
          this.setState({logoutflag:false})
        }
        else
        {
          this.setState({logoutflag:true})
        }


        let getSession_Kol_Id = sessionStorage.getItem('kol_id')
        if (getSession_Kol_Id !== null) {
            this.setState({
                kol_id: JSON.parse(getSession_Kol_Id),
            })
        }
    
    
        let getSession_Project_Id = await sessionStorage.getItem('project_id')
        if (getSession_Project_Id !== null) {
          await this.setState({
            project_id: getSession_Project_Id,
          })
        }
    
        console.log("getSession_Client_Email ====", getSession_Client_Email)
        console.log("getSession_Client_ID ====", getSession_Client_ID)
        console.log("getSession_Client_Name ====", getSession_Client_Name)
            
        console.log("getSession_kol_id ====", this.state.kol_id);
        console.log("getSession_Project_Id ===== ", getSession_Project_Id);


        const options = {
          method: 'POST',
        }
        //  Speciality type data
        fetch(global.websiteUrl+'/service/KOL_landing/Specility_list_for_landing_page', options)
          .then((Response) => Response.json())
          .then((findresponse) => {
            console.log(findresponse)
            var testDataSpeciality = findresponse.data;
            console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
            this.setState({
              SpecialityData: findresponse.data,
            })
          })

         // alert(this.state.speciality_name);

    }


    toggleLoginModal() {
      //console.log("loginclicked")
      
      this.setState({
        redirectLogin:true,
      
      });
    }
  
    
    toggleSignUpModal() {
      //console.log("loginclicked")
  
    //  sessionStorage.removeItem('quotation_id','');    
      this.setState({
        redirectSignup:true,
      
      });
  

    }


    toggleSearchModal()
    {
      
      //  alert(window.location.href);


//  // alert(this.state.speciality_name);
if (this.state.speciality_name !="")
    { 
    if( this.state.client_name == "")
    {  
      sessionStorage.setItem('client_email','')
      sessionStorage.setItem('client_id', "Guest")
      sessionStorage.setItem('client_name', "")
      sessionStorage.setItem('speciality_id',this.state.speciality_name )
    }
    else
    {
      sessionStorage.setItem('speciality_id',this.state.speciality_name )
    }
    
      if(window.location.href.includes('PClientViewKolList'))
      {
        window.location.reload(true)
      }
      else if(window.location.href.includes('PClientKolProfile'))
      {
          this.setState({
            redirectBackToKolList:true,
          })

      }

     // alert( this.state.speciality_name)
          
   } 
   else
   {
    Swal.fire({
      icon: 'error',
      title:'Oops',
      text: 'Select Speciality',
      confirmButtonColor: '#d33',
      confirmButtonText: 'OK'
    })

   }
      
  
    }

    logout = () => {
        console.log("in logout")
        this.setState({redirect: true})
        sessionStorage.removeItem('client_id')
        sessionStorage.removeItem('client_name')
        sessionStorage.removeItem('email_id')        
        sessionStorage.removeItem('kol_id')
        sessionStorage.removeItem('getSession_Project_Id')
        sessionStorage.removeItem('Buyer_order')
        sessionStorage.removeItem('Buyer_kol_id')        
        sessionStorage.removeItem('Buyer_client_name')
        sessionStorage.removeItem('Buyer_client_id')


    }


    

    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/'}/>)
        }

        if (this.state.redirectBackToKolList) {
          return (<Redirect to={{
              pathname:'/PClientViewKolList',
          }} />)
      }

      if (this.state.redirectLogin) {
        return (<Redirect to={'/login'}/>)
      }
      
      if (this.state.redirectSignup) {
        return (<Redirect to={'/signup'}/>)
      }


      //   if (this.state.redirectPClientViewKolList) {
      //     return (<Redirect to={'/PClientViewKolList'}/>)
      // }

        // // Speciality Type options 
    let speciality_type = this.state.SpecialityData;
    let OptionsSpeciality = speciality_type.map((speciality_type) =>
      <option key={speciality_type.id} value={speciality_type.id}>
        {speciality_type.speciality_type}</option>
    );
        return (


            
        <div class="Client-header1" style={{marginTop:'50px'}} > 
           <div className="header-nav" >
                <div className="container">
                 <nav class="navbar navbar-light navbar-expand-lg fixed-top" >
                    <div class="container-fluid">
                            <a style={{display: "table-cell"}} className="navbar-brand" 
                            href="https://cetas-healthcare.com/" target = "_blank" rel="noopener">
                            <img src={require('../Assets/Images/cetas_healthcare_logo.svg')}                            />  
                            </a>
                          <button className="navbar-toggler" type="button" data-toggle="collapse" 
                                    data-target="#navbarNavAltMarkup" 
                                    aria-controls="navbarNavAltMarkup" aria-expanded="false" 
                                    aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav"  >
                            <div id="search" className="text-center" >
                               
                                {/* <a className="nav-item nav-link active" href="#">Projects</a>
                                <a className="nav-item nav-link" href="#">Team Members</a> 
                                 */}
                            </div>
                        </div>


    <div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
    <div class="navbar-nav1">
        {/* <a class="nav-link1"  aria-current="page" href="#0"  ><span class="btn-label"><i class="fa fa-user"></i>   </span>Welcome,  {this.state.client_name}</a> */}
        <div class="preclient">
            <div className='searchbox' style={{marginTop:'15px'}}>            
            <Input type="select" id="SpecilitySelect"  
               //value={this.state.speciality_name}
                onChange={(e) => {
                      this.setState({speciality_name:e.target.value})
                      
                }}
                >
                <option value="0" >Search Speciality...</option>
                {OptionsSpeciality}                
                
        </Input>          
            </div>            
        </div>
       
    </div>
    <div className='searchbutton'>   
    <a href="#" data-bs-toggle="modal" onClick={this.toggleSearchModal.bind(this)} className="search_button" > <i class="fa fa-search" style={{fontWeight:'lighter'}}></i> &nbsp;&nbsp; SEARCH</a>
                                                             
            {/* <a href="#" data-bs-toggle="modal" className="btn ms-lg-1" style={{ backgroundColor:'#0C4272',color:'#fff', paddingLeft:'20px',paddingRight:'30px',paddingTop:'10px',paddingBottom:'10px', fontSize:'12px' }}> <i class="fa fa-search" style={{fontWeight:'lighter'}}></i> &nbsp;&nbsp; SEARCH</a> */}
    </div>     
    </div>

    <div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
    <div class="navbar-nav1" >
    
    <div className='logoutbox' style={{paddingRight:'30px'}}>
      
      { (this.state.logoutflag==true)?
        (<div>
          <div className='user_signout'><span class="btn-label"><i class="fa fa-user" style={{ marginRight:'10px'}}></i>   </span> {this.state.client_name}</div>      
          <button class="button_signout" style={{ fontWeight:'lighter'}} onClick={this.logout.bind(this)}><span class="btn-label" ><i class="fa fa-power-off"></i>  </span> Logout </button>
        
        </div>):(<div>

          <button class="button_signout" style={{ fontWeight:'lighter'}} > 
                <a  onClick={this.toggleLoginModal.bind(this)}>Login</a> / <a onClick={this.toggleSignUpModal.bind(this)}>Sign Up</a>      
          </button>

        </div>)

      }
      {/* <div className='user_signout'><span class="btn-label"><i class="fa fa-user" style={{ marginRight:'10px'}}></i>   </span> {this.state.client_name}</div>      
      <button class="button_signout" style={{ fontWeight:'lighter'}} onClick={this.logout.bind(this)}><span class="btn-label" ><i class="fa fa-power-off"></i>  </span> Logout </button>
    
      <button class="button_signout" style={{ fontWeight:'lighter'}} onClick={this.logout.bind(this)}> 
                <a >Login</a>/ <a>Sign Up</a>      
      </button> */}

    </div>
    </div>
  </div>

    {/* <div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
    <div class="navbar-nav">
        <div className='user_signout'><span class="btn-label"><i class="fa fa-user"></i>   </span> {this.state.client_name}kumar</div>      
      
        <button class="button_signout"  onClick={this.logout.bind(this)}><span class="btn-label" ><i class="fa fa-power-off"></i>  </span> Logout</button>
        
    </div>
    </div> */}
  
  </div>
  </div>
  


 
    </nav>
</div>

                   
                </div>
            </div> 
        );
    }
}

export default ClientHeader;