import React, { Component, useRef } from 'react';
import { Redirect, Link } from 'react-router-dom';

class Loginheader extends Component{
    constructor(props)
    {
        super(props);
        this.state=
        {
            Redirect:false,
        };

     
    
    }

    togglehome() {
        this.setState({
        Redirect:true,
        });

    };
    render(){

        if(this.state.Redirect)
        {
            return(<Redirect to={'/'}/>)
        }

        return(
            <div className="text-center" >
                <div className="container" >
                    <div className="row">
                        <div className="col-12">
                            <div className="loginheader">
                                {/* <a href="https://www.cetas-healthcare.com/"> <img id="lglogos" src={require('../Assets/Images/cetas_healthcare_logo.svg')} alt="Cetas"></img></a> */}
                                <a onClick={this.togglehome.bind(this)}> <img id="lglogos" src={require('../Assets/Images/cetas_healthcare_logo.svg')} alt="Cetas"></img></a>
                              
                                 <p> MedTech Guru KOL Network Platform by Cetas Healthcare</p>       
                            </div>
                        </div>
                    </div>            
                
                </div>
            </div>

   
        )
    }
}
export default Loginheader;