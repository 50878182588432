import React, { Component, useRef } from 'react';
import { Redirect, Link ,NavLink} from 'react-router-dom';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Col, Popover, PopoverHeader, PopoverBody, Alert } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingBar from "react-top-loading-bar";
import PhoneInput from 'react-phone-input-2';
import { Multiselect } from 'react-widgets';
//import Spinner from '../Assets/Spinner'
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
import { isEmptyArray } from 'formik';




export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}


class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowBar: false,
      alert: null,
      redirect: false,
      redirectLogin:false,
      redirectSignup:false,
      redirecthome:false,
      redirectPClientViewKolList: false,
      redirectAdministrator: false,
      
      redirectVisitor: false,
      redirectResercher: false,
      redirectManager: false,
      redirectEmployee: false,
      redirectClientDashboard: false,
      redirectPClientDashboard: false,
      
      redirectAdministratorDashboard: false,
      redirectVisitorDashboard: false,
      redirectKolProfile: false,
      redirectTermsandPrivacy: false,

      
      kdidentificationhidediv:false,
      kdprofilinghidediv:false,
      kdinteracthidediv:false,



      GetInTouchModal: true,

      ClientLoginModal: false,
      AdministratorLoginModal: false,
      VisitorLoginModal: false,
      KolProfileloginModal: false,
      WrongTeamAlertModal: false,
      ContactUsModal: false,
      NewGurusModal: false,
      NewClientsModal: false,
      TermsAndConditionModal: false,
      TermsAndConditionModalForTeam: false,

      //resercherd id for redirectig to researcher dashoard
      RID: '',
      resercher_name: '',

      SpecialityDataL: [
        { id: '76', speciality_type: 'Physics' },
        { id: 'Genetic Epidemiology', speciality_type: 'Genetic Epidemiology' },
        { id: 'English', speciality_type: 'Economics' },
        { id: 'Bengali', speciality_type: 'Pathology - Chemical' },
        { id: 'Portuguese', speciality_type: 'Clinical Psychology' },
        { id: 'Russian', speciality_type: 'Epidemiology' },
        { id: 'Japanese', speciality_type: 'Endovascular Medicine' },
        { id: 'Vascular Medicine', speciality_type: 'Vascular Medicine' },
      ],

      GetInTouchData: {
        client_name: '',
        email_id: '',
        company_nm: '',
        mobile_no:'',
        designation: '',
        message:'',
        status:'New',
       // loginType: 'pre_client',
      },


      // Manager info
      manager_id: '',
      manager_name: '',

      // Employee info
      employee_id: '',
      employee_name: '',

      speciality_id:'',
      speciality_name:'',

      // For validations
      PhoneErr: '',
      DesignationErr: '',
      MessageErr:'',
      UsernameErr:'',
      UseremailErr:'',
      CompanyNameErr:'',



      PasswordErr: '',
      SelectRoleErr: '',
      wrngUsPsErr: '',

      clientUsernameErr: '',
      clientPasswordErr: '',

      administratorUsernameErr: '',
      administratorPasswordErr: '',

      visitorUsernameErr: '',
      visitorPasswordErr: '',

      kolProfileUsernameErr: '',
      kolProfilePasswordErr: '',
      // /kolProfileSelectRoleErr: '',

      //For getting values of input
      loginData: {
        client_id: '',
        password: '',
        role: '',
        // loginType: 'team',
      },

      ClientLoginData: {
        login_id: '',
        password: '',
        login_type: 'client',
      },
      teamLoginIdForTerms: '',
      //Administrator
      AdministratorLoginData: {
        login_id: '',
        password: '',
        login_type: 'administrator',
      },
      //visitor
      VisitorLoginData: {
        login_id: '',
        password: '',
        login_type: 'visitor',
      },

      KolProfileLoginData: {
        username: '',
        password: '',
        // kol_login_role: '',
      },
      kolLoginIdForTerms: '',

      contact_us: {
        first_name: '',
        last_name: '',
        organization: '',
        email_id: '',
        phone_no: '',
        country: '',
        learn_qpharma: '',
        representative_contact_me: '',
        qpharmas_products: '',
        technical_support_issue: '',
        need_of_assistance: '',
        receive_qpharma_news: '',
        describe_issue_question: '',
      },
      cuFirstNameErr: '',
      cuLastNameErr: '',
      cuOrganizaErr: '',
      cuEmailErr: '',
      cuPhoneErr: '',
      cuCountryErr: '',

      new_guru: {
        first_name: '',
        last_name: '',
        mobile_no: '',
        email_id: '',
        speciality: '',
        sub_speciality: '',
        procedure_type: '',
        country: '',
        state: '',
        current_place_of_work: '',
        department: '',
        linkedin_link: '',
        additional_info: '',
      },
      ngFirstNameErr: '',
      ngLastNameErr: '',
      ngEmailErr: '',
      cuPhoneErr: '',
      ngCountryErr: '',
      ngStateErr: '',

      new_client: {
        first_name: '',
        last_name: '',
        email_id: '',
        mobile_no: '',
        msg: '',
      },
      ncFirstNameErr: '',
      ncLastNameErr: '',
      ncEmailErr: '',
      // ncPhoneErr: '',

      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],
      StateData: [],

      // to set form values
      Speciality: '',
      SubSpecialityType: [],
      ProcedureType: [],

      Market: [],
      Market2: [],

      PersonalInformation: {},

      toggleForgotPasswordModal: false,
      ForgotPasswordData: {
        email_id: '',
        role: '',
      }
    };

    
    //this.login = this.login.bind(this);
    
   // this.setState({redirect: true})


  }

  
  componentDidMount = async () => {
    console.log('in componentDidMount');
    
    
    const options = {
      method: 'POST',
    }
    // Speciality type data
        
    fetch(global.websiteUrl+'/service/KOL_landing/Specility_list_for_landing_page', options)
    .then((Response) => Response.json())
    .then((findresponse) => {
      console.log(findresponse)
      var testDataSpeciality = findresponse.data;
       console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
      if(findresponse.data !="undefined")
      {
        this.setState({
          SpecialityData: findresponse.data,
        })
       

      }

    })

    
    }

  toggleGetInTouchModal() {
    //console.log("loginclicked")
    
    this.setState({
        GetInTouchModal: !this.state.GetInTouchModal,
    });
  }

  

  //login = toggle validate and login
  toggleLoginModal() {
    //console.log("loginclicked")
    
    this.setState({
      redirectLogin:true,
    
    });
  }

  
  toggleSignUpModal() {
    //console.log("loginclicked")

    sessionStorage.removeItem('quotation_id','');    
    this.setState({
      redirectSignup:true,
    
    });

  }

  toggleWithoutidModal()
  {
    setTimeout(function(){
      window.location.reload();
   }, 3000);
    //alert('hi')
     // this.setState({redirecthome:true,})    
      //this.setState({redirect:true})
  }


  toggleSearchModal()
  {
    
   //alert(this.state.speciality_name);
   if ( this.state.speciality_name !="")
   {   
      sessionStorage.setItem('client_email','')
      sessionStorage.setItem('client_id', "Guest")
      sessionStorage.setItem('client_name', "")
      sessionStorage.setItem('speciality_id',this.state.speciality_name )

    this.setState({redirectPClientViewKolList:true,})
   } 
   else
   {
    Swal.fire({
      icon: 'error',
      title:'Oops',
      text: 'Select Speciality',
      confirmButtonColor: '#d33',
      confirmButtonText: 'OK'
    })

   }
    

  }

  makespaceondivkdidentification()
  {
    
    this.setState({

      hidedivkdprofiling:false,
      hidedivkdinteract:false,
      hidedivkdidentification:true,
    });

  }

  makespaceondivkdprofiling()
  {    
    this.setState({

      hidedivkdidentification:false,
      hidedivkdinteract:false,
      hidedivkdprofiling:true,

    });

  }


  makespaceondivkdinteract()
  {
    
    this.setState({
      hidedivkdidentification:false,
      hidedivkdprofiling:false,
      hidedivkdinteract:true,

    });

  }

  

  makespaceondivhome()
  {    
    this.setState({
      hidedivkdidentification:false,
      hidedivkdprofiling:false,
      hidedivkdinteract:false,

    });
  }


  validate = () => {
    let UsernameErr = '';
    let UseremailErr = '';
    let CompanyNameErr = '';
    let PhoneErr = '';
    let DesignationErr = '';
    let MessageErr='';

    if (!this.state.GetInTouchData.client_name) {
      UsernameErr = 'Please Enter Full Name';
    }
    if (!this.state.GetInTouchData.email_id) {
      UseremailErr = 'Please Enter Email id';
    }

    if (!this.state.GetInTouchData.company_nm) {
      CompanyNameErr = 'Please Enter Company Name';
    }
    
    if (!this.state.GetInTouchData.mobile_no) {
      PhoneErr = 'Please Enter phone';
    }

    if (!this.state.GetInTouchData.designation) {
      DesignationErr = 'Please Enter designation';
    }
    
    if (!this.state.GetInTouchData.message) {
      MessageErr = 'Please Enter message';
    }

    if (UsernameErr || UseremailErr ||CompanyNameErr || PhoneErr || DesignationErr || MessageErr) {
      this.setState({ UsernameErr, PhoneErr ,UseremailErr,CompanyNameErr,DesignationErr,MessageErr });
      return false;
    }
    return true;
  }


  GetInTouchfun()
  {   
    
    const isValid = this.validate();
    
    if (isValid) {
    
    this.setState({ShowBar:true,})

    const url = global.websiteUrl+'/service/KOL_landing/check_duplicate_get_in_touch';
    let formData = new FormData();
    formData.append('email_id',this.state.GetInTouchData.email_id);
  
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var lgstatus = response.data.status;
        console.log("lgstatus", JSON.stringify(lgstatus));     
        
        this.setState({ShowBar:false,})
       
      //  alert(lgstatus);
       
        if(lgstatus == false)
        {
          this.SaveGetInTouchDatafun();
        }
        else
        {
          Swal.fire({
            icon: 'error',
            title:'Oops',
            text: 'Emails Already exits pls change new email',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })

          this.setState({      
                        UseremailErr: 'Change New Email',
                        confirmpasswordErr:'',
                        UsernameErr:'',
                        CompanyNameErr:'',
                        PasswordErr:'',   
                        termconditionErr:'',
                      })
        }

      })
      .catch(error => {
        console.log(error);
      });
        
    }
    
  }


  SaveGetInTouchDatafun() {    
     
      this.setState({
        ShowBar: true,
      })

      const url = global.websiteUrl+'/service/KOL_landing/save_Gate_In_Touch_data';
      let formData = new FormData();

      formData.append('Gate_In_Touch_Info', JSON.stringify(this.state.GetInTouchData));
      
      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("lgstatus", JSON.stringify(lgstatus));
    //      console.log("response message2", response.data.message)
              
          this.setState({
            ShowBar: false,
          })

          if (response.data.status == true) {
           
              Swal.fire({
                icon: 'success',
                //text: response.data.message,
                text: 'Success! Your message has been successfully delivered to us. Our team will get in touch with one working day.',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'

              })
          
          }
          else {
            Swal.fire({
              icon: 'error',
              text: "Failed",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        
          setTimeout(function(){
            window.location.reload();
         }, 4000);
          
          
        })
        .catch(error => {
          console.log(error);
        });

    
    

    }
  
  render() {

  // if (this.state.redirect) {
  //           return (<Redirect to={'/'}/>)
  // }

  if (this.state.redirect) {
    return (<Redirect to={'/home'} />)
}

  if (this.state.redirecthome) {
    return (<Redirect to={'/home'} />)
  }

if (this.state.redirectLogin) {
  return (<Redirect to={'/login'}/>)
}

if (this.state.redirectSignup) {
  return (<Redirect to={'/signup'}/>)
}



    if (this.state.redirectPClientViewKolList) {
        return (<Redirect to={'/PClientViewKolList'}/>)
    }

    if (this.state.redirectAdmin) {
      return (<Redirect to={'/AdminDashboard'} />)
    }

    if (this.state.redirectResercher) {
      return (<Redirect to={{
        pathname: '/ResercherDashboard',
        state: { RID: this.state.RID, resercher_name: this.state.resercher_name }
      }} />)
    }

    if (this.state.redirectManager) {
      return (<Redirect to={{
        pathname: '/ManagerDashboard',
        // state:{manager_id: this.state.manager_id , manager_name: this.state.manager_name} 
      }} />)
    }

    if (this.state.redirectEmployee) {
      return (<Redirect to={{
        pathname: '/EmployeeDashboard',
        //  state:{employee_id: this.state.employee_id , employee_name: this.state.employee_name} 
      }} />)
    }

    if (this.state.redirectClientDashboard) {
      return (<Redirect to={{
        pathname: '/ClientViewProject',
      }} />)
    }

    // if (this.state.redirectPClientViewKolList) {
    //   return (<Redirect to={{
    //     pathname: '/ClientViewKolList',
    //   }} />)
    // }


    if (this.state.redirectAdministratorDashboard) {
      return (<Redirect to={{
        pathname: '/AdministratorViewProject',
      }} />)
    }

    if (this.state.redirectVisitorDashboard) {
      return (<Redirect to={{
        pathname: '/VisitorDashboard',
      }} />)
    }

    if (this.state.redirectKolProfile) {
      return (<Redirect to={{
        pathname: '/KolProfileDashboard',
      }} />)
    }

    if (this.state.redirectTermsandPrivacy) {
      return (<Redirect to={{
        pathname: '/TermsPrivacyPolicy',
      }} />)
    }

    // // Speciality Type options 
//     let OptionsSpeciality="";
//     let speciality_type = this.state.SpecialityData;
//  //  let speciality_type = this.state.SpecialityDataL;
//   alert(speciality_type);
//    if(this.state.SpecialityData !="" )
//    {
//     let OptionsSpeciality = speciality_type.map((speciality_type) =>
//       <option key={speciality_type.id} value={speciality_type.id}>
//         {speciality_type.speciality_type}</option>
//     );
//   }

let speciality_type = this.state.SpecialityData;
let OptionsSpeciality = speciality_type.map((speciality_type) =>
      <option key={speciality_type.id} value={speciality_type.id}>
        {speciality_type.speciality_type}</option>
    );

    // // Procedure List
    // let procedure_list = this.state.ProcedureData;
    // let OptionProcedure = procedure_list.map((procedure_list) =>
    //   <option key={procedure_list.name} value={procedure_list.name}>
    //     {procedure_list.procedure_type}</option>
    // );

    // // Sub Speciality List
    // let subSpeciality_list = this.state.SubSpecialityData;
    // let OptionSubSpeciality = subSpeciality_list.map((subSpeciality_list) =>
    //   <option key={subSpeciality_list.name} value={subSpeciality_list.name}>
    //     {subSpeciality_list.sub_speciality_type}</option>
    // );

    // // Country List
    // let Country_list = this.state.CountryListData;
    // let OptionsCountry = Country_list.map((Country_list) =>
    //   <option key={Country_list.id} value={Country_list.id}>
    //     {Country_list.country_name}</option>
    // );

    // // State List
    // let state_list = this.state.StateData;
    // let OptionsState = state_list.map((state_list) =>
    //   <option key={state_list.name} value={state_list.name}>
    //     {state_list.state_name}</option>
    // );

    return (

        
      <body id="landing-body">


        <div>
          {this.state.ShowBar ? <Modal isOpen={true} className='propagateLoader' centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' />
          </Modal> : null}
        </div>
        {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
          <PropagateLoader color={'#A90007'} size='40px' loading='true' />
        </Modal> : null}

        {/* Call the function to add a library */}
  {/* {AddLibrary('https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js')} */}
{/* 
  {AddLibrary('./js/jquery.min.js')}
   {AddLibrary('./js/bootstrap.bundle.min.js')}
  {AddLibrary('./js/owl.carousel.min.js')} */}
  {AddLibrary('./js/app.js')}
  

  <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top" style={{marginTop:'-12vh'}}  >
      <div className="content" style={{width:'100%'}} > 
      <div className='container2'>
            <a className="navbar-brand" href="https://www.cetas-healthcare.com/"><img src={require('./Assets/Images/cetas_healthcare_logo.svg')} width="60" height="60"></img></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav" style={{marginTop:'-30px'}} >
                <ul className="navbar-nav ms-auto" >
                <li className="nav-social">

                  <a href="https://www.linkedin.com/company/cetas-healthcare/" target="_blank"><i className='bx bxl-linkedin'></i></a>
                  </li>
                  
                  <li className='emailimg'></li>
                    <li className="nav-item">                   
                        <a className="nav-link" onClick={this.makespaceondivhome.bind(this)} href="mailto:kolmanagement@cetas-healthcare.com" style={{ fontWeight:'normal' }}>kolmanagement@cetas-healthcare.com</a>
                    </li>
                
                  
                </ul>
                
                
                <div className="nav-signup">
                                                                       
                    <a href="#" style={{color:'#A90007',fontWeight:'bolder'}} data-bs-toggle="modal" onClick={this.toggleLoginModal.bind(this)}>Log In</a>
                    <a href="#" style={{color:'#A90007',fontWeight:'bolder'}} data-bs-toggle="modal" onClick={this.toggleSignUpModal.bind(this)}>/ Sign Up</a>
                </div>

                    {/* <a href="#" data-bs-toggle="modal" className="btn btn-brand ms-lg-3" style={{ cursor: 'pointer',paddingLeft:'30px',paddingRight:'30px' , marginRight:'40px'  }} onClick={this.toggleLoginModal.bind(this)}>Log In</a> */}
            </div>
            </div>
        {/* <div className='container2' >
            <a className="navbar-brand" href="https://www.cetas-healthcare.com/"><img src={require('./Assets/Images/cetas_healthcare_logo.svg')} width="60" height="60"></img></a>
                      
            <div className="collapse navbar-collapse" id="navbarNav" >
                
                <ul className="navbar-nav" >
                   
                   <li className="nav-social">

                    <a href="https://www.linkedin.com/company/cetas-healthcare/" target="_blank"><i className='bx bxl-linkedin'></i></a>
                    </li>
                    <li className="nav-item">                      
                        <a onClick={this.makespaceondivhome.bind(this)} href="#">communication@cetas-healthcare.com</a>
                    </li>
                </ul>
              </div>
              
                                 
               <a onClick={this.makespaceondivhome.bind(this)} href="#"  class="nav-signup">Login & Register</a>
              
            </div> */}
        </div>
        </nav>
    
<div id="search" className="text-center" >
  <div class="searchbanner">
     

    <div className='searchbox'> 
            
          <Input type="select" id="SpecilitySelect"  style={{ backgroundColor:'white'}}
              //  value={this.state.loginData.role}
                // value2={this.state.newNewsData.category_name}
                onChange={(e) => {
                      this.setState({speciality_name:e.target.value})
                    // let { loginData } = this.state;
                    // loginData.role = e.target.value;
                    // console.log("employee role", e.target.value);
                    // // newNewsData.category_name = e.target.value2;
                    // // console.log(e.target.value2);
                    // this.setState({ loginData });
                }}
                >
                <option value="SearchSpeciality" >Search Speciality...</option>
                {OptionsSpeciality}
                {/* <option value="admin" >Admin</option>
                <option value="researcher" >Researcher</option>
                <option value="project manager" >Project Manager</option>
                <option value="employee" >Employee</option> */}
                
        </Input>         
    
    
    </div>

    <div className='searchbutton'>
  
                                                                                      
    <a href="#" data-bs-toggle="modal" onClick={this.toggleSearchModal.bind(this)}
                    className="btn btn-brand ms-lg-1" style={{paddingLeft:'20px',marginTop:'-5px',paddingRight:'30px',paddingTop:'10px',paddingBottom:'10px', fontSize:'12px'  }}> <i class="fa fa-search" style={{fontWeight:'lighter'}}></i> &nbsp;&nbsp; 
                    SEARCH</a>
    
    </div>

    <div className='talktoexpert'>
     <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                  className="btn btn-brand ms-lg-3" style={{paddingLeft:'20px',paddingRight:'20px',marginTop:'-5px',paddingTop:'10px',paddingBottom:'10px', fontSize:'12px'}}>Contact Us</a>
  
    </div>


        {/* <div className="container"  >
            <div className="row" style={{width:'100%',backgroundColor:'orange'}}>
            <div className="col-6" >  
                    <Input type="select" id="roleSelect" style={{width:'100%'}}
                                      //  value={this.state.loginData.role}
                                        // value2={this.state.newNewsData.category_name}
                                        // onChange={(e) => {
                                        //     let { loginData } = this.state;
                                        //     loginData.role = e.target.value;
                                        //     console.log("employee role", e.target.value);
                                        //     // newNewsData.category_name = e.target.value2;
                                        //     // console.log(e.target.value2);
                                        //     this.setState({ loginData });
                                        // }}
                                        >
                                        <option value="0" >--Select Role--</option>
                                        <option value="admin" >Admin</option>
                                        <option value="researcher" >Researcher</option>
                                        <option value="project manager" >Project Manager</option>
                                        <option value="employee" >Employee</option>
                                        
                                    </Input>
                             
                   </div>
            <div className='col-2' >
            <a href="#" data-bs-toggle="modal"
                    className="btn btn-brand ms-lg-3" style={{paddingLeft:'30px',paddingRight:'30px',paddingTop:'10px',paddingBottom:'10px', fontSize:'12px' }}>SEARCH</a>
        
            </div>  
          
          <div className='col-2' style={{float:'right'}}>
          <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal"
                  className="btn btn-brand ms-lg-3" style={{paddingLeft:'20px',paddingRight:'20px',paddingTop:'10px',paddingBottom:'10px', fontSize:'12px'}}>TALK TO EXPERT</a>

          </div>      

          </div>

          <div>
         
          </div>


        </div> */}
  </div>
  </div>
 
  <div id="banner" className="text-center" >
  <div class="banner"></div>
      
  </div> 
  
   
<div id="search" className="text-center" >
  <p className='service_insights'>Your One-Stop-Solution for Key Opinion Leaders identification and engagement</p>
           
   </div>

    
    <footer>
        <div className="footer-copyright">
            <span >{global.CopyRightText}</span>
        </div>
                   
              <div className="footer-tc">                     
                            <a href="https://www.cetas-healthcare.com/privacy_policy/" target="_blank" rel="opener">Privacy Policy</a>
                            <a href="https://www.cetas-healthcare.com/terms-of-use/" target="_blank" rel="opener">Terms & Conditions</a>
                            <a href="https://www.cetas-healthcare.com/cookie-notice/" target="_blank" rel="opener">Security</a>
                        
                
                   </div>
    </footer>


    
    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="h-10 modal-dialog modal-dialog-centered modal-lg" >
     
            <div className="modal-content">
                <div className="modal-body p-0">
              
                    <div className="container-fluid">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{float:'right',color:'#000', fontSize:'25px', marginTop:'0px'}}>x</a>

                        <div className="row gy-49">
                       
                            <div className="col-lg-120">
                                <form className="p-lg-5 col-12 row g-3">
                                  
                                    <div >
                                        <h1>Get in touch with us</h1>
                                        <p>Please fill in the form below and we will get in touch within 1 working day</p>
                                    </div>

                                    <div className="col-lg-6"  >
                                        <label for="" className="form-label">Full Name:<span class="mandatory">*</span></label>
                                        <input type="text" className="form-control" placeholder="" id="" aria-describedby=""
                                          value={this.state.GetInTouchData.client_name} onChange={(e) => {
                                            let { GetInTouchData } = this.state;
                                            GetInTouchData.client_name = e.target.value;
                                            this.setState({ GetInTouchData });
                                          }}
                                        ></input>
                                         <div className="error"> {this.state.UsernameErr}</div>
                                    </div>
                                    <div className="col-lg-6" >
                                        <label for="" className="form-label">Your work email:<span class="mandatory">*</span></label>
                                        {/* <input type="email" className="form-control" placeholder="" id="" aria-describedby=""
                                         value={this.state.GetInTouchData.email_id} onChange={(e) => { */}
                                           <input type="text" className="form-control" placeholder="" id="" aria-describedby=""
                                          value={this.state.GetInTouchData.email_id} onChange={(e) => {
                                      
                                          let { GetInTouchData } = this.state;
                                          GetInTouchData.email_id = e.target.value;
                                          this.setState({ GetInTouchData });
                                        }}
                                        ></input>
                                         <div className="error"> {this.state.UseremailErr}</div>

                                    </div>
                                    <div className="col-lg-6" >
                                        <label for="" className="form-label">Your work phone:<span class="mandatory">*</span></label>
                                        {/* <input type="text" className="form-control" placeholder=""
                                            id="" aria-describedby=""
                                            value={this.state.GetInTouchData.mobile_no} onChange={(e) => {
                                              let { GetInTouchData } = this.state;
                                              GetInTouchData.mobile_no = e.target.value;
                                              this.setState({ GetInTouchData });
                                            }}
                                            
                                            ></input> */}

<PhoneInput
                                  inputStyle={{
                                    width: "100%",
                                    height: "50px",
                                    fontSize: "15px",
                                  }}
                                  width="50%"
                                  country={'in'}
                                  value={this.state.GetInTouchData.mobile_no}
                                  onChange={(mobile_no) => {
                                    let { GetInTouchData } = this.state;
                                    GetInTouchData.mobile_no = mobile_no;
                                    this.setState({ GetInTouchData });
                                  }}
                                />
   
                                             <div className="error"> {this.state.PhoneErr}</div>

                                    </div>
                                    <div className="col-lg-6" >
                                        <label for="" className="form-label">Your company name:<span class="mandatory">*</span></label>
                                        <input type="text" className="form-control" placeholder=""
                                            id="" aria-describedby=""
                                            value={this.state.GetInTouchData.company_nm} onChange={(e) => {
                                              let { GetInTouchData } = this.state;
                                              GetInTouchData.company_nm = e.target.value;
                                              this.setState({ GetInTouchData });
                                            }}
                                            ></input>
                                             <div className="error"> {this.state.CompanyNameErr}</div>

                                    </div>
                                    <div className="col-lg-12" >
                                        <label for="" className="form-label">Your job title:<span class="mandatory">*</span></label>
                                        <input type="text" className="form-control" placeholder=""
                                            id="" aria-describedby="" 
                                            value={this.state.GetInTouchData.designation} onChange={(e) => {
                                              let { GetInTouchData } = this.state;
                                              GetInTouchData.designation = e.target.value;
                                              this.setState({ GetInTouchData });
                                            }}
                                            ></input>
                                             <div className="error"> {this.state.DesignationErr}</div>

                                    </div>
                                    <div className="col-lg-12" >
                                        <label for="exampleInputEmail1" className="form-label">Message:<span class="mandatory">*</span></label>
                                        <textarea name="" placeholder=""
                                            className="form-control" id="" rows="2"
                                            value={this.state.GetInTouchData.message} onChange={(e) => {
                                              let { GetInTouchData } = this.state;
                                              GetInTouchData.message = e.target.value;
                                              this.setState({ GetInTouchData });
                                            }}
                                            ></textarea>
                                             <div className="error"> {this.state.MessageErr}</div>

                                    </div>

                                    <div className="col-12">
                                        {/* <button onClick={this.GetInTouchfun.bind(this)} className="btn btn-brand">Send Message</button> */}
                                            <a onClick={this.GetInTouchfun.bind(this)} className="btn btn-brand">Submit</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>



   


    
    

          



      </body>
    );
  }
}

export default home;