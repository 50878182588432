import React, { Component } from 'react';

import { Redirect } from 'react-router-dom'

//datatable npm
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

// showing progress bar npm 
import { PropagateLoader } from 'react-spinners';
import LoadingBar from "react-top-loading-bar";


import Quoteinfoform from '../PClient/Quoteform';

import axios from 'axios';

import Swal from 'sweetalert2';
import Axios from 'axios';
import { Multiselect } from 'react-widgets';

import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import ClientHeader from './ClientHeader';
//import { color } from 'html2canvas/dist/types/css/types/color';
//import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';

// import '../styles/ViewProject.css';




export const Clientpagerefresh=()=>{
  
  window.location.reload(true)
};

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

const selectRow = {
  mode: 'checkbox',
  
            
  // clickToSelect: true
};

const { ExportCSVButton } = CSVExport;

class ClientViewKolList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      client_id: '',
      client_name: '',
      client_email: '',
      
      // 
      RedirectEmployeeKolList: false,
      RedirectClientProject: false,
      redirectClientKolProfile: false,
      redirectClientViewBiography:false,
      redirectClientViewProfile:false,
      redirect: false,

      // state for progress bar
      ShowBar: false,
      progressBar: false,
      isSearch: false,


      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      // CountryListData: [],
      Profile: [],

      // to set form values
      Speciality: '',

      SubSpecialityType: [],
      SubSpecialityTypeString: '',
      ProcedureType: [],
      ProcedureTypeString: '',
      Subspeciality: [],

      showProcedureFlag: true,
      showSubSpecialityFlag: true,

      // searchg modal
      SearchModal: false,
      PrilimaryModal:false,
      infoQuoteModal:false,

      // Column state for Bootstrap Table 
      //var headerSortingStyle = { backgroundColor: "#e3edf8" };

      columns: [
        // {
        //     dataField: 'kol_unique_id',
        //     text: 'ID',
        // },
        {
          dataField: 'rank_local',
          text: 'Ranking',
          sort: true,
          
          formatter: (cell, row) => {
            return(
            <div class="ttdata1">
              
                 {row.rank_local} 
               
              </div>
            )
            
           
  
           },

           headerStyle: (colum, colIndex) => {
            return {width: '3%', borderColor:'#02365F',textAlign:'left', fontSize:'13px', borderLeft:'solid', borderLeftColor:'#fff', borderTop:'solid', borderTopColor:'#fff' };
          },
          //formatter: this.RankLocalFormater,
          // //classNames:'text-center',
          // class:'text-center',
          // cellStyle: {color: 'red', 'background-color': 'green'},
        
        },

        // {
        //   dataField:'kol_unique_id',
        //   text: 'KOL ID',
        //   formatter: (cell, row) => {
        //     return(
        //     <div class="ttdata1">
              
        //         {row.kol_unique_id}
               
  
        //       </div>
        //     )
  
        //    },
        //   sort: true,

        //   headerStyle: (colum, colIndex) => {
        //      return {width: '6%',borderColor:'#02365F',textAlign:'center', fontSize:'13px', borderTop:'solid', borderTopColor:'#fff'};
        //   },


        //},

        {
          dataField: "profile_photo",
          text: "Image",
          formatter: this.PfrofileImageFormater,
          headerStyle: (colum, colIndex) => {
            return {width: '8%',borderColor:'#02365F',textAlign:'center', fontSize:'13px', borderTop:'solid', borderTopColor:'#fff'};
          },
        }, 
        {
          dataField: 'doctor_full_name',
          text: 'KOL Name',
          formatter: this.linkToDetails,
          // sort: true,
          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onProjectNameFilter = onFilter;
            return null;
          },
          headerStyle: (colum, colIndex) => {
            return {width: '12%',borderColor:'#02365F',textAlign:'left', fontSize:'13px', fontWeight:'bold', borderTop:'solid', borderTopColor:'#fff'};
          },
        },
        {
          dataField: 'speciality_type',
          text: 'Specialty',
           
          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onSpecityFilter = onFilter;
            return null;
          },
      

          formatter: (cell, row) => {
           return(
           <div class="ttdata1">
              {row.speciality_type}

             </div>
           )

          },

          
          headerStyle: (colum, colIndex) => {
             return {width: '10%',borderColor:'#02365F',textAlign:'center', fontSize:'13px', borderTop:'solid', borderTopColor:'#fff'};
                   
          },
          

        },
        
        {
          dataField: "procedure_type",
          text: "Procedure Type",
         // textAlign:'left',
          formatter:this.procedureformate,
          // formatter: (cell, row) => {
          //   return(
          //   <div class="ttdata1">
          //       {row.procedure_type}
  
          //   </div>
          //   )
  
          //  },
           headerStyle: (colum, colIndex) => {
            return {width: '10%',borderColor:'#02365F',textAlign:'center', fontSize:'13px', borderTop:'solid', borderTopColor:'#fff'};
          },
         // hidden: true,
        },
        {
         dataField: "expertise",
          text: "Expertise",
         // hidden: true,
         formatter: (cell, row) => {
          return(
          <div class="ttdata1">
              {row.expertise}

            </div>
          )

         },
         filter: textFilter(),
         filterRenderer: (onFilter, column) => {
           this.onexpertiseFilter = onFilter;
           return null;
         },

        headerStyle: (colum, colIndex) => {
          return {width: '8%',borderColor:'#02365F',textAlign:'center', fontSize:'13px', borderTop:'solid', borderTopColor:'#fff'};
        },
        },
        {
          dataField: "medical_schools",
          text: "Institution",
          formatter: (cell, row) => {
            return(
            <div class="ttdata1">
                {row.medical_schools}
  
            </div>
            )
  
           },

          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onInstitutionFilter = onFilter;
            return null;
          },

           headerStyle: (colum, colIndex) => {
            return {width: '8%',borderColor:'#02365F',textAlign:'center', fontSize:'13px', borderTop:'solid', borderTopColor:'#fff'};
          },
         // hidden: true,
        },
        {
          dataField: "country_name",
          text: "Country",
          formatter: (cell, row) => {
            return(
            <div class="ttdata1">
                {row.country_name}
  
              </div>
            )
  
           },

          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onCountryFilter = onFilter;
            return null;
          },

           headerStyle: (colum, colIndex) => {
            return {width: '8%',borderColor:'#02365F',textAlign:'center', fontSize:'13px', borderTop:'solid', borderTopColor:'#fff'};
          },
          //hidden: true,
        },
        {
          dataField: 'aggregate_score',
          text: 'Rating',
           formatter:this.rating,
          headerStyle: (colum, colIndex) => {
            return {width: '8%',borderColor:'#02365F',textAlign:'center', fontSize:'13px', borderTop:'solid', borderTopColor:'#fff'};
          },
          
        },
        
        

        // {
        //   dataField: 'qualification',
        //   text: 'Degree',
        //   csvExport: false
        // },
        // {
        //   dataField: "medical_schools",
        //   text: "Institution",
        //   // align: 'center',
        //   // headerAlign: 'center'
        // },
        // {
        //   dataField: "state_name",
        //   text: "State",
        //   csvExport: false
        // },
        // {
        //   dataField: "country_name",
        //   text: "Country",
        // },
        // {
        //   dataField: "procedure_potential",
        //   text: "Procedure Potential",
        //   csvExport: false
        // },
        // {
        //   dataField: "profile_photo",
        //   text: "Profile Photo",
        //  // hidden: true,
        // },

        {
          dataField: "",
          text: "Download",
          formatter: this.linkFollow,
          //formatter: this.linkToBio,
          
          csvExport: false,
          headerStyle: (colum, colIndex) => {
            
           return {width:'5%', borderColor:'#02365F',textAlign:'center' , borderTop:'solid', borderTopColor:'#fff' , borderRight:'solid', borderRightColor:'#fff'};
          },


        },
      ],


    }    // End of states
  }   // End of constructor

  
  

  componentDidMount = async () => {
    let getSession_Client_Email = await sessionStorage.getItem('client_email')
    if (getSession_Client_Email !== null) {
      await this.setState({
        client_email: getSession_Client_Email,
      })
    }

    let getSession_Client_ID = await sessionStorage.getItem('client_id')
    if (getSession_Client_ID !== null) {
      await this.setState({
        client_id: getSession_Client_ID,
      })
    }

    let getSession_Client_Name = await sessionStorage.getItem('client_name')
    if (getSession_Client_Name !== null) {
      await this.setState({
        client_name: getSession_Client_Name,
      })
    }

    console.log("getSession_Client_Email ====", getSession_Client_Email)
    console.log("getSession_Client_ID ====", getSession_Client_ID)
    console.log("getSession_Client_Name ====", getSession_Client_Name)

    let getSession_Project_id = await sessionStorage.getItem('project_id')
    if (getSession_Project_id !== null) {
      await this.setState({
        project_id: getSession_Project_id,
      })
    }

    console.log("getSession_Project_id ====", getSession_Project_id)

    this.setState({
      ShowBar: true,
      progressBar: true,
    })

    const url = global.websiteUrl+'/service/Client/get_ClientProjectKOLList';
    let formData = new FormData();
    formData.append('project_id', getSession_Project_id);
    console.log(getSession_Project_id);
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log('response of API', response);
        this.Profile = response.data.KOL_Project_Details;
        // console.log(this.Profile.length);
        console.log("Profile===", JSON.stringify(response.data.KOL_Project_Details));

        if (response.data.status == false) {
          console.log('status false..!!')
        }
        else if (response.data.status == true) {
          console.log('status true..!!')
          let list_here = response.data.KOL_Project_Details
          list_here.map(async (data, index) => {
            console.log("item getSession_kolInfo",data)
            data.rank_local = index+1
          })
          console.log("list_here",list_here)
          
          this.setState({
            Profile: list_here,
          })
        }
        // console.log("Length of Kol List ",this.state.Profile.length);
      })
      .catch(error => {
        console.log(error);
      });


    const options = {
      method: 'POST',
    }

    //  Speciality type data
    fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataSpeciality = findresponse.data;
        console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
        this.setState({
          SpecialityData: findresponse.data,
          ShowBar: false,
          progressBar: false,
        })
        // console.log("Length of SpecialityData ",this.state.SpecialityData.length);        
      })
  }
  


  getSubSpecialityList() {
    console.log("in Sub Speciality");
    console.log("speciality_type_id", this.state.Speciality);

    this.setState({
      showProcedureFlag: true,
      showSubSpecialityFlag: true,
    })

    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.Speciality);
    formData.append('search_type', "Sub Speciality");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var SubSpecialityData = response.data;
        console.log("SubSpecialityData", JSON.stringify(SubSpecialityData));
        console.log(SubSpecialityData.status)
        if (response.data.status == true) {
          this.setState({
            ShowBar: false,
            progressBar: false,
            SubSpecialityData: response.data.data,
          })
        }
        else if (response.data.status == false) {
          this.setState({
            ShowBar: false,
            progressBar: false,
            SubSpecialityData: [],
          })
        }
        console.log("SubSpecialityData====", this.state.SubSpecialityData)
        console.log("Length of SubSpecialityData ", this.state.SubSpecialityData.length);
      })
      .catch(error => {
        console.log(error);
      });
  }

  getProcedureList() {
    console.log("in Procedure list");
    console.log("speciality_type_id", this.state.Speciality);

    this.setState({
      showProcedureFlag: true,
      showSubSpecialityFlag: true,
    })

    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.Speciality);
    formData.append('search_type', "Procedure");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var proceduredata = response.data;
        console.log("proceduredata", JSON.stringify(proceduredata));
        // this.setState({
        //     ShowBar: false,
        //     progressBar: false,
        //     ProcedureData : response.data.data,
        // }) 
        if (response.data.status == true) {
          this.setState({
            ShowBar: false,
            progressBar: false,
            ProcedureData: response.data.data,
          })
        }
        else if (response.data.status == false) {
          this.setState({
            ShowBar: false,
            progressBar: false,
            ProcedureData: [],
          })
        }
        console.log("ProcedureData", this.state.ProcedureData)
      })
      .catch(error => {
        console.log(error);
      });
  }


  


 //Funtion to apply links to project names style={{backgroundColour:'#DC3545',borderColor:'#DC3545'}}
 rating = (cell, row) => {
  var kol_id = row.kol_unique_id;
  
  return (
    <div>
    {
       (row.aggregate_score == "NA" || row.aggregate_score == "")?
       (

        <div class="hexagon" >
            
         <span class="hexL" ><a href='#'onClick={this.toggleinfoQuoteModal.bind(this)} className='lock_anchor'></a>     </span>      
    
    </div>
       ):(<div>

        <div class="hexagon" >
        <span class="hexL" >{row.aggregate_score}</span>      
      
      </div>
        
      
        </div>)
    }
    </div>
    // <div class="hexagon" >
    //   <span class="hexL" >{row.aggregate_score}</span>      
    
    // </div>


  )
}





  //Funtion to apply links to project names
  linkToDetails = (cell, row) => {
    var kol_id = row.kol_unique_id;
    var raking=row.aggregate_score;
    
    return (
      <div>
        <a style={{
          color: '#069', fontSize:'12px', fontWeight:'bold',textAlign:'left',
          textDecoration: 'underline', cursor: 'pointer', position:'relative',marginTop:'20px',display:'inline-block'
        }}
          onClick={this.profileInfoNav.bind(this, kol_id,raking)}> {row.doctor_full_name} </a>
      </div>
    )
  }


//Funtion to apply links to Biography
linkToBio = (cell, row) => {
  var kol_id = row.kol_unique_id;

  return (
    <div>
      <a style={{
         color: '#069',
        textDecoration: 'underline', cursor: 'pointer'
      }}
        onClick={this.bioInfoNav.bind(this, kol_id)}> {row.doctor_full_name} </a>
    </div>
  )
}

  RankLocalFormater = (cell, row) => {
    var kol_id = row.kol_unique_id;
    return (
      <div>
        <a style={{
           color: '#000',
        }}
        > 
          {row.rank_local} 
        </a>
      </div>
    )
  }


  procedureformate=(cell, row)=>
  {
    var procedure=row.procedure_type;
    let pro=procedure.split(',');
    var finalprocedure=pro[0];

    if( pro.length>1)
    {
      finalprocedure += ", " + pro[1] + " and more…";
      //alert(pro[1]);
    }
    return(
    <div class="ttdata1">
      {finalprocedure}

    </div>
    
    )

  }


  PfrofileImageFormater= (cell, row) => {
    var imgsource ='';
   // alert(row.profile_photo);
        if( row.profile_photo !== null)
        {
          imgsource= row.profile_photo;
        }
        else
        {
          imgsource="require('../Assets/Images/default-user.jpg')";
        // imgsource="{require('../Assets/Images/cetas-logo.png')}";
        }



    return (
      <div>        
        <td>

        {
          ( row.profile_photo !== null ) ?   
          (
            <img src={row.profile_photo} class="Client_profile_pic" />
         
          ):
          (
            <img src={require('../Assets/Images/default-user.jpg')} class="Client_profile_pic" />
         

          )

          // else
          // {
          //   <img src={require('../Assets/Images/default-user.jpg')} class="Client_profile_pic" />
         
          // }

        }
{/* 
         <img src={row.profile_photo} class="Client_profile_pic" /> */}

         
         </td>
        
      </div>
    )
  }



  
  profileInfoNav(kol_id,raking) {

  //   sessionStorage.setItem('kol_id', JSON.stringify(kol_id));
  // //   console.log('Navigation of clientKolProfile', kol_id);
  // //   this.setState({
  // //     redirectClientKolProfile: true,
  // //   })
  // //   if(raking !=='NA')
  // //   {
  // //   sessionStorage.setItem('kol_id', JSON.stringify(kol_id));
  // //   console.log('Navigation of clientKolProfile', kol_id);
  // //   this.setState({
  // //     redirectClientKolProfile: true,
  // //   })
  // // }
  // // else
  // // {   // alert(raking);   

  // //  this.setState({
  // //     PrilimaryModal:true,
  // //  })

  //}

   
    sessionStorage.setItem('kol_id', JSON.stringify(kol_id));
    console.log('Navigation of clientKolProfile', kol_id);
    this.setState({
      redirectClientKolProfile: true,
    })
  }



  bioViewNav(kol_id,raking) {
   
  // if(raking !=='NA')
  //   {
  
            sessionStorage.setItem('kol_id', JSON.stringify(kol_id));
            console.log('Navigation of ClientViewBio', kol_id);
            this.setState({
              //redirectClientKolProfile: true,
              redirectClientViewBiography: true,
            })
//  }
//   else
//   {  
//    this.setState({
//       PrilimaryModal:true,
//    })


//   }
}


  profileViewNav(kol_id,raking) {    
  
    // if(raking !=='NA')
    // {
  
    sessionStorage.setItem('kol_id', JSON.stringify(kol_id));
    console.log('Navigation of ClientViewProfile', kol_id);
    this.setState({
      //redirectClientKolProfile: true,
      redirectClientViewProfile: true,
    })

  // }
  // else
  // {  
  //  this.setState({
  //     PrilimaryModal:true,
  //  })


  // }
  }

  
  CVViewNav(kol_id,raking,resumeurl) {    
  
    // if(raking !=='NA')
    // {
      window.open(resumeurl,'_blank')
    // sessionStorage.setItem('kol_id', JSON.stringify(kol_id));
    // console.log('Navigation of ClientViewProfile', kol_id);
    // this.setState({
    //   //redirectClientKolProfile: true,
    //   redirectClientViewProfile: true,
    // })

  // }
  // else
  // {  
  //  this.setState({
  //     PrilimaryModal:true,
  //  })


  // }
  }
  

  // nanu t

  search() {
    this.setState({
      ShowBar: true,
      progressBar: true,
    })
    console.log('in Search')
    console.log('project id', this.state.project_id)
    console.log("Speciality ID", this.state.Speciality)
    console.log("Sub Speciality selected ID", this.state.Market)
    console.log("Procedure selected ID", this.state.Market2)

    console.log("Sub Speciality selected ID", this.state.SubSpecialityTypeString)
    console.log("procedure selected ID", this.state.ProcedureTypeString)


    try {

      const url = global.websiteUrl+'/service/KOL_Dashboard/get_FilterSpecificKOLList';
      let formData = new FormData();
      formData.append('project_id', this.state.project_id);
      formData.append('speciality_id', this.state.Speciality);
      formData.append('subspeciality', this.state.SubSpecialityTypeString);
      formData.append('proceduretype', this.state.ProcedureTypeString);


      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      Axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          if (response.data.status == false) {
            Swal.fire({
              icon: 'error',
              text: 'No List Present of Selected Category',
              // text: 'Wrong Username or Password!',
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })

            this.setState({
              Profile: [],
              ShowBar: false,
              progressBar: false,
              SearchModal: false,
              ProcedureType: [],
              ProcedureTypeString: '',
              SubSpecialityType: [],
              SubSpecialityTypeString: '',
            })
          }
          else if (response.data.status == true) {
            console.log(response.data.kol_info);
            console.log(response.data.message);
            this.Profile = response.data.kol_info;
            console.log(JSON.stringify(this.state.Profile));
            this.setState({
              Profile: response.data.kol_info,
              ShowBar: false,
              progressBar: false,
              SearchModal: false,
              ProcedureType: [],
              ProcedureTypeString: '',
              SubSpecialityType: [],
              SubSpecialityTypeString: '',
            })
            console.log('profile length', this.state.Profile.length)
          }
        })
    }
    catch (e) {
      console.log(e);
    }
    this.setState({
      isSearch: false,
    })
  }

  // Funtion for displaying 'bio', 'Profile' & 'CV' buttons inside Bootstrap Grid
  linkFollow = (cell, row, rowIndex, formatExtraData) => {
    var kol_id = row.kol_unique_id;
    var rating = row.aggregate_score;
    return (
      <div>

<td class="butns1">
        {          
            <a               
              onClick={this.bioViewNav.bind(this,kol_id,rating)}> 
              <button class="btn1 btn-danger btn-block btn-sm btn-labeled" style={{width:'100px'}}>
                Bio 
              </button>
            </a>
        }
        {          
            <a               
            onClick={this.profileViewNav.bind(this, kol_id,rating)}> 
              <button class="btn1 btn-danger btn-block btn-sm btn-labeled">
              Profile 
              </button>
            </a>
        }
        { 
         
            (row.resume !== null )? ( 
                // <a href={ row.resume } target="_blank"> 
                <a onClick={this.CVViewNav.bind(this, kol_id,rating,row.resume)}> 
               
                  <button class="btn1 btn-danger btn-block btn-sm btn-labeled" >
                    CV
                  </button>
                </a>

            
            ):
            (<div></div>)
          
           
        }

        
        </td>
      </div>
    );
  };

  onProjectNameChange = (event) => {
    const { value } = event.target;
    this.onProjectNameFilter(value);
  };

  onSpecilityChange = (event) => {
    const { value } = event.target;
    this.onSpecityFilter(value);    
  };

  onInstitutionChange = (event) => {
    const { value } = event.target;
    this.onInstitutionFilter(value);    
  };

  onexpertiseChange= (event) => {
    const { value } = event.target;
    this.onexpertiseFilter(value);    
  };

  onCountryChange = (event) => {
    const { value } = event.target;
    this.onCountryFilter(value);    
  };
  

  BackToClientProject() {
    this.setState({ RedirectClientProject: true });
  }

  logout = () => {
    console.log("in logout")
    this.setState({ redirect: true })
    sessionStorage.removeItem('client_id')
    sessionStorage.removeItem('client_name')
    sessionStorage.removeItem('email_id')
  }

  // search modal functions
  toggleSearchModal = () => {
    this.setState({
      SearchModal: !this.state.SearchModal,
      ProcedureType: [],
      ProcedureTypeString: '',
      SubSpecialityType: [],
      SubSpecialityTypeString: '',
    });
  }

  // search modal functions
  togglePrilimaryModal = () => {
    this.setState({
      PrilimaryModal: !this.state.PrilimaryModal,
      
    });
  }
  
  toggleinfoQuoteModal = () => {
    this.setState({
      infoQuoteModal: !this.state.infoQuoteModal,
      
    });
  }
  

  render() {

    if (this.state.RedirectClientProject) {
      return (<Redirect to={{
        pathname: '/ClientViewProject',
      }} />)
    }

    if (this.state.redirectClientKolProfile) {
      return (<Redirect to={{
        pathname: '/ClientKolProfile',
      }} />)
    }


    if (this.state.redirectClientViewBiography) {
      return (<Redirect to={{
        pathname: '/ClientViewBio',
      }} />)
    }
    
    if (this.state.redirectClientViewProfile) {
      return (<Redirect to={{
        pathname: '/ClientViewProfile',
      }} />)
    }
    

    if (this.state.redirect) {
      return (<Redirect to={'/'} />)
    }

    

    // Speciality Type options 
    let speciality_type = this.state.SpecialityData;
    let OptionsSpeciality = speciality_type.map((speciality_type) =>
      // <option key={speciality_type.id} value={speciality_type.id}>
      //   {speciality_type.speciality_type}</option>
      <option key={speciality_type} value={speciality_type.id}>
      {speciality_type.speciality_type}</option>
    );

    // Procedure List
    // let  procedure_list = this.state.ProcedureData;
    // let OptionProcedure = procedure_list.map((procedure_list) =>
    //     <option key={procedure_list.id} value={procedure_list.id}>
    //             {procedure_list.procedure_type}</option>
    // );

    // // Sub Speciality List
    // let  subSpeciality_list = this.state.SubSpecialityData;
    // let OptionSubSpeciality = subSpeciality_list.map((subSpeciality_list) =>
    //     <option key={subSpeciality_list.id} value={subSpeciality_list.id}>
    //             {subSpeciality_list.sub_speciality_type}</option>
    // );

    // For rendering pageination buttons
    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      return (
        <li class="page-item" onClick={handleClick} ><a class="page-link" href="#">{page}</a></li>
      );
    };

    // options for passing values to bootstrap table 2
    const options = {
      pageButtonRenderer,
      paginationSize: 5,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      // firstPageText: 'First',
      prePageText: 'Previous',
      nextPageText: 'Next',
      // lastPageText: 'Last',
      // nextPageTitle: 'First page',
      // prePageTitle: 'Pre page',
      // firstPageTitle: 'Next page',
      // lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      }, {
        text: 'All', value: this.state.Profile.length
      }] // A numeric array is also available. the purpose of above example is custom the text

    };

    // const MyExportCSV = (props) => {
    //   const handleClick = () => {
    //     props.onExport();
    //   };
    //   return (
    //     <div>
    //       <button class="cetas-button" onClick={handleClick}>Export</button>
    //     </div>
    //   );
    // };

    return (
      <div style={{backgroundColor:'#F9F9F9',paddingBottom:'35px'}} >
        {this.state.ShowBar ? <Modal isOpen={true} centered>
          <PropagateLoader color={'#A90007'} size='30px' loading='true' />
        </Modal> : null}
        {this.state.progressBar ? <div style={{ position: 'fixed', top: '0' }}>
          <LoadingBar
            progress='100'
            height={3} color="#A90007" transitionTime={4000}
            loaderSpeed={3000} />
        </div> : null}
        <div>

          <ClientHeader/>

         
        {/* 
            <div class="container"  >
                            <div class="profile-backoptiopn">
                              <div class="col-lg-12 col-md-12 col-sm-12 col-12" >
                              <p><a href='#' onClick={this.BackToClientProject.bind(this)}>Dashboard</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Opinion Leaders</p>
                    
                 
                            </div>
                        </div>
                      </div>
       */}

          <div class="container-fluid" id="mainClientDashboard" style={{width:'98%' }}   >
            {/* <div className='clientheadingbox' style={{width:'100%' }} > */}
                <div class="col-lg-12 col-md-12 col-sm-12 col-12"  >                  
                      <div class="row breadcum" >
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12" >
                          <h4><b>Opinion Leaders</b></h4>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12"  >                        
                          <p><a href='#' onClick={this.BackToClientProject.bind(this)}>Dashboard</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Opinion Leaders</p>
                        </div>
                      </div>
                     
                  </div>
                   
              {/* </div> */}

              <div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12" >
                  <div class="card" >
                    <div class="card-body" >
                      
                      <div className='gridtable'>
                        {/* {alert(sessionStorage.getItem('p_name'))} */}
                      <h4 style={{height:'50px' , fontSize:'20px', fontWeight:'bolder' }} >{this.state.project_id} - {sessionStorage.getItem('p_name')} </h4>
                    
                      <div class="row" style={{marginBottom:'30px'}}>

                        <div class="col-md-2" style={{ marginLeft:'-130px'}}>
                          <p class="searching" >
                              <div className="employee-actions">
                              <div className="table-infobox" >
                                <div class="filter-box float-left mb-30" >
                                  <div class="input-group">                            
                                    <span class="btn-label" style={{marginTop:'10px'}}>
                                    <i class="fa fa-search"></i>  
                                    </span> 
                                     <input type="text" 
                                     className="form-control"  
                                     //value={this.state.Speciality}
                                      id="inlineFormInputGroupUsername"
                                      placeholder="Search by KOL Name"
                                      onChange={(e) => { this.onProjectNameChange(e) }} 
                                      />

                                      
                                  </div>
                                </div>                          
                              </div>
                            </div>
                          </p>

                        </div>
                        
                        <div class="col-lg-2 col-md-2 col-sm-12 col-12"   >
                          <p class="searching" >
                              <div className="employee-actions" >
                              <div className="table-infobox">
                                <div class="filter-box float-left mb-30" >
                                  <div class="input-group">                            
                                    {/* <span class="btn-label" style={{marginTop:'10px'}}>
                                    <i class="fa fa-search"></i>  
                                    </span>  */}
                                     <input type="text" 
                                     className="form-control"  
                                     //value={this.state.Speciality}
                                      id="inlineFormInputGroupUsername"
                                      placeholder="Search by Specialty"
                                      onChange={(e) => { this.onSpecilityChange(e) }} 
                                      />

                                      
                                  </div>
                                </div>                          
                              </div>
                            </div>
                          </p>

                        </div>
                        


                         <div class="col-lg-2 col-md-2 col-sm-12 col-12" >
                          <p class="searching" >
                              <div className="employee-actions" >
                              <div className="table-infobox">
                                <div class="filter-box float-left mb-30" >
                                  <div class="input-group">                            
                                    {/* <span class="btn-label" style={{marginTop:'10px'}}>
                                    <i class="fa fa-search"></i>  
                                    </span> */}
                                    <input type="text" className="form-control"  
                                      id="inlineFormInputGroupUsername"
                                      placeholder="Search by expertise"
                                      onChange={(e) => { this.onexpertiseChange(e) }} />
                                      
                                  </div>
                                </div>                          
                              </div>
                            </div>
                          </p>                          
                        </div> 



                         <div class="col-lg-2 col-md-2 col-sm-12 col-12" >
                          <p class="searching" >
                              <div className="employee-actions" >
                              <div className="table-infobox">
                                <div class="filter-box float-left mb-30" >
                                  <div class="input-group">                            
                                    {/* <span class="btn-label" style={{marginTop:'10px'}}>
                                    <i class="fa fa-search"></i>  
                                    </span> */}
                                    <input type="text" className="form-control"  
                                      id="inlineFormInputGroupUsername"
                                      placeholder="Search by Institution"
                                      onChange={(e) => { this.onInstitutionChange(e) }} />
                                      
                                  </div>
                                </div>                          
                              </div>
                            </div>
                          </p>                          
                        </div> 

                        <div class="col-lg-2 col-md-2 col-sm-12 col-12"  >
                          <p class="searching" >
                              <div className="employee-actions" >
                              <div className="table-infobox">
                                <div class="filter-box float-left mb-30" >
                                  <div class="input-group">                            
                                    {/* <span class="btn-label" style={{marginTop:'10px'}}>
                                    <i class="fa fa-search"></i>  
                                    </span> */}
                                    <input type="text" className="form-control"  
                                      id="inlineFormInputGroupUsername"
                                      placeholder="Search by Country"
                                      onChange={(e) => { this.onCountryChange(e) }} />
                                      
                                  </div>
                                </div>                          
                              </div>
                            </div>
                          </p>                          
                        </div> 

                      </div>








                        <table class='table table-hover' style={{width:'100%',maxWidth:'100%'}} >
                         
                        <ToolkitProvider
                        keyField="kol_unique_id"
                        data={this.state.Profile}
                        columns={this.state.columns}
                        search={this.customMatchFunc}
                        // //exportCSV
                        // exportCSV={{
                        //   fileName: 'cetas.csv',
                        //   noAutoBOM: false,
                        //   onlyExportSelection: true,
                        //   exportAll: true
                       // }}
                      >
                        {
                          props => (
                            <div  >
                              <BootstrapTable
                                {...props.baseProps}
                                defaultSortDirection="asc"
                                //hover
                                //condensed
                                ////noDataIndication="No Data Is Available"

                                filter={filterFactory()}
                                //selectRow={selectRow}
                                pagination={paginationFactory(options)}
                               // striped
                                hover
                                condensed
                                tabIndexCell
                                rowStyle={ { backgroundColor: 'White', borderLeft:'solid',  borderLeftColor:'#fff'  , borderBottom:'solid', borderBottomColor:'#EDE9E8' , borderRight:'solid', borderRightColor:'#fff' } }
                              
                              />
                              {/* <hr></hr> */}
                              {/* <ExportCSVButton  class="cetas-button" 
                                                { ...props.csvProps }>Export
                                            </ExportCSVButton> */}
                              <br />
                              {/* <MyExportCSV {...props.csvProps} /> */}
                            </div>
                          )
                        }
                      </ToolkitProvider>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              
            
        











 {/* infoQuoteModal */}


 <Modal isOpen={this.state.infoQuoteModal}  centered>
                            {/* toggle={this.toggleinfoQuoteModal.bind(this)} */}
                                        <ModalHeader toggle={this.toggleinfoQuoteModal.bind(this)}
                                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                            style={{ borderBottom: '0px' }}>
                                            <h3 style={{ justifyContent: 'center', marginTop: '20px' }} id="login-tit">Get a quote</h3>
                                        </ModalHeader>
                                        <ModalBody style={{ padding: '0px 60px 0px 60px' }}>

                                          <Quoteinfoform></Quoteinfoform>

                                        </ModalBody>
                                        {/* <ModalFooter style={{ borderTop: '0px' , paddingBottom:'40px' }}>
                                            <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '90px' }}
                                                onClick={this.toggleinfoQuoteModal.bind(this)}>Cancel</button>
                                            <button type="submit" class="cetas-button" style={{ marginRight: '30%', height: '40px', width: '90px'}}
                                                onClick={this.SaveQuoteinfo.bind(this)}>
                                                Next
                                            </button>

                                        </ModalFooter> */}
            </Modal>













{/* PrilimaryModal */}

<Modal isOpen={this.state.PrilimaryModal} toggle={this.togglePrilimaryModal.bind(this)} centered>
            <ModalHeader toggle={this.togglePrilimaryModal.bind(this)}
              cssModule={{ 'modal-title': 'w-200 text-center', 'border-bottom': '0px', }}
              style={{ borderBottom: '0px' }}>
              {/* <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">Search KOL</h3> */}
            </ModalHeader>

            <ModalBody>
              <FormGroup>
                <div className="center">
                  <h3> <u>Contact</u></h3>
                  
                </div>
                <div className="center">
                  <h4><a style={{color:'blue'}}> xyz@cetas-healthcare.com</a></h4>
                 
                </div>

                <div className="center">
                  <h4></h4>
                 
                </div>
              </FormGroup>

            </ModalBody>

  </Modal>








          {/* Search Modal */}
          <Modal isOpen={this.state.SearchModal} toggle={this.toggleSearchModal.bind(this)} centered>
            <ModalHeader toggle={this.toggleSearchModal.bind(this)}
              cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
              style={{ borderBottom: '0px' }}>
              <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">Search KOL</h3>
            </ModalHeader>

            <ModalBody>
              <FormGroup>
                <div className="center">
                  <Input type="select" id="roleSelect" className="form-control"
                    style={{ height: '50px', width: '80%' }}
                    value={this.state.Speciality}
                    onChange={async (e) => {
                      let { Speciality } = this.state;
                      Speciality = e.target.value;
                      await this.setState({
                        Speciality,
                        SubSpecialityData: [],
                        ProcedureData: [],
                        ProcedureType: [],
                        ProcedureTypeString: '',
                        SubSpecialityType: [],
                        SubSpecialityTypeString: '',

                        showProcedureFlag: false,
                        showSubSpecialityFlag: false,
                      });
                      { this.getSubSpecialityList() }
                      { this.getProcedureList() }
                    }}
                  >
                    <option value="0" >--Select Speciality--</option>
                    {OptionsSpeciality}
                  </Input>
                </div>
              </FormGroup>

              <FormGroup>
                {/* <div className="center"> */}
                {
                  (this.state.showSubSpecialityFlag == false) ? (
                    <div></div>
                  ) : (
                    <div className="center">
                      <Multiselect
                        style={{ width: '80%' }}
                        data={this.state.SubSpecialityData}
                        placeholder="--Select Sub Speciality--"
                        // defaultValue={this.state.SubSpecialityType}
                        valueField='sub_speciality_type'
                        textField='sub_speciality_type'
                        onSelect={(valueField) => console.log(valueField)}
                        onChange={(event) => {
                          console.log(event);
                          var joined = [];
                          console.log("length : " + event.length);
                          if (event.length === 0) {
                            this.setState({ SubSpecialityType: [] });
                          }
                          event.map(async (data) => {
                            console.log("data in eve map : " + JSON.stringify(data));
                            joined = joined.concat(data.sub_speciality_type);
                            console.log("data join : " + joined);
                            await this.setState({
                              SubSpecialityType: joined
                            });
                            console.log("check this", this.state.SubSpecialityType)

                            var output = this.state.SubSpecialityType.map(function (item) {
                              return item;
                            });

                            let { SubSpecialityTypeString } = this.state;
                            SubSpecialityTypeString = output.join(",");
                            this.setState({ SubSpecialityTypeString });

                            console.log("check this", this.state.SubSpecialityTypeString)
                            console.log(output.join(","))
                          })
                        }}
                      />
                    </div>
                  )
                }
                {/* </div> */}
              </FormGroup>

              <FormGroup>
                {/* <div className="center"> */}
                {
                  (this.state.showProcedureFlag == false) ? (
                    <div></div>
                  ) : (
                    <div className="center">
                      <Multiselect
                        style={{ width: '80%' }}
                        data={this.state.ProcedureData}
                        placeholder="--Select Procedure Type--"
                        // defaultValue={this.state.ProcedureType}
                        valueField='procedure_type'
                        textField='procedure_type'
                        onSelect={(valueField) => console.log(valueField)}
                        onChange={(event) => {
                          console.log(event);
                          var joined = [];
                          console.log("length : " + event.length);
                          if (event.length === 0) {
                            this.setState({ ProcedureType: [] });
                          }
                          event.map(async (data) => {
                            console.log("data in eve map : " + JSON.stringify(data));
                            joined = joined.concat(data.procedure_type);
                            console.log("data join : " + joined);
                            await this.setState({
                              ProcedureType: joined
                            });
                            console.log("check this", this.state.ProcedureType)

                            var output = this.state.ProcedureType.map(function (item) {
                              return item;
                            });

                            let { ProcedureTypeString } = this.state;
                            ProcedureTypeString = output.join(",");
                            this.setState({ ProcedureTypeString });

                            console.log("check this", this.state.ProcedureTypeString)
                            console.log(output.join(","))
                          })
                        }}
                      />
                    </div>
                  )
                }
                {/* </div> */}
              </FormGroup>

            </ModalBody>
            <ModalFooter style={{ borderTop: '0px' }}>
              <button type="submit" class="cetas-secondry-button" style={{ marginRight: '2%', marginTop: '-5px' }}
                onClick={this.toggleSearchModal.bind(this)}>
                Cancel
              </button>
              <button type="submit" class="cetas-button" style={{ marginRight: '15%', marginTop: '-5px' }}
                onClick={this.search.bind(this)}>
                Search
              </button>
            </ModalFooter>
          </Modal>
          

{/* 
          <footer class="footer_not">

                        <div class="cetas-footer">
                            <div class="container" >
                                <p>All rights reserved. www.cetashealthcare.com</p>
                            </div>
                        </div>
            </footer> */}



        </div>
      </div>
    );
  }
}

export default ClientViewKolList;