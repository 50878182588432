import React from 'react';
import {BrowserRouter, Route,Switch} from 'react-router-dom';

import Home from './home';

import pdftest from './pdftest';

import Login from './Login/Login';
import Signup from './Login/Signup';
import Forgotpassword from './Login/Forgotpass';
import Resetpassword from './Login/Resetpassword';

import ClientViewBio from './Client/ClientViewBio';
import ClientViewProfile from './Client/ClientViewProfile';

import PClientViewKolList from './PClient/ClientViewKolList';
import PClientKolProfile from './PClient/ClientKolProfile';
import PProfiletypedetails from './Profiletypedetails';

import ClientViewProject from './Client/ClientViewProject';
import ClientViewKolList from './Client/ClientViewKolList';
import ClientKolProfile from './Client/ClientKolProfile';
import SearchInCetasDb from './Client/SearchInCetasDb';
import ClientViewSearchKolList from './Client/ClientViewSearchKolList'


// componentDidMount() {
//     const { history } = this.props;

//     window.addEventListener("popstate", () => {
//       history.go(1);
//     });
// };

const Routes = () => (
     
      <BrowserRouter basename="/">

     {/* <BrowserRouter basename="/cetas"> */}
    
    <Switch>
    <Route exact path='/' component={Home}/>

    <Route  path='/pdftest' component={pdftest} />
    
    <Route  path='/Login' component={Login} />
   
    <Route  path='/home' component={Home} />
    <Route  path='/Signup' component={Signup} />

    <Route  path='/Forgotpassword' component={Forgotpassword} />
    <Route  path='/Resetpassword' component={Resetpassword} />

    
    <Route  path='/ClientViewBio' component={ClientViewBio} />
    <Route  path='/ClientViewProfile' component={ClientViewProfile} />
    
    <Route  path='/ClientViewProject' component={ClientViewProject} />
   
    <Route  path='/PClientViewKolList' component={PClientViewKolList} />
    <Route  path='/PClientKolProfile' component={PClientKolProfile} />
    <Route  path='/Profiletypedetails' component={PProfiletypedetails} />

    
   
    <Route  path='/ClientViewKolList' component={ClientViewKolList} />
    <Route  path='/ClientKolProfile' component={ClientKolProfile} />
    <Route path='/SearchInCetasDb' component={SearchInCetasDb} />
    <Route path='/ClientViewSearchKolList' component={ClientViewSearchKolList} />

    
    </Switch>
    </BrowserRouter>
   
);
export default Routes;