import React, { Component, useRef , useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Col, Popover, PopoverHeader, PopoverBody, Alert } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingBar from "react-top-loading-bar";
import PhoneInput from 'react-phone-input-2';
import { Multiselect } from 'react-widgets';
//import Spinner from '../Assets/Spinner'
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
import '../landingpage.scss';
import LoginHeader from './Loginheader';
import Pagefooter from './footer';

export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}



class Resetpassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowBar: false,
      alert: null,
      redirect: false,
      redirectSignup:false,
      redirectSignin:false,
      redirecthome:false,
      redirectAdmin: false,
      redirectAdministrator: false,
      redirectVisitor: false,
      redirectResercher: false,
      redirectManager: false,
      redirectEmployee: false,
      redirectClientDashboard: false,
      redirectPClientDashboard: false,
      
      redirectAdministratorDashboard: false,
      redirectVisitorDashboard: false,
      redirectKolProfile: false,
      redirectTermsandPrivacy: false,

      
      kdidentificationhidediv:false,
      kdprofilinghidediv:false,
      kdinteracthidediv:false,


      loginModal: false,
      ClientLoginModal: false,
      AdministratorLoginModal: false,
      VisitorLoginModal: false,
      KolProfileloginModal: false,
      WrongTeamAlertModal: false,
      ContactUsModal: false,
      NewGurusModal: false,
      NewClientsModal: false,
      TermsAndConditionModal: false,
      TermsAndConditionModalForTeam: false,

      //resercherd id for redirectig to researcher dashoard
      RID: '',
      resercher_name: '',

      termconditionAgree:false,

      // Manager info
      manager_id: '',
      manager_name: '',

      // Employee info
      employee_id: '',
      employee_name: '',

      // For validations
      UsernameErr: '',
      UseremailErr:'',
      CompanyNameErr:'',      
      PasswordErr: '',
      confirmpasswordErr: '',
      confirmpassword:'',
      password:'',
      passwordErr:'',
      termconditionErr:'',
      Url_Userid:'',

      SelectRoleErr: '',
      wrngUsPsErr: '',

      clientUsernameErr: '',
      clientPasswordErr: '',

      administratorUsernameErr: '',
      administratorPasswordErr: '',

      visitorUsernameErr: '',
      visitorPasswordErr: '',

      kolProfileUsernameErr: '',
      kolProfilePasswordErr: '',
      // /kolProfileSelectRoleErr: '',

      //For getting values of input
      signupData: {
        client_name: '',
        email_id: '',
        company_nm: '',
        password: '',
        signup_method:'Portal',
        status:'New',
       // loginType: 'pre_client',
      },

      ClientLoginData: {
        login_id: '',
        password: '',
        login_type: 'client',
      },
      teamLoginIdForTerms: '',
      //Administrator
      AdministratorLoginData: {
        login_id: '',
        password: '',
        login_type: 'administrator',
      },
      //visitor
      VisitorLoginData: {
        login_id: '',
        password: '',
        login_type: 'visitor',
      },

      KolProfileLoginData: {
        username: '',
        password: '',
        // kol_login_role: '',
      },
      kolLoginIdForTerms: '',

      contact_us: {
        first_name: '',
        last_name: '',
        organization: '',
        email_id: '',
        phone_no: '',
        country: '',
        learn_qpharma: '',
        representative_contact_me: '',
        qpharmas_products: '',
        technical_support_issue: '',
        need_of_assistance: '',
        receive_qpharma_news: '',
        describe_issue_question: '',
      },
      cuFirstNameErr: '',
      cuLastNameErr: '',
      cuOrganizaErr: '',
      cuEmailErr: '',
      cuPhoneErr: '',
      cuCountryErr: '',

      new_guru: {
        first_name: '',
        last_name: '',
        mobile_no: '',
        email_id: '',
        speciality: '',
        sub_speciality: '',
        procedure_type: '',
        country: '',
        state: '',
        current_place_of_work: '',
        department: '',
        linkedin_link: '',
        additional_info: '',
      },
      ngFirstNameErr: '',
      ngLastNameErr: '',
      ngEmailErr: '',
      cuPhoneErr: '',
      ngCountryErr: '',
      ngStateErr: '',

      new_client: {
        first_name: '',
        last_name: '',
        email_id: '',
        mobile_no: '',
        msg: '',
      },
      ncFirstNameErr: '',
      ncLastNameErr: '',
      ncEmailErr: '',
      // ncPhoneErr: '',

      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],
      StateData: [],

      // to set form values
      Speciality: '',
      SubSpecialityType: [],
      ProcedureType: [],

      Market: [],
      Market2: [],

      PersonalInformation: {},

      toggleForgotPasswordModal: false,
      ForgotPasswordData: {
        email_id: '',
        role: '',
      }
    };

    
    //this.login = this.login.bind(this);
    
   // this.setState({redirect: true})


  }

  
  componentDidMount = async () => {
    console.log('in componentDidMount');
    
    this.Getuserid_withdecrepted();
    
    //alert(this.state.Url_Userid);
  }

  
  Getuserid_withdecrepted()
  {
    const query = new URLSearchParams(this.props.location.search);
   // alert(query);
    const User_id = query.get('hwB1K5NkfcIzkLTWQeQfHLNg5FlyX3');

    if(User_id != null)
    {
    //encript_decrypt_data("decrypt",$encripted_userid)
   // alert(User_id);

    const url = global.websiteUrl+'/service/KOL_landing/encriptdecrypt';
    let formData = new FormData();

    formData.append('method', JSON.stringify('decrypt'));
    formData.append('string', JSON.stringify(User_id));
    
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var lgdata = response.data.result;
        console.log("lgdata", JSON.stringify(lgdata));
        var lgstatus = response.data.status;
        console.log("lgstatus", JSON.stringify(lgstatus));
        console.log("response message2", response.data.message)
     
      // alert(response.data.result);
      if(response.data.status == true)
      {
          this.setState({
            Url_Userid:'',
            Url_Userid:lgdata,
          })
      }
      });
    }
    else
    {
      this.setState({redirect:true})
      
    }



    


  }
  

  //login = toggle validate and login
  toggleLoginModal() {
    //console.log("loginclicked")
    this.setState({
      loginModal: !this.state.loginModal,
      UsernameErr: '',
      PasswordErr: '',
      SelectRoleErr: '',
      MySProfilePropover: false,
    });
  }

  toggleWithoutidModal()
  {  
  
      this.setState({redirectSignup:true})
    

  }

  redirectSignin()
  {
    this.setState({redirectSignin:true})
    
  }

  
  validate = () => {
    let PasswordErr = '';
    let confirmpasswordErr = '';
   
    
    if (!this.state.password) {
      PasswordErr = 'Please Enter Password';
    }

    if (!this.state.confirmpassword) {
      confirmpasswordErr = 'Please Enter Confirm Password';
    }
    
   
    if (PasswordErr || confirmpasswordErr) {
      this.setState({ PasswordErr ,confirmpasswordErr});
      return false;
    }
    return true;
  }


  passmatchfunc()
  {
    if(this.state.signupData.password != this.state.confirmpassword)
    {
        this.setState({
          confirmpasswordErr:"Password don't match",
          // ShowBar: false,
          // UsernameErr:'',
          // UseremailErr:'',
          // CompanyNameErr:'',
          // PasswordErr:'',         
        
        })
       
    }
    else
    {
      this.setState({
        confirmpasswordErr:'',
        confirmpasswordErr:'',
        
      })
    }
  }

checkboxHandler = (event) => {
  //   // if agree === true, it will be set to false
  //   // if agree === false, it will be set to true
  //   //setAgree(!agree);
  //  //termconditionAgree
  //  alert(event.target.id)
      this.setState({ termconditionAgree: !this.state.termconditionAgree})

     // alert(this.state.termconditionAgree);

    // Don't miss the exclamation mark
  }


  Setpassfun() {
    this.Getuserid_withdecrepted();
   
    
    const isValid =this.validate();
    if (isValid) {
     
      this.setState({
        ShowBar: true,
      })

      if(this.state.password != this.state.confirmpassword)
      {
          this.setState({
            confirmpasswordErr:"Passwords don't match",
            ShowBar: false,
            PasswordErr:'',         
          })
         
      }else
      {
        
        //alert(this.state.Url_Userid);
  

      const url = global.websiteUrl+'/service/KOL_landing/update_password';
      let formData = new FormData();

      formData.append('password', JSON.stringify(this.state.password));
      formData.append('Uid', JSON.stringify(this.state.Url_Userid));

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("lgstatus", JSON.stringify(lgstatus));
          //console.log("response message2", response.data.message)
       
        // alert(response.data.status);

          this.setState({
            ShowBar: false,
          })
          if (response.data.status == true) {

              Swal.fire({
                icon: 'success',
                //text: response.data.message,
                text: 'Your Password Update Successfull',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'

              })


              this.setState({redirecthome:true})
            // this.setState({redirect: true})
        
          }
          else {
            Swal.fire({
              icon: 'error',
              //text: response.data.message,
              text: 'Update failed',
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
         // this.setState({redirecthome:true})
          
          
        })
        .catch(error => {
          console.log(error);
        });

      
    }
  }

    }

  makespaceondivkdidentification()
  {
    
    this.setState({

      hidedivkdprofiling:false,
      hidedivkdinteract:false,
      hidedivkdidentification:true,
      


    });

  }

  makespaceondivkdprofiling()
  {
    
    this.setState({

      hidedivkdidentification:false,
      hidedivkdinteract:false,
      hidedivkdprofiling:true,

    });

  }


  makespaceondivkdinteract()
  {
    
    this.setState({
      hidedivkdidentification:false,
      hidedivkdprofiling:false,
      hidedivkdinteract:true,

    });

  }

  

  makespaceondivhome()
  {    
    this.setState({
      hidedivkdidentification:false,
      hidedivkdprofiling:false,
      hidedivkdinteract:false,

    });
  }

  
  render() {

   if (this.state.redirect) {
            return (<Redirect to={'/'}/>)
  }

    if (this.state.redirectSignin) {
        return (<Redirect to={'/Login'}/>)
    }

    if (this.state.redirecthome) {
      return (<Redirect to={'/home'} />)
    }

    if (this.state.redirectResercher) {
      return (<Redirect to={{
        pathname: '/ResercherDashboard',
        state: { RID: this.state.RID, resercher_name: this.state.resercher_name }
      }} />)
    }

    if (this.state.redirectManager) {
      return (<Redirect to={{
        pathname: '/ManagerDashboard',
        // state:{manager_id: this.state.manager_id , manager_name: this.state.manager_name} 
      }} />)
    }

    if (this.state.redirectEmployee) {
      return (<Redirect to={{
        pathname: '/EmployeeDashboard',
        //  state:{employee_id: this.state.employee_id , employee_name: this.state.employee_name} 
      }} />)
    }

    if (this.state.redirectClientDashboard) {
      return (<Redirect to={{
        pathname: '/ClientViewProject',
      }} />)
    }

    if (this.state.redirectPClientDashboard) {
      return (<Redirect to={{
        pathname: '/PClientViewKolList',
      }} />)
    }


    if (this.state.redirectAdministratorDashboard) {
      return (<Redirect to={{
        pathname: '/AdministratorViewProject',
      }} />)
    }

    if (this.state.redirectVisitorDashboard) {
      return (<Redirect to={{
        pathname: '/VisitorDashboard',
      }} />)
    }

    if (this.state.redirectKolProfile) {
      return (<Redirect to={{
        pathname: '/KolProfileDashboard',
      }} />)
    }

    if (this.state.redirectTermsandPrivacy) {
      return (<Redirect to={{
        pathname: '/TermsPrivacyPolicy',
      }} />)
    }

    // // Speciality Type options 
    // let speciality_type = this.state.SpecialityData;
    // let OptionsSpeciality = speciality_type.map((speciality_type) =>
    //   <option key={speciality_type.id} value={speciality_type.id}>
    //     {speciality_type.speciality_type}</option>
    // );

    // // Procedure List
    // let procedure_list = this.state.ProcedureData;
    // let OptionProcedure = procedure_list.map((procedure_list) =>
    //   <option key={procedure_list.name} value={procedure_list.name}>
    //     {procedure_list.procedure_type}</option>
    // );

    // // Sub Speciality List
    // let subSpeciality_list = this.state.SubSpecialityData;
    // let OptionSubSpeciality = subSpeciality_list.map((subSpeciality_list) =>
    //   <option key={subSpeciality_list.name} value={subSpeciality_list.name}>
    //     {subSpeciality_list.sub_speciality_type}</option>
    // );

    // // Country List
    // let Country_list = this.state.CountryListData;
    // let OptionsCountry = Country_list.map((Country_list) =>
    //   <option key={Country_list.id} value={Country_list.id}>
    //     {Country_list.country_name}</option>
    // );

    // // State List
    // let state_list = this.state.StateData;
    // let OptionsState = state_list.map((state_list) =>
    //   <option key={state_list.name} value={state_list.name}>
    //     {state_list.state_name}</option>
    // );

    return (

        
      <body id="landing-body" style={{ backgroundColor:'#ccc'}}>


        <div>
          {this.state.ShowBar ? <Modal isOpen={true} className='propagateLoader' centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' />
          </Modal> : null}
        </div>
        {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
          <PropagateLoader color={'#A90007'} size='40px' loading='true' />
        </Modal> : null}

        {/* Call the function to add a library */}
  {/* {AddLibrary('https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js')} */}
{/* 
  {AddLibrary('./js/jquery.min.js')}
   {AddLibrary('./js/bootstrap.bundle.min.js')}
  {AddLibrary('./js/owl.carousel.min.js')} */}
  {AddLibrary('./js/app.js')}
  


<div style={{ backgroundColor:'#e9ecef', marginTop:'-80px', paddingTop:'40px'}}>        
       
     <LoginHeader/>

    <section id="services" className="text-center" >
    
        <div className="container" >
            <div className="row">
                <div className="col-12">
                    <div className="login" style={{padding:'2%'}}>

                                  <div>
                                    <h4>Set New Password !</h4>
                                    <p>MedTech Guru KOL Network Platform by Cetas Healthcare</p>
                                  </div>
                                    
                                    <div className='inputbox'>
                                    <label for="" style={{marginBottom:'10px'}} >New Password<span class="mandatory">*</span></label>
                                    <Input id="password" placeholder="Password" class="input" tabindex='4'
                                          type="password"
                                          value={this.state.password} onChange={(e) => {
                                            this.setState({password:e.target.value})
                                            // let { signupData } = this.state;
                                            // signupData.password = e.target.value;
                                            // this.setState({ signupData });
                                          }}
                                        />

                                    </div>
                                    <div className="error"> {this.state.PasswordErr}</div>

                                    <div className='inputbox'>
                                    <label for="" style={{ marginBottom:'10px'}} >Confirm Password<span class="mandatory">*</span></label>
                                    <Input id="confirmpassword" placeholder="Confirm Password" class="input" tabindex='5'
                                          type="password"
                                          value={this.state.confirmpassword} 
                                          onChange={(e) => {
                                            
                                            this.setState({confirmpassword:e.target.value})
                                            
                                          }                                          
                                        }
                                        onClick={(e)=>{ this.passmatchfunc();}}
                                        />

                                    </div>
                                    <div className="error"> {this.state.confirmpasswordErr}</div>

                                    <div >
                                       <button type="submit" tabindex='3' onClick={this.Setpassfun.bind(this)}>
                                        Update
                                      </button>
                                  </div>
                                  
                                {/* </form>
                            </div> */}                   
                    </div>
                </div>
            </div>            
            <div className="signuplink">
                <p>Sign In here  <a href='#' onClick={ this.redirectSignin.bind(this)} >Signin</a></p>
            </div>
        </div>
    </section>

<Pagefooter />

</div>
    



  

    
    



      {/* </header> */}
 
      </body>
    );
  }
}

export default Resetpassword;