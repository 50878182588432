import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

//datatable npm
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Table from 'react-bootstrap/Table';
// showing progress bar npm 
import { PropagateLoader } from 'react-spinners';
import LoadingBar from "react-top-loading-bar";

import axios from 'axios';
import Swal from 'sweetalert2';
import { Multiselect } from 'react-widgets';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverBody } from 'reactstrap';

import Chart from 'react-google-charts';
import ClientHeader from './ClientHeader';
//import '../styles/ViewProject.css';
// session storage item:
// 'client_id': is the login id of client
// 'client_name' : is the name of the client
// 'email_id' : is the email id of client
// project_id : is selected project from 1st page
import './Client.scss';






const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

class ClientViewProject extends Component {
  constructor(props) {
    super(props)

    this.state = {

      pro_count: 0,
      Kol_count: 0,
      deep_kol_count: 0,
      basic_kol_count: 0,

      annotationfontsize:10,
      annotationfontcolor:'Black',
      annotationfontbold:false,

      legendfontsize:10,
      legendfontcolor:'Black',
      legendfontbold:false,

      titlefontsize:13,
      titlefontcolor:'#035B96',
      titlefontbold:true,


      hAxisfontsize:12,
      hAxisfontcolor:'Black',
      hAxisfontbold:false,

      
      vAxisfontsize:12,
      vAxisfontcolor:'Black',
      vAxisfontbold:false,

      series1color: '#035B96',
    series2color: '#A55B52' ,
    series3color: '#72DADD' ,
    series4color: '#D7B4B0' ,      

    

      searchInCetas: false,
      redirect: false,
      client_id: '',
      client_name: '',
      client_email: '',
      Project_count:'0',
      clientdashcount: [],

      redirectClientViewKolList: false,
      redirectSearchInCetasDb: false,
      RedirectLogin: false,

      // state for progress bar
      ShowBar: false,
      progressBar: false,
      isSearch: false,
      isSearch1: false,
      Profile: [],
      ProfileBySession: [],
      

      // to get data from dropdown
      SpecialityData: [],
      SpecialityData1: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],

      // Column state for Bootstrap Table 
      columns: [
        {
          dataField: 'project_id',
          text: 'Project ID',
          sort: true,
          hidden:true,
          headerStyle: (colum, colIndex) => {
            return {borderColor:'#02365F' , borderTop:'solid', borderTopColor:'#fff', borderLeft:'solid', borderLeftColor:'#fff'};
          },
        
        },
        {
          dataField: 'project_id',
          text: 'Project ID',
          sort: true,
          hidden: true
        },
        {
          dataField: 'p_name',
          text: 'Project Name',
          formatter: this.linkToDetails,
          // sort: true,
          hidden:true,
          // filter: textFilter(),
          // filterRenderer: (onFilter, column) => {
          //   this.onProjectNameFilter = onFilter;
          //   return null;
          // },
          headerStyle: (colum, colIndex) => {
            return {borderColor:'#02365F', width:'15%', borderTop:'solid', borderTopColor:'#fff'};
          },
        },

        {
          dataField: 'end_date',
          text: 'Date',
          formatter: this.linkToDetails,
          // sort: true,
        
          // filter: textFilter(),
          // filterRenderer: (onFilter, column) => {
          //   this.onProjectNameFilter = onFilter;
          //   return null;
          // },
          headerStyle: (colum, colIndex) => {
            return {borderColor:'#02365F', width:'10%', borderTop:'solid', borderTopColor:'#fff'};
          },
        },


        {
          dataField: 'speciality_type',
          text: 'Speciality Type',
          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onSPCFilter = onFilter;
            return null;
          },

          headerStyle: (colum, colIndex) => {
            return {borderColor:'#02365F', width:'10%', borderTop:'solid', borderTopColor:'#fff'};
          },
        },
        {
          dataField: "procedure_type_id",
          text: "Procedure Type",
//          formatter:this.procedureformate,
          // align: 'center',
          // headerAlign: 'center',
          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onSPCFilter = onFilter;
            return null;
          },

          headerStyle: (colum, colIndex) => {
            return {borderColor:'#02365F', width:'25%', borderTop:'solid', borderTopColor:'#fff'};
          },
        },
        {
          dataField: "country",
          text: "Countries",

          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onSPCFilter = onFilter;
            return null;
          },

          headerStyle: (colum, colIndex) => {
            return {borderColor:'#02365F', width:'10%', borderTop:'solid', borderTopColor:'#fff'};
          },
        },
        {
          dataField: "noofkols",
          text: "Overall KOL(s)",
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return {borderColor:'#02365F', width:'10%', borderTop:'solid', borderTopColor:'#fff', borderRight:'solid', borderRightColor:'#fff'};
          },
          
        },
        {
          dataField: "noofdeepkols",
          text: "Deep Profiled KOL(s)",
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return {borderColor:'#02365F', width:'13%', borderTop:'solid', borderTopColor:'#fff', borderRight:'solid', borderRightColor:'#fff'};
          },
          
        },
        {
          dataField: "noofbasickols",
          text: "Basic Profiled KOL(s)",
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return {borderColor:'#02365F', width:'13%', borderTop:'solid', borderTopColor:'#fff', borderRight:'solid', borderRightColor:'#fff'};
          },
          
        },
      ],

    }    // End of states
    // Functions will be Bind here
    this.togglesearch = this.togglesearch.bind(this);
    this.togglesearch1 = this.togglesearch1.bind(this);
  }   // End of constructor

  logout = () => {
    console.log("in logout")
    this.setState({ redirect: true })
    sessionStorage.removeItem('client_id')
    sessionStorage.removeItem('client_name')
    sessionStorage.removeItem('email_id')
  }

  togglesearch() {
    this.setState({
      isSearch: !this.state.isSearch,
      Speciality: '',
      SubSpecialityType: '',
      ProcedureType: '',
      Market: [],
      searchPopover: false,
      isSearch1: false,
    })
  }

  togglesearch1() {
    this.setState({
      isSearch1: !this.state.isSearch1,
      Speciality: '',
      SubSpecialityType: '',
      ProcedureType: '',
      Market: [],
      searchPopover: false,
      isSearch: false,
    })
  }

  componentDidMount = async () => {
    let getSession_Client_Email = await sessionStorage.getItem('client_email')
    if (getSession_Client_Email !== null) {
      await this.setState({
        client_email: getSession_Client_Email,
      })
    }

    let getSession_Client_ID = await sessionStorage.getItem('client_id')
    if (getSession_Client_ID !== null) {
      await this.setState({
        client_id: getSession_Client_ID,
      })
    }

    let getSession_Client_Name = await sessionStorage.getItem('client_name')
    if (getSession_Client_Name !== null) {
      await this.setState({
        client_name: getSession_Client_Name,
      })
    }

    console.log("getSession_Client_Email ====", getSession_Client_Email)
    console.log("getSession_Client_ID ====", getSession_Client_ID)
    console.log("getSession_Client_Name ====", getSession_Client_Name)

    this.setState({
      ShowBar: true,
      progressBar: true,
    })

  
 // Dashboard Count 

    const url1 = global.websiteUrl+'/service/Client/get_Client_Dash_Count';
    let formData1 = new FormData();
    formData1.append('client_id', getSession_Client_ID);
    const config1 = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url1, formData1, config1)
      .then(response => {
        console.log('response of API', response);
        this.Profile = response.data;
        console.log(this.Profile.length)
        console.log("Profile", JSON.stringify(response.data));

        if (response.data.status == true) {
            this.setState
            ({
              pro_count: response.data.pro_count,
              Kol_count: response.data.Kol_count,
              deep_kol_count: response.data.deep_kol_count,
              basic_kol_count: response.data.basic_kol_count,

            })
          
        
        }

    })


    const options = {
      method: 'POST',
    }
  

// fetch(global.websiteUrl+'/service/Client/get_Client_Dash_Count', options)
// .then((response) => response.json())
// .then(response => {
//   console.log(response);
//    var lgdata = response.data;
//    alert(lgdata);
//    console.log("Fetched data", JSON.stringify(lgdata));
//    var lgstatus = response.data.status;
//    console.log("Fetched status", JSON.stringify(lgstatus));
//  // console.log("pro_count", response.data.pro_count);
//   //console.log("Length of Profile ", this.state.Profile.length);
//   //  
//   alert(lgstatus);
//   this.setState({
//     Profile: response.data
//   })
//   alert(this.state.Profile.pro_count);
//   //alert(this.state.Profile.kol_count);
// })
// .then((response) => response.json())
// .then((response) => {
//   console.log(response)
//   //var clientdashboardcount = response.data;
//  // console.log("clientdashboardcount", JSON.stringify(clientdashboardcount));
//   this.setState({
//     clientdashboardcount: response.data,
//     //ShowBar: false,
//     //progressBar: false,
//   })
//  // console.log("Project Info", response.data.Project_count)
//   //var Project_count = response.data.Project_count;
//   //alert(Project_count);
//   this.setState({
//     Project_count: response.data.Project_count,
//   })

//   console.log("Length of clientdashboardcount ", this.state.clientdashboardcount.length);
//   alert(this.state.clientdashboardcount.length);
  

// })





    //  Speciality type data
    fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataSpeciality = findresponse.data;
        console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
        this.setState({
          SpecialityData: findresponse.data,
          ShowBar: false,
          progressBar: false,
        })
        
        console.log("Length of SpecialityData ", this.state.SpecialityData.length);
       // alert(this.state.SpecialityData.length)
      })

    // Country list(market) 
    fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataCountryList = findresponse.Country_Details;
        console.log("testDataCountryList", JSON.stringify(testDataCountryList));
        this.setState({
          CountryListData: findresponse.Country_Details,
          ShowBar: false,
          progressBar: false,
        })
        // console.log("Client_Unique_id", this.state.Client_Unique_id);
      })

      //alert(getSession_Client_ID);

    const url = global.websiteUrl+'/service/Client/get_ClientProjectList';
    let formData = new FormData();
    formData.append('client_id', getSession_Client_ID);
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log('response of API', response);
        this.Profile = response.data.Client_Project_Details;
        console.log(this.Profile.length)
        console.log("Profile", JSON.stringify(response.data.Client_Project_Details));
        if (response.data.status == true) {
          console.log('true');
          this.setState({
            Profile: response.data.Client_Project_Details,
            ShowBar: false,
            progressBar: false,
          })

          if (response.data.Client_Project_Details) {
            sessionStorage.setItem('ProjectInfo', JSON.stringify(response.data.Client_Project_Details))
          }
          else {
            sessionStorage.setItem('ProjectInfo', [])
          }
          let getSessionDataOfProjectProfile = [];
          let getSessionDataOfProjectProfileCheck = sessionStorage.getItem('ProjectInfo')
          if (getSessionDataOfProjectProfileCheck == null) {
            getSessionDataOfProjectProfile = [];
            this.setState({
              ProfileBySession: getSessionDataOfProjectProfile
            })
          }
          else {
            let getSessionDataOfProjectProfileParse = sessionStorage.getItem('ProjectInfo');
            getSessionDataOfProjectProfile = JSON.parse(getSessionDataOfProjectProfileParse);
            this.setState({
              ProfileBySession: getSessionDataOfProjectProfile
            })
          }
          console.log("getSessionDataOfProjectProfile", getSessionDataOfProjectProfile)
        }
        else if (response.data.status == false) {
          console.log('false');
          Swal.fire({
            icon: 'error',
            text: 'No List Present',
            // text: 'Wrong Username or Password!',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })

          this.setState({
            Profile: [],
            ShowBar: false,
            progressBar: false,
          })
        }
        this.setState({
          ShowBar: false,
          progressBar: false,
        });
      })
      .catch(error => {
        console.log(error);
        alert("Error Occurs");
        this.setState({
          ShowBar: false,
          progressBar: false,
        });
      });
  }

  getSubSpecialityList() {
    console.log("in Procedure list");
    console.log("speciality_type_id", this.state.Speciality);

    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.Speciality);
    formData.append('search_type', "Sub Speciality");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var SubSpecialityData = response.data;
        console.log("SubSpecialityData", JSON.stringify(SubSpecialityData));
        console.log(SubSpecialityData.status)
        // if(this.state.SubSpecialityData.status == false){
        //     this.setState({
        //         SubSpecialityData : [],
        //     })
        // } else {
        //     this.setState({
        //         SubSpecialityData : response.data.data,
        //     }) 
        // } 
        if (response.data.status == true) {
          this.setState({
            SubSpecialityData: response.data.data,
          })
        }
        else if (response.data.status == false) {
          this.setState({
            SubSpecialityData: [],
          })
        }
        console.log("SubSpecialityData====", this.state.SubSpecialityData)
        console.log("Length of SubSpecialityData ", this.state.SubSpecialityData.length);
      })
      .catch(error => {
        console.log(error);
      });
  }

  getProcedureList() {
    console.log("in Procedure list");
    console.log("speciality_type_id", this.state.Speciality);

    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.Speciality);
    formData.append('search_type', "Procedure");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var proceduredata = response.data;
        console.log("proceduredata", JSON.stringify(proceduredata));
        if (response.data.status == true) {
          this.setState({
            ShowBar: false,
            progressBar: false,
            ProcedureData: response.data.data,
          })
        }
        else if (response.data.status == false) {
          this.setState({
            ShowBar: false,
            progressBar: false,
            ProcedureData: [],
          })
        }
        // console.log("ProcedureData",this.state.ProcedureData)
      })
      .catch(error => {
        console.log(error);
      });
  }








  search = async () => {
    this.setState({
      ShowBar: true,
      progressBar: true,
    })
    console.log('in Search kol ')
    // console.log("Name for search", this.state.NameForSearch)
    console.log("Speciality ID", this.state.Speciality)
    console.log("Sub Speciality ID", this.state.SubSpecialityType)
    console.log("Procedure ID", this.state.ProcedureType)
    console.log("Country selected ID", this.state.Market)

    try {

      const url = global.websiteUrl+'/service/KOL/get_Category_Wise_KOL_List';
      let formData = new FormData();
      // formData.append('kol_name', this.state.NameForSearch);
      formData.append('speciality', this.state.Speciality);
      formData.append('subspeciality', this.state.SubSpecialityType);
      formData.append('procedure', this.state.ProcedureType);
      formData.append('country', this.state.Market);

      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log("response of search",response.data);
          console.log(response.data.message);
          if (response.data.status == false) {
            Swal.fire({
              icon: 'error',
              text: 'No List Present of Selected Category',
              // text: 'Wrong Username or Password!',
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })

            this.setState({
              Profile1: [],
              ShowBar: false,
              progressBar: false,
            })
          }
          else if (response.data.status == true) {
            console.log("response.data.status",response.data.KOL_Personal_Info);
            console.log(response.data.message);
            this.Profile1 = response.data.KOL_Personal_Info;
            console.log(this.Profile1.length)
            console.log(JSON.stringify(this.Profile1));
            this.setState({
              Profile1: response.data.KOL_Personal_Info,
              ShowBar: false,
              progressBar: false,
            })
            // sessionStorage.setItem('kolInfo', data) 
            var kol_ids_for_filter=[];
            if (response.data.KOL_Personal_Info) {
              response.data.KOL_Personal_Info.map((item, index) =>{
                console.log("item of map kol pers info", item.kol_unique_id)
                kol_ids_for_filter = kol_ids_for_filter.concat(item.kol_unique_id);
                console.log("kol_ids_for_filter on client search",kol_ids_for_filter)
              })
              sessionStorage.setItem('kolInfo', JSON.stringify(response.data.KOL_Personal_Info));
              sessionStorage.setItem('AllKolIdsOfSearchResults', JSON.stringify(kol_ids_for_filter));
              sessionStorage.setItem('SearchInCetas', 'true');
              sessionStorage.setItem('SearchInCetas', 'true');
              this.setState({
                redirectSearchInCetasDb: true,
              });
            }
            else {
              sessionStorage.setItem('kolInfo', [])
            }

            // let getSessionDataOfProfileInfo = [];
            // let getSessionDataOfProfileInfoCheck = sessionStorage.getItem('kolInfo')
            // if (getSessionDataOfProfileInfoCheck == null) {
            //   getSessionDataOfProfileInfo = [];
            //   this.setState({
            //     ProfileBySession: getSessionDataOfProfileInfo
            //   })
            // }
            // else {
            //   let getSessionDataOfProfileInfoParse = sessionStorage.getItem('kolInfo');
            //   getSessionDataOfProfileInfo = JSON.parse(getSessionDataOfProfileInfoParse);
            //   this.setState({
            //     ProfileBySession: getSessionDataOfProfileInfo
            //   })
            // }
            // console.log("getSessionDataOfProfileInfo", getSessionDataOfProfileInfo)
          }
        })
    }
    catch (e) {
      console.log(e);
      this.setState({
        ShowBar: false,
        progressBar: false,
      })
    }
    this.setState({
      isSearch: false,
    })
  }

  search1 = async () => {
    this.setState({
      ShowBar: true,
      progressBar: true,
    })

    try {
      const url = global.websiteUrl+'/service/client/client_profile_search';
      let formData = new FormData();
      formData.append('clientid', this.state.client_id);
      formData.append('doctor_name', this.state.NameForSearch); //doctor_name kol_name
      formData.append('specility', this.state.Speciality); //specility speciality
      formData.append('subspeciality', this.state.SubSpecialityType); //subspeciality subspeciality
      formData.append('procedure', this.state.ProcedureType); //procedure
      formData.append('country', this.state.Market); //country 

      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      console.log('client id', this.state.client_id);
      console.log("Name for search", this.state.NameForSearch);
      console.log("Speciality ID", this.state.Speciality);
      console.log("Sub Speciality ID", this.state.SubSpecialityType);
      console.log("Procedure ID", this.state.ProcedureType);
      console.log("Country selected ID", this.state.Market);

      axios.post(url, formData, config)
        .then(response => {
          console.log('im innnnnnnnnnnnnnnnnnnn');
          console.log(response.data);
          console.log(response.data.message);
          console.log(response.data.status);
          console.log(response.data.statusCode);
          if (response.data.status == 'false') {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              // text: 'Wrong Username or Password!',
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })

            this.setState({
              Profile: [],
              ShowBar: false,
              progressBar: false,
            })
          }
          else if (response.data.status == 'true') {
            console.log('in true status');
            console.log(response.data.message);
            console.log(response.data.KOL_Personal_Info);
            console.log(response.data.message);
            this.Profile = response.data.KOL_Personal_Info;
            console.log(this.Profile.length)
            // console.log(JSON.stringify(Profile));
            this.setState({
              Profile: response.data.KOL_Personal_Info,
              ShowBar: false,
              progressBar: false,
            })
            // sessionStorage.setItem('kolInfo', data) 
            if (response.data.KOL_Personal_Info) {
              sessionStorage.setItem('kolInfo', JSON.stringify(response.data.KOL_Personal_Info));
              sessionStorage.setItem('SearchInCetas', 'false');
              this.setState({
                redirectSearchInCetasDb: true,
              });
            }
            else {
              sessionStorage.setItem('kolInfo', [])
            }
            // let getSessionDataOfProfileInfo = [];
            // let getSessionDataOfProfileInfoCheck = sessionStorage.getItem('kolInfo')
            // if (getSessionDataOfProfileInfoCheck == null) {
            //   getSessionDataOfProfileInfo = [];
            //   this.setState({
            //     ProfileBySession: getSessionDataOfProfileInfo
            //   })
            // }
            // else {
            //   let getSessionDataOfProfileInfoParse = sessionStorage.getItem('kolInfo');
            //   getSessionDataOfProfileInfo = JSON.parse(getSessionDataOfProfileInfoParse);
            //   this.setState({
            //     ProfileBySession: getSessionDataOfProfileInfo
            //   })
            // }
            // console.log("getSessionDataOfProfileInfo", getSessionDataOfProfileInfo)
          }
        })
    }
    catch (e) {
      console.log(e);
    }
    this.setState({
      isSearch1: false,
    })
  }




  BackToDashboard() {
    this.setState({ RedirectFlag: true })
  }

  profileInfoNav(project_id,p_name) {
    sessionStorage.setItem('project_id', project_id);
    console.log('Navigation of clientKolList', project_id);
    sessionStorage.setItem('p_name', p_name);
    //console.log('Navigation of clientKolList', this.state.Profile.p_name);

     //alert(p_name);
    // alert(sessionStorage.getItem('p_name'));

    this.setState({
      redirectClientViewKolList: true,
    })
  }


  linkToNoofKol = (cell, row) => {
    return (
      <div style={{color: '#069', fontSize:'12px', fontWeight:'bold',textAlign:'left'}}>      
          {row.doctor_full_name}
      </div>
    )
  }


  //Funtion to apply links to project names
  linkToDetails = (cell, row) => {
    var project_id = row.project_id;
    var p_name=row.p_name;
    return (
      <div>
        <a style={{ color: '#069',textDecoration: 'underline', cursor: 'pointer',fontSize:'12px' ,fontWeight:'normal' }}
          onClick={this.profileInfoNav.bind(this, project_id,p_name)}> {row.end_date} </a>
         
      </div>
    )
  }


  procedureformate=(cell, row)=>
  {
    var procedure=row.procedure_type_id;
    let pro=procedure.split(',');
    var finalprocedure=pro[0];

    if( pro.length>1)
    {
      finalprocedure += ", " + pro[1] + " and more…";
      //alert(pro[1]);
    }
    return(
    <div>
      {finalprocedure}

    </div>
    
    )

  }


  //Project Name Filter Function
  onProjectNameChange = (event) => {
    const { value } = event.target;
    this.onSPCFilter(value);
  };

  togglesearchPopover = async () => {
    console.log('clicked on propover')
    this.setState({
      searchPopover: !this.state.searchPopover
    })
  }

  render() {
    if (this.state.redirectClientViewKolList) {
      return (<Redirect to={{
        pathname: '/ClientViewKolList',
      }} />)
    }
    if (this.state.redirect) {
      return (<Redirect to={'/'} />)
    }
    if (this.state.redirectSearchInCetasDb) {
      return (<Redirect to={{
        pathname: '/SearchInCetasDb',
      }} />)
    }

    // Speciality Type options 
    let speciality_type = this.state.SpecialityData;
    let OptionsSpeciality = speciality_type.map((speciality_type) =>
      <option key={speciality_type.id} value={speciality_type.id}>
        {speciality_type.speciality_type}</option>
    );

    // Procedure List
    let procedure_list = this.state.ProcedureData;
    let OptionProcedure = procedure_list.map((procedure_list) =>
      <option key={procedure_list.id} value={procedure_list.procedure_type}>
        {procedure_list.procedure_type}</option>
    );

    // Sub Speciality List
    let subSpeciality_list = this.state.SubSpecialityData;
    let OptionSubSpeciality = subSpeciality_list.map((subSpeciality_list) =>
      <option key={subSpeciality_list.id} value={subSpeciality_list.sub_speciality_type}>
        {subSpeciality_list.sub_speciality_type}</option>
    );

    // For rendering pageination buttons
    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      return (
        <li class="page-item" onClick={handleClick} ><a class="page-link" href="#">{page}</a></li>
      );
    };

    // options for passing values to bootstrap table 2
    const options = {
      pageButtonRenderer,
      paginationSize: 5,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      // firstPageText: 'First',
      prePageText: 'Previous',
      nextPageText: 'Next',
      // lastPageText: 'Last',
      // nextPageTitle: 'First page',
      // prePageTitle: 'Pre page',
      // firstPageTitle: 'Next page',
      // lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
       text: 'All', value: this.state.Profile.length
      }]// A numeric array is also available. the purpose of above example is custom the text

    };


    const Specilitypiedata = [
      ["Task", "Hours per Day"],
      
      ["Global", 6],
      ["National", 5],
      ["Regional", 4],
      ["Local", 8],
      
    ];
    
    const Specilitypiechartoptions = {
     
      slices: {
        0: { color: this.series1color },
        1: { color: this.series2color },
        2: { color: this.series3color },
        3: { color: this.series4color },                  
       
      },
  
  //    is3D:true,
  
      titleTextStyle : {
        // fontName : "Oswald",
         //italic : false,
         color : this.state.vAxisfontcolor,
         bold:this.state.vAxisfontbold,       
         fontSize:this.state.vAxisfontsize,
       },
  
       titleTextStyle: {
        bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
      },
  
    // title: "Geographical Area",
    chartArea: {width: '100%', 'height': '80%'},

     //bar: {width:"65%",gap:"30%"},
    // bar: {width:"25%",gap:"35%"},
  
     legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
     
      
    };
  
  
  
  
  
    const GeographicalChartdata = [
      ["Task", "Hours per Day"],    
      ["Global", 6],
      ["National", 5],
      ["Regional", 4],
      ["Local", 8],
      
    ];
    
    const GeographicalChartoptions = {
     
      slices: {
        0: { color: this.series1color },
        1: { color: this.series2color },
        2: { color: this.series3color },
        3: { color: this.series4color },                  
       
      },
  
      //is3D:true,
      pieHole: 0.4,
      titleTextStyle : {
        // fontName : "Oswald",
         //italic : false,
         color : this.state.vAxisfontcolor,
         bold:this.state.vAxisfontbold,       
         fontSize:this.state.vAxisfontsize,
       },
  
       titleTextStyle: {
        bold:this.state.titlefontbold,color:this.state.titlefontcolor,fontSize:this.state.titlefontsize
      },
      chartArea: {width: '100%', 'height': '80%'},

    // title: "Geographical Area",
     
     //bar: {width:"65%",gap:"30%"},
     //bar: {width:"25%",gap:"35%"},
  
     legend: {position: 'bottom',width:2, textStyle: {fontSize: this.state.legendfontsize,color:this.state.legendfontcolor,bold:this.state.legendfontbold} },
     
      
    };

    return (
      // <div style={{backgroundColor:'#F9F9F9'}}>
      <div style={{backgroundColor:'#F9F9F9',paddingBottom:'35px'}}>
      
        {this.state.ShowBar ? <Modal isOpen={true} centered>
          <PropagateLoader color={'#A90007'} size='30px' loading='true' />
        </Modal> : null}
        {this.state.progressBar ? <div style={{ position: 'fixed', top: '0' }}>
          <LoadingBar
            progress='100'
            height={3} color="#A90007" transitionTime={4000}
            loaderSpeed={3000} />
        </div> : null}
        <div>
          <Modal id="search" isOpen={this.state.isSearch} toggle={this.togglesearch.bind()} style={{ padding: '15px' }}>
            <ModalHeader toggle={this.togglesearch.bind(this)} style={{ border: 'none' }} cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}>
              <h3 style={{ marginTop: '40px', marginLeft: '5%' }}>Search KOL</h3>
            </ModalHeader>
            <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
              {/* <FormGroup>
                <Label for="exampleFormControlTextarea1">Name</Label>
                <input type="text" className="form-control" id="exampleInputPassword1"
                  placeholder="Enter Name"
                  value={this.state.NameForSearch}
                  onChange={(e) => {
                    let { NameForSearch } = this.state;
                    NameForSearch = e.target.value;
                    this.setState({ NameForSearch });
                  }}
                />
              </FormGroup> */}

              <FormGroup>
                <Label for="exampleFormControlTextarea1">Speciality</Label>
                <Input type="select" id="roleSelect" className="form-control"
                  style={{ height: '50px' }}
                  value={this.state.Speciality}
                  onChange={async (e) => {
                    let { Speciality } = this.state;
                    Speciality = e.target.value;
                    await this.setState({ Speciality });
                    { this.getSubSpecialityList() }
                    { this.getProcedureList() }
                  }}>
                  <option value="0" >--Select Speciality--</option>
                  {OptionsSpeciality}
                </Input>
              </FormGroup>
              <FormGroup>
                {
                  (!this.state.Speciality) ? (
                    // <label for="exampleFormControlTextarea1">Procedure Type</label>
                    <div></div>
                  ) : (
                    <div>
                      <label for="exampleFormControlTextarea1">Sub Speciality Type</label>
                      <Input type="select" id="roleSelect" className="form-control"
                        style={{ height: '50px' }}
                        value={this.state.SubSpecialityType}
                        onChange={(e) => {
                          let { SubSpecialityType } = this.state;
                          SubSpecialityType = e.target.value;
                          this.setState({ SubSpecialityType });
                        }}>
                        <option value="0" >--Select Sub Speciality Type--</option>
                        {OptionSubSpeciality}
                      </Input>
                    </div>
                  )
                }
              </FormGroup>
              <FormGroup>
                {
                  (!this.state.Speciality) ? (
                    // <label for="exampleFormControlTextarea1">Procedure Type</label>
                    <div></div>
                  ) : (
                    <div>
                      <label for="exampleFormControlTextarea1">Procedure Type</label>
                      <Input type="select" id="roleSelect" className="form-control"
                        style={{ height: '50px' }}
                        value={this.state.ProcedureType}
                        onChange={(e) => {
                          let { ProcedureType } = this.state;
                          ProcedureType = e.target.value;
                          this.setState({ ProcedureType });
                        }}>
                        <option value="0" >--Select Procedure Type--</option>
                        {OptionProcedure}
                      </Input>
                    </div>
                  )
                }
              </FormGroup>
              <FormGroup>
                <div>
                  <label for="exampleFormControlTextarea1">
                    Countries 
                    {/* <span class="mandatory">*</span> */}
                  </label>
                  <Multiselect
                    data={this.state.CountryListData}
                    placeholder='--Select Countries--'
                    valueField='id'
                    textField='country_name'
                    onSelect={(valueField) => console.log(valueField)}
                    onChange={(event) => {
                      console.log(event);
                      var joined = [];
                      console.log("length : " + event.length);
                      if (event.length === 0) {
                        this.setState({ Market: [] });
                      }
                      event.map(async (data) => {
                        console.log("data in eve map : " + JSON.stringify(data));
                        joined = joined.concat(data.id);
                        console.log("data join : " + joined);
                        await this.setState({
                          Market: joined
                        });
                        console.log("check this", this.state.Market)
                      })
                    }}
                  />
                </div>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <button color="danger" class="cetas-button cetas-button-small mr-2" onClick={this.search.bind(this)}> Search </button>
              <button class="cetas-secondry-button cetas-button-small" onClick={this.togglesearch.bind(this)}>Cancel</button>
            </ModalFooter>
          </Modal>


          <Modal id="search1" isOpen={this.state.isSearch1} toggle={this.togglesearch1.bind()} style={{ padding: '15px' }}>
            <ModalHeader toggle={this.togglesearch1.bind(this)} style={{ border: 'none' }} cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}>
              <h3 style={{ marginTop: '40px', marginLeft: '5%' }}>Search1 KOL</h3>
            </ModalHeader>
            <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
              <FormGroup>
                <Label for="exampleFormControlTextarea1">Name</Label>
                <input type="text" className="form-control" id="exampleInputPassword1"
                  placeholder="Enter Name"
                  value={this.state.NameForSearch}
                  onChange={(e) => {
                    let { NameForSearch } = this.state;
                    NameForSearch = e.target.value;
                    this.setState({ NameForSearch });
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label for="exampleFormControlTextarea1">Speciality</Label>
                <Input type="select" id="roleSelect" className="form-control"
                  style={{ height: '50px' }}
                  value={this.state.Speciality}
                  onChange={async (e) => {
                    let { Speciality } = this.state;
                    Speciality = e.target.value;
                    await this.setState({ Speciality });
                    { this.getSubSpecialityList() }
                    { this.getProcedureList() }
                  }}>
                  <option value="0" >--Select Speciality--</option>
                  {OptionsSpeciality}
                </Input>
              </FormGroup>
              <FormGroup>
                {
                  (!this.state.Speciality) ? (
                    // <label for="exampleFormControlTextarea1">Procedure Type</label>
                    <div></div>
                  ) : (
                    <div>
                      <label for="exampleFormControlTextarea1">Sub Speciality Type</label>
                      <Input type="select" id="roleSelect" className="form-control"
                        style={{ height: '50px' }}
                        value={this.state.SubSpecialityType}
                        onChange={(e) => {
                          let { SubSpecialityType } = this.state;
                          SubSpecialityType = e.target.value;
                          this.setState({ SubSpecialityType });
                        }}>
                        <option value="0" >--Select Sub Speciality Type--</option>
                        {OptionSubSpeciality}
                      </Input>
                    </div>
                  )
                }
              </FormGroup>
              <FormGroup>
                {
                  (!this.state.Speciality) ? (
                    // <label for="exampleFormControlTextarea1">Procedure Type</label>
                    <div></div>
                  ) : (
                    <div>
                      <label for="exampleFormControlTextarea1">Procedure Type</label>
                      <Input type="select" id="roleSelect" className="form-control"
                        style={{ height: '50px' }}
                        value={this.state.ProcedureType}
                        onChange={(e) => {
                          let { ProcedureType } = this.state;
                          ProcedureType = e.target.value;
                          this.setState({ ProcedureType });
                        }}>
                        <option value="0" >--Select Procedure Type--</option>
                        {OptionProcedure}
                      </Input>
                    </div>
                  )
                }
              </FormGroup>
              <FormGroup>
                <div>
                  <label for="exampleFormControlTextarea1">
                    Countries <span class="mandatory">*</span></label>
                  <Multiselect
                    data={this.state.CountryListData}
                    placeholder='--Select Countries--'
                    valueField='id'
                    textField='country_name'
                    onSelect={(valueField) => console.log('valueField of country', valueField)}
                    onChange={(event) => {
                      console.log(event);
                      var joined = [];
                      console.log("length : " + event.length);
                      if (event.length === 0) {
                        this.setState({ Market: [] });
                      }
                      event.map(async (data) => {
                        console.log("data in eve map : " + JSON.stringify(data));
                        joined = joined.concat(JSON.parse(data.id)); //sheetal
                        console.log("data join : " + joined);
                        await this.setState({
                          Market: joined,
                        });
                        console.log("check this", this.state.market);
                      })
                    }}
                  />
                </div>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <button color="danger" class="cetas-button cetas-button-small mr-2" onClick={this.search1.bind(this)}> Search </button>
              <button class="cetas-secondry-button cetas-button-small" onClick={this.togglesearch1.bind(this)}>Cancel</button>
            </ModalFooter>
          </Modal>

          <div >
         <ClientHeader />

         <div class="container-fluid" id="mainClientDashboard" style={{backgroundColor:'#F9F9F9'}} >
          {/* <div class='row mt-4 col-md-12 col-sm-12 col-xs-12' style={{marginLeft:'.1%',minWidth:'100%',maxWidth:'100%',backgroundColor:'orange'}} > */}
          <div class='row mt-12 col-md-12 col-sm-12 col-xs-12' style={{Width:'100%',marginLeft:'.1%'}} >        
            <div class="col-lg-12 col-md-12 col-sm-12 col-12" >
              <div class="row mt-3" >
                      <div class="col-lg-5 col-md-5 col-sm-12 col-12" >
                          <h4><b>My KOL Repository</b></h4>
                        </div> 

                        <div class="col-lg-5 col-md-5 col-sm-12 col-12" >                      
                          <div  class="row mt-1 mb-0"  >
                                                <div class="col-lg-9 col-md-9 col-sm-12 col-12 right">
                                                  <p class="searching float-center"  >
                                                  
                                                <div className="employee-actions"  >
                                                <div className="table-infobox"  >
                                                  <div class="filter-box float-left mb-150">
                                                    <div class="input-group"  >
                                                      <span class="btn-label" style={{marginTop:'10px'}}> <i class="fa fa-search"></i>              </span>
                                                      <input type="text" className="form-control"  
                                                        id="inlineFormInputGroupUsername"
                                                        placeholder="Specialty type, Procedure type, Country"
                                                        onChange={(e) => { this.onProjectNameChange(e) }} />
                                                        
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                                  </p>
                                                </div>
                          
                          </div>
                      </div>


                <div className='dasbox' >
                  <div class='card' style={{height:'100px'}}>
                    <div class='card-body' >
                      <h1 class="hdet">{this.state.pro_count}</h1>
                      <p class="pdet">Project(s)</p>
                    </div>
                  </div>
                </div>

                {/* <div className='dasbox'>
                  <div class='card ' style={{height:'100px'}}>
                    <div class='card-body'>
                      <h2 class="hdet">45</h2>
                      <p class="pdet">Countries</p>
                    </div>
                  </div>
                </div> */}

                <div className='dasbox'>
                  <div class='card' style={{height:'100px'}}>
                    <div class='card-body'>
                      <h1 class="hdet">{this.state.Kol_count}</h1>
                      <p class="pdet">Overall KOL(s)</p>
                    </div>
                  </div>
                </div>

                <div className='dasbox'>
                  <div class='card ' style={{height:'100px'}}>
                    <div class='card-body'>
                      <h1 class="hdet">{this.state.deep_kol_count}</h1>
                      <p class="pdet">Deep Profiled KOL(s)</p>
                    </div>
                  </div>
                </div>

                <div className='dasbox'>
                  <div class='card ' style={{height:'100px'}}>
                    <div class='card-body'>
                      <h1 class="hdet">{this.state.basic_kol_count}</h1>
                      <p class="pdet">Basic Profiled KOL(s)</p>
                    </div>
                  </div>
                </div>


                {/* <div className='dasbox'>
                  <div class='card ddetails'>
                    <div class='card-body'>
                     
                      <div class="dppep">
                      <div class='card1'>
                   
                                  <h2 className='dasbox_kolh2'>45 <span className='dasbox_kolp'>Basic Profiles</span></h2>
                                  
                      </div>
                      </div>      


                       <div class="dppep" style={{ marginTop:'5px'}}>
                      <div class='card1'>
                   
                                  <h2 className='dasbox_kolh2'>40 <span className='dasbox_kolp'>Deep Profiles</span></h2>
                               
                      </div>
                      </div>   
                    </div>  
                    </div>
                </div> */}
{/* 
                <div className='dasbox_kol'>
                  <div class='card ddetails'>
                    <div class='card-body'>
                      <h2 class="hdet">85</h2>
                      <p class="pdet">KOL(s)</p>

                      <div class="dppep">
                      <div class='card1'>
                   
                                  <h2 className='dasbox_kolh2'>45 <span className='dasbox_kolp'>Basic Profiles</span></h2>
                                  
                      </div>
                      </div>      


                       <div class="dppep" style={{ marginTop:'5px'}}>
                      <div class='card1'>
                   
                                  <h2 className='dasbox_kolh2'>40 <span className='dasbox_kolp'>Deep Profiles</span></h2>
                               
                      </div>
                      </div>   
                    </div>  
                    </div>
                </div> */}
                
              </div>

              <div class="row mt-4"  >
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="card ccdetails" >
                    <div class="card-body"  >
                      {/* <h4><b>Project Details</b></h4> */}
                    
                    
                      {/* <div class="row mt-2 mb-2"   >
                        <div class="col-lg-9 col-md-9 col-sm-12 col-12 right">
                          <p class="searching float-center"  >
                          
                        <div className="employee-actions"  >
                        <div className="table-infobox"  >
                          <div class="filter-box float-left mb-150">
                            <div class="input-group"  >
                              <span class="btn-label" style={{marginTop:'10px'}}> <i class="fa fa-search"></i>              </span>
                              <input type="text" className="form-control"  
                                id="inlineFormInputGroupUsername"
                                placeholder="Specialty type, Procedure type, Country"
                                onChange={(e) => { this.onProjectNameChange(e) }} />
                                
                            </div>
                          </div>
                        </div>
                      </div>

                          </p>
                        </div>
                      </div> */}





                      {/* <div class="table-responsive mt-2" style={{height:"302px"}} >
                        <table class='table table-hover' style={{width:"98.5%"}}>
                          */}
                     
                      {/* <div className='table-responsive' style={{height:'299px'}}>
                          <table className='table table-striped mb-2' style={{width:"98.5%"}}> */}
                          {/* <Table class="table table-bordered" >table_srls */}
                            {/* <div className='' > */}
                            <div className='table-responsive' style={{height:'350px',marginTop:'0px'}}>
                            <table className='table table-striped mb-2' style={{width:"98.5%"}}>
                            <div className='bg-light'>
                            <BootstrapTable
                                  noDataIndication="Table is Empty"
                                  keyField='project_id'
                                  data={this.state.Profile} columns={this.state.columns}
                                  filter={filterFactory()}
                                 // rowStyle={{ backgroundColor: '#fff', border:'solid' , borderBottomColor:'#ccc', borderLeftColor:'#fff',borderRightColor:'#fff' }}
                                  
                                 rowStyle={{ backgroundColor: '#fff', borderRight:'solid', borderBottom:'solid', borderBottomColor:'#EDE9E8', borderRightColor:'#fff',fontSize:'12px', borderLeft:'solid', borderLeftColor:'#fff'}}
                                 
                                 pagination={paginationFactory(options)}
                                  //border={{style:'solid',color:'orange'}}
                                  //striped
                                  hover
                                  condensed
                                  borderless
                              />

                            </div>
                            </table>
                          </div>



                    </div>
                  </div>
                </div>
              </div>
            </div>
              
            </div>
          </div>
{/* 
            <footer class="footer_not">
                        <div class="cetas-footer">
                            <div class="container" >
                                <p>All rights reserved. www.cetashealthcare.com</p>
                            </div>
                        </div>
            </footer> */}

{/* 
            <footer  >
            <div class="cetas-footer" style={{backgroundColor:'#F9F9F9'}}>
              </div>

            </footer> */}

          </div>


          </div>
        </div>
            
    );
  }
  
}

export default ClientViewProject;