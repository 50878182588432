import React, { Component } from 'react';

import { Redirect } from 'react-router-dom'

//datatable npm
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

// showing progress bar npm 
import { PropagateLoader } from 'react-spinners';
import LoadingBar from "react-top-loading-bar";

import imgSrc from "../Assets/Images/bg_banner1.jpg";

import axios from 'axios';

import Swal from 'sweetalert2';
import Axios from 'axios';
import { Multiselect } from 'react-widgets';

import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import ClientHeader from './ClientHeader';
import Signup from '../Login/Signupinfo';
//import { color } from 'html2canvas/dist/types/css/types/color';
//import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';

// import '../styles/ViewProject.css';

import Pagefooter from '../Login/footer';

import Quoteinfo from '../PClient/Quoteform';
import Quoteform from '../PClient/Quoteform';

//export const toggleClosePopup=()=>{alert('hi Aarna')};
export const otherpageClosePopup=()=>{
    window.location.reload(true)
};

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

const selectRow = {
  mode: 'checkbox',
  
            
  // clickToSelect: true
};

const { ExportCSVButton } = CSVExport;

class ClientViewKolList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      client_id: '',
      client_name: '',
      client_email: '',
      project_id:'CHCPI006',
     // project_id:'CHCPI0078',
      quotation_id:'',
  
      Search_speciality_id:'',

     Buyer_order:'',
     Buyer_kol_id:'',
     Buyer_client_name:'',
     Buyer_client_id:'',
     KOL_Profile:[],

      // nameError: '',
      // emailError: '',
      // mobile_noError: '',
      // company_nameError: '',
      // no_of_kolError: '',
      // Specialty_idError: '',
      // procedure_typeError: '',
      // countriesError: '',
     
      QuoteData: {
        client_id:'',
        client_name:'',
        kol_id:'',        
        
        no_of_deep_profile: "",
        no_of_basic_profile: "",  
        no_of_kol: '',      
        specialty_id: '',
        procedure_type: '',
        countrieslist:[],
        Multiple_single_profile: '',
                   
        status: 'New',
        
      },


      procedureshowtype:false,

      redirecthome:false,
      // 
      // RedirectEmployeeKolList: false,
      // RedirectClientProject: false,
      redirectClientKolProfile: false,
      
      redirectClientViewProfile:false,
      redirect: false,

      // state for progress bar
      ShowBar: false,
      progressBar: false,
      isSearch: false,

      QuoteModal: false,
      RedirectSignUp:false,

      SignupModal:false,

      //toggleSignupModal:false,

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],
      Profile: [],
      speciality_type_type:'',
      // to set form values
      Speciality: '',

      SubSpecialityType: [],
      SubSpecialityTypeString: '',
      ProcedureType: [],
      ProcedureTypeString: '',
      Subspeciality: [],

      showProcedureFlag: true,
      showSubSpecialityFlag: true,
      multiplekolquotmodel:false,
      redirectknowmore:false,
      infoQuoteModal:false,
      
      NewQuoteModal:false,
      // searchg modal
      SearchModal: false,
      PrilimaryModal:false,

      // Column state for Bootstrap Table 
      //var headerSortingStyle = { backgroundColor: "#e3edf8" };

      columns: [
        // {
        //     dataField: 'kol_unique_id',
        //     text: 'ID',
        // },
        // {
        //   dataField: 'rank_local',
        //   text: 'Ranking',
        //   sort: true,
          
        //   formatter: (cell, row) => {
        //     return(
        //     <div class="P_Client">
              
        //          {row.rank_local} 
               
        //       </div>
        //     )
            
           
  
        //    },

        //    headerStyle: (colum, colIndex) => {
        //     return {width: '3%', borderColor:'#02365F',textAlign:'left', fontSize:'13px', borderLeft:'solid', borderLeftColor:'#fff', borderTop:'solid', borderTopColor:'#fff' };
        //   },
        //   //formatter: this.RankLocalFormater,
        //   // //classNames:'text-center',
        //   // class:'text-center',
        //   // cellStyle: {color: 'red', 'background-color': 'green'},
        
        // },

        {
          dataField:'kol_unique_id',
          text: 'KOL ID',
          formatter: (cell, row) => {
            return(
            <div class="P_Client">
              
                {row.kol_unique_id}
               
  
              </div>
            )
  
           },
          sort: true,
          
           hidden:true,
          headerStyle: (colum, colIndex) => {
             return {width: '6%',borderColor:'#02365F',textAlign:'center', fontSize:'13px', borderTop:'solid', borderTopColor:'#fff'};
          },


        },

        // {
        //   dataField: 'doctor_full_name',
        //   text: 'KOL Name',
        //  // formatter: this.linkToDetails,
        //   // sort: true,
        //   filter: textFilter(),
        //   filterRenderer: (onFilter, column) => {
        //     this.onProjectNameFilter = onFilter;
        //     return null;
        //   },
        //   headerStyle: (colum, colIndex) => {
        //     return {width: '22%',borderColor:'#02365F',textAlign:'left', fontSize:'15px', fontWeight:'bold', borderTop:'solid', borderTopColor:'#fff'};
        //   },
        // },

        {
          dataField: "profile_photo",
          //text: "Image",
          formatter: this.PfrofileImageFormater,
          headerStyle: (colum, colIndex) => {
            return {width: '10%',borderColor:'#02365F', paddingLeft:'3.5%', textAlign:'left', fontSize:'15px', borderTop:'solid', borderTopColor:'#fff'};
          },
        }, 

        {
          dataField: 'doctor_full_name',
          text: 'KOL Name',
          
          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onSpecityFilter = onFilter;
            return null;
          },
          headerStyle: (colum, colIndex) => {
            return {width: '28%',borderColor:'#02365F', textAlign:'left', fontSize:'15px', borderTop:'solid', borderTopColor:'#fff'};
          },
         formatter: (cell, row) => {
           return(
           <div class="P_Client">
              {row.doctor_full_name}

             </div>
           )

          },
        },
        

        {
          // dataField: 'speciality_type',
          // text: 'Specialty',
         
          dataField: 'procedure_type_id',
          text: 'Procedure Type',
         
          // filter: textFilter(),
          // filterRenderer: (onFilter, column) => {
          //   this.onSpecityFilter = onFilter;
          //   return null;
          // },
          

          formatter: (cell, row) => {
           return(
           <div class="P_Client">
              {row.procedure_type_id}

             </div>
           )

          },

          
          headerStyle: (colum, colIndex) => {
             return {width: '22%',borderColor:'#02365F',textAlign:'left', fontSize:'15px', borderTop:'solid', borderTopColor:'#fff'};
                   
          },
          

        },
        // 
        

        // {
        //   dataField: "procedure_type",
        //   text: "Procedure Type",
        //   formatter:this.procedureformate,
        //   // formatter: (cell, row) => {
        //   //   return(
        //   //   <div class="P_Client">
        //   //       {row.procedure_type}
  
        //   //   </div>
        //   //   )
  
        //   //  },
        //    headerStyle: (colum, colIndex) => {
        //     return {width: '8%',borderColor:'#02365F',textAlign:'center', fontSize:'13px', borderTop:'solid', borderTopColor:'#fff'};
        //   },
        //  // hidden: true,
        // },

        // {
        //   dataField: "expertise",
        //   text: "Expertise",
        //   formatter: (cell, row) => {
        //     return(
        //     <div class="P_Client">
        //         {row.expertise}
  
        //     </div>
        //     )
  
        //    },

        //   filter: textFilter(),
        //   filterRenderer: (onFilter, column) => {
        //     this.onInstitutionFilter = onFilter;
        //     return null;
        //   },

        //    headerStyle: (colum, colIndex) => {
        //     return {width: '20%',borderColor:'#02365F',textAlign:'left', fontSize:'15px', borderTop:'solid', borderTopColor:'#fff'};
        //   },
        //  // hidden: true,
        // },
        {
          dataField: "medical_schools",
          text: "Institution",
          formatter: (cell, row) => {
            return(
            <div class="P_Client">
                {row.medical_schools}
  
            </div>
            )
  
           },

          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onInstitutionFilter = onFilter;
            return null;
          },

           headerStyle: (colum, colIndex) => {
            return {width: '28%',borderColor:'#02365F',textAlign:'left', fontSize:'15px', borderTop:'solid', borderTopColor:'#fff'};
          },
         // hidden: true,
        },
        {
          dataField: "country_name",
          text: "Country",
          formatter: (cell, row) => {
            return(
            <div class="P_Client">
                {row.country_name}
  
              </div>
            )
  
           },

          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onCountryFilter = onFilter;
            return null;
          },

           headerStyle: (colum, colIndex) => {
            return {width: '28%',borderColor:'#02365F',textAlign:'left', fontSize:'15px', borderTop:'solid', borderTopColor:'#fff'};
          },
          //hidden: true,
        },
        // {
        //   dataField: 'aggregate_score',
        //   text: 'Rating',
        //    formatter:this.rating,
        //   headerStyle: (colum, colIndex) => {
        //     return {width: '8%',borderColor:'#02365F',textAlign:'center', fontSize:'13px', borderTop:'solid', borderTopColor:'#fff'};
        //   },
          
        // },
        
        {
          dataField: "",
          text: "",
          formatter: this.KOLDetails,
          
          csvExport: false,
          headerStyle: (colum, colIndex) => {
            
           return {width:'5%', borderColor:'#02365F',textAlign:'center' , borderTop:'solid', borderTopColor:'#fff' , borderRight:'solid', borderRightColor:'#fff'};
          },


        },
      ],


    }    // End of states
  }   // End of constructor




  // validate = () => {
  //   let noofkolErr = '';
  //   let specilityErr = '';
  //   let procedureErr = '';
  //   let countryErr = '';
  //   let Multiple_single_profileErr = '';
  //   let profilingtypeErr='';

    
  //   if(this.state.QuoteData.Multiple_single_profile=="BuyMultipleProfiles")
  //   {
  //         if (!this.state.QuoteData.Multiple_single_profile) {
  //           Multiple_single_profileErr = 'Please select';
  //         }
      
  //       if (!this.state.QuoteData.no_of_kol) {
  //         noofkolErr = 'Please Enter no of kol required';
  //       }
  //       if (!this.state.QuoteData.procedure_type) {
  //         procedureErr = 'Please select procedure';
  //       }
    
  //       if (this.state.QuoteData.countrieslist.length <= 0) {
  //         countryErr = 'Please select country';
  //       }
        
  //       if (!this.state.QuoteData.specialty_id) {
  //         specilityErr = 'Please select speciality';
  //       }
    
  //       if (!this.state.QuoteData.Profilingtype) {
  //         profilingtypeErr = 'Please select profiling type';
  //       }

  //       if (noofkolErr || procedureErr ||countryErr || specilityErr || profilingtypeErr || Multiple_single_profileErr) {
  //         this.setState({ noofkolErr, specilityErr ,procedureErr,countryErr,profilingtypeErr,Multiple_single_profileErr });
  //         return false;
  //       }
  // }
  // else
  // {
  //     if (!this.state.QuoteData.Multiple_single_profile) {
  //       Multiple_single_profileErr = 'Please select';
  //     }
  //     if (Multiple_single_profileErr) {
  //       this.setState({ Multiple_single_profileErr });
  //       return false;
  //     }
  // }
  
    
  //   return true;
  // }

  validate = () => {
    let noofkolErr = '';
    let noofbasickolErr = '';
    let noofdeepkolErr = '';
    let specilityErr = '';
    let procedureErr = '';
    let countryErr = '';
   // let profilingtypeErr='';

    
    // if (!this.state.QuoteData.Multiple_single_profile) {
    //   Multiple_single_profileErr = 'Please select';
    // }

    // if (!this.state.QuoteData.no_of_kol) {
    //   noofkolErr = 'Please Enter no of kol required';
    // }
    // if (!this.state.QuoteData.procedure_type) {
    //     procedureErr = 'Please select procedure';
    //  }
  
    if (!this.state.QuoteData.no_of_basic_profile) {
      noofbasickolErr = 'Please input no of basic profile';
    }
   
    if (!this.state.QuoteData.no_of_deep_profile) {
      noofdeepkolErr = 'Please input no of deep profile';
    }   

      if (this.state.QuoteData.countrieslist.length <= 0) {
        countryErr = 'Please select country';
      }
      
      if (!this.state.QuoteData.specialty_id) {
        specilityErr = 'Please select speciality';
      }
  
      // if (!this.state.QuoteData.Profilingtype) {
      //   profilingtypeErr = 'Please select profiling type';
      // }

        // if (noofkolErr || procedureErr ||countryErr || specilityErr || profilingtypeErr ) {
        //   this.setState({ noofkolErr, specilityErr ,procedureErr,countryErr,profilingtypeErr });
        //   return false;
        // }
 
        if (noofbasickolErr || countryErr || specilityErr || noofdeepkolErr ) {
          this.setState({ noofbasickolErr, specilityErr ,countryErr,noofdeepkolErr });
          return false;
        }
    
    return true;
  }



  SaveQuoteinfo = async () =>
    {
      //const no_of_basic_profile=Number(this.state.QuoteData.no_of_basic_profile);
      //const no_of_deep_profile=Number(this.state.QuoteData.no_of_deep_profile);
     
   //  alert(this.state.QuoteData.no_of_kol);
  const isValid = this.validate();
//     const isValid =false;
      if (isValid) {
        this.state.QuoteData.no_of_kol=Number(this.state.QuoteData.no_of_basic_profile) + Number(this.state.QuoteData.no_of_deep_profile);

     
      this.setState({
        ShowBar: true,
      })

      const url = global.websiteUrl+'/service/KOL_landing/save_quote_data';
      let formData = new FormData();

      formData.append('Quote_Info', JSON.stringify(this.state.QuoteData));
      
      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("lgstatus", JSON.stringify(lgstatus));
          console.log("response message2", response.data.message)
       
          // alert(response.data.status);
          // alert(response.data.quotation_id);
        

          this.setState({
            ShowBar: false,
          })
          if (response.data.status == true) {

            if(this.state.client_id !="Guest")
            {
              Swal.fire({
                icon: 'success',
                //text: response.data.message,
                text: ' Thanks for quote,Our experts will get back to you within 24 hours.!',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'

              })

              
              this.setState({infoQuoteModal:false,QuoteModal:false})
             
              
          }
          else
          {
           // alert(response.data.quotation_id);
            sessionStorage.setItem('quotation_id',response.data.quotation_id);
            
            this.setState({infoQuoteModal:false,QuoteModal:false,SignupModal:true,quotation_id:response.data.quotation_id})
            //this.setState({ RedirectSignUp: true })
          }
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(error => {
          console.log(error);
        });

      this.setState({
        pnameErr: '',
        psowErr: '',
      });
    }

    }




  redirectknowmore = async () =>
  {
    this.setState({redirectknowmore:true})
   // alert('redirectknowmorehi');
  }

  onChangeValue= async (event) => {

    if(event.target.value == "BuyMultipleProfiles")
    {
      let { QuoteData } = this.state;
      QuoteData.kol_id = '';
      this.setState({ QuoteData });

      //alert(event.target.value);
      this.setState({multiplekolquotmodel:true,Multiple_single_profileErr : '',})
    }else
    { 
      let { QuoteData } = this.state;
      QuoteData.kol_id =this.state.kol_id;
      QuoteData.no_of_kol='';
      QuoteData.no_of_deep_profile='';
      QuoteData.no_of_basic_profile='';
      QuoteData.specialty_id='';
      QuoteData.procedure_type='';
     // QuoteData.Profilingtype='';
      QuoteData.countrieslist='';
      this.setState({ QuoteData });


      // let { QuoteData } = this.state;
      // QuoteData.kol_id =this.state.kol_id;
      // this.setState({ QuoteData });

      //alert(event.target.value);
      this.setState({
        multiplekolquotmodel:false,
        //noofkolErr: '',
        noofbasickolErr: '',
        noofdeepkolErr: '',
        specilityErr: '',
        procedureErr :'',
        countryErr : '',
        Multiple_single_profileErr : '',
       // profilingtypeErr:'',  
      
      })
    }

  // alert(event.target.value);
    let { QuoteData } = this.state;
      QuoteData.Multiple_single_profile = event.target.value;
      this.setState({ QuoteData });
    console.log(event.target.value);
  }

  
  componentDidMount = async () => {
    
    let getSession_Kol_Id = sessionStorage.getItem('kol_id')
        if (getSession_Kol_Id !== null) {
            this.setState({
                kol_id: JSON.parse(getSession_Kol_Id),
            })
          
        }

        let getSession_Client_Name = sessionStorage.getItem('client_name')
        
        if (getSession_Client_Name !== null) {
            this.setState({
                client_name: getSession_Client_Name,
            })

            let { QuoteData } = this.state;
                QuoteData.client_name = getSession_Client_Name;
            this.setState({ QuoteData });

        }

        let getSession_client_id = sessionStorage.getItem('client_id')
        if (getSession_client_id !== null) {
            this.setState({
              client_id: getSession_client_id,
            })

            let { QuoteData } = this.state;
                QuoteData.client_id = getSession_client_id;
            this.setState({ QuoteData });
        }
        


    
    // let getSession_Client_Email = await sessionStorage.getItem('client_email')
    // if (getSession_Client_Email !== null) {
    //   await this.setState({
    //     client_email: getSession_Client_Email,
    //   })

    //   let { QuoteData } = this.state;
    //   QuoteData.email = getSession_Client_Email;
    //   this.setState({ QuoteData });
    // }

    // let getSession_Client_ID = await sessionStorage.getItem('client_id')
    // if (getSession_Client_ID !== null) {
    //   await this.setState({
    //     client_id: getSession_Client_ID,
    //   })

    //   }

    // let getSession_Client_Name = await sessionStorage.getItem('client_name')

    // if (getSession_Client_Name !== null) {
    //   await this.setState({
    //     client_name: getSession_Client_Name,
    //   })

    //   let { QuoteData } = this.state;
    //   QuoteData.name = getSession_Client_Name;
    //   this.setState({ QuoteData });
    // }
    
    // console.log("getSession_Client_Email ====", getSession_Client_Email)
    // console.log("getSession_Client_ID ====", getSession_Client_ID)
    // console.log("getSession_Client_Name ====", getSession_Client_Name)

    // sessionStorage.setItem('Buyer_order',"NewBuy");
    let getSession_Buyer_order = await sessionStorage.getItem('Buyer_order')

    if (getSession_Buyer_order !== null) {
      await this.setState({
        Buyer_order: getSession_Buyer_order,
      })
    }
    
    // sessionStorage.setItem('Buyer_kol_id',this.state.kol_id);
    let getSession_Buyer_kol_id = await sessionStorage.getItem('Buyer_kol_id')
    if (getSession_Buyer_kol_id !== null) {
      await this.setState({
        Buyer_kol_id: getSession_Buyer_kol_id,
      })
    }
    // sessionStorage.setItem('Buyer_client_name',this.state.client_name);
    let getSession_Buyer_client_name = await sessionStorage.getItem('Buyer_client_name')
    if (getSession_Buyer_client_name !== null) {
      await this.setState({
        Buyer_client_name: getSession_Buyer_client_name,
      })
    }
    // sessionStorage.setItem('Buyer_client_id',this.state.client_id);
      
    let getSession_Buyer_client_id = await sessionStorage.getItem('Buyer_client_id')
    if (getSession_Buyer_client_id !== null) {
      await this.setState({
        Buyer_client_id: getSession_Buyer_client_id,
      })
    }

    let getSession_Serch_speciality_id = await sessionStorage.getItem('speciality_id')
    if (getSession_Serch_speciality_id !== null) {
      await this.setState({
        Search_speciality_id: getSession_Serch_speciality_id,
      })
    }


    if(this.state.Buyer_order=="NewBuyOrder")
    {
      //alert("order for kol");
        this.setState({QuoteModal:true})
    }

    // console.log("getSession_Project_id ====", getSession_Project_id)

    this.setState({
      ShowBar: true,
    })


    const url1 = global.websiteUrl+'/service/KOL_landing/Get_Kol_List_On_Speciality';
    let formData1 = new FormData();

    formData1.append('specialty_id', this.state.Search_speciality_id);
   
    const config1 = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
      
    axios.post(url1, formData1, config1)
      .then(response => {
        console.log(response);
        var lgdata = response.data;        
        console.log("lgdata", JSON.stringify(lgdata));

        if (response.data.status == true) {
          this.setState({
            KOL_Profile: response.data.kol_info,
            speciality_type_type:response.data.kol_info[0].speciality_type,
            ShowBar:false
          })
        }
       // alert(response.data.kol_info[0].procedure_type_id)
//       alert(this.state.KOL_Profile[0].speciality_type);

      })
      
     // alert(this.state.KOL_Profile[0]["procedure_type_id"]);


    const options = {
      method: 'POST',
    }

    // //  Speciality type data
    fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataSpeciality = findresponse.data;
        console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
        this.setState({
          SpecialityData: findresponse.data,
          ShowBar: false,
          progressBar: false,
        })
        // console.log("Length of SpecialityData ",this.state.SpecialityData.length);        
      })


    // Country list(market) 
    fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
    .then((Response) => Response.json())
    .then((findresponse) => {
      console.log(findresponse)
      var testDataCountryList = findresponse.Country_Details;
      console.log("testDataCountryList", JSON.stringify(testDataCountryList));
      this.setState({
        CountryListData: findresponse.Country_Details,
        Client_Unique_id: findresponse.client_system_id,
      })
      console.log("Client_Unique_id", this.state.Client_Unique_id);
    })

        
 
  }
  
  getProcedureList = () => {
    console.log("in Procedure list");
    console.log("speciality_type_id", this.state.QuoteData.specialty_id);
  
  //alert(this.state.QuoteData.Specialty_id);
    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.QuoteData.specialty_id);
    formData.append('search_type', "Procedure");
  
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var proceduredata = response.data;
        console.log("proceduredata", JSON.stringify(proceduredata));
        
       // alert(response.data.status);

          if (response.data.status == true) {
          this.setState({
            ProcedureData: response.data.data,
            procedureshowtype:true,
          })      
         
        }
        else {
         
          this.setState({
            ProcedureData: [],
            procedureshowtype:false,
          })
        }
        
        console.log("ProcedureData", this.state.ProcedureData)
      })
      
      .catch(error => {
        console.log(error);
      });
  }
   
 toggleClosePopup()
  {
    alert('hi Prakash')
  }


  toggleGetQuoteModal(kolIdForCredential) {
   // alert(kolIdForCredential);
  
    this.setState({
        //kol_researcher_info,
       // kolIdForCredential: kolIdForCredential,
       infoQuoteModal: !this.state.infoQuoteModal,
        //PasswordErr: '',
    });
}

toggleGetQuoteModal(kolIdForCredential) {
   this.setState({
      NewQuoteModal: !this.state.NewQuoteModal,
   });
}


toggleSignupModal()
{
  
  let idtoDelete = this.state.quotation_id;
 // alert(idtoDelete);
  if (idtoDelete !== "") {
    
    this.setState({
      SignupModal:!this.state.SignupModal,
      //toggleSignupModal:!this.state.toggleSignupModal,
    })

    this.setState({
        ShowProcedure: true,
    })
    const url = global.websiteUrl+'/service/KOL_Landing/delete_quote_data';

    let formData = new FormData();
   // formData.append('oper', "del");
    formData.append('id', idtoDelete);

    const config = {
        // headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post(url, formData, config)
        .then(response => {
            console.log(response.data);
           // alert(response.data.status);
            if(response.data.status == true)
            {
            
            }
          
        })
        .catch(error => {
            console.log(error);
        });
    
  }
 
     

}

delete_quote_id(id)
{
  
          console.log(id);
          let idtoDelete = JSON.parse(id);

            this.setState({
                ShowProcedure: true,
            })
            const url = global.websiteUrl+'/service/KOL_Landing/delete_quote_data';

            let formData = new FormData();
            formData.append('oper', "del");
            formData.append('id', idtoDelete);

            const config = {
                // headers: { 'content-type': 'multipart/form-data' }
            }

            axios.post(url, formData, config)
                .then(response => {
                    console.log(response.data);

                  
                })
                .catch(error => {
                    console.log(error);
                });

        
}





SaveQuoteRequest(id)
{
 // alert(id);
}

  getSubSpecialityList() {
    console.log("in Sub Speciality");
    console.log("speciality_type_id", this.state.Speciality);

    this.setState({
      showProcedureFlag: true,
      showSubSpecialityFlag: true,
    })

    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.Speciality);
    formData.append('search_type', "Sub Speciality");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var SubSpecialityData = response.data;
        console.log("SubSpecialityData", JSON.stringify(SubSpecialityData));
        console.log(SubSpecialityData.status)
        // if(this.state.SubSpecialityData.status == false){
        //     this.setState({
        //         SubSpecialityData : null,
        //     })
        // } else {
        //     this.setState({
        //         SubSpecialityData : response.data.data,
        //     }) 
        // } 

        if (response.data.status == true) {
          this.setState({
            ShowBar: false,
            progressBar: false,
            SubSpecialityData: response.data.data,
          })
        }
        else if (response.data.status == false) {
          this.setState({
            ShowBar: false,
            progressBar: false,
            SubSpecialityData: [],
          })
        }
        console.log("SubSpecialityData====", this.state.SubSpecialityData)
        console.log("Length of SubSpecialityData ", this.state.SubSpecialityData.length);
      })
      .catch(error => {
        console.log(error);
      });
  }

  getProcedureList() {
    console.log("in Procedure list");
    console.log("speciality_type_id", this.state.Speciality);

    this.setState({
      showProcedureFlag: true,
      showSubSpecialityFlag: true,
    })

    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.Speciality);
    formData.append('search_type', "Procedure");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var proceduredata = response.data;
        console.log("proceduredata", JSON.stringify(proceduredata));
        // this.setState({
        //     ShowBar: false,
        //     progressBar: false,
        //     ProcedureData : response.data.data,
        // }) 
        if (response.data.status == true) {
          this.setState({
            ShowBar: false,
            progressBar: false,
            ProcedureData: response.data.data,
          })
        }
        else if (response.data.status == false) {
          this.setState({
            ShowBar: false,
            progressBar: false,
            ProcedureData: [],
          })
        }
        console.log("ProcedureData", this.state.ProcedureData)
      })
      .catch(error => {
        console.log(error);
      });
  }


  


 //Funtion to apply links to project names style={{backgroundColour:'#DC3545',borderColor:'#DC3545'}}
 rating = (cell, row) => {
  var kol_id = row.kol_unique_id;
  
  return (
    <div class="hexagon" >
      <span class="hexL" >{row.aggregate_score}</span>      
    
    </div>
  )
}





  //Funtion to apply links to project names
  linkToDetails = (cell, row) => {
    var kol_id = row.kol_unique_id;
    var raking=row.aggregate_score;
    
    return (
      <div>
        <a style={{
          color: '#069', fontSize:'15px', fontWeight:'bold',textAlign:'left',
          textDecoration: 'underline', cursor: 'pointer', position:'relative',marginTop:'20px',display:'inline-block'
        }}
          onClick={this.profileInfoNav.bind(this, kol_id,raking)}> {row.doctor_full_name} </a>
      </div>
    )
  }


//Funtion to apply links to Biography
linkToBio = (cell, row) => {
  var kol_id = row.kol_unique_id;

  return (
    <div>
      <a style={{
         color: '#069',
        textDecoration: 'underline', cursor: 'pointer'
      }}
        onClick={this.bioInfoNav.bind(this, kol_id)}> {row.doctor_full_name} </a>
    </div>
  )
}

  RankLocalFormater = (cell, row) => {
    var kol_id = row.kol_unique_id;
    return (
      <div>
        <a style={{
           color: '#000',
        }}
        > 
          {row.rank_local} 
        </a>
      </div>
    )
  }


  procedureformate=(cell, row)=>
  {
    var procedure=row.procedure_type;
    let pro=procedure.split(',');
    var finalprocedure=pro[0];

    if( pro.length>1)
    {
      finalprocedure += ", " + pro[1] + " and more…";
      //alert(pro[1]);
    }
    return(
    <div class="P_Client">
      {finalprocedure}

    </div>
    
    )

  }


  PfrofileImageFormater= (cell, row) => {
    var imgsource ='';
   // alert(row.profile_photo);
        if( row.profile_photo !== null)
        {
          imgsource= row.profile_photo;
        }
        else
        {
          imgsource="require('../Assets/Images/default-user.jpg')";
        // imgsource="{require('../Assets/Images/cetas-logo.png')}";
        }



    return (
      <div>        
        <td>

        {
          ( row.profile_photo !== null ) ?   
          (
            <img src={row.profile_photo} class="Client_profile_pic" />
         
          ):
          (
            <img src={require('../Assets/Images/default-user.jpg')} class="Client_profile_pic" />
         

          )

          // else
          // {
          //   <img src={require('../Assets/Images/default-user.jpg')} class="Client_profile_pic" />
         
          // }

        }
{/* 
         <img src={row.profile_photo} class="Client_profile_pic" /> */}

         
         </td>
        
      </div>
    )
  }

  toggleQuoteModal = async () => {   
    this.setState({QuoteModal:true,})
 }

// toggleinfoBuyModal = async () => {             
//  this.setState({infoQuoteModal:true,})   
  
// }
 
// // toggleinfoBuyModal = () => {
// //   this.setState({
// //     infoQuoteModal: !this.state.infoQuoteModal,
    
// //   });
// // }



toggleinfoBuyModal = () => {
  this.setState({
    NewQuoteModal: !this.state.NewQuoteModal,
    
  });
}

togglecanQuoteinfoModal = async () => {             
           
 this.setState({infoQuoteModal:false,})     

}


  
  profileInfoNav(kol_id,raking) {

  //   sessionStorage.setItem('kol_id', JSON.stringify(kol_id));
  // //   console.log('Navigation of clientKolProfile', kol_id);
  // //   this.setState({
  // //     redirectClientKolProfile: true,
  // //   })
  // //   if(raking !=='NA')
  // //   {
  // //   sessionStorage.setItem('kol_id', JSON.stringify(kol_id));
  // //   console.log('Navigation of clientKolProfile', kol_id);
  // //   this.setState({
  // //     redirectClientKolProfile: true,
  // //   })
  // // }
  // // else
  // // {   // alert(raking);   

  // //  this.setState({
  // //     PrilimaryModal:true,
  // //  })

  //}
 // alert(JSON.stringify(kol_id))
   sessionStorage.setItem('kol_id', JSON.stringify(kol_id));    
   console.log('Navigation of clientKolProfile', kol_id);
    this.setState({
      redirectClientKolProfile: true,
    })
  }



  bioViewNav(kol_id,raking) {
   
  // if(raking !=='NA')
  //   {
  
            sessionStorage.setItem('kol_id', JSON.stringify(kol_id));
            console.log('Navigation of ClientViewBio', kol_id);
            this.setState({
              //redirectClientKolProfile: true,
              redirectClientViewBiography: true,
            })
//  }
//   else
//   {  
//    this.setState({
//       PrilimaryModal:true,
//    })


//   }
}


  profileViewNav(kol_id,raking) {    
  
    // if(raking !=='NA')
    // {
  
    sessionStorage.setItem('kol_id', JSON.stringify(kol_id));
    console.log('Navigation of ClientViewProfile', kol_id);
    this.setState({
      //redirectClientKolProfile: true,
      redirectClientViewProfile: true,
    })

  // }
  // else
  // {  
  //  this.setState({
  //     PrilimaryModal:true,
  //  })


  // }
  }

  
  CVViewNav(kol_id,raking,resumeurl) {    
  
    // if(raking !=='NA')
    // {
      window.open(resumeurl,'_blank')
    // sessionStorage.setItem('kol_id', JSON.stringify(kol_id));
    // console.log('Navigation of ClientViewProfile', kol_id);
    // this.setState({
    //   //redirectClientKolProfile: true,
    //   redirectClientViewProfile: true,
    // })

  // }
  // else
  // {  
  //  this.setState({
  //     PrilimaryModal:true,
  //  })


  // }
  }
  

  // nanu t

  search() {
    this.setState({
      ShowBar: true,
      progressBar: true,
    })
    console.log('in Search')
    console.log('project id', this.state.project_id)
    console.log("Speciality ID", this.state.Speciality)
    console.log("Sub Speciality selected ID", this.state.Market)
    console.log("Procedure selected ID", this.state.Market2)

    console.log("Sub Speciality selected ID", this.state.SubSpecialityTypeString)
    console.log("procedure selected ID", this.state.ProcedureTypeString)


    try {

      const url = global.websiteUrl+'/service/KOL_Dashboard/get_FilterSpecificKOLList';
      let formData = new FormData();
      formData.append('project_id', this.state.project_id);
      formData.append('speciality_id', this.state.Speciality);
      formData.append('subspeciality', this.state.SubSpecialityTypeString);
      formData.append('proceduretype', this.state.ProcedureTypeString);


      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      Axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          if (response.data.status == false) {
            Swal.fire({
              icon: 'error',
              text: 'No List Present of Selected Category',
              // text: 'Wrong Username or Password!',
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })

            this.setState({
              Profile: [],
              ShowBar: false,
              progressBar: false,
              SearchModal: false,
              ProcedureType: [],
              ProcedureTypeString: '',
              SubSpecialityType: [],
              SubSpecialityTypeString: '',
            })
          }
          else if (response.data.status == true) {
            console.log(response.data.kol_info);
            console.log(response.data.message);
            this.Profile = response.data.kol_info;
            console.log(JSON.stringify(this.state.Profile));
            this.setState({
              Profile: response.data.kol_info,
              ShowBar: false,
              progressBar: false,
              SearchModal: false,
              ProcedureType: [],
              ProcedureTypeString: '',
              SubSpecialityType: [],
              SubSpecialityTypeString: '',
            })
            console.log('profile length', this.state.Profile.length)
          }
        })
    }
    catch (e) {
      console.log(e);
    }
    this.setState({
      isSearch: false,
    })
  }


  KOLDetails = (cell, row) => {
    var kol_id = row.kol_unique_id;
    var raking=row.aggregate_score;
    
    return (
      <div>
        <a onClick={this.profileInfoNav.bind(this, kol_id,raking)}> 
          
              <button class="btn1 btn-danger btn-block btn-sm btn-labeled" style={{width:'130px',marginTop:'20px', height:'40px'}}>
                View Profile 
              </button>
          
          </a>
      </div>
    )
  }





  // Funtion for displaying 'bio', 'Profile' & 'CV' buttons inside Bootstrap Grid
  linkFollow = (cell, row, rowIndex, formatExtraData) => {
    var kol_id = row.kol_unique_id;
    return (
      <div>

<td class="butns1">
        {          
            // <a               
            //   onClick={this.bioViewNav.bind(this,kol_id,rating)}> 
            //   <button class="btn1 btn-danger btn-block btn-sm btn-labeled" style={{width:'100px'}}>
            //     Bio 
            //   </button>
            // </a>
        }
        {/* {          
            <a               
            onClick={this.profileViewNav.bind(this, kol_id,rating)}> 
              <button class="btn1 btn-danger btn-block btn-sm btn-labeled">
              Profile 
              </button>
            </a>
        } */}
        {/* { 
         
            (row.resume !== null )? ( 
                // <a href={ row.resume } target="_blank"> 
                <a onClick={this.CVViewNav.bind(this, kol_id,rating,row.resume)}> 
               
                  <button class="btn1 btn-danger btn-block btn-sm btn-labeled" >
                    CV
                  </button>
                </a>

            
            ):
            (<div></div>)
          
           
        } */}

        
        </td>
      </div>
    );
  };

  onProjectNameChange = (event) => {
    const { value } = event.target;
    this.onProjectNameFilter(value);
  };

  onSpecilityChange = (event) => {
    const { value } = event.target;
    this.onSpecityFilter(value);    
  };

  onInstitutionChange = (event) => {
    const { value } = event.target;
    this.onInstitutionFilter(value);    
  };

  onexpertiseChange= (event) => {
    const { value } = event.target;
    this.onexpertiseFilter(value);    
  };

  onCountryChange = (event) => {
    const { value } = event.target;
    this.onCountryFilter(value);    
  };
  

  BackToClientProject() {
    this.setState({ RedirectClientProject: true });
  }

  logout = () => {
    console.log("in logout")
    this.setState({ redirect: true })
    sessionStorage.removeItem('client_id')
    sessionStorage.removeItem('client_name')
    sessionStorage.removeItem('email_id')
  }

  // search modal functions
  toggleSearchModal = () => {
    this.setState({
      SearchModal: !this.state.SearchModal,
      ProcedureType: [],
      ProcedureTypeString: '',
      SubSpecialityType: [],
      SubSpecialityTypeString: '',
    });
  }

  // search modal functions
  togglePrilimaryModal = () => {
    this.setState({
      PrilimaryModal: !this.state.PrilimaryModal,
      
    });
  }
  
  toggleBackToHome()
    {
      this.setState({redirecthome:true})
    }

  render() {

  

    if (this.state.redirectClientKolProfile) {
      return (<Redirect to={{
        pathname: '/PClientKolProfile',
      }} />)
    }


    
    if (this.state.RedirectSignUp) {
      return (<Redirect to={{
        pathname: '/SignUp',
      }} />)
    }

    if (this.state.redirect) {
      return (<Redirect to={'/'} />)
    }

    if(this.state.redirectknowmore){

      return( <Redirect to={{
          pathname:'/Profiletypedetails',
      }} /> )
  }
  if(this.state.redirecthome){

    return( <Redirect to={{
        pathname:'/Home',
    }} /> )
}




    // Speciality Type options 
    //let speciality_type = this.state.specialityData;
    let speciality_type = this.state.SpecialityData;
    
    let OptionsSpeciality = speciality_type.map((speciality_type) =>
      // <option key={speciality_type.id} value={speciality_type.id}>
      //   {speciality_type.speciality_type}</option>
      <option key={speciality_type} value={speciality_type.id}>
      {speciality_type.speciality_type}</option>
    );

    
    
    
        // Procedure List
        let procedure_list = this.state.ProcedureData;
        let OptionProcedure = procedure_list.map((procedure_list) =>
          <option key={procedure_list.id} value={procedure_list.procedure_type}>
            {procedure_list.procedure_type}</option>
        );
    

    // For rendering pageination buttons
    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      return (
        <li class="page-item" onClick={handleClick} ><a class="page-link" href="#">{page}</a></li>
      );
    };

    // options for passing values to bootstrap table 2
    const options = {
      pageButtonRenderer,
      paginationSize: 5,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      // firstPageText: 'First',
      prePageText: 'Previous',
      nextPageText: 'Next',
      // lastPageText: 'Last',
      // nextPageTitle: 'First page',
      // prePageTitle: 'Pre page',
      // firstPageTitle: 'Next page',
      // lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      }, {
        text: 'All', value: this.state.Profile.length
      }] // A numeric array is also available. the purpose of above example is custom the text

    };

    // const MyExportCSV = (props) => {
    //   const handleClick = () => {
    //     props.onExport();
    //   };
    //   return (
    //     <div>
    //       <button class="cetas-button" onClick={handleClick}>Export</button>
    //     </div>
    //   );
    // };

    return (
      <div style={{backgroundColor:'#F9F9F9',paddingBottom:'35px', marginTop:'-10px', paddingTop:'30px'}} >
        {this.state.ShowBar ? <Modal isOpen={true} centered>
          <PropagateLoader color={'#A90007'} size='30px' loading='true' />
        </Modal> : null}
        {this.state.progressBar ? <div style={{ position: 'fixed', top: '0' }}>
          <LoadingBar
            progress='100'
            height={3} color="#A90007" transitionTime={4000}
            loaderSpeed={3000} />
        </div> : null}
        <div>

          <ClientHeader/>

         
        
            {/* <div class="container">
            <h4 style={{height:'30px' , fontSize:'20px', fontWeight:'bolder' }} >"{this.state.speciality_type_type}" KOLs </h4>
                           
                            <div class="profile-backoptiopn2">
                              <div class="col-lg-12 col-md-12 col-sm-12 col-12" > */}
                              {/* <p><a href='#' onClick={this.BackToClientProject.bind(this)}>Dashboard</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Opinion Leaders</p> */}
                                     
{/*                  
                            </div>
                        </div>
                      </div>
       */}

       <div class="container-fluid" id="mainClientDashboard" style={{width:'98%' }}   >
            
              <div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-3" >
                  <h4 style={{ height:'30px', fontSize:'20px', fontWeight:'bolder', width:'50%', display:'inline-block' }} >{this.state.speciality_type_type} KOLs </h4>
                  <h4 style={{ height:'30px', float:'right', textAlign:'right', fontSize:'15px', width:'40%' , display:'inline-block'}}>
                    <p><a href='#' onClick={this.toggleBackToHome.bind(this)}>Home</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Opinion Leaders</p>
              
                  </h4>
<div class='row'>
<div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-3" >
           
                  <div class="card" >
                    <div class="card-body" >
                      
                      <div className='gridtable'>
                      {/* <h4 style={{height:'30px' , fontSize:'20px', fontWeight:'bolder' }} >"{this.state.speciality_type_type}" KOLs </h4>
                                         */}
                        <table class='table table-hover' >
                         
                        <ToolkitProvider
                        keyField="kol_unique_id"
                        data={this.state.KOL_Profile}
                        columns={this.state.columns}
                        search={this.customMatchFunc}
                        // //exportCSV
                        // exportCSV={{
                        //   fileName: 'cetas.csv',
                        //   noAutoBOM: false,
                        //   onlyExportSelection: true,
                        //   exportAll: true
                       // }}
                      >
                        {
                          props => (
                            <div  >
                              <BootstrapTable
                                {...props.baseProps}
                                defaultSortDirection="asc"
                                //hover
                                //condensed
                                ////noDataIndication="No Data Is Available"

                                filter={filterFactory()}
                                //selectRow={selectRow}
                               // pagination={paginationFactory(options)}
                               // striped
                                hover
                                condensed
                                tabIndexCell
                                rowStyle={ { backgroundColor: 'White', borderLeft:'solid',  borderLeftColor:'#fff'  , borderBottom:'solid', borderBottomColor:'#EDE9E8' , borderRight:'solid', borderRightColor:'#fff' } }
                              
                              />
                              {/* <hr></hr> */}
                              {/* <ExportCSVButton  class="cetas-button" 
                                                { ...props.csvProps }>Export
                                            </ExportCSVButton> */}
                              <br />
                              {/* <MyExportCSV {...props.csvProps} /> */}
                            </div>
                          )
                        }
                      </ToolkitProvider>
                        </table>

                        <div class="blurimage"  ></div>
                        
                        <div className='blurimage_button' style={{position:'absolute',zIndex:'1000'}} >
                          <a onClick={this.toggleGetQuoteModal.bind(this)} >
                          {/* <a onClick={this.toggleSignupModal.bind(this)} > */}
                        
                            <button class="btn1 btn-danger btn-sm btn-labeled" style={{width:'220px', fontSize:'15px', height:'60px',borderRadius:0,textDecoration:'underline'}}>
                            View more profiles 
                              </button>
                              </a>
                       </div>

                        


                      </div>
                    </div>
                  </div>
</div>
</div>
                </div>
              </div>
            </div>
              
                    

                         {/* kdkd */}
                         <Modal isOpen={this.state.NewQuoteModal} toggle={this.toggleinfoBuyModal.bind(this)} centered>
                                        <ModalHeader toggle={this.toggleinfoBuyModal.bind(this)}
                                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                            style={{ borderBottom: '0px' }}>
                                            <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Get a quote</h3>
                                        </ModalHeader>

                                        {/* <ModalHeader toggle={this.toggleinfoBuyModal.bind(this)}
                                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                            style={{ borderBottom: '0px' , backgroundColor:'#ccc' }}>
                                            <h3 style={{ justifyContent: 'center', marginTop: '20px' }} id="login-tit">Get a quote</h3>
                                        </ModalHeader> */}
                                      
                                        <ModalBody style={{ padding: '0px 70px 0px 70px' }}>

                                           
                                                  <Quoteform></Quoteform>











                                        </ModalBody>
                                        {/* <ModalFooter style={{ borderTop: '0px' , paddingBottom:'40px' }}>
                                            <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '90px' }}
                                                onClick={this.togglecanQuoteinfoModal.bind(this)}>Cancel</button>
                                            <button type="submit" class="cetas-button" style={{ marginRight: '30%', height: '40px', width: '90px'}}
                                                onClick={this.SaveQuoteinfo.bind(this)}>
                                                Next
                                            </button>

                                        </ModalFooter> */}
                         </Modal>






















{/* PrilimaryModal */}

<Modal isOpen={this.state.PrilimaryModal} toggle={this.togglePrilimaryModal.bind(this)} centered>
            <ModalHeader toggle={this.togglePrilimaryModal.bind(this)}
              cssModule={{ 'modal-title': 'w-200 text-center', 'border-bottom': '0px', }}
              style={{ borderBottom: '0px' }}>
              {/* <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">Search KOL</h3> */}
            </ModalHeader>

            <ModalBody>
              <FormGroup>
                <div className="center">
                  <h3> <u>Contact</u></h3>
                  
                </div>
                <div className="center">
                  <h4><a style={{color:'blue'}}> xyz@cetas-healthcare.com</a></h4>
                 
                </div>

                <div className="center">
                  <h4></h4>
                 
                </div>
              </FormGroup>

            </ModalBody>

  </Modal>








          {/* Search Modal */}
          <Modal isOpen={this.state.SearchModal} toggle={this.toggleSearchModal.bind(this)} centered>
            <ModalHeader toggle={this.toggleSearchModal.bind(this)}
              cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
              style={{ borderBottom: '0px' }}>
              <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">Search KOL</h3>
            </ModalHeader>

            <ModalBody>
              <FormGroup>
                <div className="center">
                  <Input type="select" id="roleSelect" className="form-control"
                    style={{ height: '50px', width: '80%' }}
                    value={this.state.Speciality}
                    onChange={async (e) => {
                      let { Speciality } = this.state;
                      Speciality = e.target.value;
                      await this.setState({
                        Speciality,
                        SubSpecialityData: [],
                        ProcedureData: [],
                        ProcedureType: [],
                        ProcedureTypeString: '',
                        SubSpecialityType: [],
                        SubSpecialityTypeString: '',

                        showProcedureFlag: false,
                        showSubSpecialityFlag: false,
                      });
                      { this.getSubSpecialityList() }
                      { this.getProcedureList() }
                    }}
                  >
                    <option value="0" >--Select Speciality--</option>
                    {OptionsSpeciality}
                  </Input>
                </div>
              </FormGroup>

              <FormGroup>
                {/* <div className="center"> */}
                {
                  (this.state.showSubSpecialityFlag == false) ? (
                    <div></div>
                  ) : (
                    <div className="center">
                      <Multiselect
                        style={{ width: '80%' }}
                        data={this.state.SubSpecialityData}
                        placeholder="--Select Sub Speciality--"
                        // defaultValue={this.state.SubSpecialityType}
                        valueField='sub_speciality_type'
                        textField='sub_speciality_type'
                        onSelect={(valueField) => console.log(valueField)}
                        onChange={(event) => {
                          console.log(event);
                          var joined = [];
                          console.log("length : " + event.length);
                          if (event.length === 0) {
                            this.setState({ SubSpecialityType: [] });
                          }
                          event.map(async (data) => {
                            console.log("data in eve map : " + JSON.stringify(data));
                            joined = joined.concat(data.sub_speciality_type);
                            console.log("data join : " + joined);
                            await this.setState({
                              SubSpecialityType: joined
                            });
                            console.log("check this", this.state.SubSpecialityType)

                            var output = this.state.SubSpecialityType.map(function (item) {
                              return item;
                            });

                            let { SubSpecialityTypeString } = this.state;
                            SubSpecialityTypeString = output.join(",");
                            this.setState({ SubSpecialityTypeString });

                            console.log("check this", this.state.SubSpecialityTypeString)
                            console.log(output.join(","))
                          })
                        }}
                      />
                    </div>
                  )
                }
                {/* </div> */}
              </FormGroup>

              <FormGroup>
                {/* <div className="center"> */}
                {
                  (this.state.showProcedureFlag == false) ? (
                    <div></div>
                  ) : (
                    <div className="center">
                      <Multiselect
                        style={{ width: '80%' }}
                        data={this.state.ProcedureData}
                        placeholder="--Select Procedure Type--"
                        // defaultValue={this.state.ProcedureType}
                        valueField='procedure_type'
                        textField='procedure_type'
                        onSelect={(valueField) => console.log(valueField)}
                        onChange={(event) => {
                          console.log(event);
                          var joined = [];
                          console.log("length : " + event.length);
                          if (event.length === 0) {
                            this.setState({ ProcedureType: [] });
                          }
                          event.map(async (data) => {
                            console.log("data in eve map : " + JSON.stringify(data));
                            joined = joined.concat(data.procedure_type);
                            console.log("data join : " + joined);
                            await this.setState({
                              ProcedureType: joined
                            });
                            console.log("check this", this.state.ProcedureType)

                            var output = this.state.ProcedureType.map(function (item) {
                              return item;
                            });

                            let { ProcedureTypeString } = this.state;
                            ProcedureTypeString = output.join(",");
                            this.setState({ ProcedureTypeString });

                            console.log("check this", this.state.ProcedureTypeString)
                            console.log(output.join(","))
                          })
                        }}
                      />
                    </div>
                  )
                }
                {/* </div> */}
              </FormGroup>

            </ModalBody>
            <ModalFooter style={{ borderTop: '0px' }}>
              <button type="submit" class="cetas-secondry-button" style={{ marginRight: '2%', marginTop: '-5px' }}
                onClick={this.toggleSearchModal.bind(this)}>
                Cancel
              </button>
              <button type="submit" class="cetas-button" style={{ marginRight: '15%', marginTop: '-5px' }}
                onClick={this.search.bind(this)}>
                Search
              </button>
            </ModalFooter>
          </Modal>
          

{/* 
          <footer class="footer_not">

                        <div class="cetas-footer">
                            <div class="container" >
                                <p>All rights reserved. www.cetashealthcare.com</p>
                            </div>
                        </div>
            </footer> */}



        </div>
      
        <Pagefooter/>   
      </div>
      
    );
  }
}

export default ClientViewKolList;