import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
//import LoadingBar from "react-top-loading-bar";
import { Chart } from "react-google-charts";
import { Bar, Line } from 'react-chartjs-2';
import ApexChart from './ApexChart';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Multiselect } from 'react-widgets';
import Swal from 'sweetalert2';
//import ClientHeader from './ClientHeader';
import Signup from '../Login/Signupinfo';
//import 'flag-icon-css/css/flag-icon.min.css';
 //import '../styles/summary.css';
// showing progress bar npm 
import { PropagateLoader } from 'react-spinners';
import LoadingBar from "react-top-loading-bar";
import {NumberFormatter} from 'react-number-formatter';

import {otherpageClosePopup} from'../PClient/ClientViewKolList';

import {Clientpagerefresh} from'../Client/ClientViewKolList';
 
class Quoteform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kol_id: '',
            client_name: '',
            client_id:'',
            secondryEmail:'',
            quotation_id:'',
            email_id:'',
            procedureshowtype:false,
             
            // For validations
            // specilityErr: '',
            // procedureErr: '',
            // profile_typeErr: '', 
            // countrieslistErr:'',
            // no_of_kolErr: '',      
            // commentsErr: '',
            // nameErr:'',
            // email_idErr:'',
            // Company_nameErr:'',
            // PhoneErr:'',
          
            
            QuoteData: {
              client_id:'',
              client_name:'',
              kol_id:'',
              specialty_id: '',
              procedure_type: '',
              profile_type: '', 
              countrieslist:[],
              no_of_kol:'',      
              comments: '',
              email_id:'',
              companyname:'',
              phonenumber:'',
              payment_status:'Pending',
              status: 'Pending',              
            },

            userinfoflag:false,

           // state for progress bar
            ShowBar: false,
            progressBar: false,
            QuoteModal:false,
            SignupModal:false,
            infoQuoteModal:false,

            multiplekolquotmodel:false,

            DoctorCompleteName:"",
            ranking:"",
            RedirectSignUp:false,
            RedirectLogin:false,
            redirectknowmore:false,
            DoctorFormattedContactNumber:"",
            DoctorFormattedFaxNumber:"",
            DoctorFormattedAssistentContactNumber:"",
           
//****************** */ Chart Color **************
        annotationfontsize:10,
        annotationfontcolor:'Black',
        annotationfontbold:false,

       legendfontsize:12,
        legendfontcolor:'Black',
        legendfontbold:false,

        titlefontsize:18,
        titlefontcolor:'#035B96',
        titlefontbold:true,


        hAxisfontsize:12,
        hAxisfontcolor:'Black',
        hAxisfontbold:false,

        
        vAxisfontsize:14,
        vAxisfontcolor:'Black',
        vAxisfontbold:true,

        series1color: '#035B96',
      series2color: '#A55B52' ,
      series3color: '#72DADD' ,
      series4color: '#D7B4B0' ,   
      
      
      chartAreawidth:'80%',
      chartAreaheight:'60%',



// End Chart Color *****************

            // ProfileBySession: this.props.location.state.ProfileBySession,
            Redirect:false,
            RedirectFlag: false,

            redirectBackToDashboard:false,
            redirectBackToKolList:false,

            //Flag to show form of selected drawer item
            PersonalInformationFlag: true,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            SummaryFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,

            // to store data fetch from api
            PersonalInformation: {},
            WorkDetails: [],
            Biography: {},
            ProfessionalMemberships: [],
            Education: [],
            AdvisoryBoard: [],
            AwardsHonours: [],
            BooksMonographs: [],
            Commitees: [],
            ClinicalTrialDetails: [],
            EventsDetails: [],
            Grants: [],
            Guidelines: [],
            HospitalAffiliations: [],
            Interactions: [],
            PressItem: [],
            Presentation: [],
            PublicationDetails: [],
            SunshinePayment: [],

            profile_photo: '',
            LanguageSpoken: [],
            BiographyResume: '',
            profile_photo_for_edit: '',
            BiographyResume_edit: '',
            BiographyResumeFileName: '',
            FinalResumeName: '',

            CountryListData: [],
            ProcedureData:[],
            SpecialityData: [],


            ProcedureType: [],

            StateData: [],
            CityData: [],
            EmployeeListData: [],

            // KolRatingScore data
            event_kol_rating: [],
            publication_kol_rating: [],
            ad_board_kol_rating: [],
            guideline_kol_rating: [],
            clinicaltrial_kol_rating: [],

            //piechart data
            event_piechart: [],
            ad_board_piechart: [],
            publication_piechart: [],
            guideline_piechart: [],
            clinicaltrial_piechart: [],

            home_piechart_flag: true,
            

        };
    }

    //Sub Navbar functions of summary piechart
  HomePiechartFun = async () => {
    await this.setState({
      home_piechart_flag: true,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  EventPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: true,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  PublicationPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: true,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  AdboardPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: true,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  GuidelinePiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: true,
      clinicaltrial_piechart_flag: false,
    });
  }
  ClinicaltrialPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: true,
    });
  }

    // Function for drawer side selection
    PersonalInformationFun = async () => {
        await this.setState({
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            SummaryFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            PersonalInformationFlag: true,
        })
    }

    validate = () => {
      let specilityErr = '';
      let procedureErr = '';
      let profiletypeErr='';
      let countryErr = '';
      let noofkolErr = '';
      let commentsErr = '';
      let nameErr = '';
      let emailErr= '';
      let CompanynameErr = '';
      let phoneNumberErr='';
    
           
          if (!this.state.QuoteData.profile_type) {
            profiletypeErr = 'Select Profile Type';
          }   
      
          if (!this.state.QuoteData.specialty_id) {
            specilityErr = 'Select Speciality';
          }
            
          if (this.state.QuoteData.countrieslist.length <= 0) {
              countryErr = 'Select Country';
          }
            
          if (!this.state.QuoteData.no_of_kol) {
            noofkolErr = 'Enter No Of KOL';
          }

          if( this.state.client_id == "")
          {
            if (!this.state.QuoteData.client_name) {
              nameErr = 'Enter Full Name';
            }
        
            if (!this.state.QuoteData.email_id) {
              emailErr = 'Enter Working Email';
            }
            if (!this.state.QuoteData.companyname) {
              CompanynameErr = 'Enter Company Name';
            }

            if (!this.state.QuoteData.phonenumber) {
              phoneNumberErr = 'Enter Phone Number';
            }
            if ( specilityErr || countryErr || noofkolErr || profiletypeErr||nameErr||emailErr||CompanynameErr||phoneNumberErr) {
              this.setState({ specilityErr ,countryErr,noofkolErr,profiletypeErr ,nameErr,emailErr,CompanynameErr,phoneNumberErr});
              return false;
            }
        }
        else
        {
          if ( specilityErr || countryErr || noofkolErr || profiletypeErr) {
            this.setState({ specilityErr ,countryErr,noofkolErr,profiletypeErr});
            return false;
          }

        }
      
      return true;
    }
  
   


    SaveQuoteinfo = async () =>
    {
 //     alert(this.state.QuoteData.procedure_type)
//    const isValid = this.validate();
     const isValid =true;
      if (isValid) {
    
      this.setState({
        ShowBar: true,
      })

      const url = global.websiteUrl+'/service/KOL_landing/save_quote_data';
      let formData = new FormData();

      formData.append('Quote_Info', JSON.stringify(this.state.QuoteData));
      
      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }


      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("lgstatus", JSON.stringify(lgstatus));
          console.log("response message2", response.data.message)
       
          // alert(response.data.status);
          // alert(response.data.quotation_id);
        

          this.setState({
            ShowBar: false,
          })
          if (response.data.status == true) {

            // if(this.state.client_name !="Guest")
            // {
              Swal.fire({
                icon: 'success',
                //text: response.data.message,
                text: ' Thanks for quote,Our experts will get back to you within 24 hours.!',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'

              })

              setTimeout(
                function(){
                  Clientpagerefresh();              
                 // window.location.reload();
                },3000
              );
                          
              
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(error => {
          console.log(error);
        });

      
    }

    }


    redirectknowmore = async () =>
    {
      this.setState({redirectknowmore:true})
     // alert('redirectknowmorehi');
    }

    
   onChangeValue= async (event) => {

          let { QuoteData } = this.state;
          //QuoteData.kol_id = this.state.kol_id;
          QuoteData.profile_type=event.target.value;
          this.setState({ QuoteData });

      
      }

    toggleQuoteModal = async () => {   
       this.setState({QuoteModal:true,})
    }

toggleDeletequoteid()
{
  
  
  this.setState({
    SignupModal:false,
    
  })
  
  let idtoDelete = this.state.quotation_id;
 // alert(idtoDelete);
  if (idtoDelete !== "") {  
    this.setState({
        ShowProcedure: true,
    })
    const url = global.websiteUrl+'/service/KOL_Landing/delete_quote_data';

    let formData = new FormData();
   // formData.append('oper', "del");
    formData.append('id', idtoDelete);

    const config = {
        // headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post(url, formData, config)
        .then(response => {
            console.log(response.data);
           // alert(response.data.status);
            if(response.data.status == true)
            {
              // this.setState({
              //   SignupModal:!this.state.SignupModal,
              //   //toggleSignupModal:!this.state.toggleSignupModal,
              // })
            
            }
          
        })
        .catch(error => {
            console.log(error);
        });
    
  }
 
  

  // this.setState({
  //   SignupModal:!this.state.SignupModal,
  //  // toggleSignupModal:!this.state.toggleSignupModal,
  // })

}
  // toggleinfoBuyModal = async () => {             
  //   this.setState({infoQuoteModal:true,})   
     
  //  }
    
//    togglecanQuoteinfoModal = async () => {             
              
//     this.setState({ :false,})     
   
//  }

    

    toggleBuyModal = async () => {

      sessionStorage.removeItem('Buyer_order')
      sessionStorage.removeItem('Buyer_kol_id')        
      sessionStorage.removeItem('Buyer_client_name')
      sessionStorage.removeItem('Buyer_client_id')

      sessionStorage.setItem('Buyer_order',"NewBuyOrder");
      sessionStorage.setItem('Buyer_kol_id',this.state.kol_id);
      sessionStorage.setItem('Buyer_client_name',this.state.client_name);
      sessionStorage.setItem('Buyer_client_id',this.state.client_id);
      this.setState({infoQuoteModal:true})
     
      // if(this.state.client_name !="Guest")
      // {
      //  //alert(this.state.client_name);
      //  //alert(this.state.kol_id);
      //  this.setState({infoQuoteModal:true})
      //  //alert('Already Login');
      // }
      // else
      // {
      //   // alert(this.state.kol_id);
      //   // alert(this.state.client_name);
      //   // alert(this.state.client_id);
      //   this.setState({
      //     RedirectSignUp:true
      //   // redirectLogin:true
      //   })
      // }       
    
     
   }


     formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;
      
        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, '');
      
        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;
      
        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
      
        if (phoneNumberLength < 4) return  phoneNumber;
      
        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
      
        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        if(phoneNumberLength == 10 )
        {
            return `+${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
              3,
              6
            )}-${phoneNumber.slice(6, 10)}`;
        }
       else if(phoneNumberLength == 11 )
        {
            return `+${phoneNumber.slice(0, 1)} ${phoneNumber.slice(
              1,
              4
            )}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
        }
        else if(phoneNumberLength == 12 )
        {
          return `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
            2,
            5
          )}-${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`;

        }
        else if(phoneNumberLength > 5  )
        {
          
          return + phoneNumber;
        }

        // if(phoneNumberLength == 10 )
        // {
        // return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        //   3,
        //   6
        // )}-${phoneNumber.slice(6, 10)}`;
        // }
        // else
        // {
        //   return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
        //     2,
        //     5
        //   )}-${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`;

        // }
      }










    componentDidMount() {
        let getSession_Kol_Id = sessionStorage.getItem('kol_id')
        if (getSession_Kol_Id !== null) {
            this.setState({
                kol_id: JSON.parse(getSession_Kol_Id),
            })
          
            if(window.location.href.includes('ClientKolProfile') || window.location.href.includes('PClientKolProfile'))
            {
              let { QuoteData } = this.state;
              QuoteData.kol_id = JSON.parse(getSession_Kol_Id);
               this.setState({ QuoteData });

            }
            
        }

        let getSession_Client_Name = sessionStorage.getItem('client_name')
        if (getSession_Client_Name !== null) {
            this.setState({
                client_name: getSession_Client_Name,
            })

            let { QuoteData } = this.state;
                QuoteData.client_name = getSession_Client_Name;
                this.setState({ QuoteData });

        }

        
        let getSession_email_id = sessionStorage.getItem('client_email')
        if (getSession_email_id !== null) {
            this.setState({
              email_id: getSession_email_id,
            })

            let { QuoteData } = this.state;
                QuoteData.email_id = getSession_email_id;
                this.setState({ QuoteData });

        }

       // alert(this.state.QuoteData.email_id);

        let getSession_client_id = sessionStorage.getItem('client_id')
        if (getSession_client_id !== null) {

            this.setState({
              client_id: getSession_client_id,
            
            })

            let { QuoteData } = this.state;
                QuoteData.client_id = getSession_client_id;
            this.setState({ QuoteData });
        }
        else
        {
          this.setState({
            client_id: "",
            
          })
        }
        
        // alert(this.state.client_id);
        // alert(this.state.userinfoflag);

        console.log("kol_id", this.state.kol_id);
        console.log("getSession_Kol_Id", getSession_Kol_Id);

        // if(this.state.client_id == "")
        // {
        //   this.setState({userinfoflag:true,})
        // }
        // else
        // {
        //   th
        // }

        // this.setState({
        //    // LoadingBar:true,
        //     ShowBar: true,
        // })

        const options = {
          method: 'POST',
        }

        //  Speciality type data
        fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
        .then((Response) => Response.json())
        .then((findresponse) => {
          console.log(findresponse)
          var testDataSpeciality = findresponse.data;
          console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
          this.setState({
            SpecialityData: findresponse.data,
          })
        })


    // Country list 
    fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
    .then((Response) => Response.json())
    .then((findresponse) => {
      console.log(findresponse)
      var testDataCountryList = findresponse.Country_Details;
      console.log("testDataCountryList", JSON.stringify(testDataCountryList));
      this.setState({
        CountryListData: findresponse.Country_Details,
       // Client_Unique_id: findresponse.client_system_id,
      })
      //console.log("Client_Unique_id", this.state.Client_Unique_id);
    })

        
 

  //   this.setState({
  //     // LoadingBar:true,
  //      ShowBar: false,
  //  })


            
     
    }

   
    // To get procedure 
 getProcedureList = () => {
  console.log("in Procedure list");
  console.log("speciality_type_id", this.state.QuoteData.specialty_id);

//alert(this.state.QuoteData.Specialty_id);
  const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
  let formData = new FormData();
  formData.append('speciality_type_id', this.state.QuoteData.specialty_id);
  formData.append('search_type', "Procedure");

  const config = {
    //   headers: { 'content-type': 'multipart/form-data' }
  }
  axios.post(url, formData, config)
    .then(response => {
      console.log(response);
      var proceduredata = response.data;
      console.log("proceduredata", JSON.stringify(proceduredata));
      
      if (response.data.status == true) {
        this.setState({
          ProcedureData: response.data.data,
          procedureshowtype:true,
        })
       // alert(this.state.ProcedureData.length)
        //alert(this.state.ProcedureData[0]["procedure_type"])
      }
      else {
        
        //alert("No Procedure Data");
        this.setState({
          ProcedureData: [],
          procedureshowtype:false,
        })
      }
      
      console.log("ProcedureData", this.state.ProcedureData)
    })
    .catch(error => {
      console.log(error);
    });
}
 

// search modal functions
  toggleQuoteModal = () => {
    this.setState({
      QuoteModal: !this.state.QuoteModal,
      
    });
  }

  toggleinfoBuyModal = () => {
    this.setState({
      infoQuoteModal: !this.state.infoQuoteModal,
      QuoteModal:false,
    });
  }
    // rediect funcs
    BackToDashboard() {
        this.setState({ redirectBackToDashboard:true })
    }

    BackToKolList(){
        this.setState({ redirectBackToKolList:true })

    }

    render() {

      

      if (this.state.Redirect) {
        return (<Redirect to={{
            pathname:'/',
        }} />)
    }


        if (this.state.redirectBackToKolList) {
            return (<Redirect to={{
                pathname:'/PClientViewKolList',
            }} />)
        }

        if (this.state.RedirectSignUp) {
          return (<Redirect to={{
            pathname: '/SignUp',
          }} />)
        }

        if(this.state.redirectLogin){

            return( <Redirect to={{
                pathname:'/Login',
            }} /> )
        }

        if(this.state.redirectknowmore){

          return( <Redirect to={{
              pathname:'/Profiletypedetails',
          }} /> )
      }

      let speciality_type = this.state.SpecialityData;
      let OptionsSpeciality = speciality_type.map((speciality_type) =>
        <option key={speciality_type.id} value={speciality_type.id}>
          {speciality_type.speciality_type}</option>
      );

        // Procedure List
    let procedure_list = this.state.ProcedureData;
    let OptionProcedure = procedure_list.map((procedure_list) =>
      <option key={procedure_list.id} value={procedure_list.procedure_type}>
        {procedure_list.procedure_type}</option>
    );

//************ New Chart Data */










        
        return (

          <div >

            
         {this.state.ShowBar ? <Modal isOpen={true} centered>
            <PropagateLoader color={'#A90007'} size='30px' loading='true' />
          </Modal> : null}
          {this.state.progressBar ? <div style={{ position: 'fixed', top: '0' }}>
            <LoadingBar
              progress='100'
              height={3} color="#A90007" transitionTime={4000}
              loaderSpeed={3000} />
          </div> : null} 
  
 <div>        
       
 
           
                        <ModalBody style={{ padding: '0px 0px 0px 0px' }}> 
                              <FormGroup >
                                                <Label for="engStatusIN" style={{ height: '25px',fontSize:'15px',fontWeight:'500'}}> Your Requirements:      </Label>
                                                <Input type="select" id="engStatusIN" className="form-control"
                                                    style={{ height: '40px', width: '100%' ,color:'#949393' ,backgroundColor:'#fff', borderColor:'#ccc' }}
                                                    name="specialty_id"
                                                   // value={this.state.kol_researcher_info.incentive_status}
                                                    onChange={async (e) => {
                                                        let { QuoteData } = this.state;
                                                        QuoteData.specialty_id = e.target.value;
                                                        await this.setState({ QuoteData });
                                                        { this.getProcedureList()}
                                                    }}
                                                >
                                                    <option value="" >Select Speciality*</option>
                                                    {OptionsSpeciality}
                                                </Input>
                                                <div className='Quotblanck'>
                                              {this.state.specilityErr ? this.state.specilityErr : null}
                                            </div>                                          
                                            </FormGroup>

{
(this.state.procedureshowtype == false)?
(<div></div>):(<div>

                                            <FormGroup >
                                                {/* <Label for="engStatusIN" style={{ height: '25px'}} > Select Procedure Type
                                                 </Label> */}

<Multiselect
                                        data={this.state.ProcedureData}
                                        placeholder="--Select Procedure Type--"
                                        valueField='procedure_type'
                                        textField='procedure_type'
                                        defaultValue={this.state.ProcedureType}
                                        onSelect={(valueField) => console.log(valueField)}

                                        onChange={(event) => {
                                          console.log(event);
                                          var joined = [];
                                          console.log("length : " + event.length);
                                          if (event.length === 0) {
                                            this.setState({ ProcedureType: [] });
                                          }
                                          event.map(async (data) => {
                                            console.log("data in eve map : " + JSON.stringify(data));
                                            joined = joined.concat(data.procedure_type);
                                            console.log("data join : " + joined);
                                            await this.setState({
                                              ProcedureType: joined
                                            });
                                            console.log("check this ", this.state.ProcedureType)

                                            var output = this.state.ProcedureType.map(function (item) {
                                              return item;
                                            });

                                            let {QuoteData } = this.state;
                                            QuoteData.procedure_type = output.join(",");
                                            this.setState({ QuoteData});

                                            console.log("check this", this.state.QuoteData.procedure_type)
                                            console.log(output.join(","))
                                          })
                                        }}
                                      />


                                                {/* <Input type="select" id="engStatusIN" className="form-control"
                                                    style={{ height: '40px', width: '100%',color:'#949393' ,backgroundColor:'#fff', borderColor:'#ccc' }}
                                                    name="procedure_type"
                                                    onChange={async (e) => {
                                                        let { QuoteData } = this.state;
                                                        QuoteData.procedure_type = e.target.value;
                                                        await this.setState({ QuoteData });
                                                    }}
                                                    
                                                >
                                                   <option value="" >Select Procedure*</option>
                                                   {OptionProcedure}
                                                    
                                                </Input>
                                                   */}

                                                <div className='Quotblanck'>
                                              {this.state.procedureErr ? this.state.procedureErr : null}
                                            </div>                                          
                                            </FormGroup>
                                            
</div>)
}

<FormGroup>
                                          
                                          {/* <Label for="engStatusIN" style={{ height: '25px'}}> Type of profile <span class="mandatory">*</span>
                                                     </Label>
                                                */}
                                            <div onChange={this.onChangeValue} style={{ height: '30px', width: '100%',color:'#949393' ,backgroundColor:'#fff', borderColor:'#ccc' }}>
                                              <input type="radio" value="Detailedprofile" name="Biowithcontact" /><span className='Radio_button'>Detailed profile </span>
                                              <input type="radio" value="Biowithcontact" name="Biowithcontact" style={{ marginLeft:'5%'}} /> Bio with contact details only

                                            </div>
                                            
                                            <div className='Quotblanck'>
                                              {this.state.profiletypeErr ? this.state.profiletypeErr : null}
                                            </div>
                                          </FormGroup>



                             <FormGroup>
                                
                                {/* <Label for="engStatusIN" style={{ height: '25px'}} > Select Countries <span class="mandatory">*</span>
                                                 </Label> */}
                                                
                                <Multiselect 
                                      data={this.state.CountryListData}
                                      placeholder='Select Country*'
                                      valueField='id'
                                      textField='country_name'
                                      style={{ height: '40px', width: '100%' ,color:'#949393' ,backgroundColor:'#fff', borderColor:'#ccc' }}
                                                  
                                      onSelect={(valueField) => console.log(valueField)}
                                      
                                      onChange={(event) => {
                                        console.log(event);
                                        var joined = [];
                                        //var joined1 = [];
                                        console.log("length : " + event.length);
                                        if (event.length === 0) {
                                         // this.setState({ countrieslist: [] });
                                         let { QuoteData } = this.state;
                                         QuoteData.countrieslist = '';
                                          this.setState({ QuoteData });
                          
                                        }
                                        event.map(async (data) => {
                                          // console.log(data);
                                          console.log("data in eve map : " + JSON.stringify(data));
                                          joined = joined.concat(data.country_name);
                                          console.log("data join : " + joined);
                                          // await this.setState({
                                          //   countrieslist: joined
                                          // });
                                          let { QuoteData } = this.state;
                                            QuoteData.countrieslist =joined.toString();
                                           await this.setState({ QuoteData });
                                          
                                          console.log("check this", this.state.countrieslist);
                                        })

                                        
                                      }}
                                    
                                    />
                                          <div className='Quotblanck'>
                                              {this.state.countryErr ? this.state.countryErr : null}
                                            </div>                                          
                                            </FormGroup>

                                            <FormGroup>
                                            {/* <Label style={{ height: '25px'}} for="category">Number of profiles<span class="mandatory">*</span></Label> */}
                                            <Input style={{ height: '40px'}} placeholder="Number of Profiles*" class="form-control"
                                             value={this.state.QuoteData.no_of_kol} onChange={(e) => {
                                              let { QuoteData } = this.state;
                                              QuoteData.no_of_kol = e.target.value;
                                              this.setState({ QuoteData });
                                            }}
                                            />
                                            <div className='Quotblanck'>
                                              {this.state.noofkolErr ? this.state.noofkolErr : null}
                                            </div>
                                           </FormGroup>


                                           
                                           <FormGroup>
                                            {/* <Label style={{ height: '25px'}} for="category">No. of Basic Profiled KOLs<span class="mandatory">*</span></Label> */}
                                            <Input style={{ height: '40px'}} placeholder="Comments" class="form-control" 
                                            value={this.state.QuoteData.comments} onChange={(e) => {
                                              let { QuoteData } = this.state;
                                              QuoteData.comments = e.target.value;
                                              this.setState({ QuoteData });
                                            }}
                                            />
                                            {/* <div style={{ fontSize: '12', color: 'red' }}>
                                              {this.state.noofbasickolErr ? this.state.noofbasickolErr : null}
                                            </div> */}
                                           </FormGroup>
{
 
(this.state.client_id == "Guest")?
(<div>

  <FormGroup>
    <Label style={{ height: '25px',fontSize:'15px',fontWeight:'500'}} for="category">Your Contact Details:</Label>

    <FormGroup>
    {/* <Label style={{ height: '25px'}} for="category">Your Contact Details:</Label> */}
    <Input style={{ height: '40px'}} placeholder="Company Name*" class="form-control"
     value={this.state.QuoteData.companyname} onChange={(e) => {
      let { QuoteData } = this.state;
      QuoteData.companyname = e.target.value;
      this.setState({ QuoteData });
    }}
    />
    <div className='Quotblanck'>
      {this.state.CompanynameErr ? this.state.CompanynameErr : null}
    </div>
   </FormGroup>

    <Input style={{ height: '40px'}} placeholder="Full Name*" class="form-control"
     value={this.state.QuoteData.client_name} onChange={(e) => {
      let { QuoteData } = this.state;
      QuoteData.client_name = e.target.value;
      this.setState({ QuoteData });
    }}
    />
    <div className='Quotblanck'>
      {this.state.nameErr ? this.state.nameErr : null}
    </div>
   </FormGroup>



   <FormGroup>
    {/* <Label style={{ height: '25px'}} for="category">Your Contact Details:</Label> */}
    <Input style={{ height: '40px'}} placeholder="Work Email*" class="form-control" 
    value={this.state.QuoteData.email_id} onChange={(e) => {
      let { QuoteData } = this.state;
      QuoteData.email_id = e.target.value;
      this.setState({ QuoteData });
    }}
    />
    <div className='Quotblanck'>
      {this.state.emailErr ? this.state.emailErr : null}
    </div>
   </FormGroup>


   
   

   
   <FormGroup>
    {/* <Label style={{ height: '25px'}} for="category">Your Contact Details:</Label> */}
    {/* <Input style={{ height: '40px'}} placeholder="Work Phone*" class="form-control"
     value={this.state.QuoteData.phonenumber} onChange={(e) => {
      let { QuoteData } = this.state;
      QuoteData.phonenumber = e.target.value;
      this.setState({ QuoteData });
    }}
    /> */}
    <PhoneInput
                                  inputStyle={{
                                    width: "100%",
                                    height: "40px",
                                    fontSize: "15px",
                                  }}
                                  width="50%"
                                  country={'in'}
                                  value={this.state.QuoteData.phonenumber}
                                  onChange={(phonenumber) => {
                                    let { QuoteData } = this.state;
                                    QuoteData.phonenumber = phonenumber;
                                    this.setState({ QuoteData });
                                  }}
                                />
    <div className='Quotblanck'>
      {this.state.phoneNumberErr ? this.state.phoneNumberErr : null}
    </div>
   </FormGroup>
</div>):(
<div>
  {/* <h1>{this.state.client_id}</h1> */}
</div>

)
}
                        
  </ModalBody>
  <ModalFooter style={{paddingBottom:'40px', marginRight:'25%'}}>
          <button type="submit" class="cetas-button" style={{height:'40px'}}
              onClick={this.SaveQuoteinfo.bind(this)}>
            {/* onClick={this.closeform.bind(this)}> */}
            
              Send Request
          </button>

  </ModalFooter>



       
                     
</div>
</div>

);
  
    
          
}
}
                            
export default Quoteform;