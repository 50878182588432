import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';

//import '../styles/ClientHeader.css';
//import './Client.css';

class ClientHeader extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            redirect: false,
            client_id: '',
            client_name: '',
            client_email: '',
            kol_id:'',
            project_id:'',

        };        
    }

    componentDidMount = async () => {
        let getSession_Client_Email = await sessionStorage.getItem('client_email')
        if (getSession_Client_Email !== null) {
          await this.setState({
            client_email: getSession_Client_Email,
          })
        }
    
        let getSession_Client_ID = await sessionStorage.getItem('client_id')
        if (getSession_Client_ID !== null) {
          await this.setState({
            client_id: getSession_Client_ID,
          })
        }
        else
        { 
            this.setState({redirect: true})
        }
    
        let getSession_Client_Name = await sessionStorage.getItem('client_name')
        if (getSession_Client_Name !== null) {
          await this.setState({
            client_name: getSession_Client_Name,
          })
        }
        else
        { 
            this.setState({redirect: true})
        }
    

        //alert(this.state.client_name);

        let getSession_Kol_Id = sessionStorage.getItem('kol_id')
        if (getSession_Kol_Id !== null) {
            this.setState({
                kol_id: JSON.parse(getSession_Kol_Id),
            })
        }
    
    
        let getSession_Project_Id = await sessionStorage.getItem('project_id')
        if (getSession_Project_Id !== null) {
          await this.setState({
            project_id: getSession_Project_Id,
          })
        }
    
        console.log("getSession_Client_Email ====", getSession_Client_Email)
        console.log("getSession_Client_ID ====", getSession_Client_ID)
        console.log("getSession_Client_Name ====", getSession_Client_Name)
            
        console.log("getSession_kol_id ====", this.state.kol_id);
        console.log("getSession_Project_Id ===== ", getSession_Project_Id);
    }

    logout = () => {
        console.log("in logout")
        this.setState({redirect: true})
        sessionStorage.removeItem('client_id')
        sessionStorage.removeItem('client_name')
        sessionStorage.removeItem('email_id')
        
        sessionStorage.removeItem('kol_id')
        sessionStorage.removeItem('getSession_Project_Id')
    }


    

    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/'}/>)
        }
        return (
            <div class="Client-header" > 
      
            <div className="header-nav" >
                <div className="container">
                
                <nav class="navbar navbar-light navbar-expand-lg fixed-top" >
                    <div class="container-fluid">
                    
                            <a style={{display: "table-cell"}} className="navbar-brand" 
                            href="https://cetas-healthcare.com/" target = "_blank" rel="noopener">
                            <img
                                src={require('../Assets/Images/cetas_healthcare_logo.svg')} 
                            />  
                            </a>

                          <button className="navbar-toggler" type="button" data-toggle="collapse" 
                                    data-target="#navbarNavAltMarkup" 
                                    aria-controls="navbarNavAltMarkup" aria-expanded="false" 
                                    aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div className="navbar-nav" >
                                {/* <a className="nav-item nav-link" href="#">Home 
                                    <span className="sr-only">(current)</span>
                                </a>
                                <a className="nav-item nav-link active" href="#">Projects</a>
                                <a className="nav-item nav-link" href="#">Team Members</a> */}
                                {/* <a className="nav-item nav-link" >
                                    <img
                                        src={require('../Assets/Images/setting.png')} 
                                    /> 
                                    Search
                                </a> */}
                            </div>
                        </div>
                        <div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
    <div class="navbar-nav">
    
    <div className='logoutbox'>
      <div className='user_signout'><span class="btn-label"><i class="fa fa-user" style={{ marginRight:'10px'}}></i>   </span> {this.state.client_name}</div>      
      
      <button class="button_signout" style={{ fontWeight:'lighter'}} onClick={this.logout.bind(this)}><span class="btn-label" ><i class="fa fa-power-off"></i>  </span> Logout</button>
    </div>
    </div>
  </div>
{/*                         
                        <div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                        <div class="navbar-nav" >                      
                               
                            <a class="nav-link" style={{height:'40px' , marginTop:'-20px'}} aria-current="page" href="#0"  ><span class="btn-label"><i class="fa fa-user"></i>   </span>Welcome,  {this.state.client_name}</a>
                      
                            <button class="btn btn-danger btn-labeled " onClick={this.logout.bind(this)}><span class="btn-label" ><i class="fa fa-power-off"></i>  </span> Logout</button>
                            {/* <button class="nav-link btn btn-danger btn-labeled" style={{height:'40px', marginTop:'20px',paddingLeft:'15px', backgroundColor:'orange' }} onClick={this.logout.bind(this)}><span class="btn-label" ><i class="fa fa-power-off"></i>  </span> Logout</button> */}
                      
                        {/* </div>
                        </div> */}

                    </div>
                    </nav>
</div>

                    {/* <nav className="navbar navbar-expand-lg"> */}
                        {/* <a className="navbar-brand" href="https://cetas-healthcare.com/">
                            <img
                                src={require('../Assets/Images/cetas-logo.png')} 
                            />  
                        // </a> */}
                        {/* <a style={{display: "table-cell"}} className="navbar-brand"  */}
                        {/* //     href="https://cetas-healthcare.com/" target = "_blank" rel="noopener">
                        //     <img
                        //         src={require('../Assets/Images/cetas-logo.png')} 
                        //     />  
                        // </a> */}

                        {/* // <button className="navbar-toggler" type="button" data-toggle="collapse" 
                        //             data-target="#navbarNavAltMarkup" 
                        //             aria-controls="navbarNavAltMarkup" aria-expanded="false" 
                        //             aria-label="Toggle navigation">
                        //     <span className="navbar-toggler-icon"></span>
                        // </button>
                        // <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        //     <div className="navbar-nav" style={{marginLeft:'80%'}}> */}
                                {/* <a className="nav-item nav-link" href="#">Home 
                                    <span className="sr-only">(current)</span>
                                </a>
                                <a className="nav-item nav-link active" href="#">Projects</a>
                                <a className="nav-item nav-link" href="#">Team Members</a> */}
                                {/* <a className="nav-item nav-link" >
                                    <img
                                        src={require('../Assets/Images/setting.png')} 
                                    /> 
                                    Search
                                </a> */}
                            {/* </div>
                        </div>

                        <a className="nav-item nav-link username-box" >
                            <img 
                                src={require('../Assets/Images/user.svg')} 
                            />
                                Welcome,  {this.props.ClientName}
                        </a>
                        

                        <a className="nav-item nav-link username-box  float-right" 
                            onClick={this.logout.bind(this)}>
                            <img 
                                src={require('../Assets/Images/logoutByVibha.svg')} 
                            />
                                Logout
                        </a>

                    </nav> */}
                    
                </div>
            </div> 
        );
    }
}

export default ClientHeader;